import React, { useState, useReducer, useContext, useEffect, useRef, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate, useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { useCookies } from 'react-cookie';
import { useAnimation } from "react-animatable";
import { TwitterShareButton, TwitterIcon } from 'react-share';
import { QRCodeSVG } from 'qrcode.react';
import jsQR from 'jsqr';
import './App.css';
import ReconnectingWebSocket from 'reconnecting-websocket';
import decimal from 'decimal.js';
import * as bip39 from 'bip39';
import BIP32Factory from 'bip32';
import ECPairFactory from 'ecpair';
import ecc from '@bitcoinerlab/secp256k1';
// import * as ecc from 'tiny-secp256k1';
import coininfo from 'coininfo';
import * as bitcoin from 'bitcoinjs-lib';
import * as bitcoinMessage from 'bitcoinjs-message';
import iconMpurse from './mpurse.png';
import iconLogin from './login_black_24dp.svg';
import iconLoginList from './patient_list_FILL0_wght400_GRAD0_opsz48.svg';
import iconSign from './history_edu_FILL0_wght400_GRAD0_opsz48.svg';
import iconCircleLeft from './arrow_circle_left_FILL0_wght400_GRAD0_opsz48.svg';
import iconCircleRight from './arrow_circle_right_FILL0_wght400_GRAD0_opsz48.svg';
import iconSearch from './search_FILL0_wght400_GRAD0_opsz48.svg';
import iconDetail from './more_horiz_FILL0_wght400_GRAD0_opsz48.svg';
import iconInput from './input_circle_FILL0_wght400_GRAD0_opsz48.svg';
import iconOutput from './output_circle_FILL0_wght400_GRAD0_opsz48.svg';
import iconSummon from './taunt_FILL0_wght400_GRAD0_opsz48.svg';
import iconCopy from './content_copy_FILL0_wght400_GRAD0_opsz48.svg';
import iconEdit from './edit_FILL0_wght400_GRAD0_opsz48.svg';
import iconShare from './share_FILL0_wght400_GRAD0_opsz48.svg';
import iconReplay from './live_tv_FILL0_wght400_GRAD0_opsz24.svg';
import iconKeyCard from './playing_cards_FILL0_wght400_GRAD0_opsz24.svg';
import iconQr from './qr_code_2_FILL0_wght400_GRAD0_opsz24.svg';
import iconHeaderFooter from './ad_units_24dp_000000_FILL0_wght400_GRAD0_opsz24.svg';
import iconExpand from './expand_all_24dp_000000_FILL0_wght400_GRAD0_opsz24.svg';
import iconCollapse from './collapse_all_24dp_000000_FILL0_wght400_GRAD0_opsz24.svg';
import iconCheck from './check_24dp_FILL0_wght400_GRAD0_opsz24.svg';
import iconAddress from './slab_serif_24dp_000000_FILL0_wght400_GRAD0_opsz24.svg';
import iconCotto from './cotto.png';
import monaFavicon from './monaFavicon.png';
import roseRed from './roseRed.png';
import roseBlue from './roseBlue.png';
import emblemRed from './emblemRed.png';
import emblemBlue from './emblemBlue.png';
import imageMagicRed from './magicRed.png';
import imageMagicBlue from './magicBlue.png';
import imageCost from './cost.png';
import imageCemetery from './cemetery.png';
import imageHiddenMagic from './hiddenMagic.png';
import imageBulletHole01 from './bulletHole01.png';
import imageCardShopTitle from './cardShopTitle.png';
import imageMonatoka from './monatoka.png';
import imageWhatIsKnightsOfMonadom from './whatIsKnightsOfMonadom.png';
import imageEmilicaBT from './emilicaBT.png';
import imageHalcionBT from './halcionBT.png';
import imageSayokoBT from './sayokoBT.png';
import imageNarumiyakunBT from './narumiyakunBT.png';
import imageOmennoshoujoBT from './omennoshoujoBT.png';
import imageDensetsunoshoujoBT from './densetsunoshoujoBT.png';
import imageAnkokunomaouLuciferBT from './ankokunomaouLuciferBT.png';
import imageNzorichterBT from './nzorichterBT.png';
import imageKurumichanBT from './kurumichanBT.png';
import imageToshinousanBT from './toshinousanBT.png';
import imageCardDetailHarukaMizugiVersion from './cardDetailHarukaMizugiVersion.png';
import imageMonashellBanner from './monashellBanner.png';
import logoWin from './logoWin.png';
import logoLose from './logoLose.png';
import logoTheEnd from './logoTheEnd.png';
import logoDraw from './logoDraw.png';
import logoTitle from './title.png';
import logoMonacotto from './logoMonacotto.png';
// import wallPaper01 from './wallPaper01.png';
// import wallPaper02 from './wallPaper02.png';
import termsAndConditionsUserHtml from './tac_user.static.html';
import privacyPolicyHtml from './privacypolicy.static.link';
import sctHtml from './sct.static.html';
import cardRegistrationGuidelinesHtml from './cardRegistrationGuidelines.static.html';

const words = require('./words.json');
const marginOfSessionTime = 60000;
const bip32 = BIP32Factory(ecc);
const ECPair = ECPairFactory(ecc);
const networkMona = coininfo('MONA').toBitcoinJS();
// const messagePrefixMona = "\x19Monacoin Signed Message:\n";

let GlobalState;
let wallet;


// AppConnect対応
// porting nodejs EventEmitter
const EventEmitter=function(){
  function EventEmitter(){
    this._events=this._events||{};
    this._maxListeners=this._maxListeners||undefined
  }

  EventEmitter.EventEmitter=EventEmitter;
  EventEmitter.prototype._events=undefined;
  EventEmitter.prototype._maxListeners=undefined;
  EventEmitter.defaultMaxListeners=10;

  EventEmitter.prototype.setMaxListeners=function(n){
    if(!isNumber(n)||n<0||isNaN(n))
      throw TypeError("n must be a positive number");

    this._maxListeners=n;
    return this
  };

  EventEmitter.prototype.emit=function(type){
    var er,handler,len,args,i,listeners;
    if(!this._events)
      this._events={};
    if(type==="error"){
      if(!this._events.error||isObject(this._events.error)&&!this._events.error.length){
        er=arguments[1];

        if(er instanceof Error){
          throw er
        }
        else{
          var err=new Error('Uncaught, unspecified "error" event. ('+er+")");
          err.context=er;
          throw err
        }
      }
    }

    handler=this._events[type];

    if(isUndefined(handler))
      return false;

    if(isFunction(handler)){
      switch(arguments.length){
        case 1:
          handler.call(this);
          break;
        case 2:
          handler.call(this,arguments[1]);
          break;
        case 3:
          handler.call(this,arguments[1],arguments[2]);
          break;
        default:args=Array.prototype.slice.call(arguments,1);
          handler.apply(this,args)
      }
    }
    else if(isObject(handler)){
      args=Array.prototype.slice.call(arguments,1);
      listeners=handler.slice();
      len=listeners.length;
      for(i=0;i<len;i++)
        listeners[i].apply(this,args)
    }

    return true
  };

  EventEmitter.prototype.addListener=function(type,listener){
    var m;

    if(!isFunction(listener))
      throw TypeError("listener must be a function");

    if(!this._events)
      this._events={};

    if(this._events.newListener)
      this.emit("newListener",type,isFunction(listener.listener)?listener.listener:listener);

    if(!this._events[type])
      this._events[type]=listener;
    else if(isObject(this._events[type]))
      this._events[type].push(listener);
    else
      this._events[type]=[this._events[type],listener];

    if(isObject(this._events[type])&&!this._events[type].warned){
      if(!isUndefined(this._maxListeners)){
        m=this._maxListeners
      }
      else{
        m=EventEmitter.defaultMaxListeners
      }
      if(m&&m>0&&this._events[type].length>m){
        this._events[type].warned=true;
        console.error("(node) warning: possible EventEmitter memory "+"leak detected. %d listeners added. "+"Use emitter.setMaxListeners() to increase limit.",this._events[type].length);

        if(typeof console.trace==="function"){
          console.trace()
        }
      }
    }

    return this
  };

  EventEmitter.prototype.on=EventEmitter.prototype.addListener;
  EventEmitter.prototype.once=function(type,listener){
    if(!isFunction(listener))
      throw TypeError("listener must be a function");

    var fired=false;

    function g(){
      this.removeListener(type,g);

      if(!fired){
        fired=true;
        listener.apply(this,arguments)
      }
    }

    g.listener=listener;
    this.on(type,g);
    return this
  };

  EventEmitter.prototype.removeListener=function(type,listener){
    var list,position,length,i;

    if(!isFunction(listener))
      throw TypeError("listener must be a function");

    if(!this._events||!this._events[type])
      return this;

    list=this._events[type];
    length=list.length;
    position=-1;

    if(list===listener||isFunction(list.listener)&&list.listener===listener){
      delete this._events[type];
      if(this._events.removeListener)this.emit("removeListener",type,listener)
    }
    else if(isObject(list)){
      for(i=length;i-- >0;){
        if(list[i]===listener||list[i].listener&&list[i].listener===listener){
          position=i;
          break
        }
      }

      if(position<0)
        return this;

      if(list.length===1){
        list.length=0;
        delete this._events[type]
      }
      else{
        list.splice(position,1)
      }

      if(this._events.removeListener)
        this.emit("removeListener",type,listener)
    }

    return this
  };

  EventEmitter.prototype.removeAllListeners=function(type){
    var key,listeners;
    if(!this._events)
      return this;

    if(!this._events.removeListener){
      if(arguments.length===0)
        this._events={};
      else if(this._events[type])
        delete this._events[type];

      return this
    }

    if(arguments.length===0){
      for(key in this._events){
        if(key==="removeListener")
          continue;

        this.removeAllListeners(key)
      }

      this.removeAllListeners("removeListener");
      this._events={};
      return this
    }

    listeners=this._events[type];

    if(isFunction(listeners)){
      this.removeListener(type,listeners)
    }
    else if(listeners){
      while(listeners.length)
        this.removeListener(type,listeners[listeners.length-1])
    }

    delete this._events[type];
    return this
  };

  EventEmitter.prototype.listeners=function(type){
    var ret;

    if(!this._events||!this._events[type])
      ret=[];
    else if(isFunction(this._events[type]))
      ret=[this._events[type]];
    else
      ret=this._events[type].slice();

    return ret
  };

  EventEmitter.prototype.listenerCount=function(type){
    if(this._events){
      var evlistener=this._events[type];

      if(isFunction(evlistener))
        return 1;
      else if(evlistener)
        return evlistener.length
    }
    return 0
  };

  EventEmitter.listenerCount=function(emitter,type){
    return emitter.listenerCount(type)
  };

  function isFunction(arg){
    return typeof arg==="function"
  }

  function isNumber(arg){
    return typeof arg==="number"
  }

  function isObject(arg){
    return typeof arg==="object"&&arg!==null
  }

  function isUndefined(arg){
    return arg===void 0
  }

  return EventEmitter
}();

const ORIGIN = 'https://monapalette.komikikaku.com';
const SUPPORTED_METHODS = ['getAddress', 'sendAsset', 'signRawTransaction', 'signMessage', 'sendRawTransaction', 'counterBlock', 'counterParty'];
const monapaletteConnect = {};
const id2messageListener = {};
const sendMethod = async(method, params) => {
    if (!window.parent) throw new Error('AppConnect not found');
    const id = Math.random().toFixed(10).slice(-10);
    return await new Promise((resolve, reject) => {
        id2messageListener[id] = (event) => {
            try {
                if (event.origin !== ORIGIN) return;
                const data = event.data;
                if (data.id !== id) return;
                delete id2messageListener[id];
                if (data.error) reject(data.error);
                else resolve(data.value);
            }
            catch (error) { reject(error) }
        };
        window.parent.postMessage({ id, method, params }, ORIGIN);
    });
};
for (const method of SUPPORTED_METHODS) monapaletteConnect[method] = (...params) => sendMethod(method, params);
monapaletteConnect.updateEmitter = new EventEmitter();
window.monapaletteConnect = monapaletteConnect;
window.addEventListener('message', (event) => {
    for (const id in id2messageListener) id2messageListener[id](event);
});

// console.log('ancestor', window.location.ancestorOrigins[0]);

if (window.location.ancestorOrigins !== undefined && window.location.ancestorOrigins[0] === ORIGIN) {
  wallet = 'MonaPallet';
}
else {
  wallet = 'Mpurse';
  // wallet = 'MonaPallet';
}


// APP
function App() {
  const urlParams = (new URL(document.location)).searchParams;

  // 検索期間のデフォルト値
  const now = new Date();
  let yearLastMonth;
  let monthLastMonth;
  let dayEndLastMonth;

  if (now.getMonth() === 0) {
    yearLastMonth = now.getFullYear() - 1;
    monthLastMonth = 12;
  }
  else {
    yearLastMonth = now.getFullYear();
    monthLastMonth = now.getMonth();
  }

  if (now.getMonth() === 4 || now.getMonth() === 6 || now.getMonth() === 9 || now.getMonth() === 11) {
    dayEndLastMonth = 30;
  }
  else if (now.getMonth() === 2) {
    if (now.getFullYear() % 4 === 0) {
      dayEndLastMonth = 29;
    }
    else {
      dayEndLastMonth = 28;
    }
  }
  else {
    dayEndLastMonth = 31;
  }


  const initialState = {
    language: urlParams.get('language') === null ? 'japanese' : urlParams.get('language'),
    device: {
      hasCamera: undefined,
    },
    config: {
      clientParameters: {
        versionOfTheTermsAndConditions: 'dummy',
        versionOfPrivacyPolicy: 'dummy',
      },
    },
    configMP: {
      clientParameters: {
      },
    },
    login: {
      addressMain: '',
      displayAddressSection: false,
    },
    configure: {
      addressMain: '',
      displayAddressSection: false,
      userName: '',
      // images: {
      //   main: null,
      // },
      // imagesNew: {
      //   main: null,
      //   mainUrl: null,
      // },
      readTheTermsAndConditions: false,
      readPrivacyPolicy: false,
      acceptedVersionOfTheTermsAndConditions: null,
      acceptedVersionOfPrivacyPolicy: null,
      // profileText: '',
      status: null,
    },
    active: {
      addressMain: undefined,
    },
    balance: {
    },
    webSocketContainer: {
      webSocket: undefined,
    },
    websocket: {
      status: 'close',
    },
    webSocketToBroadcastContainer: {
      webSocket: undefined,
    },
    duel: {
      visible: false,
      textCount: 0,
      text: '',
      chunks: [],
      straightManChunks: [],
      round: 0,
      myStatus: null,
      action: undefined,
      pointA: 0,
      pointB: 0,
      winner: undefined,
      winningMonster: undefined,
      winningMonsterIndex: undefined,
      duelWinner: undefined,
      monstersInformation: undefined,
      monstersInformationRevised: undefined,
      monstersIndex: undefined,
      result: [],
      prefield: undefined,
      maginaPoint: {
        A: 0,
        B: 0,
      },
      timeOutTime: undefined,
      status: undefined,
      basicInformation: undefined,
      castMagics: [],
      magics: {
        A: [],
        B: [],
      },
      initiative: undefined,
      imageNo: {
        A: 0,
        B: 0,
      },
      knightBoxEffect: {
        A: undefined,
        B: undefined,
      },
      selectStatus: {
        mode: 'neutral',
      },
      cardStatusTmp: {
        A: [],
        B: [],
      },
      // {
      //     roomId: roomId,
      //     roomSubId: roomSubId,
      //     gameMode: gameMode,
      //     side: side, // B
      //     duelistA: duelistA,
      //     duelistAUserName: duelistAUserName,
      //     duelistADeck: duelistADeck,
      //     duelistB: duelistB,
      //     duelistBUserName: duelistBUserName,
      //     duelistBDeck: duelistBDeck,
      //     status: duelStatus,
      //     serverTime: now.getTime(),
      // },
    },
    user: {},
    session: {},
    balance: {},
    assetInfo: {},
    monacard: {},
    registeredCard: {},
    deckSet: {},
    purchasePoint: {
      purchaseNo: null,
      addressMain: '',
      addressPayFrom: '',
      amountToBuy: 800,
      signatureByAddressMain: '',
      signatureByAddressPayFrom: '',
      disabled: false,
      status: 'waitingForSignature',
    },
    getPointHistory: {
      addressMain: '',
      action: 'point' ,
      lastEvaluatedKey: {
        point: [],
      },
      pagingIndex: {
        point: 0,
      }
    },
    getDuelHistory: {
      action: 'duelHistoryAll' ,
      lastEvaluatedKey: {
        duelHistoryAll: [],
        duelHistoryAddress: [],
        duelHistorySingle: [], // 使わないけど、nullを受けるために必要。
      },
      pagingIndex: {
        duelHistoryAll: 0,
        duelHistoryAddress: 0,
        duelHistorySingle: 0,
      },
      lastEvaluatedKey2: {
        duelHistoryAll: [], // 使わないけど、nullを受けるために必要。
        duelHistoryAddress: [],
        duelHistorySingle: [], // 使わないけど、nullを受けるために必要。
      },
      duelistAddress: undefined,
      roomId: undefined,
      duelNo: undefined,
      round: 0,
    },
    getDuelRanking: {
      action: {
        difficultyMode: 'advanced',
        gameMode: 'human',
        period: 'monthly',
      },
      lastEvaluatedKey: {
        simple: {
          human: {
            lifetime: [],
            monthly: [],
          },
          computer: {
            lifetime: [],
            monthly: [],
          },
        },
        advanced: {
          human: {
            lifetime: [],
            monthly: [],
          },
          computer: {
            lifetime: [],
            monthly: [],
          },
        },
      },
      pagingIndex: {
        simple: {
          human: {
            lifetime: 0,
            monthly: 0
          },
          computer: {
            lifetime: 0,
            monthly: 0
          },
        },
        advanced: {
          human: {
            lifetime: 0,
            monthly: 0
          },
          computer: {
            lifetime: 0,
            monthly: 0
          },
        },
      },
      lastRank: {
        simple: {
          human: {
            lifetime: [],
            monthly: [],
          },
          computer: {
            lifetime: [],
            monthly: [],
          },
        },
        advanced: {
          human: {
            lifetime: [],
            monthly: [],
          },
          computer: {
            lifetime: [],
            monthly: [],
          },
        },
      },
      records: {
        simple: {
          human: {
            lifetime: {},
            monthly: {},
          },
          computer: {
            lifetime: {},
            monthly: {},
          },
        },
        advanced: {
          human: {
            lifetime: {},
            monthly: {},
          },
          computer: {
            lifetime: {},
            monthly: {},
          },
        },
      },
    },
    getRegisteredItem: {
      action: 'registeredItemAll',
      lastEvaluatedKey: {
        registeredItemAll: [],
      },
      pagingIndex: {
        registeredItemAll: 0,
      },
    },
    searchDateRange: {
      from: {
        epoch: undefined, // new Date(yearLastMonth, monthLastMonth - 1, 1, 0, 0, 0, 0).getTime(),
        year: undefined, // yearLastMonth,
        month: undefined, // monthLastMonth,
        day: undefined, // 1, // now.getDate,
        hours: 0, // now.getHours,
        minutes: 0, // now.getMinutes,
        seconds: 0, // now.getSeconds,
        milliseconds: 0, // now.getMilliseconds,
      },
      to: {
        epoch: undefined, // new Date(yearLastMonth, monthLastMonth - 1, dayEndLastMonth, 23, 59, 59, 999).getTime(),
        year: undefined, // yearLastMonth,
        month: undefined, // monthLastMonth,
        day: undefined, // dayEndLastMonth,
        hours: 23,
        minutes: 59,
        seconds: 59,
        milliseconds: 999,
      },
    },
    notification: {
      body: [ '' ],
      index: 0,
      fixed: false,
      inAnimation: false,
    },
    notificationPersistent: {
      message: null,
      visible: false,
    },
    popup: [
      { type: null, body: null },
      { type: null, body: null },
      { type: null, body: null },
      { type: null, body: null },
    ],
    accessing: false,
    pointHistory: {},
    itemOrderHistory: {},
    pointSum: {},
    duelHistoryAll: {},
    duelHistoryAddress: {},
    duelHistorySingle: {},
    duelRankingAll: {},
    registeredItemAll: {},
    clock: undefined,
    addressCheck: 'strict',
    cardRegistration: {
      asset: '',
      name: '',
      feature: '',
      attribute: '',
      contact: '',
    },
    creatorInformation: {
      creater: '',
      createrText: '',
      monacottoAddressMain: '',
      createrLink: [
        {
          title: '',
          url: '',
        },
        {
          title: '',
          url: '',
        },
        {
          title: '',
          url: '',
        },
      ],
    },
    animation: {
      youWinMona: 'visible',
    },
    knightBoxEffectPieces: {
      A: [],
      B: [],
    },
    checkout: {
      itemsIncart: {},
      mailAddress: '',
      paymentMethod: 'paygent',
    },
    getItemOrderHistory: {
      addressMain: '',
      action: 'itemOrder' ,
      lastEvaluatedKey: {
        itemOrder: [],
      },
      pagingIndex: {
        itemOrder: 0,
      }
    },
    displaySecret: {
      mnemonic: '',
      mnemonicHidden: '',
      wif: '',
      address: '',
      swapMnemonics: '',
      mnemonicToWif: '',
      wifFromMnemonic: '',
    },
    walletType: urlParams.get('wallettype') === null ? 'mpurse' : urlParams.get('wallettype'),
    qrCodeScanner: {
      qrCode: undefined,
    },
    headerFooterPosition: 'fixed',
    activation: {
      addressMainEncrypted: '',
    },
    activationList: {
      action: 'activationHistoryAll' ,
      lastEvaluatedKey: {
        activationHistoryAll: [],
        activationHistoryAddressMainEncrypted: [],
      },
      pagingIndex: {
        activationHistoryAll: 0,
        activationHistoryAddressMainEncrypted: 0,
      },
      // lastEvaluatedKey2: {
      //   activationHistoryAll: [], // 使わないけど、nullを受けるために必要。
      //   activationHistoryAddressMainEncrypted: [],
      // },
      addressMainEncrypted: undefined,
      activationHistoryAll: {},
      activationHistoryAddressMainEncrypted: {},
    },
    challengeDuel: {
      designatedAddressesCsv: '',
      gameOption: {
        straightMan: {
          type: 'none',
        },
      },
    },
    makeUrlToBroadcast: {
      addressMain: null,
      addressOperator: null,
      clientTime: null,
      signature: null,
      url: null,
    },
    keyPairs: {},
  }

  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies();
  GlobalState = React.createContext([state, dispatch, navigate, cookies, setCookie, removeCookie]);

  useEffect( () => {
    const func = async () => {
      let configKeys;
      let config;

      // AppConnect対応
      window.monapaletteConnect.getAddress().then(() => window.mpurse = window.monapaletteConnect).catch(() => {});

      // カメラある？

      hasCamera().then((hasCamera) => {
        dispatch({ type: 'setStateMultiLayers', keys: ['device', 'hasCamera'], value: hasCamera });
        devLog('has camera; ', hasCamera);
      });

      // 各種設定値取得
      // devLog('mainaddress', urlParams.get('mainaddress'));

      // -- cookie情報取得
      // if (cookies.addressCheck !== undefined) {
      //   dispatch({ type: 'setState', key: 'addressCheck', value: cookies.addressCheck });
      // }

      // dispatch({ type: 'setState', key: 'accessing', value: true });

      // const configKeys = ['clientParameters', 'monatokaPointParameters', 'initialNotification', 'blockbook'];
      configKeys = ['clientParameters'];
      config = await getConfig(dispatch, 'KnightsOfMonadom', configKeys , 'config');

      configKeys = ['clientParameters'];
      config = await getConfig(dispatch, 'monatokaPoint', configKeys , 'configMP');

      // 初期通知表示
      // dispatch({ type: 'setStateMultiLayers', keys: ['notification', 'body', 0], value: config.body.initialNotification.value });

      // -- 登録カード情報取得

      getRegisteredCard(state, dispatch);

      // dispatch({ type: 'setState', key: 'accessing', value: false });

      // devLog('domain', document.domain);
      // console.log('domain', document.domain);
      console.log('MONA', JSON.stringify(networkMona));
    };

    func();
  }, []);

  let bottomUp;

  if (wallet === 'MonaPallet') {
    bottomUp =
    <div className="appConnectBottomUp">
    </div>;
  }

  return (
    <div className="App">
      <Routes>
        {/* <Route exact path={ process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/' : '/'} element={<LP />} /> */}
        {/* <Route exact path={ process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/index.html' : '/index.html'} element={<LP />} /> */}
        <Route exact path={ process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/' : '/'} element={<Top />} />
        <Route exact path={ process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/index.html' : '/index.html'} element={<Top />} />
        <Route exact path={ process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/top' : '/top'} element={<Top />} />
        <Route exact path={ process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/builddecks' : '/builddecks'} element={<BuildDeck />} />
        <Route exact path={ process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/duel' : '/duel'} element={<Duel gameMode='human' difficultyMode='simple' />} />
        <Route exact path={ process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/dueladvanced' : '/dueladvanced'} element={<Duel gameMode='human' difficultyMode='advanced' />} />
        <Route exact path={ process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/duelcom' : '/duelcom'} element={<Duel gameMode='computer' difficultyMode='simple' />} />
        <Route exact path={ process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/duelcomadvanced' : '/duelcomadvanced'} element={<Duel gameMode='computer' difficultyMode='advanced' />} />
        <Route exact path={ process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/chargepoints' : '/chargepoints'} element={<ChargePoint />} />
        <Route exact path={ process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/cardregistration' : '/cardregistration'} element={<CardRegistration />} />
        <Route exact path={ process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/cardlist' : '/cardlist'} element={<CardList />} />
        <Route exact path={ process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/duelhistory' : '/duelhistory'} element={<DuelHistory />} />
        <Route exact path={ process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/duelhistorydetail' : '/duelhistorydetail'} element={<DuelHistoryDetailFullScreen />} />
        <Route exact path={ process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/duelranking' : '/duelranking'} element={<DuelRanking />} />
        <Route exact path={ process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/purchaseitems' : '/purchaseitems'} element={<PurchaseItem />} />
        <Route exact path={ process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/itemorderhistory' : '/itemorderhistory'} element={<ItemOrderHistory />} />
        <Route exact path={ process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/checkout' : '/checkout'} element={<CheckOut />} />
        <Route exact path={ process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/setting' : '/setting'} element={<Setting />} />
        <Route exact path={ process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/aboutknightsofmonadom' : '/aboutknightsofmonadom'} element={<AboutKnightsOfMonadom />} />
        <Route exact path={ process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/retailer' : '/retailer'} element={<Retailer />} />
        <Route exact path={ process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/livetext' : '/livetext'} element={<LiveText />} />
      </Routes>
      { bottomUp }
      <NotificationFadingOut />
      <RollingMagic />
    </div>
  );
}

// TOP
function Top() {
  const [state, dispatch, navigate] = useContext(GlobalState);
  let loginStyle;

  if (Object.values(state.session).some( session => session.expirationOfSession > Date.now() )) {
    loginStyle = 'backgroundColorMonacottoPale';
  }
  else {
    loginStyle = 'backgroundColorMonacottoAlmostWhite';
  }

  // TAC文言

  let tacWord;

  if (state.configure.acceptedVersionOfTheTermsAndConditions === state.config.clientParameters.versionOfTheTermsAndConditions &&
      state.configure.acceptedVersionOfPrivacyPolicy === state.config.clientParameters.versionOfPrivacyPolicy) {
    tacWord = words.theTermsAndConditionsAndPrivacypolicyHaveBeenAccepted[state.language];
  }
  else {
    tacWord = words.termsAndConditionsAndPrivacypolicy[state.language];
  }

  // // ログインセクション

  // let loginSection;

  // if (state.walletType === 'mpurse') {
  //   loginSection =
  //   <div>
  //     <div className='visibleMiddleOrMore flexRow justifyContentFlexStart alignItemsCenter marginTop0p5'>
  //       <TextLine3 fieldName='loginAddressMain' keys={['login', 'addressMain']} face={words.login[state.language]} tooltip='true' />
  //       <button className='button1' onClick={ () => handleClickMpurse(state, dispatch, ['login', 'addressMain']) }>
  //         <img className='size2x2' src={iconMpurse} />
  //       </button>
  //       <button className='button1' tabindex='0'
  //         onClick={ () => {
  //           handleClickLogin(state, dispatch, navigate, 'normal');
  //         }}
  //       >
  //         <img className='size2x2' src={iconLogin} />
  //       </button>
  //       <button
  //         className={'button1 ' + loginStyle
  //           /* ( Object.keys(state.session).length > 0 ? ' backgroundColorMonacottoPale' : ' backgroundColorMonacottoAlmostWhite') */
  //         }
  //         onClick={ () => { handleClickViewSession(state, dispatch) } }
  //       >
  //         <img className='size2x2' src={iconLoginList} />
  //       </button>
  //     </div>
  //     <div className='visibleSmallOrLess flexColumn alignItemsFlexStart marginTop0p5'>
  //       <TextLine3 fieldName='loginAddressMain' keys={['login', 'addressMain']} face={words.login[state.language]} tooltip='true' />
  //       <div className='flexRow justifyContentFlexStart alignItemsCenter '>
  //         <button className='button1' onClick={ () => handleClickMpurse(state, dispatch, ['login', 'addressMain']) }>
  //           <img className='size2x2' src={iconMpurse} />
  //         </button>
  //         <button className='button1' tabindex='0'
  //           onClick={ () => {
  //             handleClickLogin(state, dispatch, navigate, 'normal');
  //           }}
  //         >
  //           <img className='size2x2' src={iconLogin} />
  //         </button>
  //         <button className={'button1 ' + loginStyle }
  //           onClick={ () => { handleClickViewSession(state, dispatch) } }
  //         >
  //           <img className='size2x2' src={iconLoginList} />
  //         </button>
  //       </div>
  //       { 
  //         /*
  //         process.env.REACT_APP_ENVIRONMENT === 'dev' ? 
  //           <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
  //             onClick={ () => {
  //               const callback = (keyPairs) => handleClickLogin(state, dispatch, keyPairs);
  //               handleClickScanQr(state, dispatch, callback);
  //             }}
  //           >
  //             QR
  //           </button>
  //         : null
  //         */
  //       }
  //     </div>
  //   </div>
  // }
  // else if (state.walletType === 'nfc') {
  //   loginSection =
  //   <div className='flexRow justifyContentFlexStart alignItemsCenter marginTop0p5'>
  //     <button className='button2 flexRow justifyContentCenter alignItemsCenter '
  //       onClick={ async () => {
  //         const callback = (keyPairs) => handleClickLogin(state, dispatch, navigate, 'normal', keyPairs);
  //         handleClickNfc(state, dispatch, callback);
  //       }}
  //     >
  //       <div>
  //         { words.login[state.language] }
  //       </div>
  //       <div>
  //         <img className='size2x2' src={iconKeyCard} />
  //       </div>
  //     </button>
  //     <button className={'button1 ' + loginStyle}
  //       onClick={ () => { handleClickViewSession(state, dispatch) } }
  //     >
  //       <img className='size2x2' src={iconLoginList} />
  //     </button>
  //   </div>;
  // }
  // else { // qr
  //   loginSection =
  //   <div className='flexRow justifyContentFlexStart alignItemsCenter marginTop0p5'>
  //     <button className='button2 flexRow justifyContentCenter alignItemsCenter '
  //       onClick={ () => {
  //         const callback = (keyPairs) => handleClickLogin(state, dispatch, navigate, 'normal', keyPairs);
  //         handleClickScanQr(state, dispatch, callback);
  //       }}
  //     >
  //       <div>
  //         { words.login[state.language] }
  //       </div>
  //       <div>
  //         <img className='size2x2' src={iconQr} />
  //       </div>
  //     </button>
  //     <button className={'button1 ' + loginStyle}
  //       onClick={ () => { handleClickViewSession(state, dispatch) } }
  //     >
  //       <img className='size2x2' src={iconLoginList} />
  //     </button>
  //   </div>;
  // }

  // // ユーザー登録セクション

  // let registrationSection;

  // if (state.walletType === 'mpurse') {
  //   registrationSection =
  //   <div>
  //     <div className="visibleMiddleOrMore flexColumn borderKOM marginTop1 padding1">
  //       <div className=''>
  //         { words.userRegistration[state.language] }
  //       </div>
  //       {/* address main */}
  //       <div className='flexRow justifyContentFlexStart alignItemsCenter marginTop1 '>
  //         <TextLine3 fieldName='addressMain' keys={['configure', 'addressMain']} face={words.addressMain[state.language]} tooltip={true} />
  //         <button className='button1' tabindex='0' onClick={ () => handleClickMpurse(state, dispatch, ['configure', 'addressMain']) }>
  //           <img className='size2x2' src={iconMpurse} alt='' />
  //         </button>
  //       </div>
  //       {/* user name */}
  //       <div className='flexRow justifyContentFlexStart alignItemsCenter marginTop1 '>
  //         <TextLine3 fieldName='userName' keys={['configure', 'userName']} face={words.userName[state.language]} tooltip={true} />
  //         {/* <button className='dummyPadButton1' disabled={true} /> */}
  //       </div>
  //       {/* terms and conditions */}
  //       <div className='flexColumn alignItemsFlexStart '>
  //         <button className={
  //                   'borderNone backgroundColorWhite heightMin3 textLeft focusEffect01 cursor borderRadius2 riseOut2 marginTopBottom0p5' + (state.configure.readTheTermsAndConditions ? '' : 'colorRed')
  //                 }
  //           tabindex='0'
  //           onClick={ () => {
  //             const popup = { type: 'generalItems', body: <TermsAndConditionsPopup target='user' popupLayer={0} /> };
  //             dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: popup });
  //             dispatch({ type: 'setStateMultiLayers', keys: ['configure', 'readTheTermsAndConditions'], value: true });
  //             dispatch({ type: 'setStateMultiLayers', keys: ['configure', 'readPrivacyPolicy'], value: true });
  //           }}
  //         >
  //           {tacWord}
  //         </button>
  //         {/* <TermsAndConditions /> */}
  //       </div>
  //       {/* register button */}
  //       <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0' onClick={ () => handleClickSetup(state, dispatch) }>
  //         <div>
  //           {words.signByMainAddressToRegister[state.language]}
  //         </div>
  //         <img className='size2x2' src={iconSign} alt='' />
  //       </button>
  //       {/* generate mnemonic */}
  //       { 
  //         process.env.REACT_APP_ENVIRONMENT === 'dev' ? 
  //           <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
  //             onClick={ () => handleClickGenerateMnemonic(state, dispatch) }
  //           >
  //             generate mnemonic
  //           </button>
  //         : null
  //       }
  //     </div>
  //     <div className="visibleSmallOrLess flexColumn borderKOM marginTop1 padding1">
  //       {/* address main */}
  //       <div className='flexColumn alignItemsFlexStart marginTop0p5'>
  //         <TextLine3 fieldName='addressMain' keys={['configure', 'addressMain']} face={words.addressMain[state.language]} tooltip={true} />
  //         <div className='flexRow justifyContentFlexStart alignItemsCenter '>
  //           <button className='button1' tabindex='0' onClick={ () => handleClickMpurse(state, dispatch, ['configure', 'addressMain']) }>
  //             <img className='size2x2' src={iconMpurse} />
  //           </button>
  //         </div>
  //       </div>
  //       {/* user name */}
  //       <div className='flexRow justifyContentFlexStart alignItemsCenter marginTop1 '>
  //         <TextLine3 fieldName='userName' keys={['configure', 'userName']} face={words.userName[state.language]} tooltip={true} />
  //         {/* <button className='dummyPadButton1' disabled={true} /> */}
  //       </div>
  //       {/* terms and conditions */}
  //       <div className='flexColumn alignItemsFlexStart '>
  //         <button className={'borderNone backgroundColorWhite heightMin3 textLeft focusEffect01 cursor borderRadius2 riseOut2 marginTopBottom0p5' + (state.configure.readTheTermsAndConditions ? '' : 'colorRed')}
  //           tabindex='0'
  //           onClick={ () => {
  //             const popup = { type: 'generalItems', body: <TermsAndConditionsPopup target='user' popupLayer={0} /> };
  //             dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: popup });
  //             dispatch({ type: 'setStateMultiLayers', keys: ['configure', 'readTheTermsAndConditions'], value: true });
  //             dispatch({ type: 'setStateMultiLayers', keys: ['configure', 'readPrivacyPolicy'], value: true });
  //           }}
  //         >
  //           {tacWord}
  //         </button>
  //         {/* <TermsAndConditions /> */}
  //       </div>
  //       {/* register button */}
  //       <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0' onClick={ () => handleClickSetup(state, dispatch) }>
  //         <div>
  //           {words.signByMainAddressToRegister[state.language]}
  //         </div>
  //         <img className='size2x2' src={iconSign} />
  //       </button>
  //       {/* generate mnemonic */}
  //       { 
  //         process.env.REACT_APP_ENVIRONMENT === 'dev' ? 
  //           <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
  //             onClick={ () => handleClickGenerateMnemonic(state, dispatch) }
  //           >
  //             generate mnemonic
  //           </button>
  //         : null
  //       }
  //       { 
  //         /*
  //         process.env.REACT_APP_ENVIRONMENT === 'dev' ? 
  //           <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
  //             onClick={ () => {
  //               const callback = (keyPairs) => handleClickSetup(state, dispatch, keyPairs);
  //               handleClickScanQr(state, dispatch, callback);
  //             }}
  //           >
  //             QR
  //           </button>
  //         : null
  //         */
  //       }
  //     </div>
  //   </div>;
  // }
  // else if (state.walletType === 'nfc') {
  //   registrationSection =
  //   <div className="flexColumn borderKOM marginTop1 padding1">
  //     {/* user name */}
  //     <div className='flexRow justifyContentFlexStart alignItemsCenter marginTop1 '>
  //       <TextLine3 fieldName='userName' keys={['configure', 'userName']} face={words.userName[state.language]} tooltip={true} />
  //       {/* <button className='dummyPadButton1' disabled={true} /> */}
  //     </div>
  //     {/* terms and conditions */}
  //     <div className='flexColumn alignItemsFlexStart '>
  //       <button className={'borderNone backgroundColorWhite heightMin3 textLeft focusEffect01 cursor borderRadius2 riseOut2 marginTopBottom0p5' + (state.configure.readTheTermsAndConditions ? '' : 'colorRed')}
  //         tabindex='0'
  //         onClick={ () => {
  //           const popup = { type: 'generalItems', body: <TermsAndConditionsPopup target='user' popupLayer={0} /> };
  //           dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: popup });
  //           dispatch({ type: 'setStateMultiLayers', keys: ['configure', 'readTheTermsAndConditions'], value: true });
  //           dispatch({ type: 'setStateMultiLayers', keys: ['configure', 'readPrivacyPolicy'], value: true });
  //         }}
  //       >
  //         {tacWord}
  //       </button>
  //       {/* <TermsAndConditions /> */}
  //     </div>
  //     {/* register with NFC */}
  //     <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
  //       onClick={ () => {
  //         const callback = (keyPairs) => handleClickSetup(state, dispatch, keyPairs);
  //         handleClickNfc(state, dispatch, callback);
  //       }}
  //     >
  //       <div>
  //         {words.signByMainAddressToRegister[state.language]}
  //       </div>
  //       <div>
  //         <img className='size2x2' src={iconKeyCard} />
  //       </div>
  //     </button>
  //   </div>;
  // }
  // else { // qr
  //   registrationSection =
  //   <div className="flexColumn borderKOM marginTop1 padding1">
  //     {/* user name */}
  //     <div className='flexRow justifyContentFlexStart alignItemsCenter marginTop1 '>
  //       <TextLine3 fieldName='userName' keys={['configure', 'userName']} face={words.userName[state.language]} tooltip={true} />
  //       {/* <button className='dummyPadButton1' disabled={true} /> */}
  //     </div>
  //     {/* terms and conditions */}
  //     <div className='flexColumn alignItemsFlexStart '>
  //       <button className={'borderNone backgroundColorWhite heightMin3 textLeft focusEffect01 cursor borderRadius2 riseOut2 marginTopBottom0p5' + (state.configure.readTheTermsAndConditions ? '' : 'colorRed')}
  //         tabindex='0'
  //         onClick={ () => {
  //           const popup = { type: 'generalItems', body: <TermsAndConditionsPopup target='user' popupLayer={0} /> };
  //           dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: popup });
  //           dispatch({ type: 'setStateMultiLayers', keys: ['configure', 'readTheTermsAndConditions'], value: true });
  //           dispatch({ type: 'setStateMultiLayers', keys: ['configure', 'readPrivacyPolicy'], value: true });
  //         }}
  //       >
  //         {tacWord}
  //       </button>
  //       {/* <TermsAndConditions /> */}
  //     </div>
  //     {/* register with QR */}
  //     <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
  //       onClick={ () => {
  //         const callback = (keyPairs) => handleClickSetup(state, dispatch, keyPairs);
  //         handleClickScanQr(state, dispatch, callback);
  //       }}
  //     >
  //       <div>
  //         {words.signByMainAddressToRegister[state.language]}
  //       </div>
  //       <div>
  //         <img className='size2x2' src={iconQr} />
  //       </div>
  //     </button>
  //   </div>;
  // }

  // ウォレット選択ボタン

  let walletSelectBox;

  const mpurseButton =
  <button className={'box3 focusEffect01 riseOut2 ' + (state.walletType === 'mpurse' ? 'colorRed borderSelected' : 'borderNone')}
    tabindex='0'
    onClick={ () => {
      dispatch({type: 'setState', key: 'walletType', value: 'mpurse'});
    }}
  >
    <div>
      {words.mpurse[state.language]}
    </div>
    <div>
      {words.monapalette[state.language]}
    </div>
  </button>;

  const nfcButton =
  <button className={'box3 focusEffect01 riseOut2 ' + (state.walletType === 'nfc' ? 'colorRed borderSelected' : 'borderNone')}
    tabindex='0'
    onClick={ () => {
      dispatch({type: 'setState', key: 'walletType', value: 'nfc'});
    }}
  >
    <div>
      {words.keyCard[state.language]}
    </div>
    <div>
      {words.nfc[state.language]}
    </div>
  </button>;

  const bcButton =
  <button className={'box3 focusEffect01 riseOut2 ' + (state.walletType === 'qr' ? 'colorRed borderSelected' : 'borderNone')}
    tabindex='0'
    onClick={ () => {
      dispatch({type: 'setState', key: 'walletType', value: 'qr'});
    }}
  >
    <div>
      {words.keyCard[state.language]}
    </div>
    <div>
      {words.bc[state.language]}
    </div>
  </button>

  if ('NDEFReader' in window && state.device.hasCamera) {
    walletSelectBox =
    <div className='flexRow justifyContentSpaceAround widthMax '>
      { mpurseButton }
      { nfcButton }
      { bcButton }
    </div>;
  }
  else if ('NDEFReader' in window) {
    walletSelectBox =
    <div className='flexRow justifyContentSpaceAround widthMax '>
      { mpurseButton }
      { nfcButton }
    </div>;
  }
  else if (state.device.hasCamera) {
    walletSelectBox =
    <div className='flexRow justifyContentSpaceAround widthMax '>
      { mpurseButton }
      { bcButton }
    </div>;
  }
  else {
    walletSelectBox = null;
  }

  // ユーザー登録ボタン

  const registrationButton =
  <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter '
    onClick={ () => {
      const popup = { type: 'userRegistrationPopup' };
      dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: popup });
    }}
  >
    { words.userRegistration[state.language] }
  </button>;


  return (
    <div className=''>
      {/* PC */}
      <div className="visibleMiddleOrMore mainKOM">
        <div className="flexColumn alignItemsCenter relative">
          <div>
            { process.env.REACT_APP_ENVIRONMENT === 'dev' ? 'dev' : null }
          </div>
          <div className="flexColumn alignItemsCenter margin1">
            <img className='width60' src={logoTitle} alt='' />
          </div>
          <div className="flexRow justifyContentCenter marginBottom1">
            <button className='backgroundColorTransparent borderNone cursor '
              onClick={ () => navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/aboutknightsofmonadom' : '/aboutknightsofmonadom') }
            >
              <img className='width35' src={imageWhatIsKnightsOfMonadom} alt='' />
            </button>
            <button className='backgroundColorTransparent borderNone cursor '
              onClick={ () => window.open('https://' + process.env.REACT_APP_MONASHELL_MAIN_URL) }
            >
              <img className='width35' src={imageMonashellBanner} alt='' />
            </button>
          </div>
          <div className="width60 ">
            <NotificationPersistent />
          </div>
          <div className="flexRow justifyContentCenter marginBottom1 width60 ">
            { walletSelectBox }
          </div>
          <div className="flexRow justifyContentCenter ">
            <div className="flexColumn alignItemsFlexStart">
              <LoginSection />
              <div className="marginTop1">
                { registrationButton }
              </div>
            </div>
            <div className="flexColumn alignItemsFlexStart">
              <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter '
                onClick={ () => {
                  window.open('https://note.com/jungjung/n/nd8b9667dde83');
                }}
              >
                { words.howToPlay[state.language] }
              </button>
              <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter '
                onClick={ () => {
                  const popup = { type: 'challengeDuelPopup' };
                  dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: popup });
                }}
              >
                { words.playDuel[state.language] }
              </button>
              <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' onClick={ () => handleClickBuildDeck(state, dispatch, navigate) }>
                { words.decks[state.language] }
              </button>
              <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' onClick={ () => handleClickDuelHistory(state, dispatch, navigate) }>
                { words.duelHistory[state.language] }
              </button>
              <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' onClick={ () => handleClickDuelRanking(state, dispatch, navigate) }>
                { words.duelRanking[state.language] }
              </button>
            </div>
            <div className="flexColumn alignItemsFlexStart">
            </div>
            <div className="flexColumn alignItemsFlexStart">
              <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' onClick={ () => handleClickCardList(state, dispatch, navigate) }>
                { words.cardList[state.language] }
              </button>
              <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter '
                onClick={ () => {
                  const body =
                  <div className='flexRow justifyContentCenter alignItemsCenter ' >
                    <button className='buttonMainColor widthMin20 heightMin4 flexColumn justifyContentCenter alignItemsCenter '
                      onClick={ () => {
                        if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
                          window.open('https://monacotto.monatoka.com/redirect?mainaddress=M8uspWdxFbDGgV84PE2tUUGHJPUEXA39Uu');
                        }
                        else {
                          window.open('https://dev.monacotto.monatoka.com/build/redirect?mainaddress=MJehBJHrp8W46XFNpr2QZiy9vRqMVKNpPq');
                        }

                        dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: { type: null, body: null } }); 
                      }}
                    >
                      <div>
                        { words.purchaseCards[state.language] }
                      </div>
                      <div>
                        { words.purchaseInMonacoin[state.language] }
                      </div>
                    </button>
                    <button className='buttonMainColor widthMin20 heightMin4 flexColumn justifyContentCenter alignItemsCenter '
                      onClick={ () => {
                        handleClickPurchaseItem(state, dispatch, navigate);
                        dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: { type: null, body: null } }); 
                      }}
                    >
                      <div>
                        { words.purchaseCardsAndMonatokaPoints[state.language] }
                      </div>
                      <div>
                        { words.purchaseInJpy[state.language] }
                      </div>
                    </button>
                    <button className='buttonMainColor widthMin20 heightMin4 flexColumn justifyContentCenter alignItemsCenter '
                      onClick={ () => {
                        handleClickItemOrderHistory(state, dispatch, navigate);
                        dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: { type: null, body: null } }); 
                      }}
                    >
                      <div>
                        { words.purchaseHistoryOfCardsAndMonatokaPoints[state.language] }
                      </div>
                      <div>
                        { words.purchaseInJpy[state.language] }
                      </div>
                    </button>
                    <button className='buttonMainColor widthMin20 heightMin4 flexColumn justifyContentCenter alignItemsCenter '
                      onClick={ () => {
                        window.open('https://monatoka.booth.pm/');

                        dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: { type: null, body: null } }); 
                      }}
                    >
                      <div>
                        { words.monadomKeyCard[state.language] }
                      </div>
                    </button>
                    <button className='buttonMainColor widthMin20 heightMin4 flexColumn justifyContentCenter alignItemsCenter '
                      onClick={ () => {
                        handleClickChargePoint(state, dispatch, navigate);
                        dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: { type: null, body: null } }); 
                      }}
                    >
                      { words.monatokaPoint[state.language] }
                    </button>
                  </div>;

                  const popup = { type: 'generalItems', body: body };
                  dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: popup });
                }}
              >
                { words.purchaseCardsAndMonatokaPoints[state.language] }
              </button>
              {
                process.env.REACT_APP_ENVIRONMENT === 'dev' ? 
                <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter '
                  onClick={ () => {
                    if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
                      window.open('https://monachen.monatoka.com/');
                    }
                    else {
                      window.open('https://dev.monachen.monatoka.com/build/index.html');
                    }
                  }}
                >
                  { words.iWantMonacoin[state.language] }
                </button>
                : null
              }
              <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' onClick={ () => handleClickCardRegistration(state, dispatch, navigate) }>
                { words.cardRegistration[state.language] }
              </button>
              <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' onClick={ () => handleClickSetting(state, dispatch, navigate) }>
                { words.setting[state.language] }
              </button>
            </div>
          </div>
          {/* development */}
          { process.env.REACT_APP_ENVIRONMENT === 'dev' ? <ForDevelopment /> : null }
        </div>
        {/* footer */}
        <Footer />
      </div>
      {/* SP */}
      <div className="flexColumn visibleSmallOrLess mainKOM ">
        <div className="flexColumn alignItemsCenter relative widthMax">
          <div>
            { process.env.REACT_APP_ENVIRONMENT === 'dev' ? 'dev' : null }
          </div>
          <div className="flexColumn alignItemsCenter widthMax">
            <img className='widthMax' src={logoTitle} alt='' />
          </div>
          <div className="flexColumn alignItemsCenter marginTopBottom0p5">
            <button className='backgroundColorTransparent borderNone '
              onClick={ () => navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/aboutknightsofmonadom' : '/aboutknightsofmonadom') }
            >
              <img className='width60vw' src={imageWhatIsKnightsOfMonadom} alt='' />
            </button>
            <button className='backgroundColorTransparent borderNone cursor '
              onClick={ () => window.open('https://' + process.env.REACT_APP_MONASHELL_MAIN_URL) }
            >
              <img className='width75vw' src={imageMonashellBanner} alt='' />
            </button>
          </div>
          <div className='flexColumn widthMax padding0p5 marginTopBottom0p5 '>
            { walletSelectBox }
          </div>
          <LoginSection />
          <NotificationPersistent />
          <div className="marginTop1">
            { registrationButton }
          </div>
          <div className="flexColumn alignItemsFlexStart">
            <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter '
              onClick={ () => {
                window.open('https://note.com/jungjung/n/nd8b9667dde83');
              }}
            >
              { words.howToPlay[state.language] }
            </button>
            <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter '
              onClick={ () => {
                const popup = { type: 'challengeDuelPopup' };
                dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: popup });
              }}
            >
              { words.playDuel[state.language] }
            </button>
            {/*
              <button className='buttonMainColor widthMin20 heightMin4 flexColumn justifyContentCenter alignItemsCenter ' onClick={ () => handleClickChallengeDuel(state, dispatch, navigate, 'human', 'advanced') }>
                <div>
                  { words.duelAgainstHuman[state.language] }
                </div>
                <div>
                  { `(${words.advancedMode[state.language]})` }
                </div>
              </button>
              <button className='buttonMainColor widthMin20 heightMin4 flexColumn justifyContentCenter alignItemsCenter ' onClick={ () => handleClickChallengeDuel(state, dispatch, navigate, 'computer', 'advanced') }>
                <div>
                  { words.duelAgainstComputer[state.language] }
                </div>
                <div>
                  { `(${words.advancedMode[state.language]})` }
                </div>
              </button>
              <button className='buttonMainColor widthMin20 heightMin4 flexColumn justifyContentCenter alignItemsCenter ' onClick={ () => handleClickChallengeDuel(state, dispatch, navigate, 'human') }>
                <div>
                  { words.duelAgainstHuman[state.language] }
                </div>
                <div>
                  { `(${words.simpleMode[state.language]})` }
                </div>
              </button>
              <button className='buttonMainColor widthMin20 heightMin4 flexColumn justifyContentCenter alignItemsCenter ' onClick={ () => handleClickChallengeDuel(state, dispatch, navigate, 'computer') }>
                <div>
                { words.duelAgainstComputer[state.language] }
                </div>
                <div>
                  { `(${words.simpleMode[state.language]})` }
                </div>
              </button>
            */}
            <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' onClick={ () => handleClickBuildDeck(state, dispatch, navigate) }>
              { words.decks[state.language] }
            </button>
            <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' onClick={ () => handleClickDuelHistory(state, dispatch, navigate) }>
              { words.duelHistory[state.language] }
            </button>
            <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' onClick={ () => handleClickDuelRanking(state, dispatch, navigate) }>
              { words.duelRanking[state.language] }
            </button>
          </div>
          <div className="flexColumn alignItemsFlexStart">
            <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' onClick={ () => handleClickCardList(state, dispatch, navigate) }>
              { words.cardList[state.language] }
            </button>
            <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter '
              onClick={ () => {
                const body =
                <div className='flexColumn justifyContentCenter alignItemsCenter ' >
                  <button className='buttonMainColor widthMin20 heightMin4 flexColumn justifyContentCenter alignItemsCenter '
                    onClick={ () => {
                      if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
                        window.open('https://monacotto.monatoka.com/redirect?mainaddress=M8uspWdxFbDGgV84PE2tUUGHJPUEXA39Uu');
                      }
                      else {
                        window.open('https://dev.monacotto.monatoka.com/build/redirect?mainaddress=MJehBJHrp8W46XFNpr2QZiy9vRqMVKNpPq');
                      }

                      dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: { type: null, body: null } }); 
                    }}
                  >
                    <div>
                      { words.purchaseCards[state.language] }
                    </div>
                    <div>
                      { words.purchaseInMonacoin[state.language] }
                    </div>
                  </button>
                  <button className='buttonMainColor widthMin20 heightMin4 flexColumn justifyContentCenter alignItemsCenter '
                    onClick={ () => {
                      handleClickPurchaseItem(state, dispatch, navigate);
                      dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: { type: null, body: null } }); 
                    }}
                  >
                    <div>
                      { words.purchaseCardsAndMonatokaPoints[state.language] }
                    </div>
                    <div>
                      { words.purchaseInJpy[state.language] }
                    </div>
                  </button>
                  <button className='buttonMainColor widthMin20 heightMin4 flexColumn justifyContentCenter alignItemsCenter '
                    onClick={ () => {
                      handleClickItemOrderHistory(state, dispatch, navigate);
                      dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: { type: null, body: null } }); 
                    }}
                  >
                    <div>
                      { words.purchaseHistoryOfCardsAndMonatokaPoints[state.language] }
                    </div>
                    <div>
                      { words.purchaseInJpy[state.language] }
                    </div>
                  </button>
                  <button className='buttonMainColor widthMin20 heightMin4 flexColumn justifyContentCenter alignItemsCenter '
                    onClick={ () => {
                      window.open('https://monatoka.booth.pm/');

                      dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: { type: null, body: null } }); 
                    }}
                  >
                    <div>
                      { words.monadomKeyCard[state.language] }
                    </div>
                  </button>
                  <button className='buttonMainColor widthMin20 heightMin4 flexColumn justifyContentCenter alignItemsCenter '
                    onClick={ () => {
                      handleClickChargePoint(state, dispatch, navigate);
                      dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: { type: null, body: null } }); 
                    }}
                  >
                    { words.monatokaPoint[state.language] }
                  </button>
                </div>;

                const popup = { type: 'generalItems', body: body };
                dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: popup });
              }}
            >
              { words.purchaseCardsAndMonatokaPoints[state.language] }
            </button>
            <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' onClick={ () => handleClickCardRegistration(state, dispatch, navigate) }>
              { words.cardRegistration[state.language] }
            </button>
            <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' onClick={ () => handleClickSetting(state, dispatch, navigate) }>
              { words.setting[state.language] }
            </button>
          </div>
          {/*
            <div className="flexColumn alignItemsFlexStart">
              <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter '
                onClick={ () => {
                  if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
                    window.open('https://monacotto.monatoka.com/redirect?mainaddress=M8uspWdxFbDGgV84PE2tUUGHJPUEXA39Uu');
                  }
                  else {
                    window.open('https://dev.monacotto.monatoka.com/build/redirect?mainaddress=MJehBJHrp8W46XFNpr2QZiy9vRqMVKNpPq');
                  }
                }}
              >
                { words.purchaseCards[state.language] }
              </button>
              <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' onClick={ () => handleClickPurchaseItem(state, dispatch, navigate) }>
                { words.purchaseCards[state.language] }
              </button>
              <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' onClick={ () => handleClickItemOrderHistory(state, dispatch, navigate) }>
                { words.purchaseCards[state.language] }
              </button>
              <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' onClick={ () => handleClickChargePoint(state, dispatch, navigate) }>
                { words.monatokaPoint[state.language] }
              </button>
            </div>
          */}
          {/* development */}
          { process.env.REACT_APP_ENVIRONMENT === 'dev' ? <ForDevelopment /> : null }
        </div>
        {/* footer */}
        <Footer />
      </div>
      {/* popup */}
      <Popup layer={0}/>
      <Popup layer={1}/>
    </div>
  );
}

// FOOTER
function Footer() {
  const [state, dispatch, navigate] = useContext(GlobalState);

  return (
    <div className='footer'>
      <button className={'borderNone backgroundColorTransparent marginSide1 focusEffect01 cursor'} tabindex='0'
        onClick={ () => {
          window.open('https://' + process.env.REACT_APP_ABOUT_US);
        }}
      >
        {words.aboutUs[state.language]}
      </button>
      <button className={'borderNone backgroundColorTransparent marginSide1 focusEffect01 cursor'} tabindex='0'
        onClick={ () => {
          const popup = { type: 'generalItems', body: <div dangerouslySetInnerHTML={ { __html: sctHtml } } /> };
          dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: popup });
        }}
      >
        {words.notationBasedOnTheActOnSpecifiedCommercialTransactions[state.language]}
      </button>
      {/*
        <button className={'borderNone backgroundColorTransparent marginSide1 focusEffect01 cursor'} tabindex='0'
          onClick={ () => {
            window.open('https://spotlight.soy/detail?article_id=ckkgeevvg');
          }}
        >
          {words.guideline[state.language]}
        </button>
      */}
    </div>
  );
}

/*
// TEMS AND CONDITIONS
function TermsAndConditions() {
  const [state, dispatch] = useContext(GlobalState);

  if (state.configure.acceptedVersionOfTheTermsAndConditions !== state.config.clientParameters.versionOfTheTermsAndConditions ||
      state.configure.acceptedVersionOfPrivacyPolicy !== state.config.clientParameters.versionOfPrivacyPolicy) {
    return (
      <button className='borderNone backgroundColorWhite textLeft focusEffect01 colorRed cursor borderRadius2 riseOut2 marginTopBottom0p5' tabindex='0'
        onClick={ () => {
          if (state.configure.readTheTermsAndConditions === true) {
            dispatch({ type: 'setStateMultiLayers', keys: ['configure', 'acceptedVersionOfTheTermsAndConditions'], value: state.config.clientParameters.versionOfTheTermsAndConditions });
          }

          if (state.configure.readPrivacyPolicy === true) {
            dispatch({ type: 'setStateMultiLayers', keys: ['configure', 'acceptedVersionOfPrivacyPolicy'], value: state.config.clientParameters.versionOfPrivacyPolicy });
          }
        }
      }>
        {words.iAcceptTheTermsAndConditionsAndPrivacypolicy[state.language]}
      </button>
    );
  }
  else {
    return (
      <button className='borderNone backgroundColorWhite textLeft focusEffect01 borderRadius2 riseOut2 marginTopBottom0p5'>
          {words.theTermsAndConditionsAndPrivacypolicyHaveBeenAccepted[state.language]}
      </button>
    );
  }
}
*/

// ACCEPTANCE OF TEMS AND CONDITIONS
function AcceptanceOfTermsAndConditions(props) {
  const [state, dispatch] = useContext(GlobalState);
  const popupLayer = props.popupLayer;

  if (state.configure.acceptedVersionOfTheTermsAndConditions !== state.config.clientParameters.versionOfTheTermsAndConditions ||
      state.configure.acceptedVersionOfPrivacyPolicy !== state.config.clientParameters.versionOfPrivacyPolicy) {
    return (
      <button className='flexColumn justifyContentCenter alignItemsCenter borderNone backgroundColorWhite heightMin3 textLeft focusEffect01 colorRed cursor borderRadius2 riseOut2 marginTopBottom0p5' tabindex='0'
        onClick={ () => {
            dispatch({ type: 'setStateMultiLayers', keys: ['configure', 'acceptedVersionOfTheTermsAndConditions'], value: state.config.clientParameters.versionOfTheTermsAndConditions });
            dispatch({ type: 'setStateMultiLayers', keys: ['configure', 'acceptedVersionOfPrivacyPolicy'], value: state.config.clientParameters.versionOfPrivacyPolicy });
            dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayer], value: { type: null, body: null } }); 
        }
      }>
        <div>
          {words.iAcceptTheTermsAndConditionsAndPrivacypolicy[state.language]}
        </div>
      </button>
    );
  }
  else {
    return (
      <button className='borderNone backgroundColorWhite heightMin3 textLeft focusEffect01 borderRadius2 riseOut2 marginTopBottom0p5'
        onClick={ () => {
            dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayer], value: { type: null, body: null } }); 
        }
      }>
          {words.theTermsAndConditionsAndPrivacypolicyHaveBeenAccepted[state.language]}
      </button>
    );
  }
}

// TERMS AND CONDITIONS POPUP
function TermsAndConditionsPopup(props) {
  const [state, dispatch] = useContext(GlobalState);
  const popupLayer = props.popupLayer;

  const termsAndConditions = {
    user: {
      face: words.termsAndConditions[state.language],
      html: termsAndConditionsUserHtml,
    },
    privacyPolicy: {
      face: words.privacyPolicy[state.language],
      html: privacyPolicyHtml,
    },
  };

  const buttons = ['user', 'privacyPolicy'].filter( target1 => target1 !== props.target ).map( target2 =>
    <button className='backgroundColorMonacottoPale borderMonacotto margin0p5'
      onClick={ () => {
        const popup = { type: 'generalItems', body: <TermsAndConditionsPopup target={target2} popupLayer={popupLayer} /> };
        dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayer], value: popup });
      }}
    >
      { termsAndConditions[target2].face }
    </button>
  );

  return (
    <div>
      <div>
        { buttons }
      </div>
      <AcceptanceOfTermsAndConditions popupLayer={popupLayer} />
      <div dangerouslySetInnerHTML={ { __html: termsAndConditions[props.target].html } } />
      <AcceptanceOfTermsAndConditions popupLayer={popupLayer} />
    </div>
  );
}

// SCT POPUP
function SCTPopup(props) {
  return (
    <div dangerouslySetInnerHTML={ { __html: sctHtml } } />
  );
}

// LOGIN SECTION
function LoginSection(props) {
  const [state, dispatch, navigate] = useContext(GlobalState);
  const mode = props.mode || 'normal';

  // アドレスセクション・スイッチアイコン

  let addressSection;
  let switchIcon;

  if (state.login.displayAddressSection) {
    addressSection = <AddressSection keys={['login', 'addressMain']} />;
    switchIcon = iconCollapse;
  }
  else {
    switchIcon = iconExpand;
  }

  // ログインインジケータスタイル

  let loginStyle;

  if (Object.values(state.session).some( session => session.expirationOfSession > Date.now() )) {
    loginStyle = 'backgroundColorMonacottoPale';
  }
  else {
    loginStyle = 'backgroundColorMonacottoAlmostWhite';
  }

  // let loginIcon;

  // if (state.walletType === 'mpurse') {
  //   loginIcon = iconLogin;
  // }
  // else if (state.walletType === 'nfc') {
  //   loginIcon = iconKeyCard;
  // }
  // else { // qr
  //   loginIcon = iconQr;
  // }

  // ユーザー名セクション

  let userNameSection;

  if (state.active.addressMain !== undefined && state.active.addressMain !== null && state.active.addressMain !== '') {
    userNameSection =
    <div className='widthBox1 textLeft '>
      { state.user[state.active.addressMain].basicInformation.userName }
    </div>;
  }

  // ログインセクション

  let loginSection;

  // if (state.walletType === 'mpurse') {
    loginSection =
    <div>
      <div className='flexColumn alignItemsCenter marginTop0p5'>
        <div className='marginTop0p5'>
          { userNameSection }
        </div>
        <div className='marginTop0p5'>
          { addressSection }
        </div>
        <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
          onClick={ async () => {
            const result = await handleClickLogin(state, dispatch, navigate, mode);

            if (result?.message === 'nfcRequired') {
              const callback = (keyPairs) => {
                handleClickLogin(state, dispatch, navigate, mode, keyPairs);
                keepKeyPairsInMemory(state, dispatch, keyPairs);
              };

              handleClickNfc(state, dispatch, callback);
            }
            else if (result?.message === 'bcRequired') {
              const callback = (keyPairs) => {
                handleClickLogin(state, dispatch, navigate, mode, keyPairs);
                keepKeyPairsInMemory(state, dispatch, keyPairs);
              };

              handleClickScanQr(state, dispatch, callback);
            }
          }}
        >
          <div>
            { words.login[state.language] }
          </div>
          { SignIcon() }
          {/*
            <img className='size2x2' src={loginIcon} alt='' />
          */}
        </button>
        <div className='flexRow justifyContentCenter alignItemsCenter marginTop0p5'>
          <button className={'button1 ' + loginStyle }
            onClick={ () => handleClickViewSession(state, dispatch) }
          >
            <img className='size2x2' src={iconLoginList} alt='' />
          </button>
          <button className={'button1'} tabindex='0'
            onClick={ () => {
              dispatch({ type: 'setStateMultiLayers', keys: ['login', 'displayAddressSection'], value: state.login.displayAddressSection ? false : true });
            }}
          >
            <img className='size2x2' src={switchIcon} alt='' />
          </button>
        </div>
      </div>
      {/*
        <div className='visibleSmallOrLess flexColumn alignItemsFlexStart marginTop0p5'>
          <TextLine3 fieldName='loginAddressMain' keys={['login', 'addressMain']} face={words.login[state.language]} tooltip='true' />
          <div className='flexRow justifyContentFlexStart alignItemsCenter '>
            <button className='button1' onClick={ () => handleClickMpurse(state, dispatch, ['login', 'addressMain']) }>
              <img className='size2x2' src={iconMpurse} alt='' />
            </button>
            <button className='button1' tabindex='0'
              onClick={ () => {
                handleClickLogin(state, dispatch, navigate, 'normal');
              }}
            >
              <img className='size2x2' src={iconLogin} alt='' />
            </button>
            <button className={'button1 ' + loginStyle }
              onClick={ () => { handleClickViewSession(state, dispatch) } }
            >
              <img className='size2x2' src={iconLoginList} alt='' />
            </button>
          </div>
        </div>
      */}
    </div>
  // }
  // else if (state.walletType === 'nfc') {
  //   loginSection =
  //   <div className='flexRow justifyContentFlexStart alignItemsCenter marginTop0p5'>
  //     <button className='button2 flexRow justifyContentCenter alignItemsCenter '
  //       onClick={ async () => {
  //         const callback = (keyPairs) => handleClickLogin(state, dispatch, navigate, mode, keyPairs);
  //         handleClickNfc(state, dispatch, callback);
  //       }}
  //     >
  //       <div>
  //         { words.login[state.language] }
  //       </div>
  //       <div>
  //         <img className='size2x2' src={iconKeyCard} alt='' />
  //       </div>
  //     </button>
  //     <button className={'button1 ' + loginStyle}
  //       onClick={ () => { handleClickViewSession(state, dispatch) } }
  //     >
  //       <img className='size2x2' src={iconLoginList} alt='' />
  //     </button>
  //   </div>;
  // }
  // else { // qr
  //   loginSection =
  //   <div className='flexRow justifyContentFlexStart alignItemsCenter marginTop0p5'>
  //     <button className='button2 flexRow justifyContentCenter alignItemsCenter '
  //       onClick={ () => {
  //         const callback = (keyPairs) => handleClickLogin(state, dispatch, navigate, mode, keyPairs);
  //         handleClickScanQr(state, dispatch, callback);
  //       }}
  //     >
  //       <div>
  //         { words.login[state.language] }
  //       </div>
  //       <div>
  //         <img className='size2x2' src={iconQr} alt='' />
  //       </div>
  //     </button>
  //     <button className={'button1 ' + loginStyle}
  //       onClick={ () => { handleClickViewSession(state, dispatch) } }
  //     >
  //       <img className='size2x2' src={iconLoginList} alt='' />
  //     </button>
  //   </div>;
  // }


  return loginSection;
}

// ADDRESS SECTION
function AddressSection(props) {
  const [state, dispatch] = useContext(GlobalState);
  const keys = props.keys;
  let addressSection;

  addressSection =
  <div className='flexRow justifyContentCenter alignItemsCenter '>
    <TextLine3 fieldName='loginAddressMain' keys={keys} face={words.login[state.language]} tooltip='true' />
    <button className='button1'
      onClick={ () => handleClickMpurse(state, dispatch, keys) }
    >
      <img className='size2x2' src={iconAddress} alt='' />
    </button>
    {/*
      <button className='button1'
        onClick={ () =>
          handleClickAddressHistory(state, dispatch, cookies, ['login', 'addressMain'], popupLayer)
        }
      >
        <img className='size2x2' src={iconLoginList} alt='' />
      </button>
    */}
  </div>;

  return addressSection;
}

// WALLET SELECT SECTION
function WalletSelectSection() {
  const [state, dispatch] = useContext(GlobalState);
  // ウォレット選択ボタン

  let walletSelectBox;

  const mpurseButton =
  <button className={'box3 focusEffect01 riseOut2 ' + (state.walletType === 'mpurse' ? 'colorRed borderSelected' : 'borderNone')}
    tabindex='0'
    onClick={ () => {
      dispatch({type: 'setState', key: 'walletType', value: 'mpurse'});
    }}
  >
    <div>
      {words.mpurse[state.language]}
    </div>
    <div>
      {words.monapalette[state.language]}
    </div>
  </button>;

  const nfcButton =
  <button className={'box3 focusEffect01 riseOut2 ' + (state.walletType === 'nfc' ? 'colorRed borderSelected' : 'borderNone')}
    tabindex='0'
    onClick={ () => {
      dispatch({type: 'setState', key: 'walletType', value: 'nfc'});
    }}
  >
    <div>
      {words.keyCard[state.language]}
    </div>
    <div>
      {words.nfc[state.language]}
    </div>
  </button>;

  const bcButton =
  <button className={'box3 focusEffect01 riseOut2 ' + (state.walletType === 'qr' ? 'colorRed borderSelected' : 'borderNone')}
    tabindex='0'
    onClick={ () => {
      dispatch({type: 'setState', key: 'walletType', value: 'qr'});
    }}
  >
    <div>
      {words.keyCard[state.language]}
    </div>
    <div>
      {words.bc[state.language]}
    </div>
  </button>

  if ('NDEFReader' in window && state.device.hasCamera) {
    walletSelectBox =
    <div className='flexRow justifyContentSpaceAround widthMax '>
      { mpurseButton }
      { nfcButton }
      { bcButton }
    </div>;
  }
  else if ('NDEFReader' in window) {
    walletSelectBox =
    <div className='flexRow justifyContentSpaceAround widthMax '>
      { mpurseButton }
      { nfcButton }
    </div>;
  }
  else if (state.device.hasCamera) {
    walletSelectBox =
    <div className='flexRow justifyContentSpaceAround widthMax '>
      { mpurseButton }
      { bcButton }
    </div>;
  }
  else {
    walletSelectBox = null;
  }


  return walletSelectBox;
}

// SIGN ICON
function SignIcon() {
  const [state] = useContext(GlobalState);
  let signIcon;

  if (state.walletType === 'mpurse') {
    signIcon = iconMpurse;
  }
  else if (state.walletType === 'nfc') {
    signIcon = iconKeyCard;
  }
  else { // qr
    signIcon = iconQr;
  }


  return <img className='size2x2 margin0p5' src={signIcon} alt='' />;
}

// REGISTRATION SECTION
function RegistrationSection(props) {
  const [state, dispatch] = useContext(GlobalState);
  const popupLayer = props.popupLayer;
  const popupLayerNext = popupLayer !== undefined ? popupLayer + 1 : 0;
  let registrationSection;

  // アドレスセクション・スイッチアイコン

  let addressSection;
  let switchIcon;

  if (state.configure.displayAddressSection) {
    addressSection = <AddressSection keys={['configure', 'addressMain']} />;
    switchIcon = iconCollapse;
  }
  else {
    switchIcon = iconExpand;
  }

  // ユーザー名セクション

  const userNameSection =
  <div className='flexRow justifyContentFlexStart alignItemsCenter '>
    <TextLine3 fieldName='userName' keys={['configure', 'userName']} face={words.userName[state.language]} tooltip={true} />
    <button className={'button1'} tabindex='0'
      onClick={ () => {
        dispatch({ type: 'setStateMultiLayers', keys: ['configure', 'displayAddressSection'], value: state.configure.displayAddressSection ? false : true });
      }}
    >
      <img className='size2x2' src={switchIcon} alt='' />
    </button>
  </div>

  // TAC文言

  let tacWord;

  if (
    state.configure.acceptedVersionOfTheTermsAndConditions === state.config.clientParameters.versionOfTheTermsAndConditions &&
    state.configure.acceptedVersionOfPrivacyPolicy === state.config.clientParameters.versionOfPrivacyPolicy
  ) {
    tacWord = words.theTermsAndConditionsAndPrivacypolicyHaveBeenAccepted[state.language];
  }
  else {
    tacWord = words.termsAndConditionsAndPrivacypolicy[state.language];
  }

  // 利用規約ボタン

  const tacButton =
  <button
    className={
      'borderNone backgroundColorWhite heightMin3 textLeft focusEffect01 cursor borderRadius2 riseOut2 marginTopBottom0p5' +
      (state.configure.readTheTermsAndConditions ? '' : 'colorRed')
    }
    tabindex='0'
    onClick={ () => {
      const popup = {
        type: 'generalItems',
        body: <TermsAndConditionsPopup target='user' popupLayer={ popupLayerNext } />,
      };

      dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayerNext], value: popup });
      dispatch({ type: 'setStateMultiLayers', keys: ['configure', 'readTheTermsAndConditions'], value: true });
      dispatch({ type: 'setStateMultiLayers', keys: ['configure', 'readPrivacyPolicy'], value: true });
    }}
  >
    { tacWord }
  </button>;

  // 利用規約同意チェックボックス

  const tacCheckbox =
  <button
    className={
      'width1 height1 borderMonacottoNoRadius marginSide1 ' +
      (
        (
          state.configure.acceptedVersionOfTheTermsAndConditions === state.config.clientParameters.versionOfTheTermsAndConditions &&
          state.configure.acceptedVersionOfPrivacyPolicy === state.config.clientParameters.versionOfPrivacyPolicy
        ) ? 'backgroundColorPinkPale' : 'backgroundColorTransparent'
      )
    }
    onClick={ () => {
      if (!state.configure.acceptedVersionOfTheTermsAndConditions || !state.configure.acceptedVersionOfPrivacyPolicy) {
        dispatch({ type: 'setStateMultiLayers', keys: ['configure', 'acceptedVersionOfTheTermsAndConditions'], value: state.config.clientParameters.versionOfTheTermsAndConditions });
        dispatch({ type: 'setStateMultiLayers', keys: ['configure', 'acceptedVersionOfPrivacyPolicy'], value: state.config.clientParameters.versionOfPrivacyPolicy });
      }
      else {
        dispatch({ type: 'setStateMultiLayers', keys: ['configure', 'acceptedVersionOfTheTermsAndConditions'], value: null });
        dispatch({ type: 'setStateMultiLayers', keys: ['configure', 'acceptedVersionOfPrivacyPolicy'], value: null });
      }
    }}  
  >
  </button>;

  // 登録ボタン

  const registrationButton =
  <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
    onClick={ async () => {
      const result = await handleClickSetup(state, dispatch);

      if (result?.message === 'nfcRequired') {
        const callback = (keyPairs) => {
          handleClickSetup(state, dispatch, keyPairs);
          keepKeyPairsInMemory(state, dispatch, keyPairs);
        };

        handleClickNfc(state, dispatch, callback);
      }
      else if (result?.message === 'bcRequired') {
        const callback = (keyPairs) => {
          handleClickSetup(state, dispatch, keyPairs);
          keepKeyPairsInMemory(state, dispatch, keyPairs);
        };

        handleClickScanQr(state, dispatch, callback, { popupLayer: popupLayerNext });
      }
    }}
  >
    <div>
      {words.signByMainAddressToRegister[state.language]}
    </div>
    { SignIcon() }
    {/*
      <img className='size2x2' src={iconSign} alt='' />
    */}
  </button>;

  // 登録セクション

  registrationSection =
  <div>
    <div className="visibleMiddleOrMore flexColumn alignItemsCenter borderKOM marginTop1 padding1">
      <div className=''>
        { words.userRegistration[state.language] }
      </div>
      <div className='marginTop0p5 '>
        { addressSection }
      </div>
      <div className='marginTop1 '>
        { userNameSection }
      </div>
      <div className='flexRow justifyContentFlexStart alignItemsCenter marginTopBottom0p5 '>
        { tacCheckbox }
        { tacButton }
      </div>
      { registrationButton }
      {/* generate mnemonic */}
      { 
        process.env.REACT_APP_ENVIRONMENT === 'dev' ? 
          <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
            onClick={ () => handleClickGenerateMnemonic(state, dispatch) }
          >
            generate mnemonic
          </button>
        : null
      }
    </div>
    <div className="visibleSmallOrLess flexColumn alignItemsCenter borderKOM marginTop1 padding1">
      <div className=''>
        { words.userRegistration[state.language] }
      </div>
      <div className='marginTop0p5 '>
        { addressSection }
      </div>
      <div className='marginTop1 '>
        { userNameSection }
      </div>
      <div className='flexRow justifyContentFlexStart alignItemsCenter marginTopBottom0p5 '>
        { tacCheckbox }
        { tacButton }
      </div>
      { registrationButton }
      {/* generate mnemonic */}
      { 
        process.env.REACT_APP_ENVIRONMENT === 'dev' ? 
          <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
            onClick={ () => handleClickGenerateMnemonic(state, dispatch) }
          >
            generate mnemonic
          </button>
        : null
      }
    </div>
  </div>;


  return registrationSection;
}

// USER REGISTRATION POPUP
function UserRegistrationPopup(props) {
  const popupLayer = props.popupLayer;


  return (
    <div>
      {/* PC */}
      <div className='visibleMiddleOrMore flexColumn alignItemsCenter'>
        <RegistrationSection popupLayer={ popupLayer } />
      </div>
      {/* SP */}
      <div className='visibleSmallOrLess flexColumn widthMax'>
        <RegistrationSection popupLayer={ popupLayer } />
      </div>
    </div>
  );
}

// QR CODE SCANNER
function QrCodeScanner(props) {
  const [state, dispatch] = useContext(GlobalState);
  // const [resultData, setResultData] = useState(undefined);
  const popupLayer = props.popupLayer;
  const callback = props.callback;
  const callbackType = props.callbackType;
  const options = props.options;
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  useEffect( () => {
    const constraints = {
      video: {
        facingMode: 'environment',
        width: { ideal: 300 },
        height: { ideal: 300 },
      },
    };

    // デバイスのカメラにアクセスする
    navigator.mediaDevices
    .getUserMedia(constraints)
    .then((stream) => {
      // デバイスのカメラにアクセスすることに成功したら、video要素にストリームをセットする
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
        scanQrCode();
      }
    })
    .catch((err) => console.error('Error accessing media devices:', err));

    const currentVideoRef = videoRef.current

    // コンポーネントがアンマウントされたら、カメラのストリームを停止する
    return () => {
      if (currentVideoRef && currentVideoRef.srcObject) {
        const stream = currentVideoRef.srcObject;
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop());
      }
    };
  }, []);

  const scanQrCode = () => {
    const canvas = canvasRef.current;
    const video = videoRef.current;

    if (canvas && video) {
      const ctx = canvas.getContext('2d');
      // canvas.width = video.videoWidth;
      // canvas.height = video.videoHeight;

      if (ctx) {
        // カメラの映像をcanvasに描画する
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        // QRコードをスキャンする
        const qrCodeData = jsQR(imageData.data, imageData.width, imageData.height);

        if (qrCodeData) {
          // スキャンされた内容を確認する
          // if (false) {
          //   setTimeout(scanQrCode, 100); // スキャンの頻度を制限
          //   return;
          // }

          // dispatch({ type: 'setStateMultiLayers', keys: ['qrCodeScanner', 'qrCode'], value: qrCodeData.data });
          dispatch({ type: 'setNotification', key: 'notification', value: words.scaned[state.language] });
          dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayer], value: { type: null, body: null } });

          if (callbackType === 'decryptMnemonic') {
            doSomethingWithEncryptedMnemonics(state, dispatch, [qrCodeData.data], callback, options);
          }
          else { // simpelCallback
            callback(qrCodeData.data);
          }

          // doSomethingWithEncryptedMnemonics(state, dispatch, [qrCodeData.data], callback, options);
          return;
        }

        setTimeout(scanQrCode, 100);
      }
    }
  };

  // let scanner;

  // if (resultData === undefined) {
  //   scanner =
  //   <div className=''>
  //     <div className=''>
  //       <video ref={videoRef} autoPlay playsInline className='' />
  //       <canvas ref={canvasRef} width='300' height='300' className='' />
  //     </div>
  //   </div>;
  // }

  // let button;
  // 
  // if (resultData !== undefined) {
  //   button =
  //   <div className=''>
  //     <button>push</button>
  //   </div>
  // }


  return (
    <div className='flexColumn justifyContentCenter alignItemsCenter relative border'>
      <video ref={videoRef} autoPlay playsInline className='absoluteZero zM10' />
      <canvas ref={canvasRef} width='300' height='300' className='' />
    </div>
  );
}

// CHALLENGE DUEL POPUP
function ChallengeDuelPopup(props) {
  const [state, dispatch, navigate] = useContext(GlobalState);
  const popupLayer = props.popupLayer;

  const onButton =
  <button className='buttonMainColor widthMin20 heightMin4 flexColumn justifyContentCenter alignItemsCenter '
    onClick={ () => {
      dispatch({ type: 'setStateMultiLayers', keys: ['challengeDuel', 'gameOption', 'straightMan', 'type'], value: 'normal' }); 
      dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayer + 1], value: { type: null, body: null } }); 
    }}
  >
    <div>
      { words.onAri[state.language] }
    </div>
  </button>;

  const offButton =
  <button className='buttonMainColor widthMin20 heightMin4 flexColumn justifyContentCenter alignItemsCenter '
    onClick={ () => {
      dispatch({ type: 'setStateMultiLayers', keys: ['challengeDuel', 'gameOption', 'straightMan', 'type'], value: 'none' }); 
      dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayer + 1], value: { type: null, body: null } }); 
    }}
  >
    <div>
      { words.offNashi[state.language] }
    </div>
  </button>

  const straightManPopup =
  <div className='flexRow alignItemsCenter ' >
    { onButton }
    { offButton }
  </div>;

  const straightManPopupSp =
  <div className='flexColumn alignItemsCenter ' >
    { onButton }
    { offButton }
  </div>;

  let straightManStatusWord;

  if (state.challengeDuel.gameOption.straightMan.type !== 'none') {
    straightManStatusWord = words.onAri[state.language];
  }
  else {
    straightManStatusWord = words.offNashi[state.language];
  }


  return (
    <div>
      <div className="visibleMiddleOrMore ">
        <div className="flexColumn alignItemsFlexStart ">
          <div className='flexRow justifyContentCenter alignItemsCenter ' >
            <button className='buttonMainColor widthMin20 heightMin4 flexColumn justifyContentCenter alignItemsCenter '
              onClick={ () => {
                handleClickChallengeDuel(state, dispatch, navigate, 'human', 'advanced');
                dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayer], value: { type: null, body: null } }); 
              }}
            >
              <div>
                { words.duelAgainstHuman[state.language] }
              </div>
              <div>
                { `(${words.advancedMode[state.language]})` }
              </div>
            </button>
            <button className='buttonMainColor widthMin20 heightMin4 flexColumn justifyContentCenter alignItemsCenter '
              onClick={ () => {
                handleClickChallengeDuel(state, dispatch, navigate, 'computer', 'advanced');
                dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayer], value: { type: null, body: null } }); 
              }}
            >
              <div>
                { words.duelAgainstComputer[state.language] }
              </div>
              <div>
                { `(${words.advancedMode[state.language]})` }
              </div>
            </button>
            <button className='buttonMainColor widthMin20 heightMin4 flexColumn justifyContentCenter alignItemsCenter '
              onClick={ () => {
                handleClickChallengeDuel(state, dispatch, navigate, 'human');
                dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayer], value: { type: null, body: null } }); 
              }}
            >
              <div>
                { words.duelAgainstHuman[state.language] }
              </div>
              <div>
                { `(${words.simpleMode[state.language]})` }
              </div>
            </button>
            <button className='buttonMainColor widthMin20 heightMin4 flexColumn justifyContentCenter alignItemsCenter '
              onClick={ () => {
                handleClickChallengeDuel(state, dispatch, navigate, 'computer');
                dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayer], value: { type: null, body: null } }); 
              }}
            >
              <div>
                { words.duelAgainstComputer[state.language] }
              </div>
              <div>
                { `(${words.simpleMode[state.language]})` }
              </div>
            </button>
          </div>
          <div className='flexRow justifyContentFlexStart alignItemsFlexStart ' >
            <div className='flexColumn margin0p5 ' >
              <div className='margin0p5 ' >
                { words.opponentAddressOptional[state.language] }
              </div>
              <TextArea2
                fieldName='challengeDuelDesignatedAddressesCsv'
                keys={['challengeDuel', 'designatedAddressesCsv']}
                face=''
                boxClass='box4 width32 margin1'
                textAreaClass='textArea1'
              />
            </div>
            <div className='flexColumn margin0p5 ' >
              <div className='margin0p5 ' >
                { `${words.tsukkomiOption[state.language]}(${words.plus50Points[state.language]})` }
              </div>
              <button className={'box3 focusEffect01 riseOut2 ' + (state.challengeDuel.gameOption.straightMan.type !== 'none' ? 'colorRed borderSelected' : 'borderNone')}
                tabindex='0'
                onClick={ () => {
                  const popup = { type: 'generalItems', body: straightManPopup };
                  dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayer +1], value: popup });
                }}
              >
                { straightManStatusWord }
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="visibleSmallOrLess ">
        <div className='flexColumn justifyContentCenter alignItemsCenter ' >
          <button className='buttonMainColor widthMin20 heightMin4 flexColumn justifyContentCenter alignItemsCenter '
            onClick={ () => {
              handleClickChallengeDuel(state, dispatch, navigate, 'human', 'advanced');
              dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: { type: null, body: null } }); 
            }}
          >
            <div>
              { words.duelAgainstHuman[state.language] }
            </div>
            <div>
              { `(${words.advancedMode[state.language]})` }
            </div>
          </button>
          <button className='buttonMainColor widthMin20 heightMin4 flexColumn justifyContentCenter alignItemsCenter '
            onClick={ () => {
              handleClickChallengeDuel(state, dispatch, navigate, 'computer', 'advanced');
              dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: { type: null, body: null } }); 
            }}
          >
            <div>
              { words.duelAgainstComputer[state.language] }
            </div>
            <div>
              { `(${words.advancedMode[state.language]})` }
            </div>
          </button>
          <button className='buttonMainColor widthMin20 heightMin4 flexColumn justifyContentCenter alignItemsCenter '
            onClick={ () => {
              handleClickChallengeDuel(state, dispatch, navigate, 'human');
              dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: { type: null, body: null } }); 
            }}
          >
            <div>
              { words.duelAgainstHuman[state.language] }
            </div>
            <div>
              { `(${words.simpleMode[state.language]})` }
            </div>
          </button>
          <button className='buttonMainColor widthMin20 heightMin4 flexColumn justifyContentCenter alignItemsCenter '
            onClick={ () => {
              handleClickChallengeDuel(state, dispatch, navigate, 'computer');
              dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: { type: null, body: null } }); 
            }}
          >
            <div>
              { words.duelAgainstComputer[state.language] }
            </div>
            <div>
              { `(${words.simpleMode[state.language]})` }
            </div>
          </button>
          <div className='marginTop1 marginBottom0p5 ' >
            { words.opponentAddressOptional[state.language] }
          </div>
          <TextArea2
            fieldName='challengeDuelDesignatedAddressesCsv'
            keys={['challengeDuel', 'designatedAddressesCsv']}
            face=''
            boxClass='box4 width20 margin1'
            textAreaClass='textArea1'
          />
          <div className='marginTop1 marginBottom0p5 ' >
            { `${words.tsukkomiOption[state.language]}(${words.plus50Points[state.language]})` }
          </div>
          <button className={'box3 focusEffect01 riseOut2 ' + (state.challengeDuel.gameOption.straightMan.type !== 'none' ? 'colorRed borderSelected' : 'borderNone')}
            tabindex='0'
            onClick={ () => {
              const popup = { type: 'generalItems', body: straightManPopupSp };
              dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayer +1], value: popup });
            }}
          >
            { straightManStatusWord }
          </button>
        </div>
      </div>
    </div>
  );
}


// BUILD DECK
function BuildDeck() {
  const [state, dispatch, navigate] = useContext(GlobalState);
  const addressMain = state.active.addressMain;

  return (
    <div>
      {/* PC */}
      <div className="visibleMiddleOrMore">
        {/* <Header screen='gallery' /> */}
        <div className='flexColumn alignItemsCenter ' >
          <div className='flexRow justifyContentSpaceBetween widthMax marginBottom1' >
            <div className='flexRow ' >
              <button className='button2' onClick={ () => handleClickDeckNumber(state, dispatch, addressMain) }>
                { words.changeDeck[state.language] }
              </button>
              <button className='button2' onClick={ () => handleClickSaveDeck(state, dispatch) }>
                { words.saveDeck[state.language] }
              </button>
            </div>
            <div className='flexRow ' >
              <button className='button2' onClick={ () => handleClickGoTop(state, dispatch, navigate) }>
                { words.back[state.language] }
              </button>
            </div>
          </div>
          <div className='flexRow justifyContentFlexStart borderKOMFatBorder padding1' >
            <Deck addressMain={state.active.addressMain} deckNo='current' />
          </div>
          <div className='flexRow justifyContentFlexStart '>
            <Balance addressMain={state.active.addressMain} />
          </div>
        </div>
        {/* popup */}
        {
          /*
          <Popup layer={0}/>
          <Popup layer={1}/>
          <Popup layer={2}/>
          */
        }
        {/* development */}
        <div>
          { process.env.REACT_APP_ENVIRONMENT === 'dev' ? <ForDevelopment /> : null }
        </div>
      </div>
      {/* SP */}
      <div className="flexColumn visibleSmallOrLess ">
        <div className='flexColumn alignItemsCenter ' >
          <div className='flexRow justifyContentFlexEnd widthMax marginBottom0p5' >
            <div className='flexRow ' >
              <button className='button2' onClick={ () => handleClickGoTop(state, dispatch, navigate) }>
                { words.back[state.language] }
              </button>
            </div>
          </div>
          <div className='flexRow justifyContentFlexStart widthMax marginBottom1' >
            <div className='flexRow justifyContentFlexStart widthMax' >
              <button className='button2' onClick={ () => handleClickDeckNumber(state, dispatch, addressMain) }>
                { words.changeDeck[state.language] }
              </button>
              <button className='button2' onClick={ () => handleClickSaveDeck(state, dispatch) }>
                { words.saveDeck[state.language] }
              </button>
            </div>
          </div>
          <div className='flexRow justifyContentFlexStart borderKOMFatBorder padding1' >
            <Deck addressMain={state.active.addressMain} deckNo='current' />
          </div>
          <div className='flexRow justifyContentFlexStart '>
            <Balance addressMain={state.active.addressMain} />
          </div>
          <div>
            { process.env.REACT_APP_ENVIRONMENT === 'dev' ? <ForDevelopment /> : null }
          </div>
        </div>
      </div>
      {/* popup */}
        <Popup layer={0}/>
        <Popup layer={1}/>
        <Popup layer={2}/>
    </div>
  );
}

// DECK
function Deck(props) {
  const [state, dispatch] = useContext(GlobalState);
  const addressMain = props.addressMain;
  let deckNo = props.deckNo;

  if (addressMain === undefined || state.deckSet[addressMain] === undefined) {
    return (
      <div className='flexColumn justifyContentCenter alignItemsCenter ' >
        <img className='size2x2' src={monaFavicon} />
        <div className='' >
          { words.pleaseLogin[state.language] }
        </div>
      </div>
    );
  }
  else {
    if (deckNo === 'current') {
      if (state.deckSet[addressMain].decks[state.deckSet[addressMain].currentDeckNo] === undefined || state.deckSet[addressMain].decks[state.deckSet[addressMain].currentDeckNo] === null) {
        const deck = {
          deckName: `deck${state.deckSet[addressMain].currentDeckNo}`,
          cards: [
          ],
        };
        dispatch({ type: 'setStateMultiLayers', keys: ['deckSet', addressMain, 'decks', state.deckSet[addressMain].currentDeckNo], value: deck });
        return null;
      }
      else {
        deckNo = state.deckSet[addressMain].currentDeckNo;
      }
    }
    else {
      if (state.deckSet[addressMain].decks[deckNo] === undefined || state.deckSet[addressMain].decks[deckNo] === null) {
        const deck = {
          deckName: `deck${deckNo}`,
          cards: [
          ],
        };
        dispatch({ type: 'setStateMultiLayers', keys: ['deckSet', addressMain, 'decks', deckNo], value: deck });
        return null;
      }
    }
  }

  const deck = state.deckSet[addressMain].decks[deckNo];

  const items = deck.cards.map ( item => <Item item={state.balance[addressMain][item.asset]} arrows={true} /> );

  // ブランクスロット

  const slotNumbers = [];

  for(let i = 1; i <= state.config.clientParameters.numberOfSlots.advanced; i++) {
    slotNumbers.push(i);
  }

  const blankSlots = slotNumbers.slice(deck.cards.length).map( number => {
    if (number <= 3) {
      return (
        <div>
          {/* PC */}
          <div className="visibleMiddleOrMore">
            <div className='blankSlot border'>
              <div className='font2'>
                { number.toString() + '!' }
              </div>
            </div>
          </div>
          {/* SP */}
          <div className="flexColumn visibleSmallOrLess ">
            <div className='blankSlotSP border'>
              <div className='font2'>
                { number.toString() + '!' }
              </div>
            </div>
          </div>
        </div>
      );
    }
    else {
      return (
        <div>
          {/* PC */}
          <div className="visibleMiddleOrMore">
            <div className='blankSlot border'>
              <div className='font2'>
                { number }
              </div>
            </div>
          </div>
          {/* SP */}
          <div className="flexColumn visibleSmallOrLess ">
            <div className='blankSlotSP border'>
              <div className='font2'>
                { number }
              </div>
            </div>
          </div>
        </div>
      );
    }
  });

  return (
    <div>
      {/* PC */}
      <div className="visibleMiddleOrMore">
        <div>
          <TextLine3 fieldName='deckName' keys={['deckSet', addressMain, 'decks', deckNo, 'deckName']} face={words.deckName[state.language]} tooltip='right' />
        </div>
        <div className='flexRow alignItemsCenter'>
          { items }
          { blankSlots }
        </div>
      </div>
      {/* SP */}
      <div className="flexColumn visibleSmallOrLess ">
        <div>
          <TextLine3 fieldName='deckName' keys={['deckSet', addressMain, 'decks', deckNo, 'deckName']} face={words.deckName[state.language]} tooltip='right' />
        </div>
        <div className='flexRow'>
          { items }
          { blankSlots }
        </div>
      </div>
    </div>
  );
}

// BALANCE
function Balance(props) {
  const [state, dispatch, navigate] = useContext(GlobalState);
  const addressMain = props.addressMain;

  if (addressMain === undefined || state.balance[addressMain] === undefined) {
    return null;
  }

  let items =
   Object.values(state.balance[state.active.addressMain])
  .filter( item => state.registeredCard[item.asset] !== undefined )
  .sort( (a, b) => state.registeredCard[a.asset].cardNo - state.registeredCard[b.asset].cardNo )
  .map ( item => <Item item={item} style='small' quantity={true} arrows={true} />);

  return (
    <div>
      {/* PC */}
      <div className="visibleMiddleOrMore">
        <div className="flexRow justifyContentSpaceAround">
            { items }
        </div>
      </div>
      {/* SP */}
      <div className="flexColumn visibleSmallOrLess ">
        <div className="flexRow justifyContentSpaceAround">
            { items }
        </div>
      </div>
    </div>
  );
}

// ITEM
function Item(props) {
  const [state, dispatch] = useContext(GlobalState);

  // const popup = { type: 'itemDetail', body: props.item };
  const item = props.item;
  const quantity = props.quantity;
  const arrows = props.arrows;
  const popupLayerNext = props.popupLayer !== undefined ? props.popupLayer + 1 : 0;

  /*
  let addressMain;

  if (Object.keys(state.registeredCard).length !== 0) {
    addressMain = state.active.addressMain;
  }
  else {
    return null;
  }
  */

  /*
  let arrows;

  if ( state.session[addressMain] !== undefined && state.session[addressMain].expirationOfSession > Date.now() ) {
    arrows = <div>
      <button className='z200TopRight flexRow justifyContentCenter alignItemsCenter size2x2Large backgroundColorTransparent borderNone cursor borderRadiusCircle riseOut2Half '
        onClick={ (e) => {
          e.stopPropagation();
          addCardToDeck(state, dispatch, item);
        }}
      >
        <img className='size2x2' src={iconInput} />
      </button>
      <button className='z200BottomRight flexRow justifyContentCenter alignItemsCenter size2x2Large backgroundColorTransparent borderNone cursor borderRadiusCircle riseOut2Half '
        onClick={ (e) => {
          e.stopPropagation();
          removeCardFromDeck(state, dispatch, item.asset);
        }}
      >
        <img className='size2x2' src={iconOutput} />
      </button>
    </div>
  }
  else {
    arrows = null;
  }
  */

  let cardImageUrl;
  let cardImageUrlSP;

  if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
    // PCのデッキ用にmediumを用意してもよし。
    // cardImageUrl = `https://knightsofmonadom.monatoka.com/monacard/${item.asset}.png`;
    cardImageUrl = `https://knightsofmonadom.monatoka.com/monacard/small/${item.asset}_small.png`;
    cardImageUrlSP = `https://knightsofmonadom.monatoka.com/monacard/small/${item.asset}_small.png`;
  }
  else { // dev
    // PCのデッキ用にmediumを用意してもよし。
    // cardImageUrl = `https://dev.knightsofmonadom.monatoka.com/monacard/${item.asset}.png`;
    cardImageUrl = `https://dev.knightsofmonadom.monatoka.com/monacard/small/${item.asset}_small.png`;
    cardImageUrlSP = `https://dev.knightsofmonadom.monatoka.com/monacard/small/${item.asset}_small.png`;
  }

  let styleBox;
  let styleCard;

  if (props.style === 'small') {
    styleBox = 'boxCardSmall';
    styleCard = 'cardImageSmall';
  }
  else {
    styleBox = 'boxCard';
    styleCard = 'cardImage';
  }

  let styleBoxColor = '';
  let styleBorderColorSp = '';

  if (state.registeredCard[item.asset].category === 'magic') {
    styleBoxColor = ' backgroundColorLightGreen';
    styleBorderColorSp = ' borderDeepGreen';
  }
  else {
  }

  styleBox += styleBoxColor;

  /*
  let quantity;

  if (props.quantity === true) {
    const quantityInCurrentDeck = state.deckSet[addressMain].decks[state.deckSet[addressMain].currentDeckNo].cards.filter( card => card.asset === item.asset ).length;

    quantity =
    <div className='flexRow justifyContentCenter '>
      <div className=''>
        { item.quantity - quantityInCurrentDeck }
      </div>
      <div className=''>
        { ' / ' }
      </div>
      <div className=''>
        { item.quantity }
      </div>
    </div>
  }
  else {
    quantity = null;
  }
  */


  return (
    <div>
      {/* PC */}
      <div className="visibleMiddleOrMore">
        <button className={styleBox} disabled={props.disabled ? true : false}
          onClick={ () => {
            const body = {
              monster: {
                asset: item.asset, 
              },
            };

            const popup = { type: 'monsterDetail', body: body };
            dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayerNext], value: popup });
          }}
        >
          <div className='flexColumn justifyContentFlexStart alignItemsCenter'>
            <div className='relative' >
              {
                /* state.monacard[item.asset] !== undefined ? */
                /* <img className='cardImage' src={state.monacard[item.asset].imgur_url} /> */
                <img className={styleCard} src={cardImageUrl} />
                /* : null */
              }
              {/* screen */}
              {/* soldOut */}
              { arrows === true ? <Arrows item={item} /> : null }
            </div>
            <div className='flexRow justifyContentFlexStart textCenter '>
              {/* state.assetInfo[item.asset].asset_longname === null ? item.asset : state.assetInfo[item.asset].asset_longname */}
              {/* state.registeredCard[item.asset].name */}
              <CardName item={item} />
            </div>
            { quantity === true ? <Quantity item={item} /> : null }
          </div>
        </button>
      </div>
      {/* SP */}
      <div className="flexColumn visibleSmallOrLess ">
        <button className={'boxCardSP padding0p5' + styleBoxColor + styleBorderColorSp} disabled={props.disabled ? true : false}
          onClick={ () => {
            const body = {
              item: item,
              actionBox: (arrows === true ? 'switch' : 'close'),
            };

            const popup = { type: 'itemDetailSP', body: body };
            dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayerNext], value: popup });
          }}
        >
          <div className='flexColumn justifyContentFlexStart alignItemsCenter'>
            <div className='relative' >
              <img className='cardImageSmallSP' src={cardImageUrlSP} />
            </div>
            { quantity === true ? <Quantity item={item} /> : null }
          </div>
        </button>
      </div>
    </div>
  );
}

// ARROWS
function Arrows(props) {
  const [state, dispatch] = useContext(GlobalState);

  const item = props.item;
  let addressMain;

  if (state.active.addressMain !== undefined) {
    addressMain = state.active.addressMain;
  }
  else {
    return null;
  }

  if ( state.session[addressMain] !== undefined && state.session[addressMain].expirationOfSession > Date.now() ) {
    return (
      <div>
        <button className='z200TopRight flexRow justifyContentCenter alignItemsCenter size2x2Large backgroundColorTransparent borderNone cursor borderRadiusCircle riseOut2Half '
          onClick={ (e) => {
            e.stopPropagation();
            addCardToDeck(state, dispatch, item);
          }}
        >
          <img className='size2x2' src={iconInput} />
        </button>
        <button className='z200BottomRight flexRow justifyContentCenter alignItemsCenter size2x2Large backgroundColorTransparent borderNone cursor borderRadiusCircle riseOut2Half '
          onClick={ (e) => {
            e.stopPropagation();
            removeCardFromDeck(state, dispatch, item.asset);
          }}
        >
          <img className='size2x2' src={iconOutput} />
        </button>
      </div>
    );
  }
  else {
    return null;
  }
}

// QUANTITY
function Quantity(props) {
  const [state, dispatch] = useContext(GlobalState);

  const item = props.item;
  let addressMain;
  let deckSet;

  if (state.active.addressMain !== undefined && state.deckSet[state.active.addressMain] !== undefined) {
    addressMain = state.active.addressMain;
    deckSet = state.deckSet[addressMain];
  }
  else {
    return null;
  }

  const quantityInCurrentDeck = deckSet.decks[deckSet.currentDeckNo].cards.filter( card => card.asset === item.asset ).length;


  return (
    <div className='flexRow justifyContentCenter '>
      <div className=''>
        { item.quantity - quantityInCurrentDeck }
      </div>
      <div className=''>
        { ' / ' }
      </div>
      <div className=''>
        { item.quantity }
      </div>
    </div>
  );
}

// CARD NAME
function CardName(props) {
  const [state] = useContext(GlobalState);

  const item = props.item;

  if (state.registeredCard[item.asset] !== undefined) {
    return (
      <div className='textCenter '>
        { state.registeredCard[item.asset].name }
      </div>
    );
  }
  else {
    return null;
  }
}

// DUEL
function Duel(props) {
  const [state, dispatch, navigate] = useContext(GlobalState);
  const side = state.duel.basicInformation?.side;
  const gameMode = props.gameMode;
  const difficultyMode = props.difficultyMode;

  if (state.active.addressMain === undefined || state.session[state.active.addressMain]?.sessionId === undefined || state.session[state.active.addressMain]?.expirationOfSession < Date.now()) {
    return <PleaseLogin />;
  }

  let webSocketInterface;

  if (state.active.addressMain !== undefined && state.session[state.active.addressMain]?.sessionId !== undefined) {
    webSocketInterface = <WebSocketInterface gameMode={gameMode} difficultyMode={difficultyMode} />;
  }
  else {
    webSocketInterface = null;
  }

  let otherSide;
  let sideColor;
  let otherSideColor;
  let sideClass;
  let otherSideClass;
  let myAlternative;
  let opponentAlternative;
  let myAlternativeMagic;
  let opponentAlternativeMagic;
  let myPoint;
  let opponentPoint;
  let myRose;
  let opponentRose;
  let myUserName;
  let opponentUserName;

  if (side === 'A') {
    sideColor = '赤';
    otherSide = 'B';
    otherSideColor = '青';
    sideClass = 'classA';
    otherSideClass = 'classB';
    myAlternative = 'emblemRed';
    opponentAlternative = 'emblemBlue';
    myAlternativeMagic = 'imageMagicRed';
    opponentAlternativeMagic = 'imageMagicBlue';
    myPoint = state.duel.pointA;
    opponentPoint = state.duel.pointB;
    myRose = roseRed;
    opponentRose = roseBlue;
    myUserName = state.duel.basicInformation?.duelistAUserName;
    opponentUserName = state.duel.basicInformation?.duelistBUserName;
  }
  else if (side === 'B') {
    sideColor = '青';
    otherSide = 'A';
    otherSideColor = '赤';
    sideClass = 'classB';
    otherSideClass = 'classA';
    myAlternative = 'emblemBlue';
    opponentAlternative = 'emblemRed';
    myAlternativeMagic = 'imageMagicBlue';
    opponentAlternativeMagic = 'imageMagicRed';
    myPoint = state.duel.pointB;
    opponentPoint = state.duel.pointA;
    myRose = roseBlue;
    opponentRose = roseRed;
    myUserName = state.duel.basicInformation?.duelistBUserName;
    opponentUserName = state.duel.basicInformation?.duelistAUserName;
  }
  else {
    otherSide = undefined;
  }

  // デッキ

  let myDeck;
  let opponentDeck;
  let myDeckSP;
  let opponentDeckSP;

  if (state.duel.basicInformation !== undefined) {
    if (side === 'A') {
      myDeck =
      <div className='boxDeck classBackGroundA' >
        <div>
          { myUserName + words.sDeck[state.language] }
          {/* words.yourDeck[state.language] */}
        </div>
        <div className='flexRow justifyContentCenter widthMax'>
          <DeckOnDuel deck={state.duel.basicInformation?.duelistADeck} sideRelative='self' />
        </div>
      </div>;

      opponentDeck =
      <div className='boxDeck classBackGroundB' >
        <div>
          { opponentUserName !== undefined ? opponentUserName + words.sDeck[state.language] : null }
          {/* words.opponentsDeck[state.language] */}
        </div>
        <div className='flexRow justifyContentCenter widthMax'>
          <DeckOnDuel deck={state.duel.basicInformation?.duelistBDeck} sideRelative='other' />
        </div>
      </div>;

      myDeckSP =
      <button className='flexColumn justifyContentSpaceAround alignItemsCenter pointerEventsAuto button2 widthDiv3 heightMin4 marginSide0p7 font0p7' disabled={props.disabled ? true : false}
        onClick={ () => {
          const body = {
            deck: state.duel.basicInformation?.duelistADeck,
            sideRelative: 'self',
          }

          const popup = { type: 'deckOnDuelSP', body: body };
          dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: popup });

          dispatch({ type: 'setStateMultiLayers', keys: ['animation', 'youWinMona'], value: 'invisible' });
        }}
      >
        <div>
          { myUserName }
        </div>
        <div>
          { words.sDeck[state.language] }
        </div>
      </button>

      opponentDeckSP =
      <button className='flexColumn justifyContentSpaceAround alignItemsCenter pointerEventsAuto button2 widthDiv3 heightMin4 marginSide0p7 font0p7' disabled={props.disabled ? true : false}
        onClick={ () => {
          const body = {
            deck: state.duel.basicInformation?.duelistBDeck,
            sideRelative: 'other',
          }

          const popup = { type: 'deckOnDuelSP', body: body };
          dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: popup });

          dispatch({ type: 'setStateMultiLayers', keys: ['animation', 'youWinMona'], value: 'invisible' });
        }}
      >
        <div>
          { opponentUserName !== undefined ? opponentUserName : null }
        </div>
        <div>
          { opponentUserName !== undefined ? words.sDeck[state.language] : null }
        </div>
      </button>
    }
    else { // B
      myDeck =
      <div className='boxDeck classBackGroundB' >
        <div>
          { myUserName + words.sDeck[state.language] }
          {/* words.yourDeck[state.language] */}
        </div>
        <div className='flexRow justifyContentCenter widthMax'>
          <DeckOnDuel deck={state.duel.basicInformation?.duelistBDeck} sideRelative='self' />
        </div>
      </div>;

      opponentDeck =
      <div className='boxDeck classBackGroundA' >
        <div>
          { opponentUserName + words.sDeck[state.language] }
          {/* words.opponentsDeck[state.language] */}
        </div>
        <div className='flexRow justifyContentCenter widthMax'>
          <DeckOnDuel deck={state.duel.basicInformation?.duelistADeck} sideRelative='other' />
        </div>
      </div>;

      myDeckSP =
      <button className='flexColumn justifyContentSpaceAround alignItemsCenter pointerEventsAuto button2 widthDiv3 heightMin4 marginSide0p7 font0p7' disabled={props.disabled ? true : false}
        onClick={ () => {
          const body = {
            deck: state.duel.basicInformation?.duelistBDeck,
            sideRelative: 'self',
          }

          const popup = { type: 'deckOnDuelSP', body: body };
          dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: popup });

          dispatch({ type: 'setStateMultiLayers', keys: ['animation', 'youWinMona'], value: 'invisible' });
        }}
      >
        <div>
          { myUserName }
        </div>
        <div>
          { words.sDeck[state.language] }
        </div>
      </button>

      opponentDeckSP =
      <button className='flexColumn justifyContentSpaceAround alignItemsCenter pointerEventsAuto button2 widthDiv3 heightMin4 marginSide0p7 font0p7' disabled={props.disabled ? true : false}
        onClick={ () => {
          const body = {
            deck: state.duel.basicInformation?.duelistADeck,
            sideRelative: 'other',
          }

          const popup = { type: 'deckOnDuelSP', body: body };
          dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: popup });

          dispatch({ type: 'setStateMultiLayers', keys: ['animation', 'youWinMona'], value: 'invisible' });
        }}
      >
        <div>
          { opponentUserName !== undefined ? opponentUserName : null }
        </div>
        <div>
          { opponentUserName !== undefined ? words.sDeck[state.language] : null }
        </div>
      </button>
    }
  }
  else {
    myDeck = null;

    opponentDeck = null;
  }

  // 召喚モンスター

  let myMonster;
  let opponentMonster;

  myMonster = <CardOnDuel
    asset={ state.duel.monstersInformation?.[side]?.asset }
    index={state.duel.monstersIndex?.[side]}
    side={side} alternative={myAlternative}
    position='field'
   />;

  opponentMonster = <CardOnDuel
    asset={ state.duel.monstersInformation?.[otherSide]?.asset }
    index={state.duel.monstersIndex?.[otherSide]}
    side={otherSide}
    alternative={opponentAlternative}
    position='field'
  />;

  // 詠唱魔法

  let myMagic;
  let opponentMagic;

  myMagic = <CardOnDuel
    asset={ state.duel.magics?.[side]?.[0]?.asset }
    index={ state.duel.magics?.[side]?.[0]?.index }
    side={side}
    alternative={myAlternativeMagic} 
    style='small'
    position='cast'
   />;

  opponentMagic = <CardOnDuel
    asset={ state.duel.magics?.[otherSide]?.[0]?.asset }
    index={ state.duel.magics?.[otherSide]?.[0]?.index }
    side={otherSide}
    alternative={opponentAlternativeMagic}
    style='small'
    position='cast'
  />;


  // タイム
  let time;

  if (state.duel.status === 'timeUp') {
    if (state.duel.duelWinner === side) {
      time = words.timeupAndYouWin[state.language];
    }
    else if (state.duel.duelWinner === otherSide) {
      time = words.timeupAndYouLose[state.language];
    }
    else { //draw
      time = words.timeupAndDraw[state.language];
    }
  }
  else if (state.duel.status === 'leave') {
    if (state.duel.duelWinner === side) {
      time = words.opponentHasLeftTheField[state.language];
    }
    else { // lose デュエル中ログイン
      time = words.youHasLoggedInOnAnotherScreenYouLose[state.language];
    }
  }
  else if (state.duel.status === 'leaveWithoutOpponentComming') { // 対人戦待ち中ログイン
    time = words.youHasLoggedInOnAnotherScreen[state.language];
  }
  else if (state.duel.myStatus === 'waitingToSummon' || state.duel.myStatus === 'uncomfirmed') {
    time = Math.floor((state.duel.timeOutTime - state.clock) / 1000);
  }
  else if (state.duel.status === 'waitingInRoom') {
    time = words.waitingForYourOpponent[state.language];
  }
  else {
    time = null;
  }

  // 決着エフェクト
  let myBattleUpEffect;
  let opponentBattleUpEffect;
  let duelUpEffect;

  if (state.duel.status === 'battleUp' || state.duel.status === 'duelUp') {
    if (state.duel.winner === side) {
      myBattleUpEffect = <YouWin />;
      opponentBattleUpEffect = <YouLose />;
    }
    else if (state.duel.winner === otherSide) {
      myBattleUpEffect = <YouLose />;
      opponentBattleUpEffect = <YouWin />;
    }
    else { // draw
      myBattleUpEffect = <Draw />;
      opponentBattleUpEffect = <Draw />;
    }

    if (state.duel.status === 'duelUp') {
      duelUpEffect = <DuelUp />;
    }
  }
  else {
    myBattleUpEffect = null;
    opponentBattleUpEffect = null;
    duelUpEffect = null;
  }

  // 魔法エフェクト
  let magicEffect;

  if (state.duel.status === 'magicPhase') {
    if (state.duel.magics.A[0] !== undefined || state.duel.magics.B[0] !== undefined) {
      magicEffect = <Flash />;
    }
    if (state.duel.magics.A[0] !== undefined) {
      magicEffect = <Flash />;
    }
    if (state.duel.magics.B[0] !== undefined) {
      magicEffect = <Flash />;
    }
  }

  // ナイトボックスエフェクト
  let myKnightBoxEffect;
  let opponentKnightBoxEffect;
  let myKnightBoxEffectSP;
  let opponentKnightBoxEffectSP;

  if (state.duel.knightBoxEffect?.[side] === 'bulletHoles') {
    myKnightBoxEffect = <BulletHoles side={side} />;
    myKnightBoxEffectSP = <BulletHoles side={side} device='SP' />;
  }

  if (state.duel.knightBoxEffect?.[otherSide] === 'bulletHoles') {
    opponentKnightBoxEffect = <BulletHoles side={otherSide} />;
    opponentKnightBoxEffectSP = <BulletHoles side={otherSide} device='SP' />;
  }

  // KNIGHT BOX
  // ナイトボックス

  let knightBox = {};
  let knightBoxSP = {};

  if (difficultyMode === 'simple') {
    knightBox.mySide =
    <div className={'flexColumn margin0p5 ' + sideClass} >
      <div className='heightMin1p3 margin0p5' >
        { myUserName }
      </div>
      <div className='knightBox '>
        { myMonster }
        { myBattleUpEffect }
        { myKnightBoxEffect }
      </div>
    </div>;

    knightBox.opponentSide =
    <div className={'flexColumn margin0p5 ' + otherSideClass}>
      <div className='heightMin1p3 margin0p5' >
        { opponentUserName }
      </div>
      <div className='knightBox ' >
        { opponentMonster }
        { opponentBattleUpEffect }
        { opponentKnightBoxEffect }
      </div>
    </div>;

    knightBoxSP.mySide =
    <div className={'knightBox widthKnightBoxSP heightKnightBoxSP ' + sideClass} >
      <div className='heightMin1p3 font0p7' >
        { myUserName }
      </div>
      { myMonster }
      { myBattleUpEffect }
      { myKnightBoxEffectSP }
    </div>;

    knightBoxSP.opponentSide =
    <div className={'knightBox widthKnightBoxSP heightKnightBoxSP ' + otherSideClass} >
      <div className='heightMin1p3 font0p7' >
        { opponentUserName }
      </div>
      { opponentMonster }
      { opponentBattleUpEffect }
      { opponentKnightBoxEffectSP }
    </div>;
  }
  else { // advanced
    knightBox.mySide =
    <div className={'flexColumn margin0p5 ' + sideClass} >
      <div className='heightMin1p3 margin0p5' >
        { myUserName }
      </div>
      <div className='flexRow justifyContentCenter ' >
        <div className='flexColumn justifyContentCenter alignItemsCenter width3 marginTop0p5 padding0p5 ' >
          <MaginaGauge side={side} sideRelative='self' />
          <div className='margin0p5' >
            <MaginaPointApplied side={side} sideRelative='self' />
          </div>
        </div>
        <div className='flexColumn justifyContentFlexStart alignItemsCenter' >
          <div className='flexRow justifyContentCenter ' >
            <div className='flexColumn justifyContentFlexStart alignItemsCenter width11 marginTop0p5 padding0p5 ' >
              <MaginaPoint side={side} baseClass='padding0p5' initiativeClass={'borderFat borderRadius0p5 ' + borderColor(side)} />
              <div className='flexColumn justifyContentFlexStart alignItemsCenter marginTop1'> 
                { myMagic }
                <TargetIndication side={side} />
                <div className='marginTop0p5'> 
                  {/* cancelMagic */}
                  <CancelMagic />
                </div> 
              </div> 
            </div>
          </div>
          <div className='margin0p5 ' >
            <MagicInstruction fontSize='0.9em' />
          </div>
        </div>
        <div className='knightBox '>
          { myMonster }
          { myBattleUpEffect }
          { myKnightBoxEffect }
        </div>
      </div>
    </div>;

    knightBox.opponentSide =
    <div className={'flexColumn margin0p5 ' + otherSideClass}>
      <div className='heightMin1p3 margin0p5' >
        { opponentUserName }
      </div>
      <div className='flexRow justifyContentCenter ' >
        <div className='knightBox ' >
          { opponentMonster }
          { opponentBattleUpEffect }
          { opponentKnightBoxEffect }
        </div>
        <div className='flexColumn justifyContentFlexStart alignItemsCenter width11 marginTop0p5 padding0p5 ' >
          <MaginaPoint side={otherSide} baseClass='padding0p5' initiativeClass={'borderFat borderRadius0p5 ' + borderColor(otherSide)} />
          <div className='flexColumn justifyContentFlexStart alignItemsCenter marginTop1'> 
            { opponentMagic }
            <TargetIndication side={otherSide} />
          </div> 
        </div>
        <div className='flexColumn justifyContentCenter alignItemsCenter width3 marginTop0p5 padding0p5 ' >
          <MaginaGauge side={otherSide} sideRelative='other' />
          <div className='margin0p5' >
            <MaginaPointApplied side={otherSide} sideRelative='other' />
          </div>
        </div>
      </div>
    </div>;

    knightBoxSP.mySide =
    <div className={'knightBox widthKnightBoxSP heightKnightBoxSP ' + sideClass} >
      <div className='heightMin1p3 font0p7' >
        { myUserName }
      </div>
      { myMonster }
      { myBattleUpEffect }
      { myKnightBoxEffectSP }
      <div className='flexRow justifyContentFlexStart alignItemsCenter margin0p5'> 
        { myMagic }
        <div className='flexColumn justifyContentFlexStart alignItemsCenter '>
          <MaginaPoint side={side} baseClass='padding0p3 font0p7' initiativeClass={'borderFat borderRadius0p5 ' + borderColor(side)} />
          <MaginaPointApplied side={side} sideRelative='self' device='SP' />
          <TargetIndication side={side} textClass='font0p5' />
        </div>
      </div> 
    </div>;

    knightBoxSP.opponentSide =
    <div className={'knightBox widthKnightBoxSP heightKnightBoxSP ' + otherSideClass} >
      <div className='heightMin1p3 font0p7' >
        { opponentUserName }
      </div>
      { opponentMonster }
      { opponentBattleUpEffect }
      { opponentKnightBoxEffectSP }
      <div className='flexRow justifyContentFlexStart alignItemsCenter margin0p5'> 
        { opponentMagic }
        <div className='flexColumn justifyContentFlexStart alignItemsCenter '>
          <MaginaPoint side={otherSide} baseClass='padding0p3 font0p7' initiativeClass={'borderFat borderRadius0p5 ' + borderColor(otherSide)} />
          <MaginaPointApplied side={otherSide} sideRelative='other' device='SP' />
          <TargetIndication side={otherSide}  textClass='font0p5' />
        </div>
      </div> 
    </div>;
  }

  // 履歴ボタン

  let historyButton;

  const queryParameter = `?roomid=${state.duel.basicInformation?.roomId}&duelno=${state.duel.basicInformation?.duelNo}`;

  if (state.duel.status === 'duelUp' || state.duel.status === 'timeUp' || state.duel.status === 'timeUpAfterDuelUp' || state.duel.status === 'leave' || state.duel.status === 'leaveAfterDuelUp') {
    historyButton =
    <button className='button1'
      onClick={ () => {
        navigate((process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/duelhistorydetail' : '/duelhistorydetail') + queryParameter)
      }}
    >
      <img className='size2x2' src={iconReplay} />
    </button>;
  }
  else {
    historyButton = null;
  }

  // バックボタン

  let backButton;
  let backButtonSp;

  if (state.duel.status === 'duelUp' || state.duel.status === 'timeUp' || state.duel.status === 'timeUpAfterDuelUp' || state.duel.status === 'leave' || state.duel.status === 'leaveAfterDuelUp') {
    backButton =
    <button className='button2' onClick={ () => handleClickEndDuel(state, dispatch, navigate) }>
      { words.back[state.language] }
    </button>;

    backButtonSp =
    <button className='button2 widthDiv3' onClick={ () => handleClickEndDuel(state, dispatch, navigate) }>
      { words.back[state.language] }
    </button>;
  }
  else if (state.duel.status !== undefined) {
    backButton =
    <button className='button2' onClick={ () => handleClickLeaveDuel(state, dispatch, navigate) }>
      { words.leaveDuel[state.language] }
    </button>;

    backButtonSp =
    <button className='button2 widthDiv3' onClick={ () => handleClickLeaveDuel(state, dispatch, navigate) }>
      { words.leaveDuel[state.language] }
    </button>;
  }
  else {
    backButton =
    <button className='button2Dummy ' disabled={true} >
    </button>;

    backButtonSp =
    <button className='button2Dummy ' disabled={true} >
    </button>;
  }

  // -- ヘッダ・フッタポジション切替ボタン

  const headerFooterPositionButton =
  <button className='button1'
    onClick={ () => {
      if (state.headerFooterPosition === 'fixed') {
        dispatch({ type: 'setState', key: 'headerFooterPosition', value: 'relative' });
      }
      else { // relative
        dispatch({ type: 'setState', key: 'headerFooterPosition', value: 'fixed' });
      }
    }}
  >
    <img className='size2x2' src={iconHeaderFooter} alt='' />
  </button>;

  // ヘッダ・フッタ

  let headerFixed;
  let headerRelative;
  let footerFixed;
  let footerRelative;
  let headerFixedPc;
  let headerRelativePc;

  const headerCore =
  <div className='flexColumn alignItemsCenter widthMax '>
    <div className='flexRow justifyContentSpaceBetween widthMax '>
      <div className='flexRow justifyContentFlexEnd alignItemsCenter widthMin50vw '>
        <div className='font2'>
          { time }
        </div>
      </div>
      <div className='marginSide0p5'>
        { historyButton }
      </div>
      { backButtonSp }
    </div>
    <div className='flexRow justifyContentCenter widthMax'>
      <div className='flexRow justifyContentFlexEnd alignItemsCenter width40PC marginSide1 '>
        <Score src={myRose} point={myPoint} />
      </div>
      <div className='flexRow justifyContentFlexStart alignItemsCenter width40PC marginSide1 '>
        <Score src={opponentRose} point={opponentPoint} />
      </div>
    </div>
  </div>;

  const footerCore =
  <div className='flexRow justifyContentSpaceBetween alignItemsCenter widthMax '>
    { myDeckSP }
    <NextButton />
    { opponentDeckSP }
  </div>;

  const headerCorePc =
  <div className='flexRow justifyContentSpaceBetween width121 maxWidthMax '>
    <div className='flexRow justifyContentFlexStart widthMin10 '>
      <NextButton />
    </div>
    <div className='flexRow justifyContentCenter'>
      <div className='flexRow justifyContentFlexEnd alignItemsCenter widthMin10 marginSide1 '>
        <Score src={myRose} point={myPoint} />
      </div>
      <div className='flexRow justifyContentCenter alignItemsCenter font2 '>
        { time }
      </div>
      <div className='flexRow justifyContentFlexStart alignItemsCenter widthMin10 marginSide1 '>
        <Score src={opponentRose} point={opponentPoint} />
      </div>
    </div>
    <div className='flexRow justifyContentFlexEnd alignItemsCenter widthMin10 '>
      { headerFooterPositionButton }
      <div className='marginSide0p5'>
        { historyButton }
      </div>
      { backButton }
    </div>
  </div>;

  if (state.headerFooterPosition === 'fixed') {
    headerFixed =
    <div className='z200FixedZero widthMax backgroundColorWhiteTransparent '>
      { headerCore }
    </div>;

    footerFixed =
    <div className='z200FixedBottom widthMax pointerEventsNone '>
      { footerCore }
    </div>;

    headerFixedPc =
    <div className='z200FixedZero widthMax backgroundColorWhiteTransparent flexRow justifyContentCenter '>
      { headerCorePc }
    </div>;

    headerRelative =
    <div className='widthMax height11 ' >
    </div>;

    footerRelative =
    <div className='widthMax height5 ' >
    </div>;

    headerRelativePc =
    <div className='widthMax height6 ' >
    </div>;
  }
  else { // relative
    headerRelative = headerCore;
    footerRelative = footerCore;
    headerRelativePc = headerCorePc;
  }

  // メインスクリーン

  let mainScreen;

  if (state.duel.basicInformation !== undefined) {
    mainScreen =
    <div >
      {/* PC */}
      <div className="visibleHuge flexColumn alignItemsCenter">
        <div className="relative ">
          { headerRelativePc }
          <div className='flexColumn alignItemsCenter ' >
            { opponentDeck }
            <div className='flexRow duelField justifyContentSpaceAround alignItemsFlexStart'>
              { knightBox.mySide }
              <div>
                <DuelText />
                { duelUpEffect }
              </div>
              { knightBox.opponentSide }
            </div>
            <div className='flexRow ' >
              <div className='marginSide1 ' >
                { myDeck }
              </div>
              <div className='marginSide1 ' >
                { <SelectControlButton layer={0} /> }
              </div>
            </div>
          </div>
        </div>
        { headerFixedPc }
        {/* development */}
        { process.env.REACT_APP_ENVIRONMENT === 'dev' ? <ForDevelopment /> : null }
      </div>
      {/* TABLET */}
      <div className="visibleLarge flexColumn alignItemsCenter">
        <div className="relative ">
          <div className='flexColumn alignItemsCenter ' >
            { opponentDeck }
            <div className='flexRow justifyContentSpaceBetween widthMax '>
              <div className='flexRow justifyContentFlexStart widthMin10 '>
                {/* nextButton */}
                <NextButton />
              </div>
              <div className='flexRow justifyContentCenter'>
                <div className='flexRow justifyContentFlexEnd alignItemsCenter widthMin10 marginSide1 '>
                  <Score src={myRose} point={myPoint} />
                </div>
                <div className='flexRow justifyContentCenter alignItemsCenter font2 '>
                  { time }
                </div>
                <div className='flexRow justifyContentFlexStart alignItemsCenter widthMin10 marginSide1 '>
                  <Score src={opponentRose} point={opponentPoint} />
                </div>
              </div>
              <div className='flexRow justifyContentFlexEnd widthMin10 '>
                <div className='marginSide0p5'>
                  { historyButton }
                </div>
                { backButton }
              </div>
            </div>
            <div className='flexColumn justifyContentFlexStart alignItemsCenter'>
              <div className='flexRow justifyContentSpaceAround alignItemsCenter' >
                { knightBox.mySide }
                { knightBox.opponentSide }
              </div>
              <DuelText fontSizeClass='font1p5' />
              { duelUpEffect }
            </div>
            <div className='flexRow ' >
              <div className='marginSide1 ' >
                { myDeck }
              </div>
              <div className='marginSide1 ' >
                { <SelectControlButton layer={0} /> }
              </div>
            </div>
          </div>
        </div>
        {/* development */}
        {/* process.env.REACT_APP_ENVIRONMENT === 'dev' ? <ForDevelopment /> : null */}
      </div>
      {/* SP */}
      <div className="visibleMiddleOrLess relative widthMax overflowXHidden ">
        <div className="flexColumn alignItemsCenter widthMax ">
          { headerRelative }
          <div className='flexRow justifyContentSpaceAround alignItemsFlexStart'>
            { knightBoxSP.mySide }
            { knightBoxSP.opponentSide }
          </div>
          <div >
            <DuelText fontSizeClass='' />
            { duelUpEffect }
          </div>
          <div className='flexRow justifyContentFlexEnd alignItemsCenter widthMax'>
            <div className='pointerEventsAuto '>
              { headerFooterPositionButton }
            </div>
          </div>
          { footerRelative }
        </div>
        { headerFixed }
        { footerFixed }
      </div>
    </div>;
  }
  else {
    mainScreen = <NowLoading />;
  }


  return (
    <div>
      { webSocketInterface }
      <Clock />
      { mainScreen }
      {/* popup */}
      <Popup layer={0}/>
      <Popup layer={1}/>
      <Popup layer={2}/>
      <Popup layer={3}/>
      {/* magicEffect */}
      { magicEffect }
    </div>
  );
}

// WEBSOCKET INTERFACE
function WebSocketInterface(props) {
  const [state, dispatch, navigate] = useContext(GlobalState);
  // const socketRef = useRef();
  // const duelTextCountRef = useRef(0);

  useEffect( () => {
    const websocketClient = new ReconnectingWebSocket('wss://' + process.env.REACT_APP_KOM_WEBSOCKET_DOMAIN);
    dispatch({type: 'setStateMultiLayers', keys: ['webSocketContainer', 'webSocket'], value: websocketClient});
    // socketRef.current = websocketClient;

    let designatedAddresses;
    const csv = state.challengeDuel.designatedAddressesCsv.trim();

    if (csv !== undefined && csv !== null && csv !== '') {
      designatedAddresses = csv.split(/[\r\n,]+/).map( address => { return { address: address }; } );
    }

    const requestBody = {
      action: 'challengeDuel',
      addressMain: state.active.addressMain,
      sessionId: state.session[state.active.addressMain].sessionId,
      gameMode: props.gameMode,
      difficultyMode: props.difficultyMode,
      designatedAddresses: designatedAddresses,
      gameOption: state.challengeDuel.gameOption,
    };

    websocketClient?.send(JSON.stringify(requestBody));


    const onMessage = (event) => {
      let data;
      let mySide;
      let opponentSide;
      let propertyNameMyDeck;
      let propertyNameOpponentDeck;

      if (state.duel.basicInformation?.side === 'A') {
        mySide = 'A';
        opponentSide = 'B';
        propertyNameMyDeck = 'duelistADeck';
        propertyNameOpponentDeck = 'duelistBDeck';
      }
      else if (state.duel.basicInformation?.side === 'B') {
        mySide = 'B';
        opponentSide = 'A';
        propertyNameMyDeck = 'duelistBDeck';
        propertyNameOpponentDeck = 'duelistADeck';
      }

      devLog(event.data);

      try {
        data = JSON.parse(event.data);

        if (data.type === 'duelText') {
          // let duelTextCount = duelTextCountRef.current;
          // let duelText = state.duel.text;
          let chunks = state.duel.chunks;

          const chunkRecoverd = data.body.content
          .replace(/<-KOM control code : new line->/g, '\n')
          .replace(/<-KOM-field->/, '\n<<フィールドの状況>>\n');

          devLog('chunkRecoverd', chunkRecoverd);

          chunks[data.body.count] = chunkRecoverd;

          // devLog('duelTextCount', duelTextCount, chunks[duelTextCount +1]);

          /*
          while (chunks[duelTextCount + 1] !== undefined) {
            const chunkRecoverd = chunks[duelTextCount + 1].replace(/<-KOM control code : new line->/g, '\n');
            duelText += chunkRecoverd;
            // chunks[duelTextCount + 1] = undefined; // ← これ
            duelTextCount++;
            devLog('incremented', duelTextCount);
          }
          */

          // duelTextCountRef.current = duelTextCount;
          // dispatch({type: 'setStateMultiLayers', keys: ['duel', 'textCount'], value: duelTextCount});
          dispatch({type: 'setStateMultiLayers', keys: ['duel', 'chunks'], value: chunks});
          // dispatch({type: 'setStateMultiLayers', keys: ['duel', 'text'], value: duelText});
        }
        else if (data.type === 'duelMessage') {
          if (data.subType === 'waitingInRoom') {
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'basicInformation'], value: data.body});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'timeOutTime'], value: data.body.timeOutTime});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'result', 0], value: data.body.result});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'text'], value: state.config.clientParameters.descriptionAndinstructions});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'status'], value: 'waitingInRoom'});
          }
          else if (data.subType === 'duelStandby') {
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'basicInformation'], value: data.body});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'timeOutTime'], value: data.body.timeOutTime});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'myStatus'], value: 'waitingToSummon'});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'round'], value: 1});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'result', 0], value: data.body.result});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'maginaPoint'], value: data.body.maginaPoint});
            dispatch({
              type: 'setStateMultiLayers',
              keys: ['duel', 'text'],
              value: state.config.clientParameters.descriptionAndinstructions + '\n\nモナーの名の下に！\n'
              // value: state.config.clientParameters.descriptionAndinstructions + '\n\n<<フィールドの状況>>\n' + data.body.result.field + '\n\nモナーの名の下に！\n'
            });
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'status'], value: 'duelStandby'});
          }
          else if (data.subType === 'summonedMonster') {
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'text'], value: ''});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'status'], value: 'summonedMonster'});
          }
          else if (data.subType === 'battleStandby') {
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'monstersInformation'], value: data.body.monstersInformation});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'monstersIndex'], value: data.body.monstersIndex});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'round'], value: data.body.round});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'timeOutTime'], value: data.body.timeOutTime});
          }
          else if (data.subType === 'magicPhase') {
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'monstersInformation'], value: data.body.monstersInformation});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'monstersInformationRevised'], value: data.body.monstersInformationRevised});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'magics'], value: data.body.magics});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'maginaPoint'], value: data.body.maginaPoint});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'initiative'], value: data.body.initiative});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'castMagics'], value: []});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'round'], value: data.body.round});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'result', data.body.round], value: data.body.result});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'status'], value: 'magicPhase'});

            // カードオープン
            for (const magic of data.body.magics[opponentSide]) {
              dispatch({type: 'setStateMultiLayers', keys: ['duel', 'basicInformation', propertyNameOpponentDeck, 'cards', magic.index, 'asset'], value: magic.asset});
              dispatch({type: 'setStateMultiLayers', keys: ['duel', 'basicInformation', propertyNameOpponentDeck, 'cards', magic.index, 'visibility'], value: 'open'});

              for (const [index, cost] of magic.costs.entries()) {
                if (state.registeredCard[magic.asset].costs[index].type === 'discardCards') {
                  for (const card of cost.cards) {
                    dispatch({type: 'setStateMultiLayers', keys: ['duel', 'basicInformation', propertyNameOpponentDeck, 'cards', card.index, 'asset'], value: card.asset});
                    dispatch({type: 'setStateMultiLayers', keys: ['duel', 'basicInformation', propertyNameOpponentDeck, 'cards', card.index, 'visibility'], value: 'open'});
                  }
                }
              }
            }

            // 魔法ナレーション
            let text = '';
            let monsterName = {};

            // -- 同キャラ対決のとき名前に色を付ける。
            if (data.body.monstersInformation.A.asset === data.body.monstersInformation.B.asset) {
              monsterName.A = data.body.monstersInformation.A.name + '(' + sideColor('A') + ')';
              monsterName.B = data.body.monstersInformation.B.name + '(' + sideColor('B') + ')';
            }
            else {
              monsterName.A = data.body.monstersInformation.A.name;
              monsterName.B = data.body.monstersInformation.B.name;
            }

            for (const side of [data.body.initiative, otherSide(data.body.initiative)]) {
              const magic = data.body.magics[side][0];

              if (magic !== undefined) {
                text += monsterName[side] + 'は ' + state.registeredCard[magic.asset].name + ' を発動した。\n';
                // text += state.duel.basicInformation.duelistUserName[side] + 'は ' + state.registeredCard[magic.asset].name + ' を発動した。\n';

                if (magic.status === 'canceled') {
                  text += 'しかし打ち消された。\n';
                }
              }
            }

            if (text !== '') {
              text += '\n';
            }

            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'text'], value: text});
          }
          else if (data.subType === 'battleUp') {
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'winner'], value: data.body.winner});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'winningMonster'], value: data.body.winningMonster});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'winningMonsterIndex'], value: data.body.winningMonsterIndex});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'pointA'], value: data.body.pointA});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'pointB'], value: data.body.pointB});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'round'], value: data.body.round});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'result', data.body.round], value: data.body.result});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'maginaPoint'], value: data.body.maginaPoint});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'initiative'], value: data.body.initiative});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'imageNo'], value: data.body.imageNo});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'knightBoxEffect'], value: data.body.knightBoxEffect});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'cardStatusTmp'], value: { A: [], B: [] }});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'status'], value: 'battleUp'});

            // タイムアウト時刻
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'timeOutTime'], value: data.body.timeOutTime});
            
            if (data.body.status === 'onDuel') {
              if (data.body.winner === state.duel.basicInformation.side) {
                dispatch({type: 'setStateMultiLayers', keys: ['duel', 'myStatus'], value: 'uncomfirmed'});
              } // draw含む。
              else {
                dispatch({type: 'setStateMultiLayers', keys: ['duel', 'myStatus'], value: 'waitingToSummon'});
              }

              dispatch({type: 'setStateMultiLayers', keys: ['duel', 'round'], value: data.body.roundNext});

              // 負けた方はundefined
              dispatch({type: 'setStateMultiLayers', keys: ['duel', 'monsterANextRound'], value: data.body.monsterANextRound});
              dispatch({type: 'setStateMultiLayers', keys: ['duel', 'monsterIndexANextRound'], value: data.body.monsterIndexANextRound});
              dispatch({type: 'setStateMultiLayers', keys: ['duel', 'monsterBNextRound'], value: data.body.monsterBNextRound});
              dispatch({type: 'setStateMultiLayers', keys: ['duel', 'monsterIndexBNextRound'], value: data.body.monsterIndexBNextRound});

              // dispatch({type: 'setStateMultiLayers', keys: ['duel', 'castMagics'], value: []});

              dispatch({type: 'setStateMultiLayers', keys: ['duel', 'status'], value: 'battleUp'});
            }
            else { // duelUp
              dispatch({type: 'setStateMultiLayers', keys: ['duel', 'myStatus'], value: null});

              dispatch({type: 'setStateMultiLayers', keys: ['duel', 'status'], value: 'duelUp'});
            }
          }
          else if (data.subType === 'straightManPhase') {
            let chunks = state.duel.straightManChunks;

            chunks[0] = '\n<<つっこみ>>\n';
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'straightManChunks'], value: chunks});
          }
          else {
          }
        }
        else if (data.type === 'straightManText') {
          let chunks = state.duel.straightManChunks;

          const chunkRecoverd = data.body.content
          .replace(/<-KOM control code : new line->/g, '\n')

          devLog('chunkRecoverd', chunkRecoverd);

          chunks[data.body.count] = chunkRecoverd;
          dispatch({type: 'setStateMultiLayers', keys: ['duel', 'straightManChunks'], value: chunks});
        }
        else if (data.type === 'duelRecovery') {
          const side = data.body.basicInformation.side;
          const roundOnDuel = data.body.roundOnDuel;
          const results = data.body.result;

          // 基本情報を格納

          dispatch({type: 'setStateMultiLayers', keys: ['duel', 'basicInformation'], value: data.body.basicInformation});

          // その他のリカバリ情報を格納

          dispatch({type: 'setStateMultiLayers', keys: ['duel', 'timeOutTime'], value: data.body.basicInformation.timeOutTime});
          dispatch({type: 'setStateMultiLayers', keys: ['duel', 'round'], value: roundOnDuel});
          dispatch({type: 'setStateMultiLayers', keys: ['duel', 'maginaPoint'], value: data.body.basicInformation.maginaPoint});

          // ユーザーオペレーションによる状態変化を格納

          const monstersInformation = {
            A: state.registeredCard[data.body.monsterA],
            B: state.registeredCard[data.body.monsterB],
          };

          const monstersIndex = {
            A: data.body.monsterIndexA,
            B: data.body.monsterIndexB,
          };

          // 前回バトルの結果を確認して、actionを判定する。
          let action;
          const winner = results[roundOnDuel - 1].winner;

          if (winner === side) {
            action = 'skip';
          }
          else if (winner === otherSide(side)) {
            action = 'summon';
          }
          else {
            action = 'summon';
          }

          dispatch({type: 'setStateMultiLayers', keys: ['duel', 'chunks'], value: []});
          dispatch({type: 'setStateMultiLayers', keys: ['duel', 'straightManChunks'], value: []});
          dispatch({type: 'setStateMultiLayers', keys: ['duel', 'text'], value: ''});
          dispatch({type: 'setStateMultiLayers', keys: ['duel', 'monstersInformation'], value: monstersInformation});
          dispatch({type: 'setStateMultiLayers', keys: ['duel', 'monstersIndex'], value: monstersIndex});
          dispatch({type: 'setStateMultiLayers', keys: ['duel', 'myStatus'], value: 'comfirmed'});
          dispatch({type: 'setStateMultiLayers', keys: ['duel', 'status'], value: 'onBattle'});
          dispatch({type: 'setStateMultiLayers', keys: ['duel', 'action'], value: action});
          dispatch({type: 'setStateMultiLayers', keys: ['duel', 'winner'], value: undefined});
          // dispatch({type: 'setStateMultiLayers', keys: ['duel', 'magics'], value: { [side]: data.body.magics, [otherSide(side)]: [], }});
          dispatch({type: 'setStateMultiLayers', keys: ['duel', 'magics'], value: data.body.magics});
          dispatch({type: 'setStateMultiLayers', keys: ['duel', 'imageNo'], value: { A: 0, B: 0, }});
          dispatch({type: 'setStateMultiLayers', keys: ['duel', 'knightBoxEffect'], value: { A: undefined, B: undefined, }});
          dispatch({type: 'setStateMultiLayers', keys: ['animation', 'youWinMona'], value: 'visible'});
          dispatch({type: 'setStateMultiLayers', keys: ['duel', 'visible'], value: true});

          // 魔法カードオープン・墓地カード反映

          const propertyNameOpponentDeck = `duelist${otherSide(side)}Deck`;

          for (const result of results) {
            if (result.magics !== undefined) {
              for (const magic of result.magics[otherSide(side)]) {
                dispatch({type: 'setStateMultiLayers', keys: ['duel', 'basicInformation', propertyNameOpponentDeck, 'cards', magic.index, 'asset'], value: magic.asset});
                dispatch({type: 'setStateMultiLayers', keys: ['duel', 'basicInformation', propertyNameOpponentDeck, 'cards', magic.index, 'visibility'], value: 'open'});

                for (const [index, cost] of magic.costs.entries()) {
                  if (state.registeredCard[magic.asset].costs[index].type === 'discardCards') {
                    for (const card of cost.cards) {
                      dispatch({type: 'setStateMultiLayers', keys: ['duel', 'basicInformation', propertyNameOpponentDeck, 'cards', card.index, 'asset'], value: card.asset});
                      dispatch({type: 'setStateMultiLayers', keys: ['duel', 'basicInformation', propertyNameOpponentDeck, 'cards', card.index, 'visibility'], value: 'open'});
                    }
                  }
                }
              }
            }
          }

          // resultを配列で格納

          dispatch({type: 'setStateMultiLayers', keys: ['duel', 'result'], value: results});

          if (data.subType === 'summonedMonster') {
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'pointA'], value: data.body.basicInformation.pointA});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'pointB'], value: data.body.basicInformation.pointB});
          }
          else { // battleUpOrDuelUp
            // 魔法反映

            // dispatch({type: 'setStateMultiLayers', keys: ['duel', 'monstersInformation'], value: results[roundOnDuel].monstersInformation});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'monstersInformationRevised'], value: results[roundOnDuel].monstersInformationRevised});
            // dispatch({type: 'setStateMultiLayers', keys: ['duel', 'magics'], value: data.body.magics});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'initiative'], value: data.body.basicInformation.initiative});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'castMagics'], value: []});

            // 魔法ナレーション

            let text = '';
            let monsterName = {};

            // -- 同キャラ対決のとき名前に色を付ける。
            if (data.body.monsterA === data.body.monsterB) {
              monsterName.A = state.registeredCard[data.body.monsterA].name + '(' + sideColor('A') + ')';
              monsterName.B = state.registeredCard[data.body.monsterB].name + '(' + sideColor('B') + ')';
            }
            else {
              monsterName.A = state.registeredCard[data.body.monsterA].name;
              monsterName.B = state.registeredCard[data.body.monsterB].name;
            }

            let duelistInOrder;
            const initiative = results[roundOnDuel - 1].initiativeNext;

            if (initiative === 'A' || initiative === 'B') {
              duelistInOrder = [initiative, otherSide(initiative)];
            }
            else { // coinToss, undefined
              duelistInOrder = ['A', 'B'];
            }

            for (const side of duelistInOrder) {
              const magic = data.body.magics[side][0];

              if (magic !== undefined) {
                text += monsterName[side] + 'は ' + state.registeredCard[magic.asset].name + ' を発動した。\n';
                // text += state.duel.basicInformation.duelistUserName[side] + 'は ' + state.registeredCard[magic.asset].name + ' を発動した。\n';

                if (magic.status === 'canceled') {
                  text += 'しかし打ち消された。\n';
                }
              }
            }

            // ライブテキスト

            if (text !== '') {
              text += '\n';
            }

            text += results[roundOnDuel].liveText;

            // フィールドの状況

            text += '\n<<フィールドの状況>>\n';
            text += results[roundOnDuel].field;

            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'text'], value: text});
          }
        }
        else if (data.type === 'systemMessage') {
          if (data.body.status === 'closedTheRoom') {
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'status'], value: data.body.reason}); // timeUp, timeUpAfterDuelUp, leave, leaveAfterDuelUp
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'myStatus'], value: 'end'});

            if (data.body.reason === 'timeUp' || data.body.reason === 'leave') {
              dispatch({type: 'setStateMultiLayers', keys: ['duel', 'winner'], value: data.body.winner});
              dispatch({type: 'setStateMultiLayers', keys: ['duel', 'duelWinner'], value: data.body.duelWinner});
            }
            else if (data.body.reason === 'timeUpWithoutOpponentComming') {
              handleClickEndDuel(state, dispatch, navigate);
            }
            else {
            }
          }
          else if (data.body.status === 'gptError') {
            // gptError または irregularPattern によるリトライのため、デュエルテキストをリセットする。
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'chunks'], value: []});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'straightManChunks'], value: []});
          }
          else if (data.body.status === 'transactWriteSummonRejected') {
            // おそらくコンフリクトしているので、リトライする。
            if (state.duel.action === 'summon') {
              const requestBody = {
                action: 'summon',
                addressMain: state.active.addressMain,
                roomId: state.duel.basicInformation.roomId,
                roomSubId: state.duel.basicInformation.roomSubId,
                summonedMonster: state.duel.monstersInformation[state.duel.basicInformation.side].asset, 
                summonedMonsterIndex: state.duel.monstersIndex[state.duel.basicInformation.side],
                castMagics: state.duel.castMagics,
              };

              handleClickSummonMonster(state, dispatch, requestBody);
            }
            else if (state.duel.action === 'skip') {
              const message = {
                action: 'skip',
                addressMain: state.active.addressMain,
                roomId: state.duel.basicInformation.roomId,
                roomSubId: state.duel.basicInformation.roomSubId,
                castMagics: state.duel.castMagics,
              };

              handleClickNext(state, dispatch, message);
            }
          }
        }
        else { // undefined エラーメッセージ
          const messages = {
            "internal server error": words.internalServerError[state.language],
            "this address is not permitted.": words.thisAddressIsNotPermitted[state.language],
            "this address is forbidden.": words.thisAddressIsForbidden[state.language],
            "out of service": words.outOfService[state.language],
            "user does not exist.": words.pleaseRegisterAsAUserOnTheSetupPage[state.language],
            "invalid session.": words.pleaseLogin[state.language],
            "not enough points.": words.notEnoughPoints[state.language],
            "valid points do not exist.": words.notEnoughPoints[state.language],
            [`the number of cards in a deck must be between 3 and ${state.config.clientParameters.numberOfSlots.simple}.`]: words.theNumberOfCardsInADeckMustBeBetween3And7[state.language],
            [`the number of cards in a deck must be between 3 and ${state.config.clientParameters.numberOfSlots.advanced}.`]: words.theNumberOfCardsInADeckMustBeBetween3And11[state.language],
            "you can not use magic cards in simple mode.": words.youCanNotUseMagicCardsInSimpleMode[state.language],
            'gameMode or difficultyMode is unmatched.': words.youArePlayingAnotherDuelWhoseGameModeIsDifferent[state.language],
          };

          if (data.applicationMessage !== undefined && messages[data.applicationMessage] !== undefined) {
            dispatch({ type: 'setNotification', key: 'notification', value: messages[data.applicationMessage] });
          }
        }
      }
      catch (error) {
        // エラー通知
        devLog('irregular WebSocket data.', error);
      }
    };

    websocketClient.addEventListener('message', onMessage);


    const onClose = (event) => {
      // 通知
      sendLog({
        action: 'notifyWithSession',
        addressMain: state.active.addressMain,
        sessionId: state.session[state.active.addressMain].sessionId,
        message: `WebSocket closed; ${JSON.stringify(event)}`,
      });

      devLog('WebSocket closed by server.', JSON.stringify(event));
      // handleClickLogin(state, dispatch); // ← endDuelでのconnection検索＆部屋人への終了通知の妨げになる。どうせ↓でendDuel処理が走るのでコネクションのクリアは問題ない。
      handleClickEndDuel(state, dispatch, navigate);
    };

    websocketClient.addEventListener('close', onClose);


    const onError = (event) => {
      // 通知
      sendLog({
        action: 'notifyWithSession',
        addressMain: state.active.addressMain,
        sessionId: state.session[state.active.addressMain].sessionId,
        message: `WebSocket error; ${JSON.stringify(event)}`,
      });

      devLog('WebSocket error.', JSON.stringify(event));
      // handleClickLogin(state, dispatch); // ← endDuelでのconnection検索＆部屋人への終了通知の妨げになる。どうせ↓でendDuel処理が走るのでコネクションのクリアは問題ない。
      handleClickEndDuel(state, dispatch, navigate);
    };

    websocketClient.addEventListener('close', onError);


    const beforeUnload = () => {
      // const requestBody = {
      //   action: 'endDuel',
      //   addressMain: state.active.addressMain,
      //   sessionId: state.session[state.active.addressMain].sessionId,
      // };

      // websocketClient?.send(JSON.stringify(requestBody));
      websocketClient?.close();
      devLog('closed WebSocket.');
    }

    window.addEventListener('beforeunload', beforeUnload, true);


    return () => {
      try {
        // const requestBody = {
        //   action: 'endDuel',
        //   addressMain: state.active.addressMain,
        //   sessionId: state.session[state.active.addressMain].sessionId,
        // };

        // websocketClient?.send(JSON.stringify(requestBody));

        websocketClient?.close();
        devLog('WebSocket closed');
      }
      catch (error) {
        devLog('WebSocket may already be closed.', JSON.stringify(error));
      }

      dispatch({type: 'setStateMultiLayers', keys: ['webSocketContainer', 'webSocket'], value: undefined});

      window.removeEventListener('beforeunload', beforeUnload, true);
    };
  }, []);

  return (
    <div>
    </div>
  );
}

// NOW LOADING
function NowLoading() {
  return (
    <div className='flexColumn justifyContentCenter alignItemsCenter width100vw height100vh ' >
      <div className='font2 ' >
        { 'now loading' }
      </div>
    </div>
  );
}

// PLEASE LOGIN
function PleaseLogin() {
  const [state] = useContext(GlobalState);


  return (
    <div className='flexColumn justifyContentCenter alignItemsCenter width100vw height100vh ' >
      <img className='size2x2' src={monaFavicon} alt='' />
      <div className=''>
        { words.pleaseLogin[state.language] }
      </div>
    </div>
  );
}

// CLOCK
function Clock() {
  const [state, dispatch] = useContext(GlobalState);
  const clockIntervalSec = 500;

  useEffect( () => {
    const clockInterval = setInterval( async () => {
      const now = new Date();

      dispatch({type: 'setState', key: 'clock', value: now.getTime()});
    }, clockIntervalSec);

    return () => {
      clearInterval(clockInterval);
    }
  }, []);

  return null;
}

// DUEL TEXT
function DuelText(props) {
  const [state, dispatch] = useContext(GlobalState);
  const fontSizeClass = props.fontSizeClass;

  let prefield;
  let prefieldSp;

  if (state.duel.status === 'duelStandby') {
    prefield =
    <div className='prefield flexColumn justifyContentFlexStart padding0p5' >
      <div>
        { '<<フィールドの状況>>' } 
      </div>
      <div className='' >
        { state.duel.result[0].field }
      </div>
    </div>

    prefieldSp =
    <div className='prefieldSp flexColumn justifyContentFlexStart padding0p5 margin0p5' >
      <div>
        { '<<フィールドの状況>>' } 
      </div>
      <div className='' >
        { state.duel.result[0].field }
      </div>
    </div>
  }


  return (
    <div>
      {/* PC */}
      <div className="visibleHuge">
        <div className='boxDuelText'>
          { state.duel.text + state.duel.chunks.join('') }
          { state.duel.straightManChunks.join('') }
          { prefield }
        </div>
      </div>
      {/* SP */}
      <div className="visibleLargeOrLess flexColumn alignItemsCenter">
        <div className={'boxDuelText sizeBoxDuelTextSP ' + fontSizeClass} >
          { state.duel.text + state.duel.chunks.join('') }
          { state.duel.straightManChunks.join('') }
        </div>
        { prefieldSp }
      </div>
    </div>
  );
}

// NEXT BUTTON
function NextButton(props) {
  const [state, dispatch] = useContext(GlobalState);
  const popupLayers = props.popupLayers;

  let nextButton;

  if (state.duel.myStatus === 'uncomfirmed' && state.duel.selectStatus.mode === 'neutral') {
    nextButton =
    <button className='button2 pointerEventsAuto '
      onClick={ () => {
        const message = {
          action: 'skip',
          addressMain: state.active.addressMain,
          roomId: state.duel.basicInformation.roomId,
          roomSubId: state.duel.basicInformation.roomSubId,
          castMagics: state.duel.castMagics,
        };

        handleClickNext(state, dispatch, message);

        if (popupLayers !== undefined) {
          for (const popupLayer of popupLayers) {
            dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayer], value: { type: null, body: null } })
          }
        }
      }}
    >
      OK
    </button>
  }

  return nextButton;
}

// MAGINA POINT
function MaginaPoint(props) {
  const [state, dispatch] = useContext(GlobalState);
  const side = props.side;
  const baseClass = props.baseClass;
  const initiativeClass = props.initiativeClass;

  let maginaPoint;

  if (side !== undefined) {
    let additionalClass;

    if (state.duel.initiative === side && state.duel.status === 'battleUp') {
      // maginaPointClass = 'padding0p5 borderFat borderRadius0p5 ' + borderColor(side);
      additionalClass = initiativeClass;
    }
    else if (state.duel.initiative === otherSide(side) && state.duel.status === 'battleUp') {
      additionalClass = '';
    }
    else { // coinToss
      additionalClass = '';
    }

    maginaPoint =
    <div className={ baseClass + ' ' + additionalClass } > 
      { 'MP ' + state.duel.maginaPoint[side] }
    </div> 
  }

  return maginaPoint;
}

// MAGINA POINT APPLIED
function MaginaPointApplied(props) {
  const [state, dispatch] = useContext(GlobalState);
  const side = props.side;
  const sideRelative = props.sideRelative;
  const device = props.device;
  const magic = state.duel.magics?.[side]?.[0];
  const maginaPoint = state.duel.maginaPoint;

  let maginaPointApplied;

  if (magic !== undefined) {
    if (sideRelative === 'other' || state.duel.castMagics[0] === undefined) {
      if (device === 'SP') {
        maginaPointApplied =
        <div className={ 'padding0p3 font0p7 borderThin borderRadius0p5 ' + borderColor(maginaPoint.applied.side) } > 
          { '<< ' + maginaPoint.applied.point }
        </div> 
      }
      else { // PC
        maginaPointApplied =
        <div className={ 'padding0p5 borderThin borderRadius0p5 ' + borderColor(maginaPoint.applied.side) } > 
          { maginaPoint.applied.point }
        </div> 
      }
    }
  }

  return maginaPointApplied;
}

// MAGINA GAUGE
function MaginaGauge(props) {
  const [state, dispatch] = useContext(GlobalState);
  const side = props.side;
  const sideRelative = props.sideRelative;
  const direction = props.direction;
  const noMagic = props.noMagic;
  const magic = state.duel.magics?.[side]?.[0];
  const maginaPoint = state.duel.maginaPoint;

  const backgroundColorClass = {
    A: 'classBackGroundA',
    B: 'classBackGroundB',
  };

  let maginaGauge;

  // if (state.duel.maginaPoint.applied !== undefined && state.duel.maginaPoint.applied !== null) {
  //   maginaPoint = state.duel.maginaPoint.applied;
  // }
  // else {
  //   maginaPoint = state.duel.maginaPoint[side];
  // }

  if (magic !== undefined) {
    const maginaThresholds = state.registeredCard[magic.asset].maginaThresholds;
    const thresholdsWithBox = maginaThresholds.map( threshold => {
      return {
        upperBound: threshold.upperBound,
        face: threshold.face,
        box: [],
      };
    });

    if (sideRelative === 'other' || state.duel.castMagics[0] === undefined) {
      for (const threshold of thresholdsWithBox) {
        if (maginaPoint.applied.point < threshold.upperBound) {
          threshold.box.push({ side: maginaPoint.applied.side, point: maginaPoint.applied.point })
          break;
        }
      }
    }
    else {
      for (const side of ['A', 'B']) {
        for (const threshold of thresholdsWithBox) {
          if (maginaPoint[side] < threshold.upperBound) {
            threshold.box.push({ side: side, point: maginaPoint[side] })
            break;
          }
        }
      }
    }

    if (direction === 'horizontal') {
      maginaGauge =
      <div className='flexRow alignItemsCenter borderMonacotto padding0p3' >
        <div className='textCenter height1p6 font0p7' >
          { '0' }
        </div>
        {
          thresholdsWithBox.map( threshold =>
              <div className='flexRowReverse justifyContentCenter alignItemsCenter ' >
                <div className='textCenter height1p6 font0p7' >
                  { threshold.face !== undefined ? threshold.face : threshold.upperBound }
                </div>
                <div className='flexColumn justifyContentCenter alignItemsCenter width1 height1p6' >
                  {
                    threshold.box.map( ball => 
                      <div className={'width0p7 height0p7 borderRadiusCircle ' + backgroundColorClass[ball.side]} >
                      </div>
                    )
                  }
                </div>
              </div>
          )
        }
      </div>;
    }
    else { // vertical
      maginaGauge =
      <div className='flexColumnReverse alignItemsCenter borderMonacotto' >
        <div className='textCenter width2p4 height1p2' >
          { '0' }
        </div>
        {
          thresholdsWithBox.map( threshold =>
              <div className='flexColumn justifyContentCenter alignItemsCenter ' >
                <div className='textCenter width2p4 height1p2' >
                  { threshold.face !== undefined ? threshold.face : threshold.upperBound }
                </div>
                <div className='flexRow justifyContentCenter alignItemsCenter width2p4 height1p2' >
                  {
                    threshold.box.map( ball => 
                      <div className={'width1 height1 borderRadiusCircle ' + backgroundColorClass[ball.side]} >
                      </div>
                    )
                  }
                </div>
              </div>
          )
        }
      </div>;
    }
  }
  else {
    if (noMagic === 'blank') {
      maginaGauge = null;
    }
    else {
      maginaGauge =
      <div className='flexColumnReverse alignItemsCenter borderMonacotto' >
      </div>;
    }
  }

  return maginaGauge;
}

// TARGET INDICATION
function TargetIndication(props) {
  const [state, dispatch] = useContext(GlobalState);
  const thisSide = props.side;
  let textClass = props.textClass;

  if (textClass === undefined) {
    textClass = '';
  }

  if (state.duel.magics?.[thisSide]?.[0]?.targets !== undefined) {
    const targets = state.duel.magics[thisSide][0].targets;
    // const targetsReduced = targets.reduce( (acc, cur) => acc + Object.keys(cur).reduce( (acc2, cur2) => `${acc2}${words[cur2][state.language]}: ${words[cur[cur2].thisSide][state.language]} `, ''), '');

    const targetsReduced = targets.reduce( (acc, cur) =>
      acc + Object.keys(cur).reduce( (acc2, cur2) => {
        if (cur[cur2].side !== undefined && cur[cur2].side !== null) {
          return `${acc2}${words[cur[cur2].side][state.language]} `
        }
        else {
          return acc;
        }
      }, ''),
    '');

    if (targetsReduced !== '') {
      return (
        <div className={'borderKOM borderRadius0p3 margin0p5 padding0p5 ' + textClass} >
          { targetsReduced }
        </div>
      );
    }
  }
  
  return null;
}

// CANCEL MAGIC
function CancelMagic() {
  const [state, dispatch] = useContext(GlobalState);

  let cancelMagic;

  if ((state.duel.myStatus === 'waitingToSummon' || state.duel.myStatus === 'uncomfirmed') && state.duel.castMagics.length >= 1) {
    cancelMagic =
    <button className='button2' onClick={ () => { handleClickCancelMagic(state, dispatch, 0) }} >
      { words.cancel[state.language] }
    </button>;
  }

  return cancelMagic;
}

// FLASH
function Flash() {
  const animation = useAnimation(
    [
      { opacity: "1" },
      { opacity: "0" },
    ],
    {
      duration: 100,
      iterations: 10,
      easing: "ease-in-out",
    }
  );

  useEffect(() => {
    animation.play();
  }, []);

  return (
      <div
        ref={animation}
        style={{
          position: "fixed",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          border: "none",
          // border: "2px solid black",
          backgroundColor: "white",
          // top: "50%",
          // left: "50%",
          // transform: "translate(-50%,-50%)",
          zIndex: "300",
        }}
      >
        {/* <img className='widthMax heightMax' src={logoWin} /> */}
      </div>
  );
}

// DECK ON DUEL
function DeckOnDuel(props) {
  const [state, dispatch] = useContext(GlobalState);
  // const side = props.side;
  const deck = props.deck;
  const sideRelative = props.sideRelative;

  const mySide = state.duel.basicInformation?.side;
  let thisSide;
  let otherSide;

  if (sideRelative === 'self') {
    if (mySide === 'A') {
      thisSide = 'A';
      otherSide = 'B';
    }
    else {
      thisSide = 'B';
      otherSide = 'A';
    }
  }
  else { // other
    if (mySide === 'A') {
      thisSide = 'B';
      otherSide = 'A';
    }
    else {
      thisSide = 'A';
      otherSide = 'B';
    }
  }

  /*
  if (mySide === 'A') {
    deck = state.duel.basicInformation.duelistADeck;
  }
  else { // B
    deck = state.duel.basicInformation.duelistADeck;
  }
  */

  const losingMonsters = state.duel.result
  .filter( round => round.winner === otherSide ) // drawは除外される。
  .map( round => round.monstersIndex[thisSide] );

  let cards;

  if (deck !== undefined) {
    cards = deck?.cards.map ( (card, index) =>
      <CardOnDuel
        asset={card.asset}
        index={index}
        visibility={card.visibility}
        side={thisSide}
        sideRelative={sideRelative}
        losing={losingMonsters.includes(index)}
        style='small'
      />
    );
  }
  else {
    cards = [];
  }


  return (
    <div>
      {/* PC */}
      <div className="visibleMiddleOrMore">
        <div className='flexRow flexWrapNoWrap maxWidth97vw scrollXAuto '>
          { cards.map( card => <div className='width11 ' >{ card }</div> ) }
        </div>
      </div>
      {/* SP */}
      <div className="visibleSmallOrLess">
        <div className='flexRow'>
          { cards }
        </div>
      </div>
    </div>
  );
}

// DECK ON DUEL SP
function DeckOnDuelSP(props) {
  const [state, dispatch] = useContext(GlobalState);
  // const side = props.side;
  const popupLayer = props.popupLayer;
  const deck = state.popup[popupLayer].body.deck;
  const sideRelative = state.popup[popupLayer].body.sideRelative;
  const mySide = state.duel.basicInformation?.side;

  let thisSide;
  let otherSide;
  let thisSideClass;
  let thisSideBackGround;
  let thisSideAlternativeMagic;
  let nextButton;

  if (sideRelative === 'self') {
    if (mySide === 'A') {
      thisSide = 'A';
      otherSide = 'B';
      thisSideClass = 'classA';
      thisSideBackGround = 'classBackGroundA';
      thisSideAlternativeMagic = 'imageMagicRed';
    }
    else {
      thisSide = 'B';
      otherSide = 'A';
      thisSideClass = 'classB';
      thisSideBackGround = 'classBackGroundB';
      thisSideAlternativeMagic = 'imageMagicBlue';
    }

    nextButton = <NextButton popupLayers={[popupLayer]} />;
  }
  else { // other
    if (mySide === 'A') {
      thisSide = 'B';
      otherSide = 'A';
      thisSideClass = 'classB';
      thisSideBackGround = 'classBackGroundB';
      thisSideAlternativeMagic = 'imageMagicBlue';
    }
    else {
      thisSide = 'A';
      otherSide = 'B';
      thisSideClass = 'classA';
      thisSideBackGround = 'classBackGroundA';
      thisSideAlternativeMagic = 'imageMagicRed';
    }
  }

  const losingMonsters = state.duel.result
  .filter( round => round.winner === otherSide ) // drawは除外される。
  .map( round => round.monstersIndex[thisSide] );

  const cards = deck?.cards.map ( (card, index) =>
    <CardOnDuel
      asset={card.asset}
      index={index}
      visibility={card.visibility}
      side={thisSide}
      sideRelative={sideRelative}
      losing={losingMonsters.includes(index)}
      device='SP'
      style='small'
    />
  );

  // 詠唱魔法

  let thisSideMagic;
  // let cancelMagic;
  // let targetIndication;

  thisSideMagic =
  <CardOnDuel
    asset={ state.duel.magics?.[thisSide]?.[0]?.asset }
    index={ state.duel.magics?.[thisSide]?.[0]?.index }
    side={thisSide}
    alternative={thisSideAlternativeMagic} 
    device='SP'
    style='small'
    position='cast'
  />;


  return (
    <div className='visibleSmallOrLess'>
      <div className={'flexColumn alignItemsFlexStart padding0p3 marginTop0p5 ' + thisSideBackGround} >
        <div className='flexRow justifyContentSpaceBetween alignItemsCenter widthMax ' >
          <div className='flexRow justifyContentFlexStart alignItemsCenter marginLeft0p5' >
            { thisSideMagic }
            <div className='flexColumn justifyContentFlexStart alignItemsCenter marginSide0p5' >
              <MaginaPoint side={thisSide} baseClass='padding0p5' initiativeClass={'borderFat borderRadius0p5 ' + borderColor(thisSide)} />
              <TargetIndication side={thisSide} />
            </div>
          </div>
          <div className='marginRight0p5' >
            <CancelMagic />
          </div>
        </div>
        <div className='marginSide1 marginTop0p5' >
          <MaginaGauge side={thisSide} sideRelative={sideRelative} direction='horizontal' noMagic='blank' />
        </div>
        <div className='marginSide1 marginTop0p5' >
          <MagicInstruction />
        </div>
        <div className='flexRow justifyContentSpaceAround alignItemsCenter marginTop0p5 ' >
          { cards }
        </div>
        <div className='flexRow justifyContentFlexEnd alignItemsCenter widthMax marginTop0p5' >
          { nextButton }
          <button className='button2' onClick={ () => dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayer], value: { type: null, body: null } }) } >
            { words.close[state.language] }
          </button>
        </div>
      </div>
      { <SelectControlButton layer={popupLayer + 1} /> }
    </div>
  );
}

// CARD ON DUEL
function CardOnDuel(props) {
  const [state, dispatch] = useContext(GlobalState);
  const side = props.side;
  // const round = state.duel.round;
  const asset = props.asset;
  const index = props.index;
  const visibility = props.visibility;
  const losing = props.losing;
  const device = props.device;
  const sideRelative = props.sideRelative;
  const position = props.position;
  const cardStatus = getCardStatus(state, side, index);
  const cardStatusTmp = getCardStatusTmp(state, side, index);

  let styleBox;
  let styleCard;
  let styleBoxSP;
  let styleCardSP;

  if (props.style === 'small') {
    styleBox = 'boxCardSmall';
    styleCard = 'cardImageSmall';
    styleBoxSP = 'boxCardSP';
    styleCardSP = 'cardImageSmallSP';
  }
  else {
    styleBox = 'boxCard';
    styleCard = 'cardImage';
    styleBoxSP = 'boxCardSP';
    styleCardSP = 'cardImageSP';
  }

  let styleBoxColor = '';
  let styleBorderColor = '';
  let styleBoxPadding = '';

  if (asset !== undefined) {
    if (position !== 'cast' && position !== 'field') {
      if (state.registeredCard[asset].category === 'magic') {
        styleBoxColor = ' backgroundColorLightGreen';
        styleBorderColor = ' borderDeepGreenFat';
        styleBoxPadding = ' padding0p5';
      }
      else if (state.registeredCard[asset].category !== 'magic') {
        styleBoxPadding = ' padding0p5';
      }
      else {
      }
    }
    else {
    }
  }
  else {
  }

  styleBox += styleBoxColor + styleBorderColor;
  styleBoxSP += styleBoxColor + styleBorderColor + styleBoxPadding;

  // -- カードステータス

  let monsterStatus;
  let screen;

  if (position === 'field') {
    monsterStatus = null;
    screen = null;
  }
  else if (position === 'cast') {
    monsterStatus = null;
    screen = null;
  }
  // if (losing === true) 
  else if (cardStatus === 'cemetery') {
    monsterStatus =
    <div className='monsterStatus width75PC '>
      {/* <img className='widthMax' src={logoLose} /> */}
      <img className='width70P' src={imageCemetery} />
    </div>

    screen =
    <div className='screen140'>
    </div>;
  }
  else if (cardStatusTmp === 'cast') {
    if (side === 'A') {
      monsterStatus =
      <div className='monsterStatus width75PC '>
        <img className='width70P' src={imageMagicRed} />
      </div>
    }
    if (side === 'B') {
      monsterStatus =
      <div className='monsterStatus width75PC '>
        <img className='width70P' src={imageMagicBlue} />
      </div>
    }

    screen =
    <div className='screen140'>
    </div>;
  }
  else if (cardStatusTmp === 'cost') {
    monsterStatus =
    <div className='monsterStatus width75PC '>
      <img className='width70P' src={imageCost} />
    </div>

    screen =
    <div className='screen140'>
    </div>;
  }
  else {
    monsterStatus = null;
    screen = null;
  }

  let cardImageUrl;
  let cardImage;
  let cardName;
  let cardImageUrlSP;
  let cardImageSP;
  // let cardNameSP;
  let summonButtonSP;

  const summonMonsterFunction = () => {
    if (sideRelative === 'self' && state.duel.myStatus === 'waitingToSummon' && index !== undefined && cardStatus === 'hand' && cardStatusTmp === 'available') {
      const requestBody = {
        action: 'summon',
        addressMain: state.active.addressMain,
        roomId: state.duel.basicInformation.roomId,
        roomSubId: state.duel.basicInformation.roomSubId,
        summonedMonster: asset, 
        summonedMonsterIndex: index,
        castMagics: state.duel.castMagics,
      };

      handleClickSummonMonster(state, dispatch, requestBody);

      if (device === 'SP') {
        dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: { type: null, body: null } }) // ちゃんとpropsでpopupLayer受け取ってもいいよ。
      }
    }
    else {
    }
  }

  const costMonsterFunction = () => {
    if (sideRelative === 'self' && (state.duel.myStatus === 'waitingToSummon' || state.duel.myStatus === 'uncomfirmed') && index !== undefined && cardStatus === 'hand' && cardStatusTmp === 'available') {
      handleClickCostMonster(state, dispatch, asset, index);
    }
    else {
    }
  }

  const castMagicFunction = () => {
    if (sideRelative === 'self' && (state.duel.myStatus === 'waitingToSummon' || state.duel.myStatus === 'uncomfirmed') && index !== undefined && cardStatus === 'hand' && cardStatusTmp === 'available') {
      handleClickCastMagic(state, dispatch, asset, index);
    }
    else {
    }
  }

  const viewCardDetailFunction = (params) => { // あまりにもひどすぎる。
    const body = {
      monster: {
        asset: asset, 
        index: index,
      },
      item: {
        asset: asset, 
        index: index,
      },
      side: side,
      position: position,
      actionBox: params.actionBox,
    };

    const popup = { type: params.popupType, body: body };
    dispatch({ type: 'setStateMultiLayers', keys: ['popup', params.popupLayer], value: popup });
  }

  if (visibility === 'hidden') {
    // ひみつ画像
    cardImage =
    <button className={styleBox + ' '} disabled={props.disabled ? true : false} >
        <div className={styleCard + ' relative flexColumn '} >
          { screen }
          <img className='widthMax' src={imageHiddenMagic} />
        </div>
        { monsterStatus }
    </button>

    cardImageSP =
    <button className={styleBoxSP + ' padding0p5'} disabled={props.disabled ? true : false} >
        <div className={styleCardSP + ' relative flexColumn '} >
          { screen }
          <img className='widthMax' src={imageHiddenMagic} />
        </div>
        { monsterStatus }
    </button>

    cardName = null;
    summonButtonSP = null;
  }
  else if (asset === undefined) {
    // 代替画像
    if (props.alternative === 'emblemRed') {
      cardImage =
      <div className='flexColumn justifyContentCenter height27 ' >
        <img className={styleCard} src={emblemRed} />
      </div>

      cardImageSP =
      <div className='flexColumn justifyContentCenter heightEmblemSP' >
        <img className={styleCardSP} src={emblemRed} />
      </div>
    }
    else if (props.alternative === 'emblemBlue') {
      cardImage =
      <div className='flexColumn justifyContentCenter height27 ' >
        <img className={styleCard} src={emblemBlue} />
      </div>

      cardImageSP =
      <div className='flexColumn justifyContentCenter heightEmblemSP' >
        <img className={styleCardSP} src={emblemBlue} />
      </div>
    }
    else if (props.alternative === 'imageMagicRed') {
      cardImage =
      <div className='flexColumn justifyContentCenter marginTop2' >
        <img className={styleCard} src={imageMagicRed} />
      </div>

      cardImageSP =
      <div className='flexColumn justifyContentCenter ' >
        <img className={styleCardSP} src={imageMagicRed} />
      </div>
    }
    else if (props.alternative === 'imageMagicBlue') {
      cardImage =
      <div className='flexColumn justifyContentCenter marginTop2' >
        <img className={styleCard} src={imageMagicBlue} />
      </div>

      cardImageSP =
      <div className='flexColumn justifyContentCenter ' >
        <img className={styleCardSP} src={imageMagicBlue} />
      </div>
    }
    else {
      return null;
    }

    cardName = null;
    summonButtonSP = null;
  }
  else {
    // カード画像

    if (position === 'field' && state.duel.imageNo[side] !== 0) {
      // if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
      //   cardImageUrl = `https://knightsofmonadom.monatoka.com/monacard/${asset}_${state.duel.imageNo[side]}.png`;
      // }
      // else { // dev
      //   cardImageUrl = `https://dev.knightsofmonadom.monatoka.com/monacard/${asset}_${state.duel.imageNo[side]}.png`;
      // }

      cardImageUrl = `https://${process.env.REACT_APP_MONACARD_URL}${asset}_${state.duel.imageNo[side]}.png`;
    }
    else {
      // cardImageUrl = `https://dev.knightsofmonadom.monatoka.com/monacard/${asset}.png`;

      cardImageUrl = `https://${process.env.REACT_APP_MONACARD_URL}${asset}.png`;
    }

    // クリックアクション

    let onClickFunction;

    if (position === 'field' || position === 'cast') {
      onClickFunction = viewCardDetailFunction;
    }
    else {
      if (state.duel.selectStatus.mode === 'neutral') {
        if (state.registeredCard[asset].category === 'magic') {
          onClickFunction = castMagicFunction;
        }
        else { // monster
          onClickFunction = summonMonsterFunction;
        }
      }
      else if (state.duel.selectStatus.mode === 'magic') {
        if (getSelectStatusType(state) === 'cost') {
          onClickFunction = costMonsterFunction;
        }
        else {
          onClickFunction = null;
        }
      }
    }

    // カード画像ボタン

    cardImage =
    <button className={styleBox + ' '} disabled={props.disabled ? true : false}
      onClick={ () => onClickFunction({popupType: 'monsterDetail', popupLayer: 0}) } // ちゃんとpropsでpopupLayer受け取ってもいいよ。
    >
      <div className={styleCard + ' relative flexColumn '} >
        { screen }
        <img className='widthMax' src={cardImageUrl} />
      </div>
      { monsterStatus }
    </button>

    cardImageSP =
    <button className={styleBoxSP + ' '} disabled={props.disabled ? true : false}
      onClick={ () => {
        onClickFunction({popupType: 'itemDetailSP', popupLayer: 1, actionBox: 'close'}); // ちゃんとpropsでpopupLayer受け取ってもいいよ。

        if (position === 'field' || position === 'cast') {
          dispatch({ type: 'setStateMultiLayers', keys: ['animation', 'youWinMona'], value: 'invisible' });
        }
      }}
    >
      <div className={styleCardSP + ' relative flexColumn '} >
        { screen }
        <img className='widthMax' src={cardImageUrl} />
      </div>
      { monsterStatus }
    </button>

    // 補正カード名

    let nameRevised;

    if (position === 'field' && state.duel.monstersInformationRevised?.[side]?.name !== undefined) {
      nameRevised = state.duel.monstersInformationRevised?.[side]?.name;
    }
    else {
      nameRevised = state.registeredCard[asset].name;
    }

    // 魔法カラー

    let backgroundColor;

    // if (state.registeredCard[asset].category === 'magic' && position !== 'cast') {
    //   backgroundColor = 'backgroundColorMagic';
    // }
    // else {
    //   backgroundColor = 'backgroundColorTransparent';
    // }

    backgroundColor = 'backgroundColorTransparent';

    // カード名ボタン

    cardName =
    <button className={'heightMin3 borderNone cursor borderRadius2 riseOut2 marginSide0p2 marginTop0p5 ' + backgroundColor} disabled={props.disabled ? true : false}
      onClick={ () => viewCardDetailFunction({popupType: 'monsterDetail', popupLayer: 0}) } // ちゃんとpropsでpopupLayer受け取ってもいいよ。
    >
      { nameRevised }
    </button>

    /*
    cardNameSP =
    <button className='heightMin1 borderNone backgroundColorTransparent cursor borderRadius2 riseOut2 marginSide0p2 marginTop0p5 font0p5' disabled={props.disabled ? true : false}
      onClick={ () => {
          const monster = {
            asset: asset, 
            index: index,
          };

          const popup = { type: 'monsterDetail', body: monster };
          dispatch({ type: 'setStateMultiLayers', keys: ['popup', 1], value: popup });
      }}
    >
      { state.registeredCard[asset].name }
    </button>
    */

    // スマートフォンカード詳細ボタン

    // if (sideRelative === 'self' && state.duel.myStatus === 'waitingToSummon' && index !== undefined && losing === false) 
    if (position !== 'field' && position !== 'cast') {

      // 魔法カラー

      let backgroundColor = '';

      // if (state.registeredCard[asset].category === 'magic') {
      //   // backgroundColor = 'backgroundColorMagic';
      //   backgroundColor = ' backgroundImageNone backgroundColorLightGreen';
      // }
      // else {
      //   backgroundColor = '';
      // }

      summonButtonSP =
      <button className={'button1 marginTop0p5 ' + backgroundColor} tabindex='0'
        onClick={ () => viewCardDetailFunction({popupType: 'itemDetailSP', popupLayer: 1, actionBox: 'close'}) } // ちゃんとpropsでpopupLayer受け取ってもいいよ。
        // onClick={ () => {
        //     const body = {
        //       item: {
        //         asset: asset, 
        //         index: index,
        //       },
        //       side: side,
        //       position: position,
        //       actionBox: 'close',
        //     };

        //     const popup = { type: 'itemDetailSP', body: body };
        //     dispatch({ type: 'setStateMultiLayers', keys: ['popup', 1], value: popup });
        // }}
      >
        <img className='size2x2' src={iconDetail} />
      </button>
    }
    else {
      summonButtonSP = null;
    }
  }


  return (
    <div>
      {/* PC */}
      <div className="visibleMiddleOrMore ">
        <div className='flexColumn alignItemsCenter ' >
          { cardImage }
          { cardName }
        </div>
      </div>
      {/* SP */}
      <div className="flexColumn alignItemsCenter visibleSmallOrLess ">
        { cardImageSP }
        {/* cardNameSP */}
        { summonButtonSP }
      </div>
    </div>
  );
}

// SELECT ACTION BOX
function SelectActionBox(props) {
  const [state, dispatch] = useContext(GlobalState);
  const item = props.item;


  return (
    <div className='flexRow justifyContentSpaceAround widthMax'>
      <button className='button2 widthDiv3'
        onClick={ () => {
          addCardToDeck(state, dispatch, item);
          dispatch({ type: 'setStateMultiLayers', keys: ['popup', props.popupLayer], value: { type: null, body: null } });
        }}
      >
        <img className='size2x2' src={iconInput} />
      </button>
      <button className='button2 widthDiv3'
        onClick={ () => {
          removeCardFromDeck(state, dispatch, item.asset);
          dispatch({ type: 'setStateMultiLayers', keys: ['popup', props.popupLayer], value: { type: null, body: null } });
        }}
      >
        <img className='size2x2' src={iconOutput} />
      </button>
      <button className='button2 widthDiv3' onClick={ () => { dispatch({ type: 'setStateMultiLayers', keys: ['popup', props.popupLayer], value: { type: null, body: null } }); } }  >
        { words.close[state.language] }
      </button>
    </div>
  );
}

// MAGIC INSTRUCTION
function MagicInstruction(props) {
  const [state] = useContext(GlobalState);
  const fontSize = props.fontSize;
  const selectStatus = state.duel.selectStatus;
  const myStatus = state.duel.myStatus;
  let instruction = null;

  if (selectStatus.mode === 'magic') {
    const magic = state.duel.castMagics[selectStatus.magicIndex];
    const route = magic.route[selectStatus.routeIndex];

    if (route.type === 'target') {
      if (route.detail.source === 'field') {
        instruction = <InstructionAnimation text={ words.selectTheTargetOfTheMagic[state.language] } fontSize={ fontSize } />
      }
    }
    else if (route.type === 'cost') {
      if (route.detail.type === 'discardCards') {
        instruction = <InstructionAnimation text={ words.selectTheCardToDiscardToTheCemetery[state.language] } fontSize={ fontSize } />
      }
    }
  }
  else if (myStatus === 'waitingToSummon' && selectStatus.mode === 'neutral') {
    if (state.duel.castMagics.length === 0) {
      instruction = <InstructionAnimation text={ words.selectTheMagicToCastOrTheKnightToSummon[state.language] } fontSize={ fontSize } />
    }
    else {
      instruction = <InstructionAnimation text={ words.selectTheKnightToSummon[state.language] } fontSize={ fontSize } />
    }
  }
  else if (myStatus === 'uncomfirmed' && selectStatus.mode === 'neutral') {
    if (state.duel.castMagics.length === 0) {
      instruction = <InstructionAnimation text={ words.selectTheMagicToCastOrClickOk[state.language] } fontSize={ fontSize } />
    }
    else {
      instruction = <InstructionAnimation text={ words.clickOk[state.language] } fontSize={ fontSize } />
    }
  }


  return instruction;
}

// INSTRUCTION ANIMATION
function InstructionAnimation(props) {
  const text = props.text;
  const fontSize = props.fontSize;
  const KOMColor = '#b08030';

  const animation = useAnimation(
    [
      { backgroundColor: 'rgba(255,255,255,0.5)' },
      { backgroundColor: 'rgba(225,187,77,0.9)' },
    ],
    {
      duration: 1000,
      iterations: Infinity,
      easing: 'ease-in-out',
    }
  );

  useEffect(() => {
    animation.play();
  }, []);


  return (
    <button
      ref={animation}
      style={{
        border: `1px solid ${KOMColor}`,
        borderRadius: '0.5em',
        padding: '0.5em',
        backgroundColor: 'transparent',
        whiteSpace: 'pre-wrap',
        fontSize: fontSize,
      }}
      onClick={ () => {
        animation.cancel();
      }}
    >
      { text }
    </button>
  );
}

// SELECT CONTROL BUTTON
function SelectControlButton(props) {
  const [state, dispatch] = useContext(GlobalState);
  const selectStatus = state.duel.selectStatus;

  let selectControlBox = null;
  let selectControlButton = null;

  if (selectStatus.mode === 'magic') {
    const magic = state.duel.castMagics[selectStatus.magicIndex];
    const route = magic.route[selectStatus.routeIndex];
    const side = state.duel.basicInformation.side;

    if (route.type === 'target') {
      if (route.detail.source === 'field') {
        selectControlButton =
        <div className='flexRow justifyContentCenter alignItemsCenter ' >
          <button className='button2'
            onClick={ () => {
              dispatch({type: 'setStateMultiLayers', keys: ['duel', 'castMagics', selectStatus.magicIndex, 'targets', route.index, route.key, 'side'], value: 'self'});
              dispatch({type: 'setStateMultiLayers', keys: ['duel', 'magics', side, selectStatus.magicIndex, 'targets', route.index, route.key, 'side'], value: 'self'});

              // ルーティング

              if (selectStatus.routeIndex === magic.route.length - 1) {
                // 魔法モードを抜けニュートラル
                dispatch({type: 'setStateMultiLayers', keys: ['duel', 'selectStatus'], value: { mode: 'neutral' }});
              }
              else {
                // 魔法モード継続。ルート1つ進む。
                dispatch({type: 'setStateMultiLayers', keys: ['duel', 'selectStatus', 'routeIndex'], value: selectStatus.routeIndex + 1});
              }
            }}
          >
            { words.self[state.language] }
          </button>
          <button className='button2'
            onClick={ () => {
              dispatch({type: 'setStateMultiLayers', keys: ['duel', 'castMagics', selectStatus.magicIndex, 'targets', route.index, route.key, 'side'], value: 'opponent'});
              dispatch({type: 'setStateMultiLayers', keys: ['duel', 'magics', side, selectStatus.magicIndex, 'targets', route.index, route.key, 'side'], value: 'opponent'});

              // ルーティング

              if (selectStatus.routeIndex === magic.route.length - 1) {
                // 魔法モードを抜けニュートラル
                dispatch({type: 'setStateMultiLayers', keys: ['duel', 'selectStatus'], value: { mode: 'neutral' }});
              }
              else {
                // 魔法モード継続。ルート1つ進む。
                dispatch({type: 'setStateMultiLayers', keys: ['duel', 'selectStatus', 'routeIndex'], value: selectStatus.routeIndex + 1});
              }
            }}
          >
            { words.opponent[state.language] }
          </button>
        </div>

        selectControlBox =
        <div>
          {/* PC */}
          <div className="visibleMiddleOrMore">
            <div className='popupBackgroundMultiLayer' style={{ '--popupLayer': props.layer }}>
              <button className='closePopupMultiLayer' style={{ '--popupLayer': props.layer }}
                onClick={ () => handleClickCancelMagic(state, dispatch, 0) }
              />
              <div className='popupMultiLayer' style={{ '--popupLayer': props.layer }}>
                <button className='topRightClose font2 focusEffect01' tabindex='0'
                  onClick={ () => handleClickCancelMagic(state, dispatch, 0) } 
                >
                  ×
                </button>
                { selectControlButton }
              </div>
            </div>
          </div>
          {/* SP */}
          <div className="visibleSmallOrLess">
            <div className='popupBackgroundMultiLayer' style={{ '--popupLayer': props.layer }}>
              <button className='closePopupMultiLayer' style={{ '--popupLayer': props.layer }}
                onClick={ () => handleClickCancelMagic(state, dispatch, 0) }
              />
              <div className='popupMultiLayerSmallScreen ' style={{ '--popupLayer': props.layer }}>
                <button className='topRightClose font2 focusEffect01' tabindex='0'
                  onClick={ () => handleClickCancelMagic(state, dispatch, 0) } 
                >
                  ×
                </button>
                { selectControlButton }
              </div>
            </div>
          </div>
        </div>
      }
    }
  }

  return (
    <div>
      { selectControlBox }
    </div>
  );
}

// CLOSE BOX
function CloseBox(props) {
  const [state, dispatch] = useContext(GlobalState);


  return (
    <div className='flexRow justifyContentSpaceAround widthMax'>
      <button className='button2 widthDiv3' onClick={ () => { dispatch({ type: 'setStateMultiLayers', keys: ['popup', props.popupLayer], value: { type: null, body: null } }); } }  >
        { words.close[state.language] }
      </button>
    </div>
  );
}

// ITEM DETAIL SP
function ItemDetailSP(props) {
  const [state, dispatch] = useContext(GlobalState);
  const popupLayer = props.popupLayer;

  let card = state.popup[popupLayer].body.card;

  if (card === undefined) {
    card = state.popup[popupLayer].body.item;
  }

  const item = card;

  const actionBox = state.popup[popupLayer].body.actionBox;
  const side = state.popup[popupLayer].body.side;
  const position = state.popup[popupLayer].body.position;
  const source = state.popup[popupLayer].body.source;
  const monstersInformationRevisedSingleSide = state.popup[popupLayer].body.monstersInformationRevisedSingleSide;
  const imageNoSingleSide = state.popup[popupLayer].body.imageNoSingleSide;
  const information = state.registeredCard[card.asset];

  let cardImageUrl;
  let imageNo;

  // if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
  //   cardImageUrl = `https://knightsofmonadom.monatoka.com/monacard/small/${item.asset}_small.png`;
  // }
  // else { // dev
  //   cardImageUrl = `https://dev.knightsofmonadom.monatoka.com/monacard/small/${item.asset}_small.png`;
  // }

  if (source === 'history') {
    imageNo = imageNoSingleSide;
  }
  else { // duel
    imageNo = state.duel.imageNo[side];
  }

  if (position === 'field' && imageNo !== undefined && imageNo !== 0) {
    cardImageUrl = `https://${process.env.REACT_APP_MONACARD_URL}small/${item.asset}_${imageNo}_small.png`;
  }
  else {
    cardImageUrl = `https://${process.env.REACT_APP_MONACARD_URL}small/${item.asset}_small.png`;
  }

  let actionBoxComponent;
  
  if (actionBox === 'switch') {
    actionBoxComponent = <SelectActionBox item={item} popupLayer={props.popupLayer} />;
  }
  else if (actionBox === 'close') {
    actionBoxComponent = <CloseBox popupLayer={props.popupLayer} />;
  }
  else {
    actionBoxComponent = null;
  }

  const popup = { type: 'creatorInformation', body: item };

  const editButton =
  <button className='button1'
    onClick={ () => {
      const monacottoAddressMain = state.registeredCard[item.asset].monacottoLink?.url?.match(/mainaddress=(\w+)$/)[1];

      dispatch({ type: 'setStateMultiLayers', keys: ['creatorInformation', 'creater'], value: state.registeredCard[item.asset].creater }); 
      dispatch({ type: 'setStateMultiLayers', keys: ['creatorInformation', 'createrText'], value: state.registeredCard[item.asset].createrText }); 
      dispatch({ type: 'setStateMultiLayers', keys: ['creatorInformation', 'monacottoAddressMain'], value: monacottoAddressMain }); 
      dispatch({ type: 'setStateMultiLayers', keys: ['creatorInformation', 'createrLink', 0, 'title'], value: state.registeredCard[item.asset]?.createrLink?.[0]?.title }); 
      dispatch({ type: 'setStateMultiLayers', keys: ['creatorInformation', 'createrLink', 0, 'url'], value: state.registeredCard[item.asset]?.createrLink?.[0]?.url }); 
      dispatch({ type: 'setStateMultiLayers', keys: ['creatorInformation', 'createrLink', 1, 'title'], value: state.registeredCard[item.asset]?.createrLink?.[1]?.title }); 
      dispatch({ type: 'setStateMultiLayers', keys: ['creatorInformation', 'createrLink', 1, 'url'], value: state.registeredCard[item.asset]?.createrLink?.[1]?.url }); 
      dispatch({ type: 'setStateMultiLayers', keys: ['creatorInformation', 'createrLink', 2, 'title'], value: state.registeredCard[item.asset]?.createrLink?.[2]?.title }); 
      dispatch({ type: 'setStateMultiLayers', keys: ['creatorInformation', 'createrLink', 2, 'url'], value: state.registeredCard[item.asset]?.createrLink?.[2]?.url }); 
      dispatch({ type: 'setStateMultiLayers', keys: ['popup', props.popupLayer + 1], value: popup }); 
    }}
  >
    <img className='size2x2' src={iconEdit} />
  </button>;


  // 補正カード名

  let nameRevised;

  if (position === 'field') {
    if (source === 'history') {
      nameRevised = monstersInformationRevisedSingleSide?.name;
    }
    else {
      nameRevised = state.duel.monstersInformationRevised?.[side]?.name;
    }
  }

  // 補正プロフィール

  let featureRevised;

  if (position === 'field') {
    if (source === 'history') {
      featureRevised = monstersInformationRevisedSingleSide?.feature;
    }
    else {
      featureRevised = state.duel.monstersInformationRevised?.[side]?.feature;
    }
  }


  return (
    <div className='flexColumn alignItemsFlexStart widthMax marginTop0p5'>
      <div className='flexColumn alignItemsCenter widthMax'>
        <img className='cardImageFullWidth ' src={cardImageUrl} />
      </div>
      { actionBoxComponent }
      <CardSpec card={card} nameRevised={nameRevised} featureRevised={featureRevised} popupLayer={popupLayer} />
      <div className='flexColumn alignItemsCenter widthMax' >
        <div className='flexColumn alignItemsFlexStart width98vw borderKOMFatBorder padding1 marginTop0p5'>
          <div className='flexRow alignItemsFlexEnd marginBottom0p5'>
            <div className='marginRight1'>
              { words.creater[state.language] }
            </div>
            <div className='font1p5'>
              { state.registeredCard[item.asset].creater }
            </div>
          </div>
          <textarea className='widthMax height15 backgroundColorTransparent borderKOM preWrap padding0p5 marginTopBottom1'
            value={ state.registeredCard[item.asset].createrText }
          />
          <div className='flexRow justifyContentFlexStart '>
            <MonacottoLink asset={item.asset}  />
            <CreaterLink asset={item.asset} index={0} />
            <CreaterLink asset={item.asset} index={1} />
            <CreaterLink asset={item.asset} index={2} />
          </div>
          <div className='flexRow justifyContentFlexEnd widthMax '>
            { editButton }
          </div>
        </div>
      </div>
    </div>
  );
}

// MONSTER DETAIL
function MonsterDetail(props) {
  const [state, dispatch] = useContext(GlobalState);
  const popupLayer = props.popupLayer;

  let card = state.popup[popupLayer].body.card;

  // if (card === undefined) {
  //   card = state.popup[popupLayer].body.item;
  // }

  if (card === undefined) {
    card = state.popup[popupLayer].body.monster;
  }

  const side = state.popup[popupLayer].body.side;
  const position = state.popup[popupLayer].body.position;
  const source = state.popup[popupLayer].body.source;
  const monstersInformationRevisedSingleSide = state.popup[popupLayer].body.monstersInformationRevisedSingleSide;
  const imageNoSingleSide = state.popup[popupLayer].body.imageNoSingleSide;
  const information = state.registeredCard[card.asset];

  // カードイメージ

  let cardImageUrl;
  let imageNo;

  // if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
  //   cardImageUrl = `https://knightsofmonadom.monatoka.com/monacard/${card.asset}.png`;
  // }
  // else { // dev
  //   cardImageUrl = `https://dev.knightsofmonadom.monatoka.com/monacard/${card.asset}.png`;
  // }

  if (source === 'history') {
    imageNo = imageNoSingleSide;
  }
  else { // duel
    imageNo = state.duel.imageNo[side];
  }

  if (position === 'field' && imageNo !== undefined && imageNo !== 0) {
    cardImageUrl = `https://${process.env.REACT_APP_MONACARD_URL}${card.asset}_${imageNo}.png`;
  }
  else {
    cardImageUrl = `https://${process.env.REACT_APP_MONACARD_URL}${card.asset}.png`;
  }

  // クリエイター情報編集

  const popup = { type: 'creatorInformation', body: card };

  const editButton =
  <button className='button1'
    onClick={ () => {
      const monacottoAddressMain = state.registeredCard[card.asset].monacottoLink?.url?.match(/mainaddress=(\w+)$/)[1];

      dispatch({ type: 'setStateMultiLayers', keys: ['creatorInformation', 'creater'], value: state.registeredCard[card.asset].creater }); 
      dispatch({ type: 'setStateMultiLayers', keys: ['creatorInformation', 'createrText'], value: state.registeredCard[card.asset].createrText }); 
      dispatch({ type: 'setStateMultiLayers', keys: ['creatorInformation', 'monacottoAddressMain'], value: monacottoAddressMain }); 
      dispatch({ type: 'setStateMultiLayers', keys: ['creatorInformation', 'createrLink', 0, 'title'], value: state.registeredCard[card.asset]?.createrLink?.[0]?.title }); 
      dispatch({ type: 'setStateMultiLayers', keys: ['creatorInformation', 'createrLink', 0, 'url'], value: state.registeredCard[card.asset]?.createrLink?.[0]?.url }); 
      dispatch({ type: 'setStateMultiLayers', keys: ['creatorInformation', 'createrLink', 1, 'title'], value: state.registeredCard[card.asset]?.createrLink?.[1]?.title }); 
      dispatch({ type: 'setStateMultiLayers', keys: ['creatorInformation', 'createrLink', 1, 'url'], value: state.registeredCard[card.asset]?.createrLink?.[1]?.url }); 
      dispatch({ type: 'setStateMultiLayers', keys: ['creatorInformation', 'createrLink', 2, 'title'], value: state.registeredCard[card.asset]?.createrLink?.[2]?.title }); 
      dispatch({ type: 'setStateMultiLayers', keys: ['creatorInformation', 'createrLink', 2, 'url'], value: state.registeredCard[card.asset]?.createrLink?.[2]?.url }); 
      dispatch({ type: 'setStateMultiLayers', keys: ['popup', props.popupLayer + 1], value: popup }); 
    }}
  >
    <img className='size2x2' src={iconEdit} />
  </button>;

  // 補正カード名

  let nameRevised;

  if (position === 'field') {
    if (source === 'history') {
      nameRevised = monstersInformationRevisedSingleSide?.name;
    }
    else {
      nameRevised = state.duel.monstersInformationRevised?.[side]?.name;
    }
  }

  // 補正プロフィール

  let featureRevised;

  if (position === 'field') {
    if (source === 'history') {
      featureRevised = monstersInformationRevisedSingleSide?.feature;
    }
    else {
      featureRevised = state.duel.monstersInformationRevised?.[side]?.feature;
    }
  }


  return (
    <div className='flexRow justifyContentCenter'>
      <div className=''>
        <img className='cardImageLarge marginSide1' src={cardImageUrl} />
      </div>
      <div className='flexColumn alignItemsFlexStart cardImageLarge marginSide1'>
        <div className='flexColumn alignItemsFlexStart widthMax '>
          <CardSpec card={card} nameRevised={nameRevised} featureRevised={featureRevised} popupLayer={popupLayer} />
        </div>
        <div className='flexColumn alignItemsFlexStart widthMax borderKOMFatBorder padding1 marginTop0p5'>
          <div className='flexRow alignItemsFlexEnd marginBottom0p5'>
            <div className='marginRight1'>
              { words.creater[state.language] }
            </div>
            <div className='font1p5'>
              { state.registeredCard[card.asset].creater }
            </div>
          </div>
          <textarea className='widthMax height15 backgroundColorTransparent borderKOM preWrap padding0p5 marginTopBottom1'
            value={ state.registeredCard[card.asset].createrText }
          />
          <div className='flexRow justifyContentFlexStart '>
            <MonacottoLink asset={card.asset}  />
            <CreaterLink asset={card.asset} index={0} />
            <CreaterLink asset={card.asset} index={1} />
            <CreaterLink asset={card.asset} index={2} />
          </div>
          <div className='flexRow justifyContentFlexEnd widthMax '>
            { editButton }
          </div>
        </div>
      </div>
    </div>
  );
}

// CARD SPEC
function CardSpec(props) {
  const [state] = useContext(GlobalState);
  const popupLayer = props.popupLayer;
  const card = props.card;
  const nameRevised = props.nameRevised;
  const featureRevised = props.featureRevised;
  const information = state.registeredCard[card.asset];

  // カード名補正

  let name = information.name;

  if (nameRevised !== undefined) {
    name = nameRevised;
  }

  // プロフィール補正

  let feature = information.feature;

  if (featureRevised !== undefined) {
    feature = featureRevised;
  }

  // エディション(レプリカ)
  let edition;

  if (information.edition === 'replica') {
    edition =
    <div className='marginSide0p5'>
      【OFFICIAL REPLICA】
    </div>;
  }

  // カードスペック

  let spec;

  if (information.category === 'magic') {

    // タイプ
    let typeText = '';

    if (information.preEffects !== undefined) {
      typeText = information.preEffects.reduce( (acc, cur) => {
        if (cur.type !== undefined && cur.type !== null && cur.type !== '') {
          return acc + words[cur.type][state.language] + ' ';
        }
        else {
          return acc;
        }
      },
      '');
    }

    if (typeText === '') {
      typeText = words.nothing[state.language];
    }

    // コスト
    let costText = '';

    if (information.costs !== undefined) {
      for (const cost of information.costs) {
        if (cost.type === 'discardCards') {
          if (cost.comparison === 'equal') {
            costText += words.discardCards01[state.language] + cost.amount + words.discardCards02[state.language];
          }
        }
      }
    }

    if (costText === '') {
      costText = words.nothing[state.language];
    }

    // 排他
    let exclusionText = '';

    if (information.exclusions !== undefined) {
      for (const exclusion of information.exclusions) {
        if (exclusion.type === 'excludeOpponent') {
          exclusionText += exclusion.typesToExclude.reduce( (acc, cur) => acc + words[cur][state.language] + ' ', '');
        }
      }
    }

    if (exclusionText === '') {
      exclusionText = words.nothing[state.language];
    }

    spec =
    <div className='flexColumn alignItemsFlexStart widthMax padding0p5' >
      <div className='flexRow justifyContentSpaceBetween alignItemsFlexStart widthMax marginTopBottom1'>
        <div className=''>
          { words.magic[state.language] }
        </div>
        <div className='flexRow alignItemsCenter'>
          { edition }
          <ShareButton card={card} />
          <AssetInfoButton card={card} popupLayer={popupLayer} />
        </div>
      </div>
      <div className='flexColumn alignItemsFlexStart marginTopBottom1'>
        <div className=''>
          { words.name[state.language] }
        </div>
        <div className=''>
          { name }
        </div>
        <div className='preWrap marginTop1'>
          { information.flavorText }
        </div>
      </div>
      <div className='flexColumn alignItemsFlexStart marginTopBottom1'>
        <div className=''>
          { words.type[state.language] }
        </div>
        <div className='preWrap'>
          { typeText }
        </div>
      </div>
      <div className='flexColumn alignItemsFlexStart marginTopBottom1'>
        <div className=''>
          { words.effect[state.language] }
        </div>
        <div className='preWrap'>
          { information.effectText }
        </div>
      </div>
      <div className='flexColumn alignItemsFlexStart marginTopBottom1'>
        <div className=''>
          { words.cost[state.language] }
        </div>
        <div className='preWrap'>
          { costText }
        </div>
      </div>
      <div className='flexColumn alignItemsFlexStart marginTopBottom1'>
        <div className=''>
          { words.exclusion[state.language] }
        </div>
        <div className='preWrap'>
          { exclusionText }
        </div>
      </div>
      <div className='flexRow alignItemsCenter borderMonacotto marginTop1' >
        <div className='textCenter width2p4 height1p2' >
          { '0' }
        </div>
        {
          information.maginaThresholds.map( threshold =>
            <div className='textCenter width2p4 height1p2' >
              { threshold.face !== undefined ? threshold.face : threshold.upperBound }
            </div>
          )
        }
      </div>
    </div>
  }
  else { // monster
    spec =
    <div className='flexColumn alignItemsFlexStart widthMax padding0p5' >
      <div className='flexRow justifyContentSpaceBetween alignItemsFlexStart widthMax marginTopBottom1'>
        <div className=''>
          { words.knight[state.language] }
        </div>
        <div className='flexRow alignItemsCenter'>
          { edition }
          <ShareButton card={card} />
          <AssetInfoButton card={card} popupLayer={popupLayer} />
        </div>
      </div>
      <div className='flexColumn alignItemsFlexStart marginTopBottom1'>
        <div className=''>
          { words.name[state.language] }
        </div>
        <div className=''>
          { name }
        </div>
      </div>
      <div className='flexColumn alignItemsFlexStart marginTopBottom1'>
        <div className=''>
          { words.profile[state.language] }
        </div>
        <div className=''>
          { feature }
        </div>
      </div>
    </div>
  }

  return spec;
}

// SHARE BUTTON
function ShareButton(props) {
  const [state, dispatch] = useContext(GlobalState);
  const popupLayer = props.popupLayer;
  const card = props.card;

  const urlCardDetail = 'https://' + process.env.REACT_APP_KOM_EXTENSION_URL + `cardlist?asset=${card.asset}`;
  const textAreaRef = useRef(null);


  return (
    <div className='' >
      <button className='button1'
        onClick={ () => {
          navigator.clipboard.writeText(urlCardDetail)
          .then()
          .catch( err => {
            copyToClipboard(urlCardDetail, textAreaRef);
          });
          // dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayer + 1], value: assetInfoPopup });

          dispatch({ type: 'setNotification', key: 'notification', value: words.urlHasBeenCopiedToTheClipboard[state.language] });
        }}
      >
        <img className='size2x2' src={iconShare} alt='' />
      </button>
      <textarea ref={textAreaRef} style={{ position: 'absolute', left: '-9999px' }} ></textarea>
    </div>
  );
}

// ASSET INFO BUTTON
function AssetInfoButton(props) {
  const [state, dispatch] = useContext(GlobalState);
  const popupLayer = props.popupLayer;
  const card = props.card;

  const assetInfoBody =
  <div className='flexColumn '>
    <div className='flexRow justifyContentSpaceBetween alignItemsCenter textCenter heightMin4 backgroundColorMonacottoAlmostWhite borderRadius0p3 margin0p5'  >
      <div className='marginSide1'>
        { words.cardNo[state.language] }
      </div>
      <div className='marginSide1'>
        { state.registeredCard[card.asset].cardNo }
      </div>
    </div>
    <div className='flexRow justifyContentSpaceBetween alignItemsCenter textCenter heightMin4 backgroundColorMonacottoAlmostWhite borderRadius0p3 margin0p5'  >
      <div className='marginSide1'>
        { words.asset[state.language] }
      </div>
      <div className='marginSide1'>
        { state.registeredCard[card.asset].asset }
      </div>
    </div>
    <div className='flexRow justifyContentSpaceBetween alignItemsCenter textCenter heightMin4 backgroundColorMonacottoAlmostWhite borderRadius0p3 margin0p5'  >
      <div className='marginSide1'>
        { words.asset_longname[state.language] }
      </div>
      <div className='marginSide1'>
        { state.registeredCard[card.asset].asset_longname }
      </div>
    </div>
  </div>

  const assetInfoPopup = { type: 'generalItems', body: assetInfoBody };

  return (
    <button className='button1' onClick={ () => { dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayer + 1], value: assetInfoPopup }) } } >
      <img className='size2x2' src={iconDetail} />
    </button>
  );
}

// MONACOTTO LINK
function MonacottoLink(props) {
  const [state] = useContext(GlobalState);
  const asset = props.asset;

  if (state.registeredCard[asset].monacottoLink?.url === undefined) {
    return null;
  }

  return (
    <div className='flexRow alignItemsCenter marginBottom0p5 marginSide0p5'>
      <button className='buttonMainColor widthMin10 height2p2 paddingSide1 flexRow justifyContentCenter alignItemsCenter ' tabindex='0' 
        onClick={ () => {
          window.open( state.registeredCard[asset].monacottoLink.url );
        }}
      >
        <img className='heightMax' src={logoMonacotto} />
        <img className='heightMax' src={iconCotto} />
      </button>
      <button className='button1' tabindex='0' 
        onClick={ () => {
          navigator.clipboard.writeText( state.registeredCard[asset].monacottoLink.url )
          .then()
          .catch();
        }}
      >
        <img className='size2x2' src={iconCopy} />
      </button>
    </div>
  );
}

// CREATER LINK
function CreaterLink(props) {
  const [state] = useContext(GlobalState);
  const asset = props.asset;
  const index = props.index;

  if (state.registeredCard[asset].createrLink?.[index] === undefined) {
    return null;
  }

  return (
    <div className='flexRow alignItemsCenter marginBottom0p5 marginSide0p5'>
      <button className='buttonMainColor widthMin10 height2p2 paddingSide1 textCenter flexRow justifyContentCenter alignItemsCenter ' tabindex='0' 
        onClick={ () => {
          window.open( state.registeredCard[asset].createrLink[index].url );
        }}
      >
        { state.registeredCard[asset].createrLink[index].title }
      </button>
      <button className='button1' tabindex='0' 
        onClick={ () => {
          navigator.clipboard.writeText( state.registeredCard[asset].createrLink[index].url )
          .then()
          .catch();
        }}
      >
        <img className='size2x2' src={iconCopy} />
      </button>
    </div>
  );
}

// SCORE
function Score(props) {
  const [state] = useContext(GlobalState);
  let scores = [];
  let scoresSP = [];

  // if (props.side === 'A') {
  //   for (let i = 1; i <= state.duel.pointA; i++) {
  //     scores.push( <img className='size2x2 backgroundColorTransparent marginSide0p2' src={roseRed} /> );
  //   }
  // }
  // else { // B
  //   for (let i = 1; i <= state.duel.pointB; i++) {
  //     scores.push( <img className='size2x2 backgroundColorTransparent marginSide0p2' src={roseBlue} /> );
  //   }
  // }

  for (let i = 1; i <= props.point; i++) {
    scores.push( <img className='size2x2 backgroundColorTransparent marginSide0p2' src={props.src} /> );
    scoresSP.push( <img className='size1p7x1p7 backgroundColorTransparent marginSide0p2' src={props.src} /> );
  }

  return (
    <div>
      {/* PC */}
      <div className="visibleMiddleOrMore">
        <div className=''>
          { scores }
        </div>
      </div>
      {/* SP */}
      <div className="flexColumn visibleSmallOrLess ">
        <div className=''>
          { scoresSP }
        </div>
      </div>
    </div>
  );
}

// YOU WIN
function YouWin() {
  const [state, dispatch] = useContext(GlobalState);
  const parts = [];
  const animations = [];

  parts[0] = <YouWinWord />

  if (state.animation.youWinMona === 'visible') {
    for (let i = 1; i <= 15; i++) {
      parts[i] = <YouWinMona />
    }
  }

  return (
    <button className='backgroundColorTransparent borderNone' onClick={ () => { dispatch({ type: 'setStateMultiLayers', keys: ['animation', 'youWinMona'], value: 'invisible' }); } } >
      { parts }
    </button>
  );
}

// YOU WIN WORD
function YouWinWord() {
  const animation = useAnimation(
    [
      { width: "0%", height: "0%", transform: "translate(-50%,-50%) rotate(0deg)" },
      { width: "50%", height: "10%", transform: "translate(-50%,-50%) rotate(360deg)" },
    ],
    {
      duration: 1000,
      iterations: 3,
      easing: "ease-in-out",
    }
  );

  useEffect(() => {
    animation.play();
  }, []);

  return (
      <div
        ref={animation}
        style={{
          position: "absolute",
          border: "none",
          // border: "2px solid black",
          backgroundColor: "transparent",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          zIndex: "150",
        }}
      >
        <img className='widthMax heightMax' src={logoWin} />
      </div>
  );
}

// YOU WIN MONA
function YouWinMona(props) {
  const style = {
    position: "absolute",
    backgroundColor: "transparent",
    top: "50%",
    left: "50%",
    width: "10px",
    height: "10px",
    // border: "1px solid orange",
    // borderRadius: "50%",
    transform: "translate(-50%,-50%)",
    zIndex: "150",
  }

  const angle = 360 * Math.random();
  const dist = 100 + 100 * Math.random();
  const size = 0.5 + Math.random() * 4;
  // const hue = 30 + Math.random() * 25;
  // dot.style.backgroundColor = `hsl(${hue}, 90%, 60%)`;

  const hasBlendmode = Math.random() > 0.5;
  const hasBlur = Math.random() > 0.5;

  if (hasBlendmode) {
    style.mixBlendMode = "add";
  }

  if (hasBlur) {
    style.filter = `blur(${Math.random() * 20}px)`;
  }

  const animation = useAnimation(
    [
      {
        transform: `rotate(${angle}deg) translateX(0px) scale(${size})`,
        opacity: 0.8
      },
      {
        transform: `rotate(${angle}deg) translateX(${ dist * 0.8 }px) scale(${size})`,
        opacity: 0.8, offset: 0.8
      },
      {
        transform: `rotate(${angle}deg) translateX(${dist}px) scale(${size})`,
        opacity: 0
      }
    ],
    {
      duration: 2000 * Math.random(),
      iterations: Infinity,
      fill: "forwards"
    }
  );

  useEffect(() => {
    animation.play();
  }, []);

  return (
      <img ref={ animation } style={ style } src={monaFavicon} />
  );
}

// YOU LOSE
function YouLose() {
  const [state, dispatch] = useContext(GlobalState);
  const parts = [];
  const animations = [];

  for (let i = 0; i <= 0; i++) {
    parts[i] = <YouLoseWord />
  }

  return (
    <button className='backgroundColorTransparent borderNone' onClick={ () => { dispatch({ type: 'setStateMultiLayers', keys: ['animation', 'youWinMona'], value: 'invisible' }); } } >
      { parts }
    </button>
  );
}

// YOU LOSE WORD
function YouLoseWord() {
  const animation = useAnimation(
    [
      { width: "0%", height: "0%", transform: "translate(-50%,-50%) rotate(0deg)" },
      { width: "50%", height: "10%", transform: "translate(-50%,-50%) rotate(360deg)" },
    ],
    {
      duration: 1000,
      iterations: 3,
      easing: "ease-in-out",
    }
  );

  useEffect(() => {
    animation.play();
  }, []);

  return (
      <div
        ref={animation}
        style={{
          position: "absolute",
          border: "none",
          // border: "2px solid black",
          backgroundColor: "transparent",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          zIndex: "150",
        }}
      >
        <img className='widthMax heightMax' src={logoLose} />
      </div>
  );
}

// DRAW
function Draw() {
  const animationWord = useAnimation(
    [
      { width: "0%", height: "0%", transform: "translate(-50%,-50%) rotate(0deg)" },
      { width: "50%", height: "10%", transform: "translate(-50%,-50%) rotate(360deg)" },
    ],
    {
      duration: 1000,
      iterations: 3,
      easing: "ease-in-out",
    }
  );

  const animationWire = useAnimation(
    [
      { transform: "rotate(0deg)", borderRadius: "1rem" },
      { transform: "rotate(360deg)", borderRadius: "50%" },
      { transform: "rotate(720deg)", borderRadius: "1rem" },
    ],
    {
      duration: 1000,
      iterations: Infinity,
      easing: "ease-in-out",
    }
  );

  useEffect(() => {
    animationWire.play();
    animationWord.play();
  }, []);

  return (
    <div>
      <div className='z150Center'>
        <div
          ref={animationWire}
          style={{
            border: "solid 0.1rem #135569",
            height: "6rem",
            width: "6rem",
            margin: "2rem 0 2rem 2rem",
          }}
        />
      </div>
      <div
        ref={animationWord}
        style={{
          position: "absolute",
          border: "none",
          // border: "2px solid black",
          backgroundColor: "transparent",
          top: "50%",
          left: "50%",
          width: "0%",
          height: "0%",
          transform: "translate(-50%,-50%)",
          zIndex: "160",
        }}
      >
        <img className='widthMax heightMax' src={logoDraw} />
      </div>
    </div>
  );
}

// DUEL UP 
function DuelUp() {
  const parts = [];
  const animations = [];

  parts[0] = <DuelUpWord />

  // for (let i = 1; i <= 20; i++) {
  //   parts[i] = <YouWinMona />
  // }

  return (
    <div className=''>
      { parts }
    </div>
  );
}

// DUEL UP WORD
function DuelUpWord() {
  const animation = useAnimation(
    [
      { width: "0%", height: "0%", transform: "translate(-50%,-50%) rotate(0deg)" },
      { width: "50%", height: "10%", transform: "translate(-50%,-50%) rotate(360deg)", offset: 0.6 },
      { width: "50%", height: "10%", transform: "translate(-50%,-50%) rotate(360deg)" },
    ],
    {
      duration: 6000,
      iterations: 1,
      easing: "ease-in-out",
      fill: "none",
    }
  );

  useEffect(() => {
    animation.play();
  }, []);

  return (
      <div
        ref={animation}
        style={{
          position: "absolute",
          border: "none",
          // border: "2px solid black",
          backgroundColor: "transparent",
          top: "50%",
          left: "50%",
          width: "0%",
          height: "0%",
          transform: "translate(-50%,-50%)",
          zIndex: "190",
        }}
      >
        <img className='widthMax heightMax' src={logoTheEnd} />
      </div>
  );
}

// BULLET HOLES
function BulletHoles(props) {
  const [state, dispatch] = useContext(GlobalState);
  const side = props.side;
  const device = props.device;
  let amountOfBullet;

  if (device === 'SP') {
    amountOfBullet = 11;
  }
  else {
    amountOfBullet = 13;
  }

  useEffect(() => {
    const interval = setInterval( () => {
      const length = state.knightBoxEffectPieces[side].length;

      if (length >= amountOfBullet) {
        clearInterval(interval);
      }
      else {
        const x = Math.random() * 90 + 5;
        const y = Math.random() * 90 + 5;

        dispatch({type: 'setStateMultiLayers', keys: ['knightBoxEffectPieces', side, length], value: { x, y }});
      }
    }, 200); 

    const clearBullets = setTimeout( () => {
      dispatch({type: 'setStateMultiLayers', keys: ['knightBoxEffectPieces', side], value: []});
    }, 10000);

    return () => {
      dispatch({type: 'setStateMultiLayers', keys: ['knightBoxEffectPieces', side], value: []});
      clearTimeout(clearBullets);
      clearInterval(interval);
    };
  }, []);

  const bullets = state.knightBoxEffectPieces[side].map( bullet => <BulletHole x={bullet.x} y={bullet.y} /> );

  return (
    <div className='backgroundColorTransparent borderNone pointerEventsNone' >
      { bullets }
    </div>
  );
}

// BULLET HOLE
function BulletHole(props) {
  const x = props.x;
  const y = props.y;

  const style = {
    position: 'absolute',
    width: '10em',
    height: '10em',
    border: 'none',
    // borderRadius: '50%',
    backgroundColor: 'transparent',
    top: `${y}%`,
    left: `${x}%`,
    transform: 'translate(-50%, -50%)',
    zIndex: "100",
  };

  return (
    <img style={style} src={imageBulletHole01} />

    // <button style={style} onClick={ () => { /* dispatch({ type: 'setStateMultiLayers', keys: ['animation', 'youWinMona'], value: 'invisible' }); */ } } >
    //   <img className='' src={imageBulletHole01} />
    // </button>
  );
}

// CHARGE POINT
function ChargePoint(props) {
  const [state, dispatch, navigate] = useContext(GlobalState);
  let historyItems;
  let historyFunction;
  let arrowLeft;
  let arrowRight;
  let pointSum;

  useEffect( () => {
    const func = async () => {
      if (state.getPointHistory.addressMain !== undefined && state.getPointHistory.addressMain !== null && state.getPointHistory.addressMain !== '') {
        const result = await handleClickGetPointSum(state, dispatch);

        if (result?.message === 'nfcRequired') {
          const callback = (keyPairs) => {
            handleClickGetPointSum(state, dispatch, null, null, keyPairs);
            keepKeyPairsInMemory(state, dispatch, keyPairs);
          };

          handleClickNfc(state, dispatch, callback);
        }
        else if (result?.message === 'bcRequired') {
          const callback = (keyPairs) => {
            handleClickGetPointSum(state, dispatch, null, null, keyPairs);
            keepKeyPairsInMemory(state, dispatch, keyPairs);
          };

          handleClickScanQr(state, dispatch, callback);
        }
      }
    }

    func();

    dispatch({ type: 'setState', key: 'purchasePoint',
      value: {
        purchaseNo: null,
        addressMain: state.purchasePoint.addressMain,
        addressPayFrom: state.purchasePoint.addressPayFrom,
        amountToBuy: state.purchasePoint.amountToBuy,
        signatureByAddressMain: '',
        signatureByAddressPayFrom: '',
        disabled: false,
        status: 'waitingForSignature',
      }
    });
  }, []);

  let purchaseNo;

  if (state.purchasePoint.purchaseNo !== undefined && state.purchasePoint.purchaseNo !== null && state.purchasePoint.purchaseNo !== '') {
    purchaseNo = state.purchasePoint.purchaseNo;
  }
  else {
    purchaseNo = '-';
  }

  // 保有数
  let balance;
  let balanceNum = 0;

  // if (Object.keys(state.session).length > 0) {
  //   balance = <div className='flexRow alignItemsFlexEnd marginSide1'>
  //     <div className=''>
  //       {words.numberOfHoldings[state.language]}
  //     </div>
  //     <div className='font1p5 marginSide0p5'>
  //       { balanceNum }
  //     </div>
  //   </div>
  // }

  let confirmation;

  // if (wallet === 'MonaPallet') {
  //   confirmation = confirmTheDetailsOfSendingMona;
  // }
  // else { // Mpurse
  //   confirmation = null;
  // }

  // ポイント合計

  if (state.pointSum[state.getPointHistory.addressMain]?.pointSum !== undefined) {
    pointSum = <div>
      { state.pointSum[state.getPointHistory.addressMain]?.pointSum }
    </div>;
  }
  else {
    pointSum = <div>
      -
    </div>;
  }

  // アクションごとのhistoryItemsとhistoryFunction設定。

  if (state.getPointHistory.action === 'point') {
    if (state.pointHistory[state.getPointHistory.addressMain]?.point !== undefined) {
      historyItems = state.pointHistory[state.getPointHistory.addressMain].point
      .filter( record => record.purchaseNo !== 0 )
      .map( record => {
        if (true) {
          return <PointRecord record={record} />
        }
        else {
          return null;
        }
      });

      historyItems.unshift(<PointRecordTitle />);
    }
    else {
      historyItems = [<PointRecordTitle />];
    }

    historyFunction = async (pagingIndex) => {
      const result = await handleClickGetPointHistory(state, dispatch, pagingIndex);

      if (result?.message === 'nfcRequired') {
        const callback = (keyPairs) => {
          handleClickGetPointHistory(state, dispatch, pagingIndex, null, null, keyPairs);
          keepKeyPairsInMemory(state, dispatch, keyPairs);
        };

        handleClickNfc(state, dispatch, callback);
      }
      else if (result?.message === 'bcRequired') {
        const callback = (keyPairs) => {
          handleClickGetPointHistory(state, dispatch, pagingIndex, null, null, keyPairs);
          keepKeyPairsInMemory(state, dispatch, keyPairs);
        };

        handleClickScanQr(state, dispatch, callback);
      }
    };
  }

  // 矢印

  if ( state.getPointHistory.pagingIndex[state.getPointHistory.action] >= 1 ) {
    arrowLeft = <div>
      <button className='flexRow justifyContentCenter alignItemsCenter size2x2Large backgroundColorTransparent borderNone cursor borderRadiusCircle riseOut2 '
        onClick={ () => historyFunction(state.getPointHistory.pagingIndex[state.getPointHistory.action] - 1) }
      >
        <img className='size2x2' src={iconCircleLeft} />
      </button>
    </div>
  }
  else {
    arrowLeft = <div>
      <button className='size2x2Large backgroundColorTransparent borderNone' disabled={true} >
        <div className='size2x2' />
      </button>
    </div>
  }

  if ( state.getPointHistory.lastEvaluatedKey[state.getPointHistory.action][state.getPointHistory.pagingIndex[state.getPointHistory.action]] !== undefined &&
       state.getPointHistory.lastEvaluatedKey[state.getPointHistory.action][state.getPointHistory.pagingIndex[state.getPointHistory.action]] !== null ) {
    arrowRight = <div>
      <button className='flexRow justifyContentCenter alignItemsCenter size2x2Large backgroundColorTransparent borderNone cursor borderRadiusCircle riseOut2 '
        onClick={ () => { historyFunction(state.getPointHistory.pagingIndex[state.getPointHistory.action] + 1) } }
      >
        <img className='size2x2' src={iconCircleRight} />
      </button>
    </div>
  }
  else {
    arrowRight = <div>
      <button className='size2x2Large backgroundColorTransparent borderNone' disabled={true} >
        <div className='size2x2' />
      </button>
    </div>
  }

  // ポイント購入セクション

  let purchaseSection;

  if (state.walletType === 'mpurse') {
    purchaseSection =
    <div>
      <div className='visibleMiddleOrMore flexColumn alignItemsFlexStart borderKOM marginSide1 padding0p5'>
        <div className='flexRow justifyContentSpaceBetween alignItemsCenter widthMax marginTop1 '>
          <div>
            { words.purchasePoints[state.language] }
          </div>
          <button className='borderNone backgroundColorTransparent cursor borderRadius2 riseOut2 marginSide0p2 ' tabindex='0'
            onClick={ () => { handleClickClearPurchase(state, dispatch) } }
          >
            {words.clear[state.language]}
          </button>
        </div>
        <div className='flexRow justifyContentFlexStart alignItemsCenter marginTop1 '>
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter marginTop1 widthMax'>
            <div>
              { 'No. ' + purchaseNo }
            </div>
          </div>
        </div>
        <div className='flexRow justifyContentFlexStart alignItemsCenter marginTop1 '>
          <TextLine3 fieldName='purchaseAddressMain' keys={['purchasePoint', 'addressMain']} face={words.addressMain[state.language]} tooltip={true} disabled={state.purchasePoint.disabled} />
          <button className='button1' disabled={state.purchasePoint.disabled} onClick={ () => handleClickMpurse(state, dispatch, ['purchasePoint', 'addressMain']) }>
            <img className='size2x2' src={iconMpurse} />
          </button>
        </div>
        <div className='flexRow justifyContentFlexStart alignItemsCenter marginTop1 '>
          <TextLine3 fieldName='addressPayFrom' keys={['purchasePoint', 'addressPayFrom']} face={words.addressPayFrom[state.language]} tooltip={true} disabled={state.purchasePoint.disabled} />
          <button className='button1' disabled={state.purchasePoint.disabled} onClick={ () => handleClickMpurse(state, dispatch, ['purchasePoint', 'addressPayFrom']) }>
            <img className='size2x2' src={iconMpurse} />
          </button>
        </div>
        <TextLine3 fieldName='amountToBuy' keys={['purchasePoint', 'amountToBuy']} face={words.amountToBuy[state.language]} type='setStateMultiLayersNum' disabled={state.purchasePoint.disabled} />
        {
          (state.purchasePoint.signatureByAddressMain === undefined ||
           state.purchasePoint.signatureByAddressMain === null ||
           state.purchasePoint.signatureByAddressMain === '') ?
            <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
              onClick={ () => { handleClickSignPurchase(state, dispatch, 'addressMain') } }
            >
              <div>
                {words.signByMainAddress[state.language]}
              </div>
              <img className='size2x2' src={iconSign} />
            </button>
          : null
        }
        {
          ( state.purchasePoint.signatureByAddressMain !== undefined &&
            state.purchasePoint.signatureByAddressMain !== null &&
            state.purchasePoint.signatureByAddressMain !== '' &&
           (state.purchasePoint.signatureByAddressPayFrom === undefined ||
            state.purchasePoint.signatureByAddressPayFrom === null ||
            state.purchasePoint.signatureByAddressPayFrom === '')) ?
            <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
              onClick={ () => { handleClickSignPurchase(state, dispatch, 'addressPayFrom') } }
            >
              <div>
                {words.signByAddressYouPayFrom[state.language]}
              </div>
              <img className='size2x2' src={iconSign} />
            </button>
          : null
        }
      </div>
      <div className='visibleSmallOrLess flexColumn alignItemsFlexStart borderKOM marginTop1 padding0p5'>
        <div className='flexRow justifyContentSpaceBetween alignItemsCenter widthMax marginTop1 '>
          <div>
            { words.purchasePoints[state.language] }
          </div>
          <button className='borderNone backgroundColorTransparent cursor borderRadius2 riseOut2 marginSide0p2 ' tabindex='0'
            onClick={ () => { handleClickClearPurchase(state, dispatch) } }
          >
            {words.clear[state.language]}
          </button>
        </div>
        <div className='flexRow justifyContentFlexStart alignItemsCenter marginTop1 '>
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter marginTop1 widthMax'>
            <div>
              { 'No. ' + purchaseNo }
            </div>
          </div>
        </div>
        <div className='flexColumn alignItemsFlexStart marginTop1'>
          <TextLine3 fieldName='purchaseAddressMain' keys={['purchasePoint', 'addressMain']} face={words.addressMain[state.language]} tooltip={true} disabled={state.purchasePoint.disabled} />
          <button className='button1' disabled={state.purchasePoint.disabled} onClick={ () => handleClickMpurse(state, dispatch, ['purchasePoint', 'addressMain']) }>
            <img className='size2x2' src={iconMpurse} />
          </button>
        </div>
        <div className='flexColumn alignItemsFlexStart marginTop1'>
          <TextLine3 fieldName='addressPayFrom' keys={['purchasePoint', 'addressPayFrom']} face={words.addressPayFrom[state.language]} tooltip={true} disabled={state.purchasePoint.disabled} />
          <button className='button1' disabled={state.purchasePoint.disabled} onClick={ () => handleClickMpurse(state, dispatch, ['purchasePoint', 'addressPayFrom']) }>
            <img className='size2x2' src={iconMpurse} />
          </button>
        </div>
        <TextLine3 fieldName='amountToBuy' keys={['purchasePoint', 'amountToBuy']} face={words.amountToBuy[state.language]} type='setStateMultiLayersNum' disabled={state.purchasePoint.disabled} />
        {
          (state.purchasePoint.signatureByAddressMain === undefined ||
           state.purchasePoint.signatureByAddressMain === null ||
           state.purchasePoint.signatureByAddressMain === '') ?
            <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
              onClick={ () => { handleClickSignPurchase(state, dispatch, 'addressMain') } }
            >
              <div>
                {words.signByMainAddress[state.language]}
              </div>
              <img className='size2x2' src={iconSign} />
            </button>
          : null
        }
        {
          ( state.purchasePoint.signatureByAddressMain !== undefined &&
            state.purchasePoint.signatureByAddressMain !== null &&
            state.purchasePoint.signatureByAddressMain !== '' &&
           (state.purchasePoint.signatureByAddressPayFrom === undefined ||
            state.purchasePoint.signatureByAddressPayFrom === null ||
            state.purchasePoint.signatureByAddressPayFrom === '')) ?
            <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
              onClick={ () => { handleClickSignPurchase(state, dispatch, 'addressPayFrom') } }
            >
              <div>
                {words.signByAddressYouPayFrom[state.language]}
              </div>
              <img className='size2x2' src={iconSign} />
            </button>
          : null
        }
      </div>
    </div>;
  }
  else if (state.walletType === 'nfc') {
    purchaseSection =
    <div className='flexColumn alignItemsFlexStart borderKOM marginTop1 padding0p5'>
      <div className='flexRow justifyContentSpaceBetween alignItemsCenter widthMax marginTop1 '>
        <div>
          { words.purchasePoints[state.language] }
        </div>
        <button className='borderNone backgroundColorTransparent cursor borderRadius2 riseOut2 marginSide0p2 ' tabindex='0'
          onClick={ () => { handleClickClearPurchase(state, dispatch) } }
        >
          {words.clear[state.language]}
        </button>
      </div>
      <div className='flexRow justifyContentFlexStart alignItemsCenter marginTop1 '>
        <div className='flexRow justifyContentSpaceBetween alignItemsCenter marginTop1 widthMax'>
          <div>
            { 'No. ' + purchaseNo }
          </div>
        </div>
      </div>
      <TextLine3 fieldName='amountToBuy' keys={['purchasePoint', 'amountToBuy']} face={words.amountToBuy[state.language]} type='setStateMultiLayersNum' disabled={state.purchasePoint.disabled} />
      {
        ((state.purchasePoint.signatureByAddressMain === undefined ||
          state.purchasePoint.signatureByAddressMain === null ||
          state.purchasePoint.signatureByAddressMain === '') &&
         (state.purchasePoint.signatureByAddressPayFrom === undefined ||
          state.purchasePoint.signatureByAddressPayFrom === null ||
          state.purchasePoint.signatureByAddressPayFrom === '')) ?
          <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
            onClick={ async () => {
              const callback = (keyPairs) => handleClickSignPurchase(state, dispatch, 'addressMain', null, keyPairs);
              handleClickNfc(state, dispatch, callback);
            }}
          >
            <div>
              {words.signByMainAddressToSendMona[state.language]}
            </div>
            <div>
              <img className='size2x2' src={iconKeyCard} />
            </div>
          </button>
        : null
      }
    </div>
  }
  else { // qr
    purchaseSection =
    <div className='flexColumn alignItemsFlexStart borderKOM marginTop1 padding0p5'>
      <div className='flexRow justifyContentSpaceBetween alignItemsCenter widthMax marginTop1 '>
        <div>
          { words.purchasePoints[state.language] }
        </div>
        <button className='borderNone backgroundColorTransparent cursor borderRadius2 riseOut2 marginSide0p2 ' tabindex='0'
          onClick={ () => { handleClickClearPurchase(state, dispatch) } }
        >
          {words.clear[state.language]}
        </button>
      </div>
      <div className='flexRow justifyContentFlexStart alignItemsCenter marginTop1 '>
        <div className='flexRow justifyContentSpaceBetween alignItemsCenter marginTop1 widthMax'>
          <div>
            { 'No. ' + purchaseNo }
          </div>
        </div>
      </div>
      <TextLine3 fieldName='amountToBuy' keys={['purchasePoint', 'amountToBuy']} face={words.amountToBuy[state.language]} type='setStateMultiLayersNum' disabled={state.purchasePoint.disabled} />
      {
        ((state.purchasePoint.signatureByAddressMain === undefined ||
          state.purchasePoint.signatureByAddressMain === null ||
          state.purchasePoint.signatureByAddressMain === '') &&
         (state.purchasePoint.signatureByAddressPayFrom === undefined ||
          state.purchasePoint.signatureByAddressPayFrom === null ||
          state.purchasePoint.signatureByAddressPayFrom === '')) ?
          <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
            onClick={ async () => {
              const callback = (keyPairs) => handleClickSignPurchase(state, dispatch, 'addressMain', null, keyPairs);
              handleClickScanQr(state, dispatch, callback);
            }}
          >
            <div>
              {words.signByMainAddressToSendMona[state.language]}
            </div>
            <div>
              <img className='size2x2' src={iconQr} />
            </div>
          </button>
        : null
      }
    </div>
  }

  // 履歴コントロールセクション

  let controlSection;

  if (state.walletType === 'mpurse') {
    controlSection =
    <div>
      <div className='visibleMiddleOrMore flexRow justifyContentFlexStart alignItemsCenter marginTop1 '>
        {/* action */}
        {/*
          <div className='flexRow justifyContentSpaceAround marginSide0p5 marginTop0p5'>
            <button className={'box3 focusEffect01 riseOut2 ' + (state.getPointHistory.action === 'permit' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
              onClick={ () => {
                        dispatch({type: 'setStateMultiLayers', keys: ['getPointHistory', 'action'], value: 'permit'});
              }}>
              {words.permit[state.language]}
            </button>
          </div>
        */}
        {/* addressMain */}
        <TextLine3 fieldName='historyAddressMain' keys={['getPointHistory', 'addressMain']} face={words.addressMain[state.language]} tooltip={true}
          extraFunctionOnChange={ [
            {
              function: () => {
                dispatch({ type: 'setStateMultiLayers', keys: ['getPointHistory', 'pagingIndex'],
                  value: { point: 0 }
                });
                dispatch({ type: 'setStateMultiLayers', keys: ['getPointHistory', 'lastEvaluatedKey'],
                  value: { point: [] }
                });
              },
              arguments: [],
            }
          ]}
        />
        <button className='button1'
          onClick={ () => {
            handleClickMpurse(state, dispatch, ['getPointHistory', 'addressMain']);
            dispatch({ type: 'setStateMultiLayers', keys: ['getPointHistory', 'pagingIndex'],
              value: { point: 0 }
            });
            dispatch({ type: 'setStateMultiLayers', keys: ['getPointHistory', 'lastEvaluatedKey'],
              value: { point: [] }
            });
          }}
        >
          <img className='size2x2' src={iconMpurse} />
        </button>
        {/*
          <button className='button1'
            onClick={ () => {
              handleClickAddressHistory(state, dispatch, cookies, ['getPointHistory', 'addressMain']);
              dispatch({ type: 'setStateMultiLayers', keys: ['getPointHistory', 'pagingIndex'],
                value: { permit: 0, permitted: 0, permittedInclusively: 0, requestPermission: 0, requestedPermission: 0, delegate: 0, delegated: 0 }
              });
              dispatch({ type: 'setStateMultiLayers', keys: ['getPointHistory', 'lastEvaluatedKey'],
                value: { permit: [], permitted: [], permittedInclusively: [], requestPermission: [], requestedPermission: [], delegate: [], delegated: [] }
              });
            }}
          >
            <img className='size2x2' src={iconList} />
          </button>
        */}
        <button className='button1' tabindex='0' onClick={ () => historyFunction(0) } >
          <img className='size2x2' src={iconSearch} />
        </button>
        { arrowLeft }
        { arrowRight }
      </div>
      <div className="visibleSmallOrLess flexColumn">
        {/* addressMain */}
        <TextLine3 fieldName='historyAddressMain' keys={['getPointHistory', 'addressMain']} face={words.addressMain[state.language]} tooltip={true}
          extraFunctionOnChange={ [
            {
              function: () => {
                dispatch({ type: 'setStateMultiLayers', keys: ['getPointHistory', 'pagingIndex'],
                  value: { point: 0 }
                });
                dispatch({ type: 'setStateMultiLayers', keys: ['getPointHistory', 'lastEvaluatedKey'],
                  value: { point: [] }
                });
              },
              arguments: [],
            }
          ]}
        />
        <div className='flexRow justifyContentSpaceBetween alignItemsCenter widthMax '>
          <div className='flexRow'>
            <button className='button1'
              onClick={ () => {
                handleClickMpurse(state, dispatch, ['getPointHistory', 'addressMain']);
                dispatch({ type: 'setStateMultiLayers', keys: ['getPointHistory', 'pagingIndex'],
                  value: { point: 0 }
                });
                dispatch({ type: 'setStateMultiLayers', keys: ['getPointHistory', 'lastEvaluatedKey'],
                  value: { point: [] }
                });
              }}
            >
              <img className='size2x2' src={iconMpurse} />
            </button>
            {/*
              <button className='button1'
                onClick={ () => {
                  handleClickAddressHistory(state, dispatch, cookies, ['getPointHistory', 'addressMain']);
                  dispatch({ type: 'setStateMultiLayers', keys: ['getPointHistory', 'pagingIndex'],
                    value: { permit: 0, permitted: 0, permittedInclusively: 0, requestPermission: 0, requestedPermission: 0, delegate: 0, delegated: 0 }
                  });
                  dispatch({ type: 'setStateMultiLayers', keys: ['getPointHistory', 'lastEvaluatedKey'],
                    value: { permit: [], permitted: [], permittedInclusively: [], requestPermission: [], requestedPermission: [], delegate: [], delegated: [] }
                  });
                }}
              >
                <img className='size2x2' src={iconList} />
              </button>
            */}
            <button className='button1' tabindex='0' onClick={ () => historyFunction(0) } >
              <img className='size2x2' src={iconSearch} />
            </button>
          </div>
          <div className='flexRow'>
            <div className='marginSide0p5'>
              { arrowLeft }
            </div>
            <div className='marginSide0p5'>
              { arrowRight }
            </div>
          </div>
        </div>
      </div>
    </div>;
  }
  else { //nfc
    controlSection =
    <div>
      <div className='visibleMiddleOrMore flexRow justifyContentFlexStart alignItemsCenter '>
        <div className='flexRow'>
          {/*
            <button className='button1'
              onClick={ () => {
                handleClickAddressHistory(state, dispatch, cookies, ['getPointHistory', 'addressMain']);
                dispatch({ type: 'setStateMultiLayers', keys: ['getPointHistory', 'pagingIndex'],
                  value: { permit: 0, permitted: 0, permittedInclusively: 0, requestPermission: 0, requestedPermission: 0, delegate: 0, delegated: 0 }
                });
                dispatch({ type: 'setStateMultiLayers', keys: ['getPointHistory', 'lastEvaluatedKey'],
                  value: { permit: [], permitted: [], permittedInclusively: [], requestPermission: [], requestedPermission: [], delegate: [], delegated: [] }
                });
              }}
            >
              <img className='size2x2' src={iconList} />
            </button>
          */}
          <button className='button1' tabindex='0' onClick={ () => historyFunction(0) } >
            <img className='size2x2' src={iconSearch} />
          </button>
        </div>
        <div className='flexRow'>
          <div className='marginSide0p5'>
            { arrowLeft }
          </div>
          <div className='marginSide0p5'>
            { arrowRight }
          </div>
        </div>
      </div>
      <div className='visibleSmallOrLess flexRow justifyContentSpaceBetween alignItemsCenter widthMax '>
        <div className='flexRow'>
          {/*
            <button className='button1'
              onClick={ () => {
                handleClickAddressHistory(state, dispatch, cookies, ['getPointHistory', 'addressMain']);
                dispatch({ type: 'setStateMultiLayers', keys: ['getPointHistory', 'pagingIndex'],
                  value: { permit: 0, permitted: 0, permittedInclusively: 0, requestPermission: 0, requestedPermission: 0, delegate: 0, delegated: 0 }
                });
                dispatch({ type: 'setStateMultiLayers', keys: ['getPointHistory', 'lastEvaluatedKey'],
                  value: { permit: [], permitted: [], permittedInclusively: [], requestPermission: [], requestedPermission: [], delegate: [], delegated: [] }
                });
              }}
            >
              <img className='size2x2' src={iconList} />
            </button>
          */}
          <button className='button1' tabindex='0' onClick={ () => historyFunction(0) } >
            <img className='size2x2' src={iconSearch} />
          </button>
        </div>
        <div className='flexRow'>
          <div className='marginSide0p5'>
            { arrowLeft }
          </div>
          <div className='marginSide0p5'>
            { arrowRight }
          </div>
        </div>
      </div>
    </div>;
  }

  // ポイント合計検索ボタン

  const pointSumButton =
  <button className='button1' tabindex='0'
    onClick={ async () => {
      const result = await handleClickGetPointSum(state, dispatch);

      if (result?.message === 'nfcRequired') {
        const callback = (keyPairs) => {
          handleClickGetPointSum(state, dispatch, null, null, keyPairs);
          keepKeyPairsInMemory(state, dispatch, keyPairs);
        };

        handleClickNfc(state, dispatch, callback);
      }
      else if (result?.message === 'bcRequired') {
        const callback = (keyPairs) => {
          handleClickGetPointSum(state, dispatch, null, null, keyPairs);
          keepKeyPairsInMemory(state, dispatch, keyPairs);
        };

        handleClickScanQr(state, dispatch, callback);
      }
    }}
  >
    <img className='size2x2' src={iconSearch} alt='' />
  </button>;


  return (
    <div>
      {/* PC */}
      <div className="visibleMiddleOrMore">
        <div className='flexColumn'>
          <div className='flexRow justifyContentFlexEnd widthMax marginBottom1' >
            <div className='flexRow ' >
              <button className='button2' onClick={ () => handleClickGoTop(state, dispatch, navigate) }>
                { words.back[state.language] }
              </button>
            </div>
          </div>
          <div className='flexRow justifyContentCenter'>
            <div className='flexRow alignItemsFlexEnd marginSide1'>
              <div className='flexColumn justifyContentCenter alignItemsCenter boxMonatokaPoint '>
                <div className='font2'>
                  { pointSum }
                </div>
                <div className=''>
                  { 'point' }
                </div>
              </div>
              { pointSumButton }
            </div>
            { purchaseSection }
            <div className='flexColumn alignItemsFlexStart width50 preWrap borderKOM marginSide1 padding0p5'>
              { state.config.clientParameters.monatokaPointDescription }
            </div>
          </div>
          {/* history */}
          { controlSection }
          { historyItems }
          {/* development */}
          <div>
            { process.env.REACT_APP_ENVIRONMENT === 'dev' ? <ForDevelopment /> : null }
          </div>
        </div>
      </div>
      {/* SP */}
      <div className="visibleSmallOrLess flexColumn">
        <div className='flexRow justifyContentFlexEnd widthMax marginBottom1' >
          <button className='button2' onClick={ () => handleClickGoTop(state, dispatch, navigate) }>
            { words.back[state.language] }
          </button>
        </div>
        <div className='flexRow justifyContentCenter alignItemsCenter widthMax marginTopBottom1'>
          <div className='flexColumn justifyContentCenter alignItemsCenter marginRight1'>
            <div className='font2'>
              { pointSum }
            </div>
            <div className=''>
              { 'point' }
            </div>
          </div>
          { pointSumButton }
        </div>
        { purchaseSection }
        <div className='flexColumn alignItemsFlexStart widthMax preWrap borderKOM marginTopBottom1 padding0p5'>
          { state.config.clientParameters.monatokaPointDescription }
        </div>
        {/* history */}
        <div className='flexRow justifyContentFlexStart alignItemsCenter marginTopBottom1 '>
          {/* action */}
          {/*
            <div className='flexRow justifyContentSpaceAround marginSide0p5 marginTop0p5'>
              <button className={'box3 focusEffect01 riseOut2 ' + (state.getPointHistory.action === 'permit' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
                onClick={ () => {
                          dispatch({type: 'setStateMultiLayers', keys: ['getPointHistory', 'action'], value: 'permit'});
                }}>
                {words.permit[state.language]}
              </button>
            </div>
          */}
        </div>
        { controlSection }
        { historyItems }
      </div>
      {/* popup */}
      <Popup layer={0}/>
      <Popup layer={1}/>
    </div>
  );
}

// POINT RECORD TITLE
function PointRecordTitle() {
  const [state, dispatch] = useContext(GlobalState);

  return (
    <div>
      {/* PC */}
      <div className="visibleLargeOrMore">
        <div className='boxHistory flexRow justifyContentSpaceBetween alignItemsCenter textCenter'  >
          <div className='marginSide1 widthMin7' >
            { words.purchaseNo[state.language] }
          </div>
          <div className='marginSide1 widthMin15' >
            { words.amountFree[state.language] }
          </div>
          <div className='marginSide1 widthMin15' >
            { words.amountToBuy[state.language] }
          </div>
          <div className='marginSide1 widthMin15' >
            { words.expirationTime[state.language] }
          </div>
          <div className='marginSide1 widthMin15' >
            { words.status[state.language] }
          </div>
          <button className='dummyPadButton1 backgroundColorMonacottoAlmostWhite' disabled={true} />
        </div>
      </div>
      {/* SP */}
    </div>
  );
}

// POINT RECORD
function PointRecord(props) {
  const [state, dispatch] = useContext(GlobalState);
  const record = props.record;

  const popup = { type: 'pointRecordDetail', body: record };

  const buttons =
  <button className='button1' onClick={ () => { dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: popup }) } } >
    <img className='size2x2' src={iconDetail} />
  </button>;


  return (
    <div>
      {/* PC */}
      <div className="visibleLargeOrMore">
        <div className='boxHistory flexRow justifyContentSpaceBetween alignItemsCenter textCenter'  >
          <div className='marginSide1 widthMin7' >
            { 'No. ' + record.purchaseNo }
          </div>
          <div className='marginSide1 widthMin15' >
            { record.amountFree }
          </div>
          <div className='marginSide1 widthMin15' >
            { record.amountToBuy }
          </div>
          <div className='marginSide1 widthMin15' >
            { localTime(record.pointExpirationTime, 'yearToSecond') }
          </div>
          <div className='marginSide1 widthMin15' >
            { words[record.status][state.language] }
          </div>
          { buttons }
        </div>
      </div>
      {/* SP */}
      <div className="visibleMiddleOrLess">
        <div className='boxMonacotto1SmallScreen flexColumn alignItemsFlexStart '  >
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter width98PC'>
            <div className='' >
              { words.purchaseNo[state.language] }
            </div>
            <div className='flexRow alignItemsCenter' >
              <div>
                { 'No. ' + record.purchaseNo }
              </div>
              { buttons }
            </div>
          </div>
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter width98PC'>
            <div className='' >
              { words.amountFree[state.language] }
            </div>
            <div className='' >
              { record.amountFree }
            </div>
          </div>
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter width98PC'>
            <div className='' >
              { words.amountToBuy[state.language] }
            </div>
            <div className='' >
              { record.amountToBuy }
            </div>
          </div>
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter width98PC'>
            <div className='' >
              { words.expirationTime[state.language] }
            </div>
            <div className='' >
              { localTime(record.pointExpirationTime, 'yearToSecond') }
            </div>
          </div>
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter width98PC'>
            <div className='' >
              { words.status[state.language] }
            </div>
            <div className='' >
              { words[record.status][state.language] }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// POINT RECORD DETAIL
function PointRecordDetail(props) {
  const [state, dispatch] = useContext(GlobalState);
  const record = state.popup[props.popupLayer].body;

  // useEffect( () => {
  //   // state.purchaseに値を格納する。 ←　これやんなくてもいいかも。むしろやらないほうがいいかも。
  //   const purchase = {
  //     purchaseNo: record.purchaseNo,
  //     addressMain: record.addressMain,
  //     addressPayFrom: record.addressPayFrom,
  //     amountToBuy: record.amountToBuy,
  //     signatureByAddressMain: record.signatureByAddressMain,
  //     signatureByAddressPayFrom: record.signatureByAddressPayFrom,
  //     status: record.status,
  //   };

  //   dispatch({ type: 'setState', key: 'purchasePoint', value: purchase });
  // }, []);

  return (
    <div>
      {/* PC */}
      <div className="visibleLargeOrMore">
        <div>
          <ResumePurchaseSigning popupLayer={props.popupLayer} />
        </div>
        <ResumePurchase record={record} popupLayer={props.popupLayer} />
        <div className='flexColumn boxDetail'>
          <div>
            {words.addressMain[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.addressMain}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.purchaseNo[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.purchaseNo}
          </div>
        </div>
        <div className='flexRow boxDetail justifyContentSpaceBetween'>
          <div className='flexColumn '>
            <div>
              {words.addressPayFrom[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.addressPayFrom}
            </div>
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.unitPriceMona[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.priceMona + ' MONA'}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.amountToBuy[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.amountToBuy + ' point'}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            { words.amountFree[state.language] }
          </div>
          <div className='paddingLeft1'>
            { record.amountFree + ' point' }
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.totalPriceMona[state.language]}
          </div>
          <div className='paddingLeft1'>
            {(record.priceMonaTotalWatanabe / 100000000) + 'MONA'}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.status[state.language]}
          </div>
          <div className='paddingLeft1'>
            {words[record.status][state.language]}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.txidOfSendingMona[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.receiveMonaTxid}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.applicationTimeOfPurchase[state.language]}
          </div>
          <div className='paddingLeft1'>
            {localTime(record.serverTimeOfAddressMain, 'yearToSecond')}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.purchaseTime[state.language]}
          </div>
          <div className='paddingLeft1'>
            {localTime(record.receiveMonaConfirmationTime, 'yearToSecond')}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.expirationTime[state.language]}
          </div>
          <div className='paddingLeft1'>
            {localTime(record.pointExpirationTime, 'yearToSecond')}
          </div>
        </div>
        {/*
          record.status === 'waitingForSignature' || record.status === 'expiredWithoutSecondSignature' ?
          <div className='flexColumn boxDetail'>
            <div>
              {words.deadlineOfTheSecondSignature[state.language]}
            </div>
            <div className='paddingLeft1'>
              {localTime(record.serverTimeOfAddressMain + state.config.clientParameters.purchaseSecondSignatureExpirationTime)}
            </div>
          </div>
          : null
        */}
        {/*
          record.status === 'waitingForMona' || record.status === 'expired' ?
          <div className='flexColumn boxDetail'>
            <div>
              {words.deadlineOfSendingMona[state.language]}
            </div>
            <div className='paddingLeft1'>
              {localTime(record.serverTimeOfAddressPayFrom + state.config.clientParameters.purchaseExpirationTime)}
            </div>
          </div>
          : null
        */}
      </div>
      {/* SP */}
      <div className="visibleMiddleOrLess">
        <div>
          <ResumePurchaseSigning popupLayer={props.popupLayer} />
        </div>
        <ResumePurchase record={record} popupLayer={props.popupLayer} />
        {/* detail */}
        <div className='flexColumn alignItemsCenter'>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.addressMain[state.language]}
            </div>
            <div className='paddingLeft1 breakAll'>
              {record.addressMain}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.purchaseNo[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.purchaseNo}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.addressPayFrom[state.language]}
            </div>
            <div className='paddingLeft1 breakAll'>
              {record.addressPayFrom}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.unitPriceMona[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.priceMona + ' MONA'}
            </div>
          </div>
          {/*
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.exhibitorProceedsNetMona[state.language]}
            </div>
            <div className='paddingLeft1'>
              {(record.proceedsNetMona / 100000000) + 'MONA'}
            </div>
          </div>
          */}
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.amountToBuy[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.amountToBuy}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              { words.amountFree[state.language] }
            </div>
            <div className='paddingLeft1'>
              { record.amountFree }
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.totalPriceMona[state.language]}
            </div>
            <div className='paddingLeft1'>
              {(record.priceMonaTotalWatanabe / 100000000) + 'MONA'}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.status[state.language]}
            </div>
            <div className='paddingLeft1'>
              {words[record.status][state.language]}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.txidOfSendingMona[state.language]}
            </div>
            <div className='paddingLeft1 breakAll'>
              {record.receiveMonaTxid}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.applicationTimeOfPurchase[state.language]}
            </div>
            <div className='paddingLeft1'>
              {localTime(record.serverTimeOfAddressMain, 'yearToSecond')}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.purchaseTime[state.language]}
            </div>
            <div className='paddingLeft1'>
              {localTime(record.receiveMonaConfirmationTime, 'yearToSecond')}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.expirationTime[state.language]}
            </div>
            <div className='paddingLeft1'>
              {localTime(record.pointExpirationTime, 'yearToSecond')}
            </div>
          </div>
          {/*
            record.status === 'waitingForSignature' || record.status === 'expiredWithoutSecondSignature' ?
            <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
              <div className='width96PC '>
                {words.deadlineOfTheSecondSignature[state.language]}
              </div>
              <div className='paddingLeft1'>
                {localTime(record.serverTimeOfAddressMain + state.config.clientParameters.purchaseSecondSignatureExpirationTime)}
              </div>
            </div>
            : null
          */}
          {/*
            record.status === 'waitingForMona' || record.status === 'expired' ?
            <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
              <div className='width96PC '>
                {words.deadlineOfSendingMona[state.language]}
              </div>
              <div className='paddingLeft1'>
                {localTime(record.serverTimeOfAddressPayFrom + state.config.clientParameters.purchaseExpirationTime)}
              </div>
            </div>
            : null
          */}
        </div>
      </div>
    </div>
  );
}

// RESUME PURCHASE
function ResumePurchase(props) {
  const [state, dispatch] = useContext(GlobalState);
  const record = props.record;
  const popupLayer = props.popupLayer;
  let sendMonaButton;

  if (record.status === 'waitingForMona' && record.sendMonaTxidFromClient === undefined) {
    if (state.walletType === 'mpurse') {
      sendMonaButton =
      <button className='button2 borderRadius2 riseOut2' tabindex='0'
        onClick={ async () => {
          let result;

          // Mpurseのアドレスが合ってるかチェック

          result = await window.mpurse.getAddress()
          .then( (result) => {
            const addressActual = result;
            devLog('address', record.addressPayFrom, addressActual);

            if (addressActual !== record.addressPayFrom) {
              dispatch({ type: 'setNotification', key: 'notification', value: words.theRemittanceSourceAddressAndTheAddressInYourWalletAreDifferent[state.language] });
              return 'rejected';
            }
            else {
              return 'fulfilled';
            }
          })
          .catch( (error) => {
            dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressInYourWalletCanNotBeGotten[state.language] });
            return 'rejected';
          });

          if (result === 'rejected') {
            return { status: 'rejected' };
          }

          // MONA送金
          // モナ量計算

          const amountToBuyDecimal = new decimal(record.amountToBuy);
          const priceMonaDecimal = new decimal(record.priceMona);
          const amountMona = amountToBuyDecimal.times(priceMonaDecimal).toNumber();
          devLog(`amountToBuy ${record.amountToBuy}, priceMona ${record.priceMona}, amountMona ${amountMona}`);

          // 送金！

          result = await window.mpurse.sendAsset(
            record.monatokaPointAddress,
            'MONA', 
            amountMona, 
            'no',
          )
          .then( async result => {
            devLog('succeeded to send MONA', result);
            dispatch({ type: 'setNotification', key: 'notification', value: words.succeededToSendMona[state.language] + "\n" + result });

            await sendLog({
              action: 'sendMona',
              addressMain: record.addressMain,
              purchaseNo: record.purchaseNo,
              signatureByAddressMainFromClient: record.signatureByAddressMain,
              sendMonaTxidFromClient: result,
              amountToBuy: record.amountToBuy,
            });

            return { status: 'fulfilled', body: result };
          })
          .catch( error => {
            devLog('failed to send MONA.', error);
            dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSendMona[state.language] });

            /*
            sendLog({
              message: 'fail to send MONA.(window.mpurse.sendAsset())',
              orderNo: resultSendOrder.body.orderNo,
              addressMona: state.addressMona,
              addressPolygon: state.addressPolygon,
              amountMona: state.amountMona.value,
              amountJpyc: state.amountJpyc.value,
              addressMonaFixed: stateFixed.addressMona,
              addressPolygonFixed: stateFixed.addressPolygon,
              amountMonaFixed: stateFixed.amountMona.value,
              amountJpycFixed: stateFixed.amountJpyc.value,
              clientTime: clientTimeMona,
              serverTime: resultSendOrder.body.serverTime,
              walletTime: Date.now(),
              error: error,
            });
            */

            return { status: 'failToSendMona', body: error };
          });

          if (result.status === 'fulfilled') {
            handleClickGetPointHistory(state, dispatch, 0, null, true);
            dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayer], value: { type: null, body: null } });
          }
        }}
      >
        {words.sendMona[state.language]}
      </button>;
    }
    else if (state.walletType === 'nfc') {
      sendMonaButton =
      <button className='button2 flexRow justifyContentCenter alignItemsCenter borderRadius2 riseOut2' tabindex='0'
        onClick={ async () => {
          const callback = async (keyPairs) => {
            let result;
            let addressActual;
            let txid;
            const keyPair = keyPairs[0];

            // NFCのアドレスが合ってるかチェック

            result = getAddressFromKey(state, dispatch, keyPair);

            if (result.status === 'fulfilled') {
              addressActual = result.address;
            }
            else {
              dispatch({ type: 'setNotification', key: 'notification', value: words.failedToGetTheAddress[state.language] });
              return result;
            }

            if (addressActual !== record.addressPayFrom) {
              dispatch({ type: 'setNotification', key: 'notification', value: words.theRemittanceSourceAddressAndTheAddressInYourKeyCardAreDifferent[state.language] });
              return {
                status: 'rejected',
                error: 'theAddressFilledAndTheAddressInWalletAreDifferent',
              };
            }

            // MONA送金
            // モナ量計算

            const amountToBuyDecimal = new decimal(record.amountToBuy);
            const priceMonaDecimal = new decimal(record.priceMona);
            const amountMona = amountToBuyDecimal.times(priceMonaDecimal).toNumber();
            devLog(`amountToBuy ${record.amountToBuy}, priceMona ${record.priceMona}, amountMona ${amountMona}`);

            result = await sendMonaSequence(
              state, dispatch, keyPair, record.addressPayFrom, state.configMP.clientParameters.monatokaPointAddress, amountMona,
              {
                provisionalTransactionFee: state.config.clientParameters.provisionalTransactionFee,
                transactionFeeUpperBound: state.config.clientParameters.transactionFeeUpperBound,
              }
            );

            if (result.status === 'fulfilled') {
              txid = result.body;
            }
            else {
              return result;
            }

            dispatch({ type: 'setNotification', key: 'notification', value: words.succeededToSendMona[state.language] + "\n" + txid });

            await sendLog({
              action: 'sendMona',
              addressMain: record.addressMain,
              purchaseNo: record.purchaseNo,
              signatureByAddressMainFromClient: record.signatureByAddressMain,
              sendMonaTxidFromClient: txid,
              amountToBuy: record.amountToBuy,
            });
            // ↑ sendMonaのnotifyが完了するまで待ちたいから、awaitは必要！ 

            handleClickGetPointHistory(state, dispatch, 0, null, true);
            dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayer], value: { type: null, body: null } });
          };

          handleClickNfc(state, dispatch, callback);
        }}
      >
        <div>
          {words.sendMona[state.language]}
        </div>
        <div>
          <img className='size2x2' src={iconKeyCard} />
        </div>
      </button>;
    }
    else { // qr
      sendMonaButton =
      <button className='button2 flexRow justifyContentCenter alignItemsCenter borderRadius2 riseOut2' tabindex='0'
        onClick={ async () => {
          const callback = async (keyPairs) => {
            let result;
            let addressActual;
            let txid;
            const keyPair = keyPairs[0];

            // NFCのアドレスが合ってるかチェック

            result = getAddressFromKey(state, dispatch, keyPair);

            if (result.status === 'fulfilled') {
              addressActual = result.address;
            }
            else {
              dispatch({ type: 'setNotification', key: 'notification', value: words.failedToGetTheAddress[state.language] });
              return result;
            }

            if (addressActual !== record.addressPayFrom) {
              dispatch({ type: 'setNotification', key: 'notification', value: words.theRemittanceSourceAddressAndTheAddressInYourKeyCardAreDifferent[state.language] });
              return {
                status: 'rejected',
                error: 'theAddressFilledAndTheAddressInWalletAreDifferent',
              };
            }

            // MONA送金
            // モナ量計算

            const amountToBuyDecimal = new decimal(record.amountToBuy);
            const priceMonaDecimal = new decimal(record.priceMona);
            const amountMona = amountToBuyDecimal.times(priceMonaDecimal).toNumber();
            devLog(`amountToBuy ${record.amountToBuy}, priceMona ${record.priceMona}, amountMona ${amountMona}`);

            result = await sendMonaSequence(
              state, dispatch, keyPair, record.addressPayFrom, state.configMP.clientParameters.monatokaPointAddress, amountMona,
              {
                provisionalTransactionFee: state.config.clientParameters.provisionalTransactionFee,
                transactionFeeUpperBound: state.config.clientParameters.transactionFeeUpperBound,
              }
            );

            if (result.status === 'fulfilled') {
              txid = result.body;
            }
            else {
              return result;
            }

            dispatch({ type: 'setNotification', key: 'notification', value: words.succeededToSendMona[state.language] + "\n" + txid });

            await sendLog({
              action: 'sendMona',
              addressMain: record.addressMain,
              purchaseNo: record.purchaseNo,
              signatureByAddressMainFromClient: record.signatureByAddressMain,
              sendMonaTxidFromClient: txid,
              amountToBuy: record.amountToBuy,
            });
            // ↑ sendMonaのnotifyが完了するまで待ちたいから、awaitは必要！ 

            handleClickGetPointHistory(state, dispatch, 0, null, true);
            dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayer], value: { type: null, body: null } });
          };

          handleClickScanQr(state, dispatch, callback);
        }}
      >
        <div>
          {words.sendMona[state.language]}
        </div>
        <div>
          <img className='size2x2' src={iconQr} />
        </div>
      </button>;
    }
  }


  return (
    <div className=''>
      {/* PC */}
      <div className='visibleLargeOrMore flexColumn alignItemsCenter'>
        { sendMonaButton }
      </div>
      {/* SP */}
      <div className="visibleMiddleOrLess flexColumn alignItemsCenter">
        { sendMonaButton }
      </div>
    </div>
  );
}

// RESUME PURCHASE SIGNING
function ResumePurchaseSigning(props) {
  const [state, dispatch] = useContext(GlobalState);

  return (
      <div className='flexRow justifyContentCenter'>
        {
          (state.popup[props.popupLayer].body.signatureByAddressPayFrom === undefined && state.popup[props.popupLayer].body.status === 'waitingForSignature') ?
            <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
              onClick={ async () => {
                const result = await handleClickSignPurchase(state, dispatch, 'addressPayFrom', props.popupLayer);

                if (result.status === 'fulfilled') {
                  handleClickGetPointHistory(state, dispatch, 0, null, true);
                }
              }}
            >
              <div>
                {words.signByAddressYouPayFrom[state.language]}
              </div>
              <img className='size2x2' src={iconSign} />
            </button>
          : null
        }
      </div>
  );
}


// DUEL HISTORY
function DuelHistory() {
  const [state, dispatch, navigate] = useContext(GlobalState);
  const location = useLocation();
  let historyItems;
  let historyFunction;
  let arrowLeft;
  let arrowRight;

  // アクションごとのhistoryItemsとhistoryFunction設定。

  if (state.getDuelHistory.action === 'duelHistoryAll') {
    if (state.duelHistoryAll.duelHistory !== undefined) {
      historyItems = state.duelHistoryAll.duelHistory.map( record => {
        if (true) {
          return <DuelRecord record={record} />
        }
        else {
          return null;
        }
      });

      historyItems.unshift(<DuelRecordTitle />);
    }
    else {
      historyItems = [<DuelRecordTitle />];
    }

    historyFunction = (pagingIndex) => handleClickGetDuelHistoryAll(state, dispatch, pagingIndex);
  }

  if (state.getDuelHistory.action === 'duelHistoryAddress') {
    if (state.duelHistoryAddress.duelHistory !== undefined) {
      historyItems = state.duelHistoryAddress.duelHistory.map( record => {
        if (true) {
          return <DuelRecord record={record} />
        }
        else {
          return null;
        }
      });

      historyItems.unshift(<DuelRecordTitle />);
    }
    else {
      historyItems = [<DuelRecordTitle />];
    }

    historyFunction = (pagingIndex) => handleClickGetDuelHistoryAll(state, dispatch, pagingIndex);
  }

  // 矢印

  if ( state.getDuelHistory.pagingIndex[state.getDuelHistory.action] >= 1 ) {
    arrowLeft = <div>
      <button className='flexRow justifyContentCenter alignItemsCenter size2x2Large backgroundColorTransparent borderNone cursor borderRadiusCircle riseOut2 '
        onClick={ () => historyFunction(state.getDuelHistory.pagingIndex[state.getDuelHistory.action] - 1) }
      >
        <img className='size2x2' src={iconCircleLeft} />
      </button>
    </div>
  }
  else {
    arrowLeft = <div>
      <button className='size2x2Large backgroundColorTransparent borderNone' disabled={true} >
        <div className='size2x2' />
      </button>
    </div>
  }

  if ( (state.getDuelHistory.lastEvaluatedKey[state.getDuelHistory.action][state.getDuelHistory.pagingIndex[state.getDuelHistory.action]] !== undefined &&
        state.getDuelHistory.lastEvaluatedKey[state.getDuelHistory.action][state.getDuelHistory.pagingIndex[state.getDuelHistory.action]] !== null        ) ||
       (state.getDuelHistory.lastEvaluatedKey2[state.getDuelHistory.action]?.[state.getDuelHistory.pagingIndex[state.getDuelHistory.action]] !== undefined &&
        state.getDuelHistory.lastEvaluatedKey2[state.getDuelHistory.action]?.[state.getDuelHistory.pagingIndex[state.getDuelHistory.action]] !== null        ) ) {
    arrowRight = <div>
      <button className='flexRow justifyContentCenter alignItemsCenter size2x2Large backgroundColorTransparent borderNone cursor borderRadiusCircle riseOut2 '
        onClick={ () => { historyFunction(state.getDuelHistory.pagingIndex[state.getDuelHistory.action] + 1) } }
      >
        <img className='size2x2' src={iconCircleRight} />
      </button>
    </div>
  }
  else {
    arrowRight = <div>
      <button className='size2x2Large backgroundColorTransparent borderNone' disabled={true} >
        <div className='size2x2' />
      </button>
    </div>
  }

  // ページングパラメータリセット関数

  const resetPagingParameters = () => {
    dispatch({ type: 'setStateMultiLayers', keys: ['getDuelHistory', 'pagingIndex'],
      value: { duelHistoryAll: 0, duelHistoryAddress: 0 }
    });

    dispatch({ type: 'setStateMultiLayers', keys: ['getDuelHistory', 'lastEvaluatedKey'],
      value: { duelHistoryAll: [], duelHistoryAddress: [] }
    });

    dispatch({ type: 'setStateMultiLayers', keys: ['getDuelHistory', 'lastEvaluatedKey2'],
      value: { duelHistoryAll: [], duelHistoryAddress: [] }
    });
  };

  // アドレス取得ボタン

  let getAddressButton;

  if (state.walletType === 'mpurse') {
    getAddressButton =
    <button className='button1'
      onClick={ async () => {
        const result = await handleClickMpurse(state, dispatch, ['getDuelHistory', 'duelistAddress']);
        devLog('handleClickMpurse; result', JSON.stringify(result));

        if (result.status === 'fulfilled') {
          if (state.getDuelHistory.action !== 'duelHistoryAddress') {
            dispatch({ type: 'setStateMultiLayers', keys: ['getDuelHistory', 'action'], value: 'duelHistoryAddress' });
          }

          resetPagingParameters();
        }
        else {
          dispatch({ type: 'setNotification', key: 'notification', value: words.failedToGetTheAddress[state.language] });
        }
      }}
    >
      <img className='size2x2' src={iconMpurse} />
    </button>;
  }
  else if (state.walletType === 'nfc') {
    getAddressButton =
    <button className='button2 flexRow justifyContentCenter alignItemsCenter '
      onClick={ () => {
        const callback = (keyPairs) => {
          const result = getAddressFromKey(state, dispatch, keyPairs[0]);
          devLog('touch NFC to get address; result', JSON.stringify(result));

          if (result.status === 'fulfilled') {
            dispatch({ type: 'setStateMultiLayers', keys: ['getDuelHistory', 'duelistAddress'], value: result.address });

            if (state.getDuelHistory.action !== 'duelHistoryAddress') {
              dispatch({ type: 'setStateMultiLayers', keys: ['getDuelHistory', 'action'], value: 'duelHistoryAddress' });
            }

            resetPagingParameters();
          }
          else {
            dispatch({ type: 'setNotification', key: 'notification', value: words.failedToGetTheAddress[state.language] });
          }
        };

        handleClickNfc(state, dispatch, callback);
      }}
    >
      <div>
        { words.address[state.language] }
      </div>
      <div>
        <img className='size2x2' src={iconKeyCard} />
      </div>
    </button>;
  }
  else { // qr
    getAddressButton =
    <button className='button2 flexRow justifyContentCenter alignItemsCenter '
      onClick={ () => {
        const callback = (keyPairs) => {
          const result = getAddressFromKey(state, dispatch, keyPairs[0]);
          devLog('touch NFC to get address; result', JSON.stringify(result));

          if (result.status === 'fulfilled') {
            dispatch({ type: 'setStateMultiLayers', keys: ['getDuelHistory', 'duelistAddress'], value: result.address });

            if (state.getDuelHistory.action !== 'duelHistoryAddress') {
              dispatch({ type: 'setStateMultiLayers', keys: ['getDuelHistory', 'action'], value: 'duelHistoryAddress' });
            }

            resetPagingParameters();
          }
          else {
            dispatch({ type: 'setNotification', key: 'notification', value: words.failedToGetTheAddress[state.language] });
          }
        };

        handleClickScanQr(state, dispatch, callback);
      }}
    >
      <div>
        { words.address[state.language] }
      </div>
      <div>
        <img className='size2x2' src={iconQr} />
      </div>
    </button>;
  }


  return (
    <div>
      {/* PC */}
      <div className="visibleMiddleOrMore">
        <div className='flexColumn'>
          <div className='flexRow justifyContentFlexEnd widthMax marginBottom1' >
            <div className='flexRow ' >
              <button className='button2' onClick={ () => handleClickGoBack(state, dispatch, navigate, location) }>
                { words.back[state.language] }
              </button>
            </div>
          </div>
          {/* history */}
          <div className='flexRow justifyContentFlexStart alignItemsCenter '>
            {/* action */}
            <div className='flexRow justifyContentSpaceAround marginSide0p5 '>
              <button className={'box3 focusEffect01 riseOut2 ' + (state.getDuelHistory.action === 'duelHistoryAll' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
                onClick={ () => {
                          dispatch({type: 'setStateMultiLayers', keys: ['getDuelHistory', 'action'], value: 'duelHistoryAll'});
                }}>
                {words.duelHistoryAll[state.language]}
              </button>
            </div>
            {/* duelistAddress */}
            <TextLine3 fieldName='historyAddressMain' keys={['getDuelHistory', 'duelistAddress']} face={words.duelistAddress[state.language]} tooltip={true}
              extraFunctionOnChange={ [
                {
                  function: (duelistAddress) => {
                    dispatch({ type: 'setStateMultiLayers', keys: ['getDuelHistory', 'pagingIndex'],
                      value: { duelHistoryAll: 0, duelHistoryAddress: 0 }
                    });

                    dispatch({ type: 'setStateMultiLayers', keys: ['getDuelHistory', 'lastEvaluatedKey'],
                      value: { duelHistoryAll: [], duelHistoryAddress: [] }
                    });

                    dispatch({ type: 'setStateMultiLayers', keys: ['getDuelHistory', 'lastEvaluatedKey2'],
                      value: { duelHistoryAll: [], duelHistoryAddress: [] }
                    });

                    if (state.getDuelHistory.action === 'duelHistoryAddress') {
                      if (duelistAddress === undefined || duelistAddress === null || duelistAddress === '') {
                        dispatch({ type: 'setStateMultiLayers', keys: ['getDuelHistory', 'action'], value: 'duelHistoryAll' });
                      }
                    }
                    else {
                      if (duelistAddress !== undefined && duelistAddress !== null && duelistAddress !== '') {
                        dispatch({ type: 'setStateMultiLayers', keys: ['getDuelHistory', 'action'], value: 'duelHistoryAddress' });
                      }
                    }
                  },
                  arguments: [],
                  targetValue: true,
                }
              ]}
            />
            { getAddressButton }
            {/*
              <button className='button1'
                onClick={ async () => {
                  const result = await handleClickMpurse(state, dispatch, ['getDuelHistory', 'duelistAddress']);
                  devLog('handleClickMpurse; result', JSON.stringify(result));

                  if (state.getDuelHistory.action !== 'duelHistoryAddress') {
                    if (result.status === 'fulfilled') {
                      dispatch({ type: 'setStateMultiLayers', keys: ['getDuelHistory', 'action'], value: 'duelHistoryAddress' });
                    }
                  }

                  dispatch({ type: 'setStateMultiLayers', keys: ['getDuelHistory', 'pagingIndex'],
                    value: { duelHistoryAll: 0, duelHistoryAddress: 0 }
                  });

                  dispatch({ type: 'setStateMultiLayers', keys: ['getDuelHistory', 'lastEvaluatedKey'],
                    value: { duelHistoryAll: [], duelHistoryAddress: [] }
                  });

                  dispatch({ type: 'setStateMultiLayers', keys: ['getDuelHistory', 'lastEvaluatedKey2'],
                    value: { duelHistoryAll: [], duelHistoryAddress: [] }
                  });
                }}
              >
                <img className='size2x2' src={iconMpurse} />
              </button>
            */}
            {/*
              <button className='button1'
                onClick={ () => {
                  handleClickAddressHistory(state, dispatch, cookies, ['getPointHistory', 'addressMain']);
                  dispatch({ type: 'setStateMultiLayers', keys: ['getPointHistory', 'pagingIndex'],
                    value: { permit: 0, permitted: 0, permittedInclusively: 0, requestPermission: 0, requestedPermission: 0, delegate: 0, delegated: 0 }
                  });
                  dispatch({ type: 'setStateMultiLayers', keys: ['getPointHistory', 'lastEvaluatedKey'],
                    value: { permit: [], permitted: [], permittedInclusively: [], requestPermission: [], requestedPermission: [], delegate: [], delegated: [] }
                  });
                }}
              >
                <img className='size2x2' src={iconList} />
              </button>
            */}
            <button className='button1' tabindex='0' onClick={ () => historyFunction(0) } >
              <img className='size2x2' src={iconSearch} />
            </button>
            {/* range */}
            <div className='flexRow justifyContentFlexStart marginSide1 '>
              <div className='flexColumn alignItemsFlexStart justifyContentCenter '>
                <div className='flexColumn justifyContentCenter height2p2'>
                  <div>
                    from
                  </div>
                </div>
                <div className='flexColumn justifyContentCenter height2p2'>
                  <div>
                    to
                  </div>
                </div>
              </div>
              {/* from */}
              <div className='flexColumn alignItemsFlexStart justifyContentCenter '>
                <div className='flexRow justifyContentFlexStart alignItemsCenter '>
                  <div className='marginSide0p5'>
                    <TextLine3 fieldName='searchDateRangeFromYear' keys={['searchDateRange', 'from', 'year']} face={'year'} type='setStateMultiLayersNum' boxClass='box4chars'
                      extraFunctionOnChange={ [
                        {function: setEpochTime, arguments: [state, dispatch, ['searchDateRange', 'from'], 'year'], targetValue: true},
                      ] } 
                    />
                  </div>
                  <div className='marginSide0p5'>
                    <TextLine3 fieldName='searchDateRangeFromMonth' keys={['searchDateRange', 'from', 'month']} face={'month'} type='setStateMultiLayersNum' boxClass='box4chars'
                      extraFunctionOnChange={ [
                        {function: setEpochTime, arguments: [state, dispatch, ['searchDateRange', 'from'], 'month'], targetValue: true},
                      ] } 
                    />
                  </div>
                  <div className='marginSide0p5'>
                    <TextLine3 fieldName='searchDateRangeFromDay' keys={['searchDateRange', 'from', 'day']} face={'day'} type='setStateMultiLayersNum' boxClass='box4chars'
                      extraFunctionOnChange={ [
                        {function: setEpochTime, arguments: [state, dispatch, ['searchDateRange', 'from'], 'day'], targetValue: true},
                      ] } 
                    />
                  </div>
                </div>
                {/* to */}
                <div className='flexRow justifyContentFlexStart alignItemsCenter ' >
                  <div className='marginSide0p5'>
                    <TextLine3 fieldName='searchDateRangeToYear' keys={['searchDateRange', 'to', 'year']} face={'year'} type='setStateMultiLayersNum' boxClass='box4chars'
                      extraFunctionOnChange={ [
                        {function: setEpochTime, arguments: [state, dispatch, ['searchDateRange', 'to'], 'year'], targetValue: true},
                      ] } 
                    />
                  </div>
                  <div className='marginSide0p5'>
                    <TextLine3 fieldName='searchDateRangeToMonth' keys={['searchDateRange', 'to', 'month']} face={'month'} type='setStateMultiLayersNum' boxClass='box4chars'
                      extraFunctionOnChange={ [
                        {function: setEpochTime, arguments: [state, dispatch, ['searchDateRange', 'to'], 'month'], targetValue: true},
                      ] } 
                    />
                  </div>
                  <div className='marginSide0p5'>
                    <TextLine3 fieldName='searchDateRangeToDay' keys={['searchDateRange', 'to', 'day']} face={'day'} type='setStateMultiLayersNum' boxClass='box4chars'
                      extraFunctionOnChange={ [
                        {function: setEpochTime, arguments: [state, dispatch, ['searchDateRange', 'to'], 'day'], targetValue: true},
                      ] } 
                    />
                  </div>
                </div>
              </div>
            </div>
            { arrowLeft }
            { arrowRight }
          </div>
          { historyItems }
          {/* development */}
          <div>
            { process.env.REACT_APP_ENVIRONMENT === 'dev' ? <ForDevelopment /> : null }
          </div>
        </div>
      </div>
      {/* SP */}
      <div className="visibleSmallOrLess">
        <div className='flexColumn'>
          <div className='flexRow justifyContentFlexEnd widthMax marginBottom1' >
            <button className='button2' onClick={ () => handleClickGoBack(state, dispatch, navigate, location) }>
              { words.back[state.language] }
            </button>
          </div>
          {/* control */}
          {/* action */}
          <div className='flexRow justifyContentFlexStart '>
            <button className={'box3 focusEffect01 riseOut2 ' + (state.getDuelHistory.action === 'duelHistoryAll' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
              onClick={ () => {
                        dispatch({type: 'setStateMultiLayers', keys: ['getDuelHistory', 'action'], value: 'duelHistoryAll'});
              }}>
              {words.duelHistoryAll[state.language]}
            </button>
          </div>
          {/* duelistAddress */}
          <div className='flexColumn justifyContentCenter alignItemsFlexStart marginBottom1 '>
            <TextLine3 fieldName='historyAddressMain' keys={['getDuelHistory', 'duelistAddress']} face={words.duelistAddress[state.language]} tooltip={true}
              extraFunctionOnChange={ [
                {
                  function: (duelistAddress) => {
                    dispatch({ type: 'setStateMultiLayers', keys: ['getDuelHistory', 'pagingIndex'],
                      value: { duelHistoryAll: 0, duelHistoryAddress: 0 }
                    });

                    dispatch({ type: 'setStateMultiLayers', keys: ['getDuelHistory', 'lastEvaluatedKey'],
                      value: { duelHistoryAll: [], duelHistoryAddress: [] }
                    });

                    dispatch({ type: 'setStateMultiLayers', keys: ['getDuelHistory', 'lastEvaluatedKey2'],
                      value: { duelHistoryAll: [], duelHistoryAddress: [] }
                    });

                    if (state.getDuelHistory.action === 'duelHistoryAddress') {
                      if (duelistAddress === undefined || duelistAddress === null || duelistAddress === '') {
                        dispatch({ type: 'setStateMultiLayers', keys: ['getDuelHistory', 'action'], value: 'duelHistoryAll' });
                      }
                    }
                    else {
                      if (duelistAddress !== undefined && duelistAddress !== null && duelistAddress !== '') {
                        dispatch({ type: 'setStateMultiLayers', keys: ['getDuelHistory', 'action'], value: 'duelHistoryAddress' });
                      }
                    }
                  },
                  arguments: [],
                  targetValue: true,
                }
              ]}
            />
            <div className='flexRow justifyContentFlexStart alignItemsCenter '>
              { getAddressButton }
              {/*
                <button className='button1'
                  onClick={ () => {
                    handleClickAddressHistory(state, dispatch, cookies, ['getPointHistory', 'addressMain']);
                    dispatch({ type: 'setStateMultiLayers', keys: ['getPointHistory', 'pagingIndex'],
                      value: { permit: 0, permitted: 0, permittedInclusively: 0, requestPermission: 0, requestedPermission: 0, delegate: 0, delegated: 0 }
                    });
                    dispatch({ type: 'setStateMultiLayers', keys: ['getPointHistory', 'lastEvaluatedKey'],
                      value: { permit: [], permitted: [], permittedInclusively: [], requestPermission: [], requestedPermission: [], delegate: [], delegated: [] }
                    });
                  }}
                >
                  <img className='size2x2' src={iconList} />
                </button>
              */}
              <button className='button1' tabindex='0' onClick={ () => historyFunction(0) } >
                <img className='size2x2' src={iconSearch} />
              </button>
            </div>
          </div>
          {/* range */}
          <div className='flexRow justifyContentFlexStart marginSide1 '>
            <div className='flexColumn alignItemsFlexStart justifyContentCenter '>
              <div className='flexColumn justifyContentCenter height2p2'>
                <div>
                  from
                </div>
              </div>
              <div className='flexColumn justifyContentCenter height2p2'>
                <div>
                  to
                </div>
              </div>
            </div>
            {/* from */}
            <div className='flexColumn alignItemsFlexStart justifyContentCenter '>
              <div className='flexRow justifyContentFlexStart alignItemsCenter '>
                <div className='marginSide0p5'>
                  <TextLine3 fieldName='searchDateRangeFromYear' keys={['searchDateRange', 'from', 'year']} face={'year'} type='setStateMultiLayersNum' boxClass='box4chars'
                    extraFunctionOnChange={ [
                      {function: setEpochTime, arguments: [state, dispatch, ['searchDateRange', 'from'], 'year'], targetValue: true},
                    ] } 
                  />
                </div>
                <div className='marginSide0p5'>
                  <TextLine3 fieldName='searchDateRangeFromMonth' keys={['searchDateRange', 'from', 'month']} face={'month'} type='setStateMultiLayersNum' boxClass='box4chars'
                    extraFunctionOnChange={ [
                      {function: setEpochTime, arguments: [state, dispatch, ['searchDateRange', 'from'], 'month'], targetValue: true},
                    ] } 
                  />
                </div>
                <div className='marginSide0p5'>
                  <TextLine3 fieldName='searchDateRangeFromDay' keys={['searchDateRange', 'from', 'day']} face={'day'} type='setStateMultiLayersNum' boxClass='box4chars'
                    extraFunctionOnChange={ [
                      {function: setEpochTime, arguments: [state, dispatch, ['searchDateRange', 'from'], 'day'], targetValue: true},
                    ] } 
                  />
                </div>
              </div>
              {/* to */}
              <div className='flexRow justifyContentFlexStart alignItemsCenter ' >
                <div className='marginSide0p5'>
                  <TextLine3 fieldName='searchDateRangeToYear' keys={['searchDateRange', 'to', 'year']} face={'year'} type='setStateMultiLayersNum' boxClass='box4chars'
                    extraFunctionOnChange={ [
                      {function: setEpochTime, arguments: [state, dispatch, ['searchDateRange', 'to'], 'year'], targetValue: true},
                    ] } 
                  />
                </div>
                <div className='marginSide0p5'>
                  <TextLine3 fieldName='searchDateRangeToMonth' keys={['searchDateRange', 'to', 'month']} face={'month'} type='setStateMultiLayersNum' boxClass='box4chars'
                    extraFunctionOnChange={ [
                      {function: setEpochTime, arguments: [state, dispatch, ['searchDateRange', 'to'], 'month'], targetValue: true},
                    ] } 
                  />
                </div>
                <div className='marginSide0p5'>
                  <TextLine3 fieldName='searchDateRangeToDay' keys={['searchDateRange', 'to', 'day']} face={'day'} type='setStateMultiLayersNum' boxClass='box4chars'
                    extraFunctionOnChange={ [
                      {function: setEpochTime, arguments: [state, dispatch, ['searchDateRange', 'to'], 'day'], targetValue: true},
                    ] } 
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='flexRow justifyContentFlexStart alignItemsCenter margin1 '>
            { arrowLeft }
            { arrowRight }
          </div>
          { historyItems }
        </div>
      </div>
      {/* popup */}
      <Popup layer={0}/>
      <Popup layer={1}/>
      <Popup layer={2}/>
      <Popup layer={3}/>
    </div>
  );
}

// DUEL RECORD TITLE
function DuelRecordTitle() {
  const [state, dispatch] = useContext(GlobalState);

  return (
    <div>
      {/* PC */}
      <div className="visibleLargeOrMore">
        <div className='boxHistory flexRow justifyContentSpaceBetween alignItemsCenter textCenter'  >
          {/*
            <div className='marginSide1 widthMin15' >
              { words.duelNo[state.language] }
            </div>
          */}
          <div className='marginSide1 widthMin12' >
            { words.duelistA[state.language] /* 赤 */ }
          </div>
          <div className='marginSide1 widthMin12' >
            { words.duelistB[state.language] /* 青 */ }
          </div>
          <div className='marginSide1 widthMin15' >
            { words.winner[state.language] }
          </div>
          {/*
            <div className='marginSide1 widthMin15' >
              { words.loser[state.language] }
            </div>
          */}
          <div className='marginSide1 widthMin7' >
            { words.point[state.language] }
          </div>
          <div className='marginSide1 widthMin20' >
            { words.finisher[state.language] }
          </div>
          <div className='marginSide1 widthMin12' >
            { words.startTime[state.language] }
          </div>
          <button className='dummyPadButton1 backgroundColorMonacottoAlmostWhite' disabled={true} />
        </div>
      </div>
      {/* SP */}
    </div>
  );
}

// DUEL RECORD
function DuelRecord(props) {
  const [state, dispatch, navigate] = useContext(GlobalState);
  const record = props.record;
  const result = record.result;
  const finisher = result[record.round].monsters[result[record.round].winner];
  let buttons;
  let popup;

  popup = { type: 'duelHistoryDetail', body: record };
  buttons =
  <button className='button1'
    onClick={ () => {
      dispatch({ type: 'setStateMultiLayers', keys: ['getDuelHistory', 'round'], value: 0 });
      // dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: popup });
      dispatch({ type: 'setStateMultiLayers', keys: ['popup', 100], value: popup });
      navigate(
        process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/duelhistorydetail' : '/duelhistorydetail',
        { state: { previousLocation: 'duelhistory' } }
      )
    }}
  >
    <img className='size2x2' src={iconDetail} />
  </button>;

  let duelWinnerColorAndName;

  if (record.duelWinner === 'A') {
    duelWinnerColorAndName = words.duelistA[state.language] + ' - ' + record.duelistAUserName;
  }
  else if (record.duelWinner === 'B') {
    duelWinnerColorAndName = words.duelistB[state.language] + ' - ' + record.duelistBUserName;
  }
  else if (record.duelWinner === 'draw') {
    duelWinnerColorAndName = 'draw';
  }
  else {
    duelWinnerColorAndName = '-';
  }

  let finisherName;

  if (finisher !== undefined) {
    finisherName = state.registeredCard[finisher]?.name;
  }
  else {
    finisherName = '-';
  }

  let point;

  if (result[record.round].reason === 'normal' || result[record.round].reason === 'additionalJudgement') {
    point = record.pointA + ' - ' + record.pointB;
  }
  else if (result[record.round].reason === 'timeUp') {
    point = words.timeup[state.language];
  }
  else if (result[record.round].reason === 'leave') {
    point = words.leftTheGame[state.language];
  }


  return (
    <div>
      {/* PC */}
      <div className="visibleLargeOrMore">
        <div className='boxHistory flexRow justifyContentSpaceBetween alignItemsCenter textCenter'  >
          {/*
            <div className='marginSide1 widthMin15' >
              { 'hoge' + record.roomId + '-' + record.duelNo }
            </div>
          */}
          <div className='marginSide1 widthMin12' >
            { record.duelistAUserName }
          </div>
          <div className='marginSide1 widthMin12' >
            { record.duelistBUserName }
          </div>
          <div className='marginSide1 widthMin15' >
            { duelWinnerColorAndName }
          </div>
          <div className='marginSide1 widthMin7' >
            { point }
          </div>
          <div className='marginSide1 widthMin20' >
            { finisherName }
          </div>
          <div className='marginSide1 widthMin12' >
            { localTime(record.startTime, 'yearToSecond' ) }
          </div>
          { buttons }
        </div>
      </div>
      {/* SP */}
      <div className="visibleMiddleOrLess">
        <div className='boxMonacotto1SmallScreen flexColumn alignItemsFlexStart '  >
          <div className='flexRow justifyContentFlexEnd alignItemsCenter width98PC'>
            { buttons }
          </div>
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter width98PC'>
            <div className='' >
              { words.duelistA[state.language] /* 赤 */ }
            </div>
            <div className='flexRow alignItemsCenter' >
              { record.duelistAUserName }
            </div>
          </div>
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter width98PC'>
            <div className='' >
              { words.duelistB[state.language] /* 青 */ }
            </div>
            <div className='' >
              { record.duelistBUserName }
            </div>
          </div>
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter width98PC'>
            <div className='' >
              { words.winner[state.language] }
            </div>
            <div className='' >
              { duelWinnerColorAndName }
            </div>
          </div>
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter width98PC'>
            <div className='' >
              { words.point[state.language] }
            </div>
            <div className='' >
              { point }
            </div>
          </div>
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter width98PC'>
            <div className='' >
              { words.finisher[state.language] }
            </div>
            <div className='' >
              { finisherName }
            </div>
          </div>
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter width98PC'>
            <div className='' >
              { words.startTime[state.language] }
            </div>
            <div className='' >
              { localTime(record.startTime, 'yearToSecond' ) }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// DUEL HISTORY DETAIL
function DuelHistoryDetail(props) {
  const [state, dispatch, navigate] = useContext(GlobalState);
  const location = useLocation();
  const popupLayer = props.popupLayer;
  const record = state.popup[props.popupLayer].body;
  const round = state.getDuelHistory.round;
  const thisRound = record.result[round];

  // デッキ

  const deckA =
  <div className='boxDeckSmall classBackGroundA' >
    <div>
      { record.duelistAUserName + words.sDeck[state.language] }
    </div>
    <div className='flexRow justifyContentCenter widthMax'>
      <DeckInHistory record={record} side='A' popupLayer={props.popupLayer} />
    </div>
  </div>;

  const deckB =
  <div className='boxDeckSmall classBackGroundB' >
    <div>
      { record.duelistBUserName + words.sDeck[state.language] }
    </div>
    <div className='flexRow justifyContentCenter widthMax'>
      <DeckInHistory record={record} side='B' popupLayer={props.popupLayer} />
    </div>
  </div>;

  const deckASP =
  <button className='flexColumn justifyContentSpaceAround alignItemsCenter button2 width96PC heightMin4 font0p7' disabled={props.disabled ? true : false}
    onClick={ () => {
      const body = {
        record: record,
        side: 'A',
        round: round,
      }

      const popup = { type: 'deckInHistorySP', body: body };
      dispatch({ type: 'setStateMultiLayers', keys: ['popup', props.popupLayer + 1], value: popup });
    }}
  >
    <div>
      { record.duelistAUserName }
    </div>
    <div>
      { words.sDeck[state.language] }
    </div>
  </button>

  const deckBSP =
  <button className='flexColumn justifyContentSpaceAround alignItemsCenter button2 width96PC heightMin4 font0p7' disabled={props.disabled ? true : false}
    onClick={ () => {
      const body = {
        record: record,
        side: 'B',
        round: round,
      }

      const popup = { type: 'deckInHistorySP', body: body };
      dispatch({ type: 'setStateMultiLayers', keys: ['popup', props.popupLayer + 1], value: popup });
    }}
  >
    <div>
      { record.duelistBUserName }
    </div>
    <div>
      { words.sDeck[state.language] }
    </div>
  </button>

  // ポイント

  const pointA = record.result
  .slice(0, round + 1)
  .reduce( (acc, cur) => {
    if (cur.winner === 'A') {
      return acc + 1;
    }
    else {
      return acc;
    }
  }, 0);

  const pointB = record.result
  .slice(0, round + 1)
  .reduce( (acc, cur) => {
    if (cur.winner === 'B') {
      return acc + 1;
    }
    else {
      return acc;
    }
  }, 0);

  // ボタン

  let nextButton;
  let backButton;
  let closeButton;
  let closeButtonSP;

  if (round <= record.round - 1) {
    nextButton =
    <button className='button2Resp pointerEventsAuto '
      onClick={ () => {
        dispatch({ type: 'setStateMultiLayers', keys: ['getDuelHistory', 'round'], value: round + 1 })
      }}
    >
      NEXT
    </button>
  }
  else {
    nextButton = <button className='button2Dummy' disabled={true} />;
  }

  if (round >= 1) {
    backButton =
    <button className='button2Resp pointerEventsAuto '
      onClick={ () => {
        dispatch({ type: 'setStateMultiLayers', keys: ['getDuelHistory', 'round'], value: round - 1 })
      }}
    >
      BACK
    </button>
  }
  else {
    backButton = <button className='button2Dummy' disabled={true} />;
  }

  if (state.getDuelHistory.action === 'duelHistorySingle') {
    closeButton =
    <button className='button2Resp'
      onClick={ () => {
        dispatch({type: 'setStateMultiLayers', keys: ['getDuelHistory', 'action'], value: 'duelHistoryAll'});
        handleClickGoBack(state, dispatch, navigate, location);
      }}
    >
      {/* TOP */}
      { words.back[state.language] }
    </button>;

    closeButtonSP =
    <button className='topRightClose z210 width4 focusEffect01'
      onClick={ () => {
        dispatch({type: 'setStateMultiLayers', keys: ['getDuelHistory', 'action'], value: 'duelHistoryAll'});
        handleClickGoBack(state, dispatch, navigate, location);
      }}
    >
      {/* [TOP] */}
      { `[${words.back[state.language]}]` }
    </button>;
  }
  else { // duelHistoryAll, duelHistoryAddress
    closeButton =
    <button className='button2Resp'
      onClick={ () => handleClickGoBack(state, dispatch, navigate, location) }
    >
      BACK TO LIST
    </button>;

    closeButtonSP =
    <button className='topRightClose z210 font2 focusEffect01'
      onClick={ () => handleClickGoBack(state, dispatch, navigate, location) }
    >
      ×
    </button>;
  }

  // -- シェアボタン

  const shareButton =
  <button className='button1'
    onClick={ () => {
      const popup = { type: 'generalItems', body: <Share record={record} /> };
      dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayer + 1], value: popup });
    }}
  >
    <img className='size2x2' src={iconShare} alt='' />
  </button>;

  // -- ヘッダ・フッタポジション切替ボタン

  const headerFooterPositionButton =
  <button className='button1'
    onClick={ () => {
      if (state.headerFooterPosition === 'fixed') {
        dispatch({ type: 'setState', key: 'headerFooterPosition', value: 'relative' });
      }
      else { // relative
        dispatch({ type: 'setState', key: 'headerFooterPosition', value: 'fixed' });
      }
    }}
  >
    <img className='size2x2' src={iconHeaderFooter} alt='' />
  </button>;

  let monster = {};
  let monsterSP = {};
  let result = {};
  let magic = {};
  let magicSP = {};
  let knightBox = {};
  let knightBoxSP = {};

  const alternativeMonster = {
    A: 'emblemRed',
    B: 'emblemBlue',
  }

  const alternativeMagic = {
    A: 'imageMagicRed',
    B: 'imageMagicBlue',
  }

  const sideClass = {
    A: 'classA',
    B: 'classB',
  }

  const duelistUserName = {
    A: record.duelistAUserName,
    B: record.duelistBUserName,
  }

  for (const side of ['A', 'B']) {
    // 召喚ナイト

    monster[side] =
    <CardInHistory
      asset={ thisRound.monsters?.[side] }
      card={ thisRound.cards?.[side][thisRound.monstersIndex?.[side]] }
      popupLayer={props.popupLayer}
      alternative={alternativeMonster[side]}
      position='field'
      monstersInformationRevisedSingleSide={thisRound.monstersInformationRevised?.[side]}
      imageNoSingleSide={thisRound.imageNo?.[side]}
    />;

    monsterSP[side] =
    <CardInHistory
      asset={ thisRound.monsters?.[side] }
      card={ thisRound.cards?.[side][thisRound.monstersIndex?.[side]] }
      popupLayer={props.popupLayer}
      alternative={alternativeMonster[side]}
      position='field'
      monstersInformationRevisedSingleSide={thisRound.monstersInformationRevised?.[side]}
      imageNoSingleSide={thisRound.imageNo?.[side]}
      device='SP'
    />;

    // バトル結果

    if (thisRound.winner === side) {
      result[side] =
      <div className='flexRow justifyContentCenter widthMax' >
        <img className='width44PC ' src={logoWin} />
      </div>
    }
    else if (thisRound.winner === otherSide(side)) {
      result[side] =
      <div className='flexRow justifyContentCenter widthMax' >
        <img className='width44PC ' src={logoLose} />
      </div>
    }
    else if (thisRound.winner === 'draw') {
      result[side] =
      <div className='flexRow justifyContentCenter widthMax' >
        <img className='width44PC ' src={logoDraw} />
      </div>
    }
    else {
      result[side] = null;
    }

    // 詠唱魔法

    magic[side] =
    <CardInHistory
      asset={ thisRound.magics?.[side]?.[0]?.asset }
      index={ thisRound.magics?.[side]?.[0]?.index }
      card={ thisRound.cards?.[side][thisRound.magics?.[side]?.[0]?.index] }
      alternative={ alternativeMagic[side] }
      position='cast'
      style='small'
      popupLayer={props.popupLayer}
    />;

    magicSP[side] =
    <CardInHistory
      asset={ thisRound.magics?.[side]?.[0]?.asset }
      index={ thisRound.magics?.[side]?.[0]?.index }
      card={ thisRound.cards?.[side][thisRound.magics?.[side]?.[0]?.index] }
      alternative={ alternativeMagic[side] }
      device='SP'
      position='cast'
      style='small'
      popupLayer={props.popupLayer}
    />;

    if (record.difficultyMode === 'simple' || record.difficultyMode === undefined) {
      knightBox[side] =
      <div className={'knightBox ' + sideClass[side]} >
        <div className='' >
          { duelistUserName[side] }
        </div>
        { monster[side] }
        { result[side] }
      </div>;

      knightBoxSP[side] =
      <div className={'knightBox widthKnightBoxSP heightKnightBoxSP ' + sideClass[side]} >
        <div className='heightMin1p3 font0p7' >
          { duelistUserName[side] }
        </div>
        { monsterSP[side] }
      </div>
    }
    else { // advanced SPの場合対称だから。
      knightBoxSP[side] =
      <div className={'knightBox widthKnightBoxSP heightKnightBoxSP ' + sideClass[side]} >
        <div className='heightMin1p3 font0p7' >
          { duelistUserName[side] }
        </div>
        { monsterSP[side] }
        <div className='flexRow justifyContentFlexStart alignItemsCenter margin0p5'> 
          { magicSP[side] }
          <div className='flexColumn justifyContentFlexStart alignItemsCenter '>
            <MaginaPointInHistory side={side} thisRound={thisRound} baseClass='padding0p3 font0p7' initiativeClass={'borderFat borderRadius0p5 ' + borderColor(side)} />
            <MaginaPointAppliedInHistory side={side} thisRound={thisRound} device='SP' />
            <TargetIndicationInHistory side={side} thisRound={thisRound} textClass='font0p5' />
          </div>
        </div> 
      </div>
    }
  }

  // KNIGHT BOX ADVANCED
  // ナイトボックス(アドバンスト)
  // 非対称だから、ループの外で定義。

  if (record.difficultyMode === 'advanced') {
    knightBox.A =
    <div className={'flexColumn margin0p5 classA' } >
      <div className='heightMin1p3 margin0p5' >
        { duelistUserName.A }
      </div>
      <div className='flexRow justifyContentCenter ' >
        <div className='flexColumn justifyContentCenter alignItemsCenter width3 marginTop0p5 padding0p5 ' >
          <MaginaGaugeInHistory magic={thisRound.magics?.A[0]} maginaPoint={thisRound.maginaPoint} />
          <div className='margin0p5' >
            <MaginaPointAppliedInHistory side='A' thisRound={thisRound} />
          </div>
        </div>
        <div className='flexColumn justifyContentFlexStart alignItemsCenter' >
          <div className='flexColumn justifyContentFlexStart alignItemsCenter width11 marginTop0p5 padding0p5 ' >
            <MaginaPointInHistory side='A' thisRound={thisRound} baseClass='padding0p5' initiativeClass='borderFat borderRadius0p5 borderColorA' />
            <div className='flexColumn justifyContentFlexStart alignItemsCenter marginTop1'> 
              { magic.A }
              <TargetIndicationInHistory side='A' thisRound={thisRound} />
            </div> 
          </div>
        </div>
        <div className='knightBox '>
          { monster.A }
          { result.A }
        </div>
      </div>
    </div>;

    knightBox.B =
    <div className={'flexColumn margin0p5 classB' } >
      <div className='heightMin1p3 margin0p5' >
        { duelistUserName.B }
      </div>
      <div className='flexRow justifyContentCenter ' >
        <div className='knightBox '>
          { monster.B }
          { result.B }
        </div>
        <div className='flexColumn justifyContentFlexStart alignItemsCenter' >
          <div className='flexColumn justifyContentFlexStart alignItemsCenter width11 marginTop0p5 padding0p5 ' >
            <MaginaPointInHistory side='B' thisRound={thisRound} baseClass='padding0p5' initiativeClass='borderFat borderRadius0p5 borderColorB' />
            <div className='flexColumn justifyContentFlexStart alignItemsCenter marginTop1'> 
              { magic.B }
              <TargetIndicationInHistory side='B' thisRound={thisRound} />
            </div> 
          </div>
        </div>
        <div className='flexColumn justifyContentCenter alignItemsCenter width3 marginTop0p5 padding0p5 ' >
          <MaginaGaugeInHistory magic={thisRound.magics?.B[0]} maginaPoint={thisRound.maginaPoint} />
          <div className='margin0p5' >
            <MaginaPointAppliedInHistory side='B' thisRound={thisRound} />
          </div>
        </div>
      </div>
    </div>;
  }

  // デュエルの結果・決着の理由

  let duelResult;
  let reason;

  if (round === record.round) {
    if (record.duelWinner === 'draw') {
      duelResult =
      <div className='flexRow justifyContentCenter alignItemsCenter font2 marginSide0p5'>
        { words.draw[state.language] }
      </div>
    }
    else {
      duelResult =
      <div className='flexRow justifyContentCenter alignItemsCenter font2 marginSide0p5'>
        { words[record.duelWinner][state.language] + words.win2[state.language] }
      </div>
    }

    if (record.result[round].reason === 'timeUp') {
      reason =
      <div className='flexRow justifyContentCenter alignItemsCenter marginSide1'>
        { `(${words.timeup[state.language]})` }
      </div>
    }
    else if (record.result[round].reason === 'leave') {
      reason =
      <div className='flexRow justifyContentCenter alignItemsCenter marginSide1'>
        { `(${words.leftTheGame[state.language]})` }
      </div>
    }
    else { // normal, additionalJudgement
      reason = null
    }
  }
  else {
    duelResult = null;
    reason = null
  }

  // ヘッダ・フッタ

  let headerFixed;
  let headerRelative;
  let footerFixed;
  let footerRelative;
  let headerFixedPc;
  let headerRelativePc;

  const headerCore =
  <div className='flexColumn alignItemsCenter relative widthMax '>
    { closeButtonSP }
    <div className='font0p5 marginTopBottom0p5'>
      { record.roomId + '-' + record.duelNo }
    </div>
    <div className='flexRow justifyContentSpaceBetween widthMax marginTop0p5 '>
      <div className='flexRow justifyContentCenter alignItemsCenter font2 marginSide0p5'>
        { 'round ' + round }
      </div>
      { duelResult }
      { reason }
    </div>
    <div className='flexRow justifyContentCenter widthMax'>
      <div className='flexRow justifyContentFlexEnd alignItemsCenter width40PC marginSide1 '>
        <Score src={roseRed} point={pointA} />
      </div>
      <div className='flexRow justifyContentFlexStart alignItemsCenter width40PC marginSide1 '>
        <Score src={roseBlue} point={pointB} />
      </div>
    </div>
  </div>;

  const footerCore =
  <div className='flexColumn alignItemsCenter widthMax '>
    <div className='flexRow justifyContentSpaceBetween widthMax '>
      <div className='flexRow justifyContentCenter alignItemsCenter width48vw '>
        { backButton }
      </div>
      <div className='flexRow justifyContentCenter alignItemsCenter width48vw '>
        { nextButton }
      </div>
    </div>
    <div className='flexRow justifyContentSpaceBetween widthMax '>
      <div className='flexRow justifyContentCenter alignItemsCenter width48vw pointerEventsAuto '>
        { deckASP }
      </div>
      <div className='flexRow justifyContentCenter alignItemsCenter width48vw pointerEventsAuto '>
        { deckBSP }
      </div>
    </div>
  </div>;

  const headerCorePc =
  <div className='flexRow justifyContentSpaceBetween widthMax '>
    <div className='flexRow justifyContentFlexStart widthMin10 '>
      { backButton }
      { nextButton }
    </div>
    <div className='flexRow justifyContentCenter'>
      <div className='flexRow justifyContentFlexEnd alignItemsCenter widthMin10 marginSide1 '>
        <Score src={roseRed} point={pointA} />
      </div>
      <div className='flexRow '>
        <div className='flexRow justifyContentCenter alignItemsCenter font2 marginSide0p5'>
          { 'round ' + round }
        </div>
        { duelResult }
        { reason }
      </div>
      <div className='flexRow justifyContentFlexStart alignItemsCenter widthMin10 marginSide1 '>
        <Score src={roseBlue} point={pointB} />
      </div>
    </div>
    <div className='flexRow justifyContentFlexEnd alignItemsFlexEnd widthMin10 '>
      <div className='font0p5 marginTopBottom0p5 marginSide1'>
        { record.roomId + '-' + record.duelNo }
      </div>
      { headerFooterPositionButton }
      <div className='marginSide0p5'>
        { shareButton }
      </div>
      { closeButton }
    </div>
  </div>;

  if (state.headerFooterPosition === 'fixed') {
    headerFixed =
    <div className='z200FixedZero widthMax backgroundColorWhiteTransparent '>
      { headerCore }
    </div>;

    footerFixed =
    <div className='z200FixedBottom widthMax pointerEventsNone '>
      { footerCore }
    </div>;

    headerFixedPc =
    <div className='z200FixedZero widthMax backgroundColorWhiteTransparent '>
      { headerCorePc }
    </div>;

    headerRelative =
    <div className='widthMax height8 ' >
    </div>;

    footerRelative =
    <div className='widthMax height9 ' >
    </div>;

    headerRelativePc =
    <div className='widthMax height6 ' >
    </div>;
  }
  else { // relative
    headerRelative = headerCore;
    footerRelative = footerCore;
    headerRelativePc = headerCorePc;
  }


  return (
    <div>
      {/* PC */}
      <div className="visibleHuge">
        <div className="flexColumn alignItemsCenter ">
          { headerRelativePc }
          <div className='flexColumn alignItemsCenter ' >
            { deckB }
            <div className='flexRow duelField justifyContentSpaceAround alignItemsFlexStart'>
              {/*
                <div className='knightBox classA' >
                  <div className='' >
                    { record.duelistAUserName }
                  </div>
                  { monsterA }
                  { resultA }
                </div>
              */}
              { knightBox.A }
              <div >
                <DuelTextInHistory record={record} />
                {/* duelUpEffect */}
              </div>
              { knightBox.B }
              {/*
                <div className='knightBox classB' >
                  <div className='' >
                    { record.duelistBUserName }
                  </div>
                  { monsterB }
                  { resultB }
                </div>
              */}
            </div>
            { deckA }
          </div>
          {/* development */}
          {/* process.env.REACT_APP_ENVIRONMENT === 'dev' ? <ForDevelopment /> : null */}
        </div>
        { headerFixedPc }
      </div>
      {/* TABLET */}
      <div className="visibleLarge">
        <div className="flexColumn alignItemsCenter ">
          <div className='flexColumn alignItemsCenter ' >
            { deckB }
            <div className='flexRow justifyContentSpaceBetween widthMax '>
              <div className='flexRow justifyContentFlexStart widthMin10 '>
                { backButton }
                { nextButton }
              </div>
              <div className='flexRow justifyContentCenter'>
                <div className='flexRow justifyContentFlexEnd alignItemsCenter widthMin10 marginSide1 '>
                  <Score src={roseRed} point={pointA} />
                </div>
                <div className='flexRow '>
                  <div className='flexRow justifyContentCenter alignItemsCenter font2 marginSide0p5'>
                    { 'round ' + round }
                  </div>
                  { duelResult }
                  { reason }
                </div>
                <div className='flexRow justifyContentFlexStart alignItemsCenter widthMin10 marginSide1 '>
                  <Score src={roseBlue} point={pointB} />
                </div>
              </div>
              <div className='flexRow justifyContentFlexEnd alignItemsFlexEnd widthMin10 '>
                <div className='font0p5 marginTopBottom0p5 marginSide1'>
                  { record.roomId + '-' + record.duelNo }
                </div>
                <div className='marginSide0p5'>
                  { shareButton }
                </div>
                { closeButton }
              </div>
            </div>
            <div className='flexColumn justifyContentFlexStart alignItemsCenter'>
              <div className='flexRow justifyContentSpaceAround alignItemsCenter' >
                { knightBox.A }
                { knightBox.B }
              </div>
              <DuelTextInHistory record={record} fontSizeClass='font1p5' />
            </div>
            { deckA }
          </div>
          {/* development */}
          {/* process.env.REACT_APP_ENVIRONMENT === 'dev' ? <ForDevelopment /> : null */}
        </div>
      </div>
      {/* SP */}
      {/* <div className="flexColumn alignItemsCenter widthMax paddingBottom1 visibleMiddleOrLess "> */}
      <div className="visibleMiddleOrLess relative widthMax overflowXHidden ">
        <div className="flexColumn alignItemsCenter widthMax ">
          { headerRelative }
          <div className='flexRow justifyContentSpaceAround alignItemsFlexStart'>
            { knightBoxSP.A }
            { knightBoxSP.B }
            {/*
              <div className='knightBox widthKnightBoxSP heightKnightBoxSP classA' >
                <div className='heightMin1p3 font0p7' >
                  { record.duelistAUserName }
                </div>
                { monsterSP[side] }
              </div>
              <div className='knightBox widthKnightBoxSP heightKnightBoxSP classB' >
                <div className='heightMin1p3 font0p7' >
                  { record.duelistBUserName }
                </div>
                { monsterSP[side] }
              </div>
            */}
          </div>
          <div className='flexRow justifyContentCenter alignItemsCenter widthMax'>
            <div className='flexRow justifyContentCenter alignItemsCenter width48vw'>
              { result.A }
            </div>
            <div className='flexRow justifyContentCenter alignItemsCenter width48vw'>
              { result.B }
            </div>
          </div>
          <div >
            <DuelTextInHistory record={record} fontSizeClass='' />
          </div>
          <div className='flexRow justifyContentFlexEnd alignItemsCenter widthMax'>
            <div className='pointerEventsAuto '>
              { headerFooterPositionButton }
            </div>
            <div className='pointerEventsAuto '>
              { shareButton }
            </div>
          </div>
          { footerRelative }
        </div>
        { headerFixed }
        { footerFixed }
      </div>
    </div>
  );


}

// DUEL HISTORY DETAIL FULL SCREEN
function DuelHistoryDetailFullScreen() {
  const [state, dispatch, navigate] = useContext(GlobalState);
  const urlParams = (new URL(document.location)).searchParams;
  const roomId = urlParams.get('roomid') === null ? null : urlParams.get('roomid');
  const duelNo = urlParams.get('duelno') === null ? null : parseInt(urlParams.get('duelno'), 10);
  const round = urlParams.get('round') === null ? 0 : parseInt(urlParams.get('round'), 10);

  useEffect( () => {
    const func = async () => {
      devLog('A', roomId, duelNo);
      if (roomId !== null && duelNo !== null) {
        devLog('B', roomId, duelNo);
        dispatch({type: 'setStateMultiLayers', keys: ['getDuelHistory', 'action'], value: 'duelHistorySingle'});
        dispatch({type: 'setStateMultiLayers', keys: ['getDuelHistory', 'roomId'], value: roomId});
        dispatch({type: 'setStateMultiLayers', keys: ['getDuelHistory', 'duelNo'], value: duelNo});
        const response = await handleClickGetDuelHistoryAll(state, dispatch, 0, { action: 'duelHistorySingle', roomId: roomId, duelNo: duelNo });

        const popup = { type: 'duelHistoryDetail', body: response.body.duelHistory };
        dispatch({ type: 'setStateMultiLayers', keys: ['getDuelHistory', 'round'], value: round });
        dispatch({ type: 'setStateMultiLayers', keys: ['popup', 100], value: popup });
      }
      else if (state.popup[100].type !== null) {
        // 特になにもしない。
        devLog('C', roomId, duelNo);
      }
      else {
        devLog('D', roomId, duelNo);
        navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/top' : '/top');
      }
    }

    func();
    devLog('E', roomId, duelNo);
  }, []);

  let content;

  if (state.popup[100]?.type === 'duelHistoryDetail' && Object.keys(state.registeredCard).length >= 1) {
    content = <DuelHistoryDetail popupLayer={100} />;
  }
  else {
    content = null;
  }

  return (
    <div>
      {/* PC */}
      <div className="visibleMiddleOrMore">
        { content }
      </div>
      {/* SP */}
      <div className="flexColumn alignItemsCenter widthMax paddingBottom1 visibleSmallOrLess ">
        { content }
      </div>
      {/* popup */}
      <Popup layer={101}/>
      <Popup layer={102}/>
      <Popup layer={103}/>
      <Popup layer={104}/>
    </div>
  );
}

// MAGINA POINT IN HISTORY
function MaginaPointInHistory(props) {
  const side = props.side;
  const thisRound = props.thisRound;
  const baseClass = props.baseClass;
  const initiativeClass = props.initiativeClass;

  let maginaPoint;
  let additionalClass;

  if (thisRound.maginaPoint?.[side] !== undefined) {
    if (thisRound.initiativeNext === side) {
      additionalClass = initiativeClass;
    }
    else if (thisRound.initiativeNext === 'coinToss') {
      additionalClass = '';
    }
    else {
      additionalClass = '';
    }

    maginaPoint =
    <div className={ baseClass + ' ' + additionalClass } > 
      { 'MP ' + thisRound.maginaPoint[side] }
    </div> 
  }

  return maginaPoint;
}

// SHARE
function Share(props) {
  const [state] = useContext(GlobalState);
  const record = props.record;
  const roomId = record.roomId;
  const duelNo = record.duelNo;

  const urlFromTheBeginning = 'https://' + process.env.REACT_APP_KOM_EXTENSION_URL + `duelhistorydetail?roomid=${roomId}&duelno=${duelNo}`;
  const urlThisRound = 'https://' + process.env.REACT_APP_KOM_EXTENSION_URL + `duelhistorydetail?roomid=${roomId}&duelno=${duelNo}&round=${state.getDuelHistory.round}`;

  const title = ''
  const hashtags = ['KnightsOfMonadom', 'モナダム'];

  const textAreaRef = useRef(null);

  const fromTheBeginning =
  <div className='flexColumn alignItemsCenter borderKOM padding1' >
    <div className='margin0p5' >
      {words.fromTheBeginning[state.language]}
    </div>
    <div className='flexRow justifyContentCenter' >
      <div className='margin1' >
        <TwitterShareButton
          url={urlFromTheBeginning}
          title={title}
          hashtags={hashtags}
        >
          <TwitterIcon size={32} round />
        </TwitterShareButton>
      </div>
      <button className='button1 margin1' tabindex='0' 
        onClick={ () => {
          navigator.clipboard.writeText(urlFromTheBeginning)
          .then()
          .catch( err => {
            copyToClipboard(urlFromTheBeginning, textAreaRef);
          });
        }}
      >
        <img className='size2x2' src={iconCopy} />
      </button>
      <textarea ref={textAreaRef} style={{ position: 'absolute', left: '-9999px' }} ></textarea>
    </div>
  </div>;

  const thisRound =
  <div className='flexColumn alignItemsCenter borderKOM padding1' >
    <div className='margin0p5' >
      {words.thisRound[state.language]}
    </div>
    <div className='flexRow justifyContentCenter' >
      <div className='margin1' >
        <TwitterShareButton
          url={urlThisRound}
          title={title}
          hashtags={hashtags}
        >
          <TwitterIcon size={32} round />
        </TwitterShareButton>
      </div>
      <button className='button1 margin1' tabindex='0' 
        onClick={ () => {
          navigator.clipboard.writeText(urlThisRound)
          .then()
          .catch( err => {
            copyToClipboard(urlThisRound, textAreaRef);
          });
        }}
      >
        <img className='size2x2' src={iconCopy} />
      </button>
      <textarea ref={textAreaRef} style={{ position: 'absolute', left: '-9999px' }} ></textarea>
    </div>
  </div>;

  return (
    <div className='flexColumn alignItemsCenter' >
      {/* PC */}
      <div className="visibleMiddleOrMore flexRow">
        <div className="margin1 ">
          { fromTheBeginning }
        </div>
        <div className="margin1 ">
          { thisRound }
        </div>
      </div>
      {/* SP */}
      <div className="visibleSmallOrLess flexColumn">
        <div className="margin1 ">
          { fromTheBeginning }
        </div>
        <div className="margin1 ">
          { thisRound }
        </div>
      </div>
    </div>
  );
}

// MAGINA POINT APPLIED IN HISTORY
function MaginaPointAppliedInHistory(props) {
  const side = props.side;
  const thisRound = props.thisRound;
  const device = props.device;
  const magic = thisRound.magics?.[side]?.[0];
  const maginaPoint = thisRound.maginaPoint;

  let maginaPointApplied;

  if (magic !== undefined && maginaPoint.applied !== undefined) {
    if (device === 'SP') {
      maginaPointApplied =
      <div className={ 'padding0p3 font0p7 borderThin borderRadius0p5 ' + borderColor(maginaPoint.applied.side) } > 
        { '<< ' + maginaPoint.applied.point }
      </div> 
    }
    else { // PC
      maginaPointApplied =
      <div className={ 'padding0p5 borderThin borderRadius0p5 ' + borderColor(maginaPoint.applied.side) } > 
        { maginaPoint.applied.point }
      </div> 
    }
  }

  return maginaPointApplied;
}

// MAGINA GAUGE IN HISTORY
function MaginaGaugeInHistory(props) {
  const [state, dispatch] = useContext(GlobalState);
  const magic = props.magic;
  const maginaPoint = props.maginaPoint;
  const direction = props.direction;
  const noMagic = props.noMagic;

  const backgroundColorClass = {
    A: 'classBackGroundA',
    B: 'classBackGroundB',
  };

  let maginaGauge;

  // if (state.duel.maginaPoint.applied !== undefined && state.duel.maginaPoint.applied !== null) {
  //   maginaPoint = state.duel.maginaPoint.applied;
  // }
  // else {
  //   maginaPoint = state.duel.maginaPoint[side];
  // }

  if (magic !== undefined) {
    const maginaThresholds = state.registeredCard[magic.asset].maginaThresholds;
    const thresholdsWithBox = maginaThresholds.map( threshold => {
      return {
        upperBound: threshold.upperBound,
        face: threshold.face,
        box: [],
      };
    });

    for (const threshold of thresholdsWithBox) {
      if (maginaPoint.applied?.point < threshold.upperBound) {
        threshold.box.push({ side: maginaPoint.applied.side, point: maginaPoint.applied.point })
        break;
      }
    }

    if (direction === 'horizontal') {
      maginaGauge =
      <div className='flexRow alignItemsCenter borderMonacotto padding0p3' >
        <div className='textCenter height1p6 font0p7' >
          { '0' }
        </div>
        {
          thresholdsWithBox.map( threshold =>
              <div className='flexRowReverse justifyContentCenter alignItemsCenter ' >
                <div className='textCenter height1p6 font0p7' >
                  { threshold.face !== undefined ? threshold.face : threshold.upperBound }
                </div>
                <div className='flexColumn justifyContentCenter alignItemsCenter width1 height1p6' >
                  {
                    threshold.box.map( ball => 
                      <div className={'width0p7 height0p7 borderRadiusCircle ' + backgroundColorClass[ball.side]} >
                      </div>
                    )
                  }
                </div>
              </div>
          )
        }
      </div>;
    }
    else { // vertical
      maginaGauge =
      <div className='flexColumnReverse alignItemsCenter borderMonacotto' >
        <div className='textCenter width2p4 height1p2' >
          { '0' }
        </div>
        {
          thresholdsWithBox.map( threshold =>
              <div className='flexColumn justifyContentCenter alignItemsCenter ' >
                <div className='textCenter width2p4 height1p2' >
                  { threshold.face !== undefined ? threshold.face : threshold.upperBound }
                </div>
                <div className='flexRow justifyContentCenter alignItemsCenter width2p4 height1p2' >
                  {
                    threshold.box.map( ball => 
                      <div className={'width1 height1 borderRadiusCircle ' + backgroundColorClass[ball.side]} >
                      </div>
                    )
                  }
                </div>
              </div>
          )
        }
      </div>;
    }

    // return (
    //   <div className='flexColumnReverse alignItemsCenter borderMonacotto' >
    //     <div className='textCenter width2p4 height1p2' >
    //       { '0' }
    //     </div>
    //     {
    //       thresholdsWithBox.map( threshold =>
    //           <div className='flexColumn justifyContentCenter alignItemsCenter ' >
    //             <div className='textCenter width2p4 height1p2' >
    //               { threshold.face !== undefined ? threshold.face : threshold.upperBound }
    //             </div>
    //             <div className='flexRow justifyContentCenter alignItemsCenter width2p4 height1p2' >
    //               {
    //                 threshold.box.map( ball => 
    //                   <div className={'width1 height1 borderRadiusCircle ' + backgroundColorClass[ball.side]} >
    //                   </div>
    //                 )
    //               }
    //             </div>
    //           </div>
    //       )
    //     }
    //   </div>
    // );
  }
  // else {
  //   return (
  //     <div className='flexColumnReverse alignItemsCenter borderMonacotto' >
  //     </div>
  //   );
  // }
  else {
    if (noMagic === 'blank') {
      maginaGauge = null;
    }
    else {
      maginaGauge =
      <div className='flexColumnReverse alignItemsCenter borderMonacotto' >
      </div>;
    }
  }

  return maginaGauge;
}

// TARGET INDICATION IN HISTORY
function TargetIndicationInHistory(props) {
  const [state, dispatch] = useContext(GlobalState);
  const side = props.side;
  const thisRound = props.thisRound;
  const textClass = props.textClass;
  const targets = thisRound.magics?.[side]?.[0]?.targets;

  let targetIndication;

  if (targets !== undefined) {
    const targetsReduced = targets.reduce( (acc, cur) =>
      acc + Object.keys(cur).reduce( (acc2, cur2) => {
        if (cur[cur2].side !== undefined && cur[cur2].side !== null) {
          return `${acc2}${words[cur[cur2].side][state.language]} `
        }
        else {
          return acc;
        }
      }, ''),
    '');

    if (targetsReduced !== '') {
      targetIndication =
      <div className={'borderKOM borderRadius0p3 margin0p5 padding0p5 ' + textClass} >
        { targetsReduced }
      </div>;
    }
  }

  return targetIndication;
}

// DECK IN HISTORY
function DeckInHistory(props) {
  const [state, dispatch] = useContext(GlobalState);
  const record = props.record;
  const side = props.side;
  const round = state.getDuelHistory.round;
  const thisRound = record.result[round];

  let deck;
  let otherSide;

  if (side === 'A') {
    deck = record.duelistADeck;
    otherSide = 'B';
  }
  else { // B
    deck = record.duelistBDeck;
    otherSide = 'A';
  }

  const losingMonsters = record.result
  .filter( (round, index) => round.winner === otherSide && index <= state.getDuelHistory.round ) // drawは除外される。
  .map( round => round.monstersIndex[side] );

  const cards = deck?.cards.map ( (card, index) =>
    <CardInHistory
      asset={card.asset}
      index={index}
      card={thisRound.cards?.[side][index]}
      losing={losingMonsters.includes(index)}
      style='small'
      popupLayer={props.popupLayer}
    />
  );

  return (
    <div>
      {/* PC */}
      <div className="visibleMiddleOrMore">
        <div className='flexRow flexWrapNoWrap maxWidth97vw scrollXAuto '>
          { cards.map( card => <div className='width11 ' >{ card }</div> ) }
        </div>
      </div>
      {/* SP */}
      <div className="visibleSmallOrLess">
        <div className='flexRow'>
          { cards }
        </div>
      </div>
    </div>
  );
}

// DECK IN HISTORY SP
function DeckInHistorySP(props) {
  const [state, dispatch] = useContext(GlobalState);
  const popupLayer = props.popupLayer;
  const record = state.popup[popupLayer].body.record;
  const side = state.popup[popupLayer].body.side;
  const round = state.popup[popupLayer].body.round;
  const thisRound = record.result[round];

  let deck;
  let otherSide;
  let thisSideBackGround;
  let thisSideAlternativeMagic;

  if (side === 'A') {
    deck = record.duelistADeck;
    otherSide = 'B';
    thisSideBackGround = 'classBackGroundA';
    thisSideAlternativeMagic = 'imageMagicRed';
  }
  else { // B
    deck = record.duelistBDeck;
    otherSide = 'A';
    thisSideBackGround = 'classBackGroundB';
    thisSideAlternativeMagic = 'imageMagicBlue';
  }

  const losingMonsters = record.result
  .filter( (round, index) => round.winner === otherSide && index <= state.getDuelHistory.round ) // drawは除外される。
  .map( round => round.monstersIndex[side] );

  const thisSideMagic =
  <CardInHistory
    asset={ thisRound.magics?.[side]?.[0]?.asset }
    index={ thisRound.magics?.[side]?.[0]?.index }
    card={thisRound.cards?.[side][thisRound.magics?.[side]?.[0]?.index]}
    alternative={thisSideAlternativeMagic} 
    device='SP'
    style='small'
    position='cast'
    popupLayer={popupLayer}
  />;

  const cards = deck?.cards.map ( (card, index) =>
    <CardInHistory
      asset={card.asset}
      index={index}
      card={thisRound.cards?.[side][index]}
      losing={losingMonsters.includes(index)}
      device='SP'
      style='small'
      popupLayer={popupLayer}
    />
  );


  return (
    <div className='visibleSmallOrLess'>
      <div className={'flexColumn alignItemsFlexStart padding0p3 marginTop0p5 ' + thisSideBackGround} >
        <div className='flexRow justifyContentCenter alignItemsCenter widthMax ' >
          { thisSideMagic }
          <div className='flexColumn justifyContentFlexStart alignItemsCenter marginSide0p5' >
            <MaginaPointInHistory side={side} thisRound={thisRound} baseClass='padding0p5' initiativeClass={'borderFat borderRadius0p5 ' + borderColor(side)} />
            <TargetIndicationInHistory side={side} thisRound={thisRound} textClass='font0p5' />
          </div>
        </div>
        <div className='marginSide1 marginTop0p5' >
          <MaginaGaugeInHistory magic={thisRound.magics?.[side]?.[0]} maginaPoint={thisRound.maginaPoint} direction='horizontal' noMagic='blank' />
        </div>
        <div className='flexRow justifyContentSpaceAround alignItemsCenter marginTop0p5 ' >
          { cards }
        </div>
        <div className='flexRow justifyContentFlexEnd alignItemsCenter widthMax marginTop0p5' >
          <button className='button2' onClick={ () => dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayer], value: { type: null, body: null } }) } >
            { words.close[state.language] }
          </button>
        </div>
      </div>
    </div>
  );
}

// CARD IN HISTORY
function CardInHistory(props) {
  const [state, dispatch] = useContext(GlobalState);
  const asset = props.asset;
  const index = props.index; // 実はいらないかも。
  const monstersInformationRevisedSingleSide = props.monstersInformationRevisedSingleSide;
  const imageNoSingleSide = props.imageNoSingleSide;
  const card = props.card;
  const losing = props.losing;
  const alternative = props.alternative;
  const position = props.position;
  const device = props.device;
  const style = props.style;
  const popupLayer = props.popupLayer;

  // スタイル

  let styleBox;
  let styleCard;
  let emblemContainerHeight;
  let alternativeMargin;

  if (device === 'SP') {
    if (style === 'small') {
      styleBox = 'boxCardSP';
      styleCard = 'cardImageSmallSP';
    }
    else {
      styleBox = 'boxCardSP';
      styleCard = 'cardImageSP';
      emblemContainerHeight = 'heightEmblemSP';
    }
  }
  else {
    if (style === 'small') {
      styleBox = 'boxCardSmall';
      styleCard = 'cardImageSmall';
      alternativeMargin = 'marginTop4';
    }
    else {
      styleBox = 'boxCard';
      styleCard = 'cardImage';
      emblemContainerHeight = 'height27';
    }
  }

  let styleBoxColor = '';
  let styleBorderColor = '';
  let styleBoxPadding = '';

  if (asset !== undefined) {
    if (position !== 'cast' && position !== 'field') {
      if (state.registeredCard[asset].category === 'magic') {
        styleBoxColor = ' backgroundColorLightGreen';
        styleBorderColor = ' borderDeepGreenFat';

        if (device === 'SP') {
          styleBoxPadding = ' padding0p5';
        }
      }
      else if (state.registeredCard[asset].category !== 'magic') {
        if (device === 'SP') {
          styleBoxPadding = ' padding0p5';
        }
      }
      else {
      }
    }
    else {
    }
  }
  else {
  }

  styleBox += styleBoxColor + styleBorderColor + styleBoxPadding;

  // カードイメージ

  let cardImageUrl;
  let cardName;
  let cardImage;
  let cardStatus;
  let cardStatusImage;
  let screen;


  if (asset === undefined) {
    if (alternative === 'emblemRed') {
      cardImage =
      <div className={'flexColumn justifyContentCenter ' + emblemContainerHeight} >
        <img className={styleCard} src={emblemRed} />
      </div>
    }
    else if (alternative === 'emblemBlue') {
      cardImage =
      <div className={'flexColumn justifyContentCenter ' + emblemContainerHeight} >
        <img className={styleCard} src={emblemBlue} />
      </div>
    }
    else if (alternative === 'imageMagicRed') {
      cardImage =
      <button className={'flexColumn justifyContentCenter backgroundColorTransparent borderNone ' + alternativeMargin} disabled={true} >
        <img className={styleCard} src={imageMagicRed} />
      </button>
    }
    else if (alternative === 'imageMagicBlue') {
      cardImage =
      <button className={'flexColumn justifyContentCenter backgroundColorTransparent borderNone ' + alternativeMargin} disabled={true} >
        <img className={styleCard} src={imageMagicBlue} />
      </button>
    }
    else {
      return null;
    }
  }
  else {
    // カードステータス

    if (card !== undefined) {
      cardStatus = card.status; // assetがあればindexもあるからcardもある。とは限らない！魔法以前のデータにはcardは無い！

      if (position === 'field') {
        cardStatusImage = null;
        screen = null;
      }
      else if (position === 'cast') {
        cardStatusImage = null;
        screen = null;
      }
      else if (cardStatus === 'cemetery') {
        cardStatusImage =
        <div className='monsterStatus width75PC '>
          <img className='width70P' src={imageCemetery} />
        </div>

        screen =
        <div className='screen140'>
        </div>;
      }
      // else if (cardStatusTmp === 'cast') {
      //   if (side === 'A') {
      //     cardStatusImage =
      //     <div className='monsterStatus width75PC '>
      //       <img className='width70P' src={imageMagicRed} />
      //     </div>
      //   }
      //   if (side === 'B') {
      //     cardStatusImage =
      //     <div className='monsterStatus width75PC '>
      //       <img className='width70P' src={imageMagicBlue} />
      //     </div>
      //   }

      //   screen =
      //   <div className='screen140'>
      //   </div>;
      // }
      // else if (cardStatusTmp === 'cost') {
      //   cardStatusImage =
      //   <div className='monsterStatus width75PC '>
      //     <img className='width70P' src={imageCost} />
      //   </div>

      //   screen =
      //   <div className='screen140'>
      //   </div>;
      // }
      else {
        cardStatusImage = null;
        screen = null;
      }
    }
    else if (losing === true) {
      cardStatusImage =
      <div className='monsterStatus width75PC '>
        <img className='widthMax' src={logoLose} />
      </div>

      screen =
      <div className='screen140'>
      </div>;
    }
    else {
      cardStatusImage = null;
      screen = null;
    }

    // カードイメージURL

    if (position === 'field' && imageNoSingleSide !== undefined && imageNoSingleSide !== 0) {
      if (device === 'SP') {
        cardImageUrl = `https://${process.env.REACT_APP_MONACARD_URL}small/${asset}_${imageNoSingleSide}_small.png`;
      }
      else {
        cardImageUrl = `https://${process.env.REACT_APP_MONACARD_URL}${asset}_${imageNoSingleSide}.png`;
      }
    }
    else {
      if (device === 'SP') {
        cardImageUrl = `https://${process.env.REACT_APP_MONACARD_URL}small/${asset}_small.png`;
      }
      else {
        cardImageUrl = `https://${process.env.REACT_APP_MONACARD_URL}${asset}.png`;
      }
    }

    // if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
    //   if (device === 'SP') {
    //     cardImageUrl = `https://knightsofmonadom.monatoka.com/monacard/small/${asset}_small.png`;
    //   }
    //   else {
    //     cardImageUrl = `https://knightsofmonadom.monatoka.com/monacard/${asset}.png`;
    //   }
    // }
    // else { // dev
    //   if (device === 'SP') {
    //     cardImageUrl = `https://dev.knightsofmonadom.monatoka.com/monacard/small/${asset}_small.png`;
    //   }
    //   else {
    //     cardImageUrl = `https://dev.knightsofmonadom.monatoka.com/monacard/${asset}.png`;
    //   }
    // }

    // カードネーム

    if (device === 'SP') {
      // if (state.registeredCard[asset].category === 'magic' && position !== 'cast') {
      //   cardName =
      //   <div className='widthMax borderNone borderRadius2 padding0p3 backgroundColorMagic' >
      //   </div>
      // }
      // else {
      //   cardName = null;
      // }

      cardName = null;
    }
    else {
      if (position === 'field' && monstersInformationRevisedSingleSide?.name !== undefined) {
        cardName =
        <div className='borderNone borderRadius2 padding0p3 marginTop0p5 ' >
          { monstersInformationRevisedSingleSide?.name }
        </div>
      }
      else if (state.registeredCard[asset].category === 'magic' && position !== 'cast') {
        cardName =
        // <div className='borderNone borderRadius2 padding0p3 marginTop0p5 backgroundColorMagic' >
        <div className='borderNone borderRadius2 padding0p3 marginTop0p5 ' >
          { state.registeredCard[asset].name }
        </div>
      }
      else {
        cardName =
        <div className='borderNone borderRadius2 padding0p3 marginTop0p5 ' >
          { state.registeredCard[asset].name }
        </div>
      }
    }

    // // 魔法カラー

    // let backgroundColor;
    // let backgroundColorBox;

    // if (state.registeredCard[asset].category === 'magic' && position !== 'cast') {
    //   if (device === 'SP') {
    //     backgroundColor = 'backgroundColorTransparent';
    //     backgroundColorBox = 'backgroundColorMagic';
    //   }
    //   else { // PC
    //     backgroundColor = 'backgroundColorMagic';
    //     backgroundColorBox = 'backgroundColorTransparent';
    //   }
    // }
    // else {
    //   backgroundColor = 'backgroundColorTransparent';
    //   backgroundColorBox = 'backgroundColorTransparent';
    // }

    // カードイメージ結合品

    cardImage =
    <button className={styleBox + ' '} disabled={props.disabled ? true : false}
      onClick={ () => {
          let popup;

          if (device === 'SP') {
            const body = {
              item: {
                asset: asset, 
                index: index,
              },
              actionBox: 'close',
              position: position,
              source: 'history',
              monstersInformationRevisedSingleSide: monstersInformationRevisedSingleSide,
              imageNoSingleSide: imageNoSingleSide,
            };

            popup = { type: 'itemDetailSP', body: body };
          }
          else {
            const body = {
              monster: {
                asset: asset, 
                index: index,
              },
              position: position,
              source: 'history',
              monstersInformationRevisedSingleSide: monstersInformationRevisedSingleSide,
              imageNoSingleSide: imageNoSingleSide,
            };

            popup = { type: 'monsterDetail', body: body };
          }

          dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayer + 1], value: popup });
      }}
    >
        <div className={styleCard + ' relative flexColumn '} >
          { cardStatusImage }
          { screen }
          <img className='widthMax' src={cardImageUrl} />
        </div>
        { cardName }
    </button>
  }


  return (
    <div className='flexColumn alignItemsCenter ' >
      { cardImage }
    </div>
  );
}


// DUEL TEXT IN HISTORY
function DuelTextInHistory(props) {
  const [state, dispatch] = useContext(GlobalState);
  const round = state.getDuelHistory.round;
  const thisRound = props.record.result[round];
  // const gameOption = props.record.gameOption;
  const straightManText = thisRound.straightManText;
  const fontSizeClass = props.fontSizeClass;

  let magicTextDiv;
  let magicTextDivSP;
  const magicText = magicNarration(state, thisRound.magics, thisRound.initiative, thisRound.monsters);

  if (magicText !== '') {
    magicTextDiv =
    <div className='marginTopBottom1'>
      { magicText }
    </div>;

    magicTextDivSP =
    <div className='marginTopBottom0p5'>
      { magicText }
    </div>
  }

  // つっこみ

  let straightManTextFormatted;
  const controlCodeFinished = '<-KOM-finished->';

  // if (gameOption?.A?.straightMan !== undefined && gameOption.A.straightMan.type !== 'none' || gameOption?.B?.straightMan !== undefined && gameOption.B.straightMan.type !== 'none') {
  if (straightManText !== undefined && straightManText !== null && straightManText !== '') {
    const straightManTextReplaced = straightManText.replace(controlCodeFinished, '');

    straightManTextFormatted = '<<つっこみ>>\n' + straightManTextReplaced;
  }


  return (
    <div>
      {/* PC */}
      <div className="visibleHuge">
        <div className='flexColumn justifyContentFlexStart alignItemsFlexStart boxDuelText '>
          { magicTextDiv }
          <div className='marginTopBottom1'>
            { props.record.result[state.getDuelHistory.round].liveText }
          </div>
          <div className='marginTopBottom1'>
            { '<<フィールドの状況>>\n' + props.record.result[state.getDuelHistory.round].field }
          </div>
          <div className='marginTopBottom1'>
            { straightManTextFormatted }
          </div>
        </div>
      </div>
      {/* SP */}
      <div className="flexColumn visibleLargeOrLess ">
        <div className={'flexColumn justifyContentFlexStart alignItemsFlexStart boxDuelText sizeBoxDuelTextSP ' + fontSizeClass} >
          { magicTextDivSP }
          <div className='marginTopBottom0p5'>
            { props.record.result[state.getDuelHistory.round].liveText }
          </div>
          <div className='marginTopBottom0p5'>
            { '<<フィールドの状況>>\n' + props.record.result[state.getDuelHistory.round].field }
          </div>
          <div className='marginTopBottom0p5'>
            { straightManTextFormatted }
          </div>
        </div>
      </div>
    </div>
  );
}


// DUEL RANKING
function DuelRanking() {
  const [state, dispatch, navigate] = useContext(GlobalState);
  const action = state.getDuelRanking.action;
  const duelRankingRecords = state.getDuelRanking.records[action.difficultyMode][action.gameMode][action.period].duelRanking;
  const pagingIndex = state.getDuelRanking.pagingIndex[action.difficultyMode][action.gameMode][action.period];
  const lastEvaluatedKey = state.getDuelRanking.lastEvaluatedKey[action.difficultyMode][action.gameMode][action.period][pagingIndex];

  let items;
  let searchFunction;
  let arrowLeft;
  let arrowRight;

  // アクションごとのitemsとsearchFunction設定。

  // if (state.getDuelRanking.action === 'duelRankingAll') {
  //   if (state.duelRankingAll.duelRanking !== undefined) {
  //     items = state.duelRankingAll.duelRanking.map( record => {
  //       if (true) {
  //         return <DuelRankingRecord record={record} />
  //       }
  //       else {
  //         return null;
  //       }
  //     });

  //     items.unshift(<DuelRankingRecordTitle />);
  //   }
  //   else {
  //     items = [<DuelRankingRecordTitle />];
  //   }

  //   searchFunction = (pagingIndex) => handleClickGetDuelRankingAll(state, dispatch, pagingIndex);
  // }

  if (duelRankingRecords !== undefined) {
    items = duelRankingRecords.map( record => {
      if (true) {
        return <DuelRankingRecord record={record} action={action} />
      }
      else {
        return null;
      }
    });

    items.unshift(<DuelRankingRecordTitle />);
  }
  else {
    items = [<DuelRankingRecordTitle />];
  }

  searchFunction = (pagingIndex) => handleClickGetDuelRankingAll(state, dispatch, pagingIndex);


  // 矢印

  if ( pagingIndex >= 1 ) {
    arrowLeft = <div>
      <button className='flexRow justifyContentCenter alignItemsCenter size2x2Large backgroundColorTransparent borderNone cursor borderRadiusCircle riseOut2 '
        onClick={ () => searchFunction(pagingIndex - 1) }
      >
        <img className='size2x2' src={iconCircleLeft} />
      </button>
    </div>
  }
  else {
    arrowLeft = <div>
      <button className='size2x2Large backgroundColorTransparent borderNone' disabled={true} >
        <div className='size2x2' />
      </button>
    </div>
  }

  if (lastEvaluatedKey !== undefined && lastEvaluatedKey !== null) {
    arrowRight = <div>
      <button className='flexRow justifyContentCenter alignItemsCenter size2x2Large backgroundColorTransparent borderNone cursor borderRadiusCircle riseOut2 '
        onClick={ () => { searchFunction(pagingIndex + 1) } }
      >
        <img className='size2x2' src={iconCircleRight} />
      </button>
    </div>
  }
  else {
    arrowRight = <div>
      <button className='size2x2Large backgroundColorTransparent borderNone' disabled={true} >
        <div className='size2x2' />
      </button>
    </div>
  }

  // 集計期間

  let period;

  if (action.period === 'monthly') {
    period =
    <div className='marginSide1' >
      { `${state.config.clientParameters.statisticsParameters.thisYear}年${state.config.clientParameters.statisticsParameters.thisMonth}月` }
    </div>
  }

  // ランキング戦賞品

  let prize;

  if (state.config.clientParameters.ranking.prize.display === 'normal') {
    prize =
    <button className='borderNone backgroundColorWhite focusEffect01 cursor borderRadius2 riseOut2 ' tabindex='0'
      onClick={ () => {
        window.open(state.config.clientParameters.ranking.prize.url);
      }}
    >
      { state.config.clientParameters.ranking.prize.face }
    </button>;
  }


  return (
    <div>
      {/* PC */}
      <div className="visibleMiddleOrMore">
        <div className='flexColumn'>
          <div className='flexRow justifyContentFlexEnd widthMax marginBottom1' >
            <div className='flexRow ' >
              {/*
                <div className='marginSide2'>
                  総合の勝率で順位をつけています。同率の場合は、ランダムに順位を表示しています。
                </div>
              */}
              <button className='button2' onClick={ () => handleClickGoTop(state, dispatch, navigate) }>
                { words.back[state.language] }
              </button>
            </div>
          </div>
          {/* history */}
          <div className='flexRow justifyContentFlexStart alignItemsCenter '>
            {/* action */}
            <div className='flexRow justifyContentSpaceAround marginSide0p5 '>
              <button className={'box3 focusEffect01 riseOut2 ' + (state.getDuelRanking.action.difficultyMode === 'advanced' ? 'colorRed borderSelected' : 'borderNone')}
                tabindex='0'
                onClick={ () => {
                  dispatch({type: 'setStateMultiLayers', keys: ['getDuelRanking', 'action', 'difficultyMode'], value: 'advanced'});
                }}
              >
                {words.advancedMode[state.language]}
              </button>
              <button className={'box3 focusEffect01 riseOut2 ' + (state.getDuelRanking.action.difficultyMode === 'simple' ? 'colorRed borderSelected' : 'borderNone')}
                tabindex='0'
                onClick={ () => {
                  dispatch({type: 'setStateMultiLayers', keys: ['getDuelRanking', 'action', 'difficultyMode'], value: 'simple'});
                }}
              >
                {words.simpleMode[state.language]}
              </button>
              <button className={'box3 focusEffect01 riseOut2 ' + (state.getDuelRanking.action.gameMode === 'human' ? 'colorRed borderSelected' : 'borderNone')}
                tabindex='0'
                onClick={ () => {
                  dispatch({type: 'setStateMultiLayers', keys: ['getDuelRanking', 'action', 'gameMode'], value: 'human'});
                }}
              >
                {words.pvp[state.language]}
              </button>
              <button className={'box3 focusEffect01 riseOut2 ' + (state.getDuelRanking.action.gameMode === 'computer' ? 'colorRed borderSelected' : 'borderNone')}
                tabindex='0'
                onClick={ () => {
                  dispatch({type: 'setStateMultiLayers', keys: ['getDuelRanking', 'action', 'gameMode'], value: 'computer'});
                }}
              >
                {words.pvc[state.language]}
              </button>
              <button className={'box3 focusEffect01 riseOut2 ' + (state.getDuelRanking.action.period === 'lifetime' ? 'colorRed borderSelected' : 'borderNone')}
                tabindex='0'
                onClick={ () => {
                  dispatch({type: 'setStateMultiLayers', keys: ['getDuelRanking', 'action', 'period'], value: 'lifetime'});
                }}
              >
                {words.lifetime[state.language]}
              </button>
              <button className={'box3 focusEffect01 riseOut2 ' + (state.getDuelRanking.action.period === 'monthly' ? 'colorRed borderSelected' : 'borderNone')}
                tabindex='0'
                onClick={ () => {
                  dispatch({type: 'setStateMultiLayers', keys: ['getDuelRanking', 'action', 'period'], value: 'monthly'});
                }}
              >
                {words.monthly[state.language]}
              </button>
            </div>
            <button className='button1' tabindex='0'
              onClick={ () => {
                dispatch({type: 'setStateMultiLayers', keys: ['getDuelRanking', 'lastRank', action.difficultyMode, action.gameMode, action.period], value: []});
                searchFunction(0);
              }}
            >
              <img className='size2x2' src={iconSearch} />
            </button>
            { arrowLeft }
            { arrowRight }
            { period }
            <div className='marginSide1' >
              { prize }
            </div>
          </div>
          { items }
          {/* development */}
          <div>
            { process.env.REACT_APP_ENVIRONMENT === 'dev' ? <ForDevelopment /> : null }
          </div>
        </div>
      </div>
      {/* SP */}
      <div className="visibleSmallOrLess">
        <div className='flexColumn widthMax'>
          <div className='flexRow justifyContentFlexEnd widthMax marginBottom1' >
            <button className='button2' onClick={ () => handleClickGoTop(state, dispatch, navigate) }>
              { words.back[state.language] }
            </button>
          </div>
          {/*
            <div className='flexRow justifyContentFlexStart widthMax marginBottom1' >
              <div className=''>
                総合の勝率で順位をつけています。同率の場合は、ランダムに順位を表示しています。
              </div>
            </div>
          */}
          {/* control */}
          {/* action */}
          <div className='flexColumn widthMax padding0p5 '>
            <div className='flexRow justifyContentSpaceAround widthMax padding0p5 '>
              <button className={'box3 focusEffect01 riseOut2 ' + (state.getDuelRanking.action.difficultyMode === 'advanced' ? 'colorRed borderSelected' : 'borderNone')}
                tabindex='0'
                onClick={ () => {
                  dispatch({type: 'setStateMultiLayers', keys: ['getDuelRanking', 'action', 'difficultyMode'], value: 'advanced'});
                }}
              >
                {words.advancedMode[state.language]}
              </button>
              <button className={'box3 focusEffect01 riseOut2 ' + (state.getDuelRanking.action.difficultyMode === 'simple' ? 'colorRed borderSelected' : 'borderNone')}
                tabindex='0'
                onClick={ () => {
                  dispatch({type: 'setStateMultiLayers', keys: ['getDuelRanking', 'action', 'difficultyMode'], value: 'simple'});
                }}
              >
                {words.simpleMode[state.language]}
              </button>
            </div>
            <div className='flexRow justifyContentSpaceAround widthMax padding0p5 '>
              <button className={'box3 focusEffect01 riseOut2 ' + (state.getDuelRanking.action.gameMode === 'human' ? 'colorRed borderSelected' : 'borderNone')}
                tabindex='0'
                onClick={ () => {
                  dispatch({type: 'setStateMultiLayers', keys: ['getDuelRanking', 'action', 'gameMode'], value: 'human'});
                }}
              >
                {words.pvp[state.language]}
              </button>
              <button className={'box3 focusEffect01 riseOut2 ' + (state.getDuelRanking.action.gameMode === 'computer' ? 'colorRed borderSelected' : 'borderNone')}
                tabindex='0'
                onClick={ () => {
                  dispatch({type: 'setStateMultiLayers', keys: ['getDuelRanking', 'action', 'gameMode'], value: 'computer'});
                }}
              >
                {words.pvc[state.language]}
              </button>
            </div>
            <div className='flexRow justifyContentSpaceAround widthMax padding0p5 '>
              <button className={'box3 focusEffect01 riseOut2 ' + (state.getDuelRanking.action.period === 'lifetime' ? 'colorRed borderSelected' : 'borderNone')}
                tabindex='0'
                onClick={ () => {
                  dispatch({type: 'setStateMultiLayers', keys: ['getDuelRanking', 'action', 'period'], value: 'lifetime'});
                }}
              >
                {words.lifetime[state.language]}
              </button>
              <button className={'box3 focusEffect01 riseOut2 ' + (state.getDuelRanking.action.period === 'monthly' ? 'colorRed borderSelected' : 'borderNone')}
                tabindex='0'
                onClick={ () => {
                  dispatch({type: 'setStateMultiLayers', keys: ['getDuelRanking', 'action', 'period'], value: 'monthly'});
                }}
              >
                {words.monthly[state.language]}
              </button>
            </div>
          </div>
          <div className='flexRow justifyContentFlexStart alignItemsCenter marginBottom1 '>
            <button className='button1 marginSide1' tabindex='0' onClick={ () => searchFunction(0) } >
              <img className='size2x2' src={iconSearch} />
            </button>
            <div className='marginSide1' >
              { arrowLeft }
            </div>
            <div className='marginSide1' >
              { arrowRight }
            </div>
          </div>
          <div className='flexRow justifyContentFlexStart alignItemsCenter marginBottom1 '>
            <div className='marginSide1' >
              { period }
            </div>
            <div className='marginSide1' >
              { prize }
            </div>
          </div>
          { items }
        </div>
      </div>
      {/* popup */}
      <Popup layer={0}/>
      <Popup layer={1}/>
      <Popup layer={2}/>
    </div>
  );
}

// DUEL RANKING RECORD TITLE
function DuelRankingRecordTitle() {
  const [state, dispatch] = useContext(GlobalState);

  return (
    <div>
      {/* PC */}
      <div className="visibleLargeOrMore">
        <div className='boxHistory flexRow justifyContentSpaceBetween alignItemsCenter textCenter'  >
          <div className='marginSide1 widthMin5' >
            { words.rank[state.language] }
          </div>
          <div className='marginSide1 widthMin10' >
            { words.userName[state.language] }
          </div>
          <div className='flexColumn justifyContentCenter alignItemsCenter marginSide1 widthMin15' >
            <div>
              { words.scoreDetail[state.language] }
            </div>
          </div>
          {/*
            <div className='flexColumn justifyContentCenter alignItemsCenter marginSide1 widthMin15' >
              <div>
                { words.overall[state.language] }
              </div>
              <div>
                { words.scoreDetail[state.language] }
              </div>
            </div>
            <div className='flexColumn justifyContentCenter alignItemsCenter marginSide1 widthMin15' >
              <div>
                { words.pvp[state.language] }
              </div>
              <div>
                { words.scoreDetail[state.language] }
              </div>
            </div>
            <div className='flexColumn justifyContentCenter alignItemsCenter marginSide1 widthMin15' >
              <div>
                { words.pvc[state.language] }
              </div>
              <div>
                { words.scoreDetail[state.language] }
              </div>
            </div>
          */}
          {/*
            <button className='dummyPadButton1 backgroundColorMonacottoAlmostWhite' disabled={true} />
          */}
        </div>
      </div>
      {/* SP */}
      <div className='visibleMiddleOrLess boxMonacotto1SmallScreen flexColumn alignItemsFlexStart '  >
        <div className='flexRow justifyContentFlexEnd alignItemsCenter width98PC'>
          <div>
            { words.scoreDetail[state.language] }
          </div>
        </div>
      </div>
    </div>
  );
}

// DUEL RANKING RECORD
function DuelRankingRecord(props) {
  const [state, dispatch] = useContext(GlobalState);
  const record = props.record;
  const difficultyMode = props.action.difficultyMode;
  const gameMode = props.action.gameMode;
  const period = props.action.period;

  const winRateAttributeName = {
    simple: {
      human: {
        lifetime: 'winRateHuman',
        monthly: 'winRateSimpleHumanMonthly',
      },
      computer: {
        lifetime: 'winRateComputer',
        monthly: 'winRateSimpleComputerMonthly',
      },
      overall: {
        lifetime: 'winRateOverall',
        monthly: 'winRateSimpleOverallMonthly',
      },
    },
    advanced: {
      human: {
        lifetime: 'winRateAdvancedHumanLifetime',
        monthly: 'winRateAdvancedHumanMonthly',
      },
      computer: {
        lifetime: 'winRateAdvancedComputerLifetime',
        monthly: 'winRateAdvancedComputerMonthly',
      },
      overall: {
        lifetime: 'winRateAdvancedOverallLifetime',
        monthly: 'winRateAdvancedOverallMonthly',
      },
    },
  }

  const statistics = `${decimalAdjust('round', record[winRateAttributeName[difficultyMode][gameMode][period]] * 100, -2)}% [${record.statistics[difficultyMode][gameMode][period].numberOfDuels}] ${record.statistics[difficultyMode][gameMode][period].win.total} - ${record.statistics[difficultyMode][gameMode][period].lose.total} - ${record.statistics[difficultyMode][gameMode][period].draw.total}`;

  // const overall = `${decimalAdjust('round', record[winRateAttributeName[difficultyMode].overall[period]] * 100, -2)}% [${record.statistics[difficultyMode].human[period].numberOfDuels + record.statistics[difficultyMode].computer[period].numberOfDuels}] ${record.statistics[difficultyMode].human[period].win.total + record.statistics[difficultyMode].computer[period].win.total} - ${record.statistics[difficultyMode].human[period].lose.total + record.statistics[difficultyMode].computer[period].lose.total} - ${record.statistics[difficultyMode].human[period].draw.total + record.statistics[difficultyMode].computer[period].draw.total}`;

  // const pvp = `${decimalAdjust('round', record[winRateAttributeName[difficultyMode].human[period]] * 100, -2)}% [${record.statistics[difficultyMode].human[period].numberOfDuels}] ${record.statistics[difficultyMode].human[period].win.total} - ${record.statistics[difficultyMode].human[period].lose.total} - ${record.statistics[difficultyMode].human[period].draw.total}`;

  // const pvc = `${decimalAdjust('round', record[winRateAttributeName[difficultyMode].computer[period]] * 100, -2)}% [${record.statistics[difficultyMode].computer[period].numberOfDuels}] ${record.statistics[difficultyMode].computer[period].win.total} - ${record.statistics[difficultyMode].computer[period].lose.total} - ${record.statistics[difficultyMode].computer[period].draw.total}`;

  // let buttons;
  // let popup;

  // popup = { type: 'duelHistoryDetail', body: record };
  // buttons =
  // <button className='button1'
  //   onClick={ () => {
  //     dispatch({ type: 'setStateMultiLayers', keys: ['getDuelHistory', 'round'], value: 0 });
  //     dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: popup });
  //   }}
  // >
  //   <img className='size2x2' src={iconDetail} />
  // </button>;


  return (
    <div>
      {/* PC */}
      <div className="visibleLargeOrMore">
        <div className='boxHistory flexRow justifyContentSpaceBetween alignItemsCenter textCenter'  >
          <div className='marginSide1 widthMin5' >
            { record.rank }
          </div>
          <div className='marginSide1 widthMin10' >
            { record.userName }
          </div>
          <div className='marginSide1 widthMin15' >
            { statistics }
          </div>
          {/*
            <div className='marginSide1 widthMin15' >
              { overall }
            </div>
            <div className='marginSide1 widthMin15' >
              { pvp }
            </div>
            <div className='marginSide1 widthMin15' >
              { pvc }
            </div>
          */}
          {/* buttons */}
        </div>
      </div>
      {/* SP */}
      <div className="visibleMiddleOrLess">
        <div className='boxMonacotto1SmallScreen flexColumn alignItemsFlexStart '  >
          {/*
          <div className='flexRow justifyContentFlexEnd alignItemsCenter width98PC'>
            {/ buttons /}
          </div>
          */}
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter width98PC'>
            <div className='' >
              { words.rank[state.language] }
            </div>
            <div className='flexRow alignItemsCenter' >
              { record.rank }
            </div>
          </div>
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter width98PC'>
            <div className='' >
              { words.userName[state.language] }
            </div>
            <div className='' >
              { record.userName }
            </div>
          </div>
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter width98PC'>
            <div className='' >
              { words.gameRecord[state.language] }
            </div>
            <div className='' >
              { statistics }
            </div>
          </div>
          {/*
            <div className='flexRow justifyContentSpaceBetween alignItemsCenter width98PC'>
              <div className='' >
                { words.overall[state.language] }
              </div>
              <div className='' >
                { overall }
              </div>
            </div>
            <div className='flexRow justifyContentSpaceBetween alignItemsCenter width98PC'>
              <div className='' >
                { words.pvp[state.language] }
              </div>
              <div className='' >
                { pvp }
              </div>
            </div>
            <div className='flexRow justifyContentSpaceBetween alignItemsCenter width98PC'>
              <div className='' >
                { words.pvc[state.language] }
              </div>
              <div className='' >
                { pvc }
              </div>
            </div>
          */}
        </div>
      </div>
    </div>
  );
}



// CARD REGISTRATION
function CardRegistration() {
  const [state, dispatch, navigate] = useContext(GlobalState);

  // 登録ボタン

  let registerButton;

  if (state.walletType === 'mpurse') {
    registerButton =
    <div className='flexRow justifyContentCenter widthMax marginTop1'>
      <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0' onClick={ () => handleClickApplyForCardRegistration(state, dispatch) }>
        <div>
          {words.signByOwnerAddressToRegister[state.language]}
        </div>
        <img className='size2x2' src={iconSign} />
      </button>
    </div>
  }
  else if (state.walletType === 'nfc') {
    registerButton =
    <div className='flexRow justifyContentCenter widthMax marginTop1'>
      <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
        onClick={ () => {
          const callback = (keyPairs) => handleClickApplyForCardRegistration(state, dispatch, { keyPairs });
          handleClickNfc(state, dispatch, callback);
        }}
      >
        <div>
          {words.signByOwnerAddressToRegister[state.language]}
        </div>
        <div>
          <img className='size2x2' src={iconKeyCard} />
        </div>
      </button>
    </div>
  }
  else { // qr
    registerButton =
    <div className='flexRow justifyContentCenter widthMax marginTop1'>
      <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
        onClick={ () => {
          const callback = (keyPairs) => handleClickApplyForCardRegistration(state, dispatch, { keyPairs });
          handleClickScanQr(state, dispatch, callback);
        }}
      >
        <div>
          {words.signByOwnerAddressToRegister[state.language]}
        </div>
        <div>
          <img className='size2x2' src={iconQr} />
        </div>
      </button>
    </div>
  }


  return (
    <div>
      {/* PC */}
      <div className="visibleMiddleOrMore flexColumn alignItemsCenter">
        <div className='flexRow justifyContentFlexEnd widthMax marginBottom1' >
          <button className='button2' onClick={ () => handleClickGoTop(state, dispatch, navigate) }>
            { words.back[state.language] }
          </button>
        </div>
        <div className='flexColumn alignItemsFlexStart width98vw borderKOM marginSide1 padding0p5'>
          <div dangerouslySetInnerHTML={ { __html: cardRegistrationGuidelinesHtml } } />
        </div>
        <div className='flexColumn alignItemsFlexStart borderKOM marginSide1 marginTop1 padding0p5'>
          {/* asset */}
          <div className='margin1'>
            <TextLine3 fieldName='cardRegistrationAsset' keys={['cardRegistration', 'asset']} face={words.asset[state.language]} tooltip='true' />
          </div>
          {/* name */}
          <TextArea2 fieldName='cardRegistrationName' keys={['cardRegistration', 'name']} face={words.name[state.language]} boxClass='box4 height4 margin1 ' textAreaClass='textArea1 height70PC' />
          {/* feature */}
          <TextArea2 fieldName='cardRegistrationFeature' keys={['cardRegistration', 'feature']} face={words.profile[state.language]} boxClass='box4 margin1' textAreaClass='textArea1' />
          {/* attribute */}
          <TextArea2 fieldName='cardRegistrationAttribute' keys={['cardRegistration', 'attribute']} face={words.gender[state.language]} boxClass='box4 height4 margin1 ' textAreaClass='textArea1 height70PC' />
          {/* contact */}
          <TextArea2 fieldName='cardRegistrationContact' keys={['cardRegistration', 'contact']} face={words.contact[state.language]} boxClass='box4 height4 margin1 ' textAreaClass='textArea1 height70PC' />
          {/* register button */}
          { registerButton }
          {/*
            <div className='flexRow justifyContentCenter widthMax'>
              <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0' onClick={ () => handleClickApplyForCardRegistration(state, dispatch) }>
                <div>
                  {words.signByOwnerAddressToRegister[state.language]}
                </div>
                <img className='size2x2' src={iconSign} />
              </button>
            </div>
          */}
        </div>
      </div>
      {/* SP */}
      <div className="visibleSmallOrLess flexColumn ">
        <div className='flexRow justifyContentFlexEnd widthMax marginBottom1' >
          <button className='button2' onClick={ () => handleClickGoTop(state, dispatch, navigate) }>
            { words.back[state.language] }
          </button>
        </div>
        <div className='flexColumn alignItemsFlexStart widthMax borderKOM padding0p5'>
          <div dangerouslySetInnerHTML={ { __html: cardRegistrationGuidelinesHtml } } />
        </div>
        <div className='flexColumn alignItemsFlexStart widthMax borderKOM marginTop1 padding0p5'>
          {/* asset */}
          <TextLine3 fieldName='cardRegistrationAsset' keys={['cardRegistration', 'asset']} face={words.asset[state.language]} tooltip='true' />
          {/* name */}
          <TextArea2 fieldName='cardRegistrationName' keys={['cardRegistration', 'name']} face={words.name[state.language]}
            outerClass='widthMax' boxClass='box4 width96PC height4 marginTop1 ' textAreaClass='textArea1 height70PC'
          />
          {/* feature */}
          <TextArea2 fieldName='cardRegistrationFeature' keys={['cardRegistration', 'feature']} face={words.profile[state.language]}
            outerClass='widthMax' boxClass='box4 width96PC marginTop1 ' textAreaClass='textArea1'
          />
          {/* attribute */}
          <TextArea2 fieldName='cardRegistrationAttribute' keys={['cardRegistration', 'attribute']} face={words.gender[state.language]}
            outerClass='widthMax' boxClass='box4 width96PC height4 marginTop1 ' textAreaClass='textArea1 height70PC'
          />
          {/* contact */}
          <TextArea2 fieldName='cardRegistrationContact' keys={['cardRegistration', 'contact']} face={words.contact[state.language]}
            outerClass='widthMax' boxClass='box4 width96PC height4 marginTop1 ' textAreaClass='textArea1 height70PC'
          />
          {/* register button */}
          { registerButton }
        </div>
      </div>
      {/* popup */}
      <Popup layer={0}/>
      <Popup layer={1}/>
    </div>
  );
}

// CREATOR INFORMATION
function CreatorInformation(props) {
  const [state, dispatch] = useContext(GlobalState);
  const item = state.popup[props.popupLayer].body;

  // 登録ボタン

  let registerButton;

  if (state.walletType === 'mpurse') {
    registerButton =
    <div className='flexRow justifyContentCenter widthMax marginTop1'>
      <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
        onClick={ () => {
          handleClickEditCreatorInformation(state, dispatch, item.asset);
        }}
      >
        <div>
          {words.signByOwnerAddressToRegister[state.language]}
        </div>
        <img className='size2x2' src={iconSign} />
      </button>
    </div>
  }
  else if (state.walletType === 'nfc') {
    registerButton =
    <div className='flexRow justifyContentCenter widthMax marginTop1'>
      <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
        onClick={ () => {
          const callback = (keyPairs) => handleClickEditCreatorInformation(state, dispatch, item.asset, { keyPairs });
          handleClickNfc(state, dispatch, callback);
        }}
      >
        <div>
          {words.signByOwnerAddressToRegister[state.language]}
        </div>
        <div>
          <img className='size2x2' src={iconKeyCard} />
        </div>
      </button>
    </div>
  }
  else { // qr
    registerButton =
    <div className='flexRow justifyContentCenter widthMax marginTop1'>
      <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
        onClick={ () => {
          const callback = (keyPairs) => handleClickEditCreatorInformation(state, dispatch, item.asset, { keyPairs });
          handleClickScanQr(state, dispatch, callback, { popupLayer: props.popupLayer + 1 });
        }}
      >
        <div>
          {words.signByOwnerAddressToRegister[state.language]}
        </div>
        <div>
          <img className='size2x2' src={iconQr} />
        </div>
      </button>
    </div>
  }

  return (
    <div>
      {/* PC */}
      <div className="visibleMiddleOrMore flexColumn alignItemsCenter">
        <div className='flexColumn alignItemsFlexStart borderKOM marginSide1 marginTop1 padding0p5'>
          {/* asset */}
          <div className='margin1'>
            { item.asset }
          </div>
          {/* creater */}
          <TextArea2 fieldName='creatorInformationCreater' keys={['creatorInformation', 'creater']} face={words.creater[state.language]}
            boxClass='box4 height4 margin1 ' textAreaClass='textArea1 height70PC'
          />
          {/* createrText */}
          <TextArea2 fieldName='creatorInformationCreaterText' keys={['creatorInformation', 'createrText']} face={words.createrText[state.language]}
            boxClass='box4 margin1' textAreaClass='textArea1'
          />
          {/* monacottoAddressMain */}
          <TextArea2 fieldName='creatorInformationMonacottoAddressMain' keys={['creatorInformation', 'monacottoAddressMain']}
            face={words.monacottoAddressMain[state.language]} boxClass='box4 height4 margin1 ' textAreaClass='textArea1 height70PC'
          />
          {/* createrLink */}
          <TextArea2 fieldName='creatorInformationCreaterLink0Title' keys={['creatorInformation', 'createrLink', 0, 'title']} face={words.createrLinkTitle[state.language] + 1}
            boxClass='box4 height4 margin1 ' textAreaClass='textArea1 height70PC'
          />
          <TextArea2 fieldName='creatorInformationCreaterLink0Url' keys={['creatorInformation', 'createrLink', 0, 'url']} face={words.createrLinkUrl[state.language] + 1}
            boxClass='box4 height4 margin1 ' textAreaClass='textArea1 height70PC'
          />
          <TextArea2 fieldName='creatorInformationCreaterLink1Title' keys={['creatorInformation', 'createrLink', 1, 'title']} face={words.createrLinkTitle[state.language] + 2}
            boxClass='box4 height4 margin1 ' textAreaClass='textArea1 height70PC'
          />
          <TextArea2 fieldName='creatorInformationCreaterLink1Url' keys={['creatorInformation', 'createrLink', 1, 'url']} face={words.createrLinkUrl[state.language] + 2}
            boxClass='box4 height4 margin1 ' textAreaClass='textArea1 height70PC'
          />
          <TextArea2 fieldName='creatorInformationCreaterLink2Title' keys={['creatorInformation', 'createrLink', 2, 'title']} face={words.createrLinkTitle[state.language] + 3}
            boxClass='box4 height4 margin1 ' textAreaClass='textArea1 height70PC'
          />
          <TextArea2 fieldName='creatorInformationCreaterLink2Url' keys={['creatorInformation', 'createrLink', 2, 'url']} face={words.createrLinkUrl[state.language] + 3}
            boxClass='box4 height4 margin1 ' textAreaClass='textArea1 height70PC'
          />
          {/* register button */}
          { registerButton }
          {/*
            <div className='flexRow justifyContentCenter widthMax'>
              <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0' onClick={ () => handleClickEditCreatorInformation(state, dispatch, item.asset) }>
                <div>
                  {words.signByOwnerAddressToRegister[state.language]}
                </div>
                <img className='size2x2' src={iconSign} />
              </button>
            </div>
          */}
        </div>
      </div>
      {/* SP */}
      <div className="visibleSmallOrLess flexColumn alignItemsFlexStart widthMax padding1">
        {/* asset */}
        <div className=''>
          { item.asset }
        </div>
        {/* creater */}
        <TextArea2 fieldName='creatorInformationCreater' keys={['creatorInformation', 'creater']} face={words.creater[state.language]}
          outerClass='widthMax' boxClass='box4 width96PC height4 marginTop1 ' textAreaClass='textArea1 height70PC'
        />
        {/* createrText */}
        <TextArea2 fieldName='creatorInformationCreaterText' keys={['creatorInformation', 'createrText']} face={words.createrText[state.language]}
          outerClass='widthMax' boxClass='box4 width96PC marginTop1 ' textAreaClass='textArea1 '
        />
        {/* monacottoAddressMain */}
        <TextArea2 fieldName='creatorInformationMonacottoAddressMain' keys={['creatorInformation', 'monacottoAddressMain']}
          face={words.monacottoAddressMain[state.language]} outerClass='widthMax' boxClass='box4 width96PC height4 marginTop1 ' textAreaClass='textArea1 height70PC'
        />
        {/* createrLink */}
        <TextArea2 fieldName='creatorInformationCreaterLink0Title' keys={['creatorInformation', 'createrLink', 0, 'title']} face={words.createrLinkTitle[state.language] + 1}
          outerClass='widthMax' boxClass='box4 width96PC height4 marginTop1 ' textAreaClass='textArea1 height70PC'
        />
        <TextArea2 fieldName='creatorInformationCreaterLink0Url' keys={['creatorInformation', 'createrLink', 0, 'url']} face={words.createrLinkUrl[state.language] + 1}
          outerClass='widthMax' boxClass='box4 width96PC height4 marginTop1 ' textAreaClass='textArea1 height70PC'
        />
        <TextArea2 fieldName='creatorInformationCreaterLink1Title' keys={['creatorInformation', 'createrLink', 1, 'title']} face={words.createrLinkTitle[state.language] + 2}
          outerClass='widthMax' boxClass='box4 width96PC height4 marginTop1 ' textAreaClass='textArea1 height70PC'
        />
        <TextArea2 fieldName='creatorInformationCreaterLink1Url' keys={['creatorInformation', 'createrLink', 1, 'url']} face={words.createrLinkUrl[state.language] + 2}
          outerClass='widthMax' boxClass='box4 width96PC height4 marginTop1 ' textAreaClass='textArea1 height70PC'
        />
        <TextArea2 fieldName='creatorInformationCreaterLink2Title' keys={['creatorInformation', 'createrLink', 2, 'title']} face={words.createrLinkTitle[state.language] + 3}
          outerClass='widthMax' boxClass='box4 width96PC height4 marginTop1 ' textAreaClass='textArea1 height70PC'
        />
        <TextArea2 fieldName='creatorInformationCreaterLink2Url' keys={['creatorInformation', 'createrLink', 2, 'url']} face={words.createrLinkUrl[state.language] + 3}
          outerClass='widthMax' boxClass='box4 width96PC height4 marginTop1 ' textAreaClass='textArea1 height70PC'
        />
        {/* register button */}
        { registerButton }
      </div>
    </div>
  );
}

// CARD LIST
function CardList() {
  const [state, dispatch, navigate] = useContext(GlobalState);
  const location = useLocation();

  useEffect( () => {
    const urlParams = (new URL(document.location)).searchParams;
    const asset = urlParams.get('asset');

    if (state.registeredCard[asset] !== undefined) {
      let type;

      if (window.innerWidth >= 832) {
        type = 'monsterDetail';
      }
      else {
        type = 'itemDetailSP';
      }

      const body = {
        card: {
          asset: asset, 
        },
        actionBox: 'close',
      };

      const popup = { type: type, body: body };
      dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: popup });
    }
  }, [state.registeredCard]);

  let items =
   Object.values(state.registeredCard)
  .sort( (a, b) => a.cardNo - b.cardNo )
  .map ( item => <Item item={item} style='small' quantity={false} arrows={false} />);


  return (
    <div>
      {/* PC */}
      <div className="visibleMiddleOrMore flexColumn alignItemsCenter">
        <div className='flexRow justifyContentFlexEnd widthMax marginBottom1 ' >
          <button className='button2' onClick={ () => handleClickGoBack(state, dispatch, navigate, location) }>
            { words.back[state.language] }
          </button>
        </div>
        <div className="flexRow justifyContentSpaceAround ">
            { items }
        </div>
      </div>
      {/* SP */}
      <div className="flexColumn visibleSmallOrLess ">
        <div className='flexRow justifyContentFlexEnd widthMax marginBottom0p5' >
          <div className='flexRow ' >
            <button className='button2' onClick={ () => handleClickGoBack(state, dispatch, navigate, location) }>
              { words.back[state.language] }
            </button>
          </div>
        </div>
        <div className="flexRow justifyContentSpaceAround">
            { items }
        </div>
      </div>
      {/* popup */}
      <Popup layer={0}/>
      <Popup layer={1}/>
      <Popup layer={2}/>
    </div>
  );
}

// PURCHASE ITEM
function PurchaseItem() {
  const [state, dispatch, navigate] = useContext(GlobalState);
  const location = useLocation();
  let items;
  let itemFunction;
  let arrowLeft;
  let arrowRight;

  useEffect( () => {
     handleClickGetRegisteredItem(state, dispatch, 0);
  }, []);

  // アクションごとのitemsとitemFunction設定。

  if (state.getRegisteredItem.action === 'registeredItemAll') {
    if (state.registeredItemAll.registeredItem !== undefined) {
      items = state.registeredItemAll.registeredItem.map( item => <Merchandise item={item} /> );

      // items.unshift(<DuelRecordTitle />);
    }
    else {
      // items = [<DuelRecordTitle />];
    }

    itemFunction = (pagingIndex) => handleClickGetRegisteredItem(state, dispatch, pagingIndex);
  }

  // 矢印

  if ( state.getRegisteredItem.pagingIndex[state.getRegisteredItem.action] >= 1 ) {
    arrowLeft = <div>
      <button className='flexRow justifyContentCenter alignItemsCenter size2x2Large backgroundColorTransparent borderNone cursor borderRadiusCircle riseOut2 '
        onClick={ () => itemFunction(state.getRegisteredItem.pagingIndex[state.getRegisteredItem.action] - 1) }
      >
        <img className='size2x2' src={iconCircleLeft} />
      </button>
    </div>
  }
  else {
    arrowLeft = <div>
      <button className='size2x2Large backgroundColorTransparent borderNone' disabled={true} >
        <div className='size2x2' />
      </button>
    </div>
  }

  if ( state.getRegisteredItem.lastEvaluatedKey[state.getRegisteredItem.action][state.getRegisteredItem.pagingIndex[state.getRegisteredItem.action]] !== undefined &&
       state.getRegisteredItem.lastEvaluatedKey[state.getRegisteredItem.action][state.getRegisteredItem.pagingIndex[state.getRegisteredItem.action]] !== null         ) {
    arrowRight = <div>
      <button className='flexRow justifyContentCenter alignItemsCenter size2x2Large backgroundColorTransparent borderNone cursor borderRadiusCircle riseOut2 '
        onClick={ () => { itemFunction(state.getRegisteredItem.pagingIndex[state.getRegisteredItem.action] + 1) } }
      >
        <img className='size2x2' src={iconCircleRight} />
      </button>
    </div>
  }
  else {
    arrowRight = <div>
      <button className='size2x2Large backgroundColorTransparent borderNone' disabled={true} >
        <div className='size2x2' />
      </button>
    </div>
  }


  return (
    <div>
      {/* PC */}
      <div className="visibleMiddleOrMore flexColumn alignItemsCenter">
        <img className='width80' src={imageCardShopTitle} />
        <div className='flexRow justifyContentFlexEnd widthMax marginBottom1 ' >
          <button className='button2' onClick={ () => handleClickGoBack(state, dispatch, navigate, location) }>
            { words.back[state.language] }
          </button>
        </div>
        <div className='flexRow justifyContentFlexStart alignItemsCenter '>
          <button className='button2' onClick={ () => handleClickCheckOut(state, dispatch, navigate, { previousLocation: 'purchaseitems' }) }>
            { words.checkout[state.language] }
          </button>
          <button className='button1' tabindex='0' onClick={ () => itemFunction(0) } >
            <img className='size2x2' src={iconSearch} />
          </button>
          { arrowLeft }
          { arrowRight }
        </div>
        <div className="flexRow justifyContentSpaceAround ">
            { items }
        </div>
      </div>
      {/* SP */}
      <div className="flexColumn visibleSmallOrLess ">
        <img className='widthMax' src={imageCardShopTitle} />
        {/*
          <div className='flexRow justifyContentFlexEnd widthMax marginBottom0p5' >
          </div>
        */}
        <div className='flexRow justifyContentSpaceBetween alignItemsCenter margin1 '>
          <button className='button2' onClick={ () => handleClickCheckOut(state, dispatch, navigate, { previousLocation: 'purchaseitems' }) }>
            { words.checkout[state.language] }
          </button>
          <button className='button2 ' onClick={ () => handleClickGoBack(state, dispatch, navigate, location) }>
            { words.back[state.language] }
          </button>
        </div>
        <div className='flexRow alignItemsCenter marginSide1 marginBottom1 '>
          <button className='button1' tabindex='0' onClick={ () => itemFunction(0) } >
            <img className='size2x2' src={iconSearch} />
          </button>
          { arrowLeft }
          { arrowRight }
        </div>
        <div className="flexRow justifyContentFlexStart">
            { items }
        </div>
      </div>
      {/* popup */}
      <Popup layer={0}/>
      <Popup layer={1}/>
      <Popup layer={2}/>
      <Popup layer={3}/>
    </div>
  );
}

// MERCHANDISE
function Merchandise(props) {
  const [state, dispatch] = useContext(GlobalState);
  const item = props.item;

  let amountToBuy;

  if (state.checkout.itemsIncart[item.itemNo] !== undefined) {
    amountToBuy = state.checkout.itemsIncart[item.itemNo].amountToBuy;
  }
  else {
    amountToBuy = 0;
  }


  return (
    <div>
      {/* PC */}
      <div className="visibleMiddleOrMore">
        <div className='flexRow justifyContentCenter alignItemsCenter boxShadow01 margin1'>
          <MerchandiseImage item={item} />
          <div className='flexColumn justifyContentFlexStart alignItemsCenter'>
            <MerchandiseName item={item} />
            <div className='flexRow justifyContentCenter alignItemsCenter padding0p5 marginTop0p5 '>
              <div>
                { words.unitPriceJpy[state.language] }
              </div>
              <div className='marginSide1' >
                { item.priceJpy.toLocaleString() }
              </div>
              <div>
                { words.yen[state.language] + words.taxIncluded[state.language] }
              </div>
            </div>
            <MerchandiseStock item={item} />
            <div className='flexRow justifyContentCenter alignItemsCenter padding0p5 '>
              <div>
                { words.addToYourCart[state.language] }
              </div>
              <AddToCartButtons item={item} word={amountToBuy} />
            </div>
          </div>
        </div>
      </div>
      {/* SP */}
      <div className="flexColumn visibleSmallOrLess ">
        <div className='flexColumn justifyContentFlexStart alignItemsCenter width96vw marginBottom2 boxShadow01'>
          <MerchandiseImage item={item} />
          <MerchandiseName item={item} />
          <div className='flexRow justifyContentCenter alignItemsCenter padding0p5 marginTop0p5 '>
            <div>
              { words.unitPriceJpy[state.language] }
            </div>
            <div className='marginSide1' >
              { item.priceJpy.toLocaleString() }
            </div>
            <div>
              { words.yen[state.language] + words.taxIncluded[state.language] }
            </div>
          </div>
          <MerchandiseStock item={item} />
          <div className='flexRow justifyContentCenter alignItemsCenter padding0p5 '>
            <div>
              { words.addToYourCart[state.language] }
            </div>
            <AddToCartButtons item={item} word={amountToBuy} />
          </div>
        </div>
      </div>
    </div>
  );
}

// MERCHANDISE IMAGE
function MerchandiseImage(props) {
  const [state, dispatch] = useContext(GlobalState);
  const item = props.item;
  const popupLayerNext = props.popupLayer !== undefined && props.popupLayer !== null ? props.popupLayer + 1 : 0;

  let itemImageBox;

  if (item.itemImage?.type === 'subItem') {
    const cardImageUrl = `https://${process.env.REACT_APP_MONACARD_URL}small/${item.subItems[item.itemImage.indexCard].asset}_small.png`;

    itemImageBox =
    <div className='flexRow itemImage' >
      <div className='itemImageSingleCard' >
        <img className='width96PC' src={cardImageUrl} alt=''/>
      </div>
      <div className='flexColumn justifyContentCenter alignItemsCenter itemImageSingleCard ' >
        <div className='' >
          { words.monatokaPoint[state.language] }
        </div>
        <div className='' >
          { `${item.subItems[item.itemImage.indexMonatokaPoint].amount} ${words.point[state.language]}` }
        </div>
        <div className='' >
          { words.included[state.language] }
        </div>
      </div>
    </div>;
  }
  else {
    const cardImageUrl = `https://${process.env.REACT_APP_KOM_ITEMIMAGE_URL}item${item.itemNo.toString().padStart(6, '0')}.png`;
    // const cardImageUrlSP = `https://${process.env.REACT_APP_KOM_ITEMIMAGE_URL}item${item.itemNo.toString().padStart(6, '0')}.png`;

    itemImageBox = <img className='itemImage' src={cardImageUrl} alt=''/>;
  }

  return (
    <button className='backgroundColorTransparent borderNone cursor ' disabled={props.disabled ? true : false}
      onClick={ () => {
        const body = item;
        const popup = { type: 'merchandiseDetail', body: body };
        dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayerNext], value: popup });
      }}
    >
      <div className='relative' >
        { itemImageBox }
        {/* <img className='itemImage' src={cardImageUrl} /> */}
        {/* screen */}
        {/* soldOut */}
      </div>
    </button>
  );
}

// MERCHANDISE NAME
function MerchandiseName(props) {
  const [state, dispatch] = useContext(GlobalState);
  const item = props.item;
  const popupLayerNext = props.popupLayer !== undefined && props.popupLayer !== null ? props.popupLayer + 1 : 0;

  return (
    <button className='backgroundColorUp backgroundColorTransparent borderLink fontHyperLink margin0p5 boxShadow01 cursor' disabled={props.disabled ? true : false}
      onClick={ () => {
        const body = item;
        const popup = { type: 'merchandiseDetail', body: body };
        dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayerNext], value: popup });
      }}
    >
      { item.name }
    </button>
  );
}

// MERCHANDISE STOCK
function MerchandiseStock(props) {
  const [state, dispatch] = useContext(GlobalState);
  const item = props.item;

  let stock;

  if (item.displayStock === 'normal') { // else none
    stock =
    <div className='flexRow justifyContentCenter alignItemsCenter padding0p5 '>
      <div>
        { words.stock[state.language] }
      </div>
      <div className='marginSide1' >
        { item.stock.toLocaleString() }
      </div>
      <div>
        { words.ko[state.language] }
      </div>
    </div>;
  }

  return stock;
}

// MERCHANDISE DETAIL
function MerchandiseDetail(props) {
  const [state] = useContext(GlobalState);
  const item = state.popup[props.popupLayer].body;
  const subItemsAll = item.subItems;

  let subItems = {};
  let cardSection;
  let pointSection;

  subItems.card = subItemsAll
  .filter( item => item.type === 'card' )
  .sort( (a, b) => a.subItemNo - b.subItemNo )
  .map ( item => <Item item={item} style='small' popupLayer={props.popupLayer} quantity={false} arrows={false} />);

  if (subItems.card.length >= 1) {
    cardSection =
    <div>
      <div className='flexRow justifyContentFlexStart alignItemsCenter marginBottom1'>
        { words.card[state.language] }
      </div>
      <div className='flexRow justifyContentFlexStart alignItemsCenter marginBottom1'>
        { subItems.card }
      </div>
    </div>;
  }

  subItems.point = subItemsAll
  .filter( item => item.type === 'monatokaPoint' )[0];

  if (subItems.point !== undefined) {
    pointSection =
    <div>
      <div className='flexRow justifyContentFlexStart alignItemsCenter marginBottom1'>
        { words.monatokaPoint[state.language] }
      </div>
      <div className='flexRow justifyContentCenter alignItemsCenter padding0p5 '>
        <div className='marginSide1'>
          { subItems.point.amount.toLocaleString() }
        </div>
        <div>
          { words.point[state.language] }
        </div>
      </div>
    </div>;
  }


  return (
    <div>
      {/* PC */}
      <div className="visibleLargeOrMore">
        <div className='flexColumn justifyContentFlexStart alignItemsFlexStart '>
          <div className='flexRow justifyContentFlexStart alignItemsCenter borderBottom marginBottom1'>
            { item.name }
          </div>
          <div className='flexRow justifyContentCenter alignItemsCenter padding0p5 '>
            <div>
              { words.unitPriceJpy[state.language] }
            </div>
            <div className='marginSide1' >
              { item.priceJpy.toLocaleString() }
            </div>
            <div>
              { words.yen[state.language] + words.taxIncluded[state.language] }
            </div>
          </div>
          <MerchandiseStock item={item} />
          <div className='padding0p5 '>
            { cardSection }
          </div>
          <div className='padding0p5 '>
            { pointSection }
          </div>
        </div>
      </div>
      {/* SP */}
      <div className="visibleMiddleOrLess">
        <div className='flexColumn justifyContentFlexStart alignItemsFlexStart marginSide1 '>
          <div className='flexRow justifyContentFlexStart alignItemsCenter borderBottom marginBottom1'>
            { item.name }
          </div>
          <div className='flexRow justifyContentCenter alignItemsCenter padding0p5 '>
            <div>
              { words.unitPriceJpy[state.language] }
            </div>
            <div className='marginSide1' >
              { item.priceJpy.toLocaleString() }
            </div>
            <div>
              { words.yen[state.language] + words.taxIncluded[state.language] }
            </div>
          </div>
          <MerchandiseStock item={item} />
          <div className='padding0p5 '>
            { cardSection }
          </div>
          <div className='padding0p5 '>
            { pointSection }
          </div>
        </div>
      </div>
    </div>
  );
}

// MERCHANDISE IN CART
function MerchandiseInCart(props) {
  const [state, dispatch] = useContext(GlobalState);

  const itemInCart = props.itemInCart;
  const itemInformation = state.registeredItemAll

  if (itemInCart.amountToBuy <= 0) {
    return null;
  }

  const priceJpyDecimal = new decimal(itemInCart.information.priceJpy);
  const priceJpySubTotal = priceJpyDecimal.times(itemInCart.amountToBuy).toNumber();


  return (
    <div>
      {/* PC */}
      <div className="visibleMiddleOrMore">
        <div className='flexRow justifyContentCenter alignItemsCenter boxShadow01 margin1'>
          <MerchandiseImage item={itemInCart.information} />
          <div className='flexColumn justifyContentFlexStart alignItemsCenter'>
            <MerchandiseName item={itemInCart.information} />
            <div className='flexRow justifyContentCenter alignItemsCenter padding0p5 '>
              <div>
                { words.unitPriceJpy[state.language] }
              </div>
              <div className='marginSide1' >
                { itemInCart.information.priceJpy.toLocaleString() }
              </div>
              <div>
                { words.yen[state.language] + words.taxIncluded[state.language] }
              </div>
            </div>
            <div className='flexRow justifyContentCenter alignItemsCenter padding0p5 '>
              <div className='flexRow justifyContentCenter alignItemsCenter padding0p5 '>
                <div>
                  { words.amountToBuy[state.language] }
                </div>
                <div className='marginSide1' >
                  { itemInCart.amountToBuy.toLocaleString() }
                </div>
                <div>
                  { words.ko[state.language] }
                </div>
              </div>
              <AddToCartButtons item={itemInCart.information} word='' />
            </div>
            <div className='flexRow justifyContentCenter alignItemsCenter padding0p5 '>
              <div>
                { words.subtotal[state.language] }
              </div>
              <div className='marginSide1'>
                { priceJpySubTotal.toLocaleString() }
              </div>
              <div>
                { words.yen[state.language] + words.taxIncluded[state.language] }
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* SP */}
      <div className="flexColumn visibleSmallOrLess ">
        <div className='flexColumn justifyContentFlexStart alignItemsCenter width96vw marginBottom2 boxShadow01'>
          <MerchandiseImage item={itemInCart.information} />
          <MerchandiseName item={itemInCart.information} />
          <div className='flexRow justifyContentCenter alignItemsCenter padding0p5 '>
            <div>
              { words.unitPriceJpy[state.language] }
            </div>
            <div className='marginSide1' >
              { itemInCart.information.priceJpy.toLocaleString() }
            </div>
            <div>
              { words.yen[state.language] + words.taxIncluded[state.language] }
            </div>
          </div>
          <div className='flexRow justifyContentCenter alignItemsCenter padding0p5 '>
            <div className='flexRow justifyContentCenter alignItemsCenter padding0p5 '>
              <div>
                { words.amountToBuy[state.language] }
              </div>
              <div className='marginSide1' >
                { itemInCart.amountToBuy.toLocaleString() }
              </div>
              <div>
                { words.ko[state.language] }
              </div>
            </div>
            <AddToCartButtons item={itemInCart.information} word='' />
          </div>
          <div className='flexRow justifyContentCenter alignItemsCenter padding0p5 '>
            <div>
              { words.subtotal[state.language] }
            </div>
            <div className='marginSide1'>
              { priceJpySubTotal.toLocaleString() }
            </div>
            <div>
              { words.yen[state.language] + words.taxIncluded[state.language] }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// ADD TO CART BUTTONS
function AddToCartButtons(props) {
  const [state, dispatch] = useContext(GlobalState);
  const item = props.item;
  const word = props.word;

  return (
    <div className='flexRow justifyContentCenter alignItemsCenter '>
      <button className='button1' disabled={props.disabled ? true : false}
        onClick={ () => removeFromCart(state, dispatch, item) }
      >
        -
      </button>
      <div>
        { word }
      </div>
      <button className='button1' disabled={props.disabled ? true : false}
        onClick={ () => addToCart(state, dispatch, item) }
      >
        +
      </button>
    </div>
  );
}


// CHECK OUT
function CheckOut(props) {
  const [state, dispatch, navigate] = useContext(GlobalState);
  const location = useLocation();

  const itemsIncart = Object.values(state.checkout.itemsIncart).map( item => <MerchandiseInCart itemInCart={item} /> );

  const priceJpyTotal = Object.values(state.checkout.itemsIncart).reduce( (acc, cur) => acc + cur.information.priceJpy * cur.amountToBuy, 0);

  // 購入確定ボタン

  let submitButton;

  if (state.walletType === 'mpurse') {
    submitButton =
    <div>
      <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
        onClick={ () => handleClickSubmitOrder(state, dispatch, navigate) }
      >
        <div>
          { words.submitOrder[state.language] }
        </div>
        <div>
          <img className='size2x2' src={iconSign} alt='' />
        </div>
      </button>
    </div>;
  }
  else if (state.walletType === 'nfc') {
    submitButton =
    <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
      onClick={ () => {
        const callback = (keyPairs) => handleClickSubmitOrder(state, dispatch, navigate, { keyPairs });
        handleClickNfc(state, dispatch, callback);
      }}
    >
      <div>
        { words.submitOrder[state.language] }
      </div>
      <div>
        <img className='size2x2' src={iconKeyCard} alt='' />
      </div>
    </button>
  }
  else { // qr
    submitButton =
    <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter ' tabindex='0'
      onClick={ () => {
        const callback = (keyPairs) => handleClickSubmitOrder(state, dispatch, navigate, { keyPairs });
        handleClickScanQr(state, dispatch, callback);
      }}
    >
      <div>
        { words.submitOrder[state.language] }
      </div>
      <div>
        <img className='size2x2' src={iconQr} />
      </div>
    </button>
  }

  // 決済手段

  let paymentMethod;

  if (state.checkout.paymentMethod === 'bankTransfer') {
    paymentMethod =
    <div className='flexColumn alignItemsCenter ' >
      <div className='' >
        { words.bankTransfer[state.language] }
      </div>
    </div>;
  }
  else { // paygent
    paymentMethod =
    <div className='flexColumn alignItemsCenter ' >
      <div className='' >
        { words.paygent2[state.language] }
      </div>
      {/*
        <div className='colorRed marginTop0p5' >
          { words.underTesting[state.language] }
        </div>
      */}
    </div>;
  }

  // 注意書き

  let notes;

  if (state.checkout.paymentMethod === 'bankTransfer') {
    notes =
    <div className='flexColumn justifyContentFlexStart alignItemsFlexStart backgroundColorMonacottoAlmostWhite borderRadius0p3 padding1 marginTopBottom1 '>
      <div className='preWrap '>
        { state.config.clientParameters.itemSales.notes4 }
      </div>
      <div className='preWrap '>
        { state.config.clientParameters.itemSales.notes1 }
      </div>
      <div className='preWrap marginTopBottom1'>
        { state.config.clientParameters.itemSales.notes2 }
      </div>
      <div className='preWrap '>
        { state.config.clientParameters.itemSales.notes3 }
      </div>
    </div>;
  }
  else { // paygent
    notes =
    <div className='flexColumn justifyContentFlexStart alignItemsFlexStart backgroundColorMonacottoAlmostWhite borderRadius0p3 padding1 marginTopBottom1 '>
      <div className='preWrap '>
        { state.config.clientParameters.itemSales.notes4 }
      </div>
      <div className='preWrap '>
        { state.config.clientParameters.itemSales.notes3 }
      </div>
    </div>;
  }


  return (
    <div>
      {/* PC */}
      <div className="visibleMiddleOrMore flexColumn alignItemsCenter">
        <div className='flexRow justifyContentFlexEnd widthMax marginBottom1 ' >
          <button className='button2' onClick={ () => handleClickGoBack(state, dispatch, navigate, location) }>
            { words.back[state.language] }
          </button>
          <button className='button2' onClick={ () => handleClickGoTop(state, dispatch, navigate) }>
            top
          </button>
        </div>
        <div className="flexColumn justifyContentFlexStart alignItemsCenter ">
          <div className='flexRow justifyContentFlexStart alignItemsCenter preWrap marginTop1 '>
            { words.purchasedItems[state.language] }
          </div>
          { itemsIncart }
        </div>
        {/* total price */}
        <div className='flexRow justifyContentFlexStart alignItemsCenter font2 marginTop1 '>
          { words.priceTotal[state.language] }
          <div className='marginSide1 '>
            { priceJpyTotal.toLocaleString() }
          </div>
          <div>
            { words.yen[state.language] + words.taxIncluded[state.language] }
          </div>
        </div>
        {/* main address */}
        <div className='flexRow justifyContentFlexStart alignItemsCenter marginTop1 '>
          { words.addressMain[state.language] }
          <div className='marginSide1 '>
            { state.active.addressMain }
          </div>
        </div>
        {/* payment method */}
        <div className='flexColumn justifyContentFlexStart alignItemsCenter marginTop1 '>
          <div className='flexRow justifyContentFlexStart alignItemsCenter marginTop1 '>
            { words.paymentMethod[state.language] }
            <div className='flexRow justifyContentSpaceAround '>
              <button className={'box3 focusEffect01 riseOut2 ' + (state.checkout.paymentMethod === 'bankTransfer' ? 'colorRed borderSelected' : 'borderNone')}
                tabindex='0'
                onClick={ () => {
                  dispatch({type: 'setStateMultiLayers', keys: ['checkout', 'paymentMethod'], value: 'bankTransfer'});
                }}
              >
                <div>
                  {words.bankTransfer[state.language]}
                </div>
              </button>
              <button className={'box3 focusEffect01 riseOut2 ' + (state.checkout.paymentMethod === 'paygent' ? 'colorRed borderSelected' : 'borderNone')}
                tabindex='0'
                onClick={ () => {
                  dispatch({type: 'setStateMultiLayers', keys: ['checkout', 'paymentMethod'], value: 'paygent'});
                }}
              >
                <div>
                  {words.paygent[state.language]}
                </div>
              </button>
            </div>
          </div>
          { paymentMethod }
        </div>
        {/* notes */}
        { notes }
        {/* mail address */}
        <div className='flexColumn alignItemsCenter marginTopBottom1 '>
          <div className='flexRow justifyContentFlexStart alignItemsCenter preWrap '>
            { state.config.clientParameters.itemSales.fillInYourEMailAddress }
          </div>
          <div className='flexRow justifyContentFlexStart alignItemsCenter marginTop1 '>
            <TextLine3 fieldName='mailAddress' keys={['checkout', 'mailAddress']} face={words.mailAddress[state.language]} tooltip={true} />
          </div>
        </div>
        {/* submit */}
        <div className='flexColumn alignItemsCenter marginTopBottom1 '>
          <div className='flexRow justifyContentFlexStart alignItemsCenter preWrap '>
            { state.config.clientParameters.itemSales.signAfterClickPurchase[state.walletType] }
          </div>
          { submitButton }
          {/*
            <button className='button2' onClick={ () => handleClickSubmitOrder(state, dispatch, navigate) }>
              { words.submitOrder[state.language] }
            </button>
          */}
        </div>
      </div>
      {/* SP */}
      <div className="flexColumn alignItemsCenter visibleSmallOrLess ">
        <div className='flexRow justifyContentFlexEnd widthMax marginBottom1 ' >
          <button className='button2' onClick={ () => handleClickGoBack(state, dispatch, navigate, location) }>
            { words.back[state.language] }
          </button>
          <button className='button2' onClick={ () => handleClickGoTop(state, dispatch, navigate) }>
            top
          </button>
        </div>
        <div className="flexColumn justifyContentFlexStart alignItemsCenter ">
          <div className='flexRow justifyContentFlexStart alignItemsCenter preWrap marginTop1 '>
            { words.purchasedItems[state.language] }
          </div>
          { itemsIncart }
        </div>
        {/* total price */}
        <div className='flexColumn alignItemsCenter marginTop1 '>
          <div className='font2'>
            { words.priceTotal[state.language] }
          </div>
          <div className='flexRow justifyContentFlexStart alignItemsCenter font2 '>
            <div className='marginSide0p5 '>
              { priceJpyTotal.toLocaleString() }
            </div>
            <div>
              { words.yen[state.language] + words.taxIncluded[state.language] }
            </div>
          </div>
        </div>
        {/* main address */}
        <div className='flexColumn alignItemsCenter marginTop1 '>
          <div className=''>
            { words.addressMain[state.language] }
          </div>
          <div className=''>
            { state.active.addressMain }
          </div>
        </div>
        {/* payment method */}
        <div className='flexColumn justifyContentFlexStart alignItemsCenter marginTop1 '>
          { words.paymentMethod[state.language] }
          <div className='flexRow justifyContentSpaceAround '>
            <button className={'box3 focusEffect01 riseOut2 ' + (state.checkout.paymentMethod === 'bankTransfer' ? 'colorRed borderSelected' : 'borderNone')}
              tabindex='0'
              onClick={ () => {
                dispatch({type: 'setStateMultiLayers', keys: ['checkout', 'paymentMethod'], value: 'bankTransfer'});
              }}
            >
              <div>
                {words.bankTransfer[state.language]}
              </div>
            </button>
            <button className={'box3 focusEffect01 riseOut2 ' + (state.checkout.paymentMethod === 'paygent' ? 'colorRed borderSelected' : 'borderNone')}
              tabindex='0'
              onClick={ () => {
                dispatch({type: 'setStateMultiLayers', keys: ['checkout', 'paymentMethod'], value: 'paygent'});
              }}
            >
              <div>
                {words.paygent[state.language]}
              </div>
            </button>
          </div>
          { paymentMethod }
        </div>
        {/* notes1 */}
        {/*
          <div className='flexColumn justifyContentFlexStart alignItemsFlexStart backgroundColorMonacottoAlmostWhite borderRadius0p3 padding1 marginTopBottom1 '>
            <div className='preWrap '>
              { state.config.clientParameters.itemSales.notes1 }
            </div>
            <div className='preWrap marginTopBottom1'>
              { state.config.clientParameters.itemSales.notes2 }
            </div>
            <div className='preWrap '>
              { state.config.clientParameters.itemSales.notes3 }
            </div>
          </div>
        */}
        { notes }
        {/* mail address */}
        <div className='flexColumn alignItemsCenter marginTopBottom1 '>
          <div className='flexRow justifyContentFlexStart alignItemsCenter preWrap '>
            { state.config.clientParameters.itemSales.fillInYourEMailAddress }
          </div>
          <div className='flexRow justifyContentFlexStart alignItemsCenter marginTop1 '>
            <TextLine3 fieldName='mailAddress' keys={['checkout', 'mailAddress']} face={words.mailAddress[state.language]} tooltip={true} />
          </div>
        </div>
        {/* submit */}
        <div className='flexColumn alignItemsCenter marginTopBottom1 '>
          <div className='flexRow justifyContentFlexStart alignItemsCenter preWrap marginBottom1'>
            { state.config.clientParameters.itemSales.signAfterClickPurchase[state.walletType] }
          </div>
          { submitButton }
        </div>
      </div>
      {/* popup */}
      <Popup layer={0}/>
      <Popup layer={1}/>
      <Popup layer={2}/>
      <Popup layer={3}/>
    </div>
  );
}

// ITEM ORDER HISTORY
function ItemOrderHistory(props) {
  const [state, dispatch, navigate] = useContext(GlobalState);
  let historyItems;
  let historyFunction;
  let arrowLeft;
  let arrowRight;

  useEffect( () => {
    const func = async () => {
      if (
        state.getItemOrderHistory.addressMain !== undefined && state.getItemOrderHistory.addressMain !== null && state.getItemOrderHistory.addressMain !== '' &&
        state.itemOrderHistory[state.getItemOrderHistory.addressMain]?.itemOrder === undefined
      ) {
        const result = await handleClickGetItemOrderHistory(state, dispatch, 0);

        if (result?.message === 'nfcRequired') {
          const callback = (keyPairs) => {
            handleClickGetItemOrderHistory(state, dispatch, 0, null, null, keyPairs);
            keepKeyPairsInMemory(state, dispatch, keyPairs);
          };

          handleClickNfc(state, dispatch, callback);
        }
        else if (result?.message === 'bcRequired') {
          const callback = (keyPairs) => {
            handleClickGetItemOrderHistory(state, dispatch, 0, null, null, keyPairs);
            keepKeyPairsInMemory(state, dispatch, keyPairs);
          };

          handleClickScanQr(state, dispatch, callback);
        }
      }
    };

    func();
  }, []);

  // アクションごとのhistoryItemsとhistoryFunction設定。

  if (state.getItemOrderHistory.action === 'itemOrder') {
    if (state.itemOrderHistory[state.getItemOrderHistory.addressMain]?.itemOrder !== undefined) {
      historyItems = state.itemOrderHistory[state.getItemOrderHistory.addressMain].itemOrder
      .filter( record => record.purchaseItemNo !== 0 )
      .map( record => {
        if (true) {
          return <ItemOrderHistoryRecord record={record} />
        }
        else {
          return null;
        }
      });

      historyItems.unshift(<ItemOrderHistoryRecordTitle />);
    }
    else {
      historyItems = [<ItemOrderHistoryRecordTitle />];
    }

    historyFunction = async (pagingIndex) => {
      const result = await handleClickGetItemOrderHistory(state, dispatch, pagingIndex);

      if (result?.message === 'nfcRequired') {
        const callback = (keyPairs) => {
          handleClickGetItemOrderHistory(state, dispatch, pagingIndex, null, null, keyPairs);
          keepKeyPairsInMemory(state, dispatch, keyPairs);
        };

        handleClickNfc(state, dispatch, callback);
      }
      else if (result?.message === 'bcRequired') {
        const callback = (keyPairs) => {
          handleClickGetItemOrderHistory(state, dispatch, pagingIndex, null, null, keyPairs);
          keepKeyPairsInMemory(state, dispatch, keyPairs);
        };

        handleClickScanQr(state, dispatch, callback);
      }
    };
  }

  // 矢印

  if ( state.getItemOrderHistory.pagingIndex[state.getItemOrderHistory.action] >= 1 ) {
    arrowLeft = <div>
      <button className='flexRow justifyContentCenter alignItemsCenter size2x2Large backgroundColorTransparent borderNone cursor borderRadiusCircle riseOut2 '
        onClick={ () => historyFunction(state.getItemOrderHistory.pagingIndex[state.getItemOrderHistory.action] - 1) }
      >
        <img className='size2x2' src={iconCircleLeft} alt='' />
      </button>
    </div>
  }
  else {
    arrowLeft = <div>
      <button className='size2x2Large backgroundColorTransparent borderNone' disabled={true} >
        <div className='size2x2' />
      </button>
    </div>
  }

  if ( state.getItemOrderHistory.lastEvaluatedKey[state.getItemOrderHistory.action][state.getItemOrderHistory.pagingIndex[state.getItemOrderHistory.action]] !== undefined &&
       state.getItemOrderHistory.lastEvaluatedKey[state.getItemOrderHistory.action][state.getItemOrderHistory.pagingIndex[state.getItemOrderHistory.action]] !== null ) {
    arrowRight = <div>
      <button className='flexRow justifyContentCenter alignItemsCenter size2x2Large backgroundColorTransparent borderNone cursor borderRadiusCircle riseOut2 '
        onClick={ () => { historyFunction(state.getItemOrderHistory.pagingIndex[state.getItemOrderHistory.action] + 1) } }
      >
        <img className='size2x2' src={iconCircleRight} alt='' />
      </button>
    </div>
  }
  else {
    arrowRight = <div>
      <button className='size2x2Large backgroundColorTransparent borderNone' disabled={true} >
        <div className='size2x2' />
      </button>
    </div>
  }

  // 商品購入履歴コントロールセクション

  let controlSection;

  if (state.walletType === 'mpurse') {
    controlSection =
    <div>
      <div className='visibleMiddleOrMore flexRow justifyContentFlexStart alignItemsCenter marginTop1 '>
        {/* action */}
        {/*
          <div className='flexRow justifyContentSpaceAround marginSide0p5 marginTop0p5'>
            <button className={'box3 focusEffect01 riseOut2 ' + (state.getItemOrderHistory.action === 'permit' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
              onClick={ () => {
                        dispatch({type: 'setStateMultiLayers', keys: ['getItemOrderHistory', 'action'], value: 'permit'});
              }}>
              {words.permit[state.language]}
            </button>
          </div>
        */}
        {/* addressMain */}
        <TextLine3 fieldName='itemOrderHistoryAddressMain' keys={['getItemOrderHistory', 'addressMain']} face={words.addressMain[state.language]} tooltip={true}
          extraFunctionOnChange={ [
            {
              function: () => {
                dispatch({ type: 'setStateMultiLayers', keys: ['getItemOrderHistory', 'pagingIndex'],
                  value: { itemOrder: 0 }
                });
                dispatch({ type: 'setStateMultiLayers', keys: ['getItemOrderHistory', 'lastEvaluatedKey'],
                  value: { itemOrder: [] }
                });
              },
              arguments: [],
            }
          ]}
        />
        <button className='button1'
          onClick={ () => {
            handleClickMpurse(state, dispatch, ['getItemOrderHistory', 'addressMain']);
            dispatch({ type: 'setStateMultiLayers', keys: ['getItemOrderHistory', 'pagingIndex'],
              value: { itemOrder: 0 }
            });
            dispatch({ type: 'setStateMultiLayers', keys: ['getItemOrderHistory', 'lastEvaluatedKey'],
              value: { itemOrder: [] }
            });
          }}
        >
          <img className='size2x2' src={iconMpurse} alt='' />
        </button>
        {/*
          <button className='button1'
            onClick={ () => {
              handleClickAddressHistory(state, dispatch, cookies, ['getItemOrderHistory', 'addressMain']);
              dispatch({ type: 'setStateMultiLayers', keys: ['getItemOrderHistory', 'pagingIndex'],
                value: { permit: 0, permitted: 0, permittedInclusively: 0, requestPermission: 0, requestedPermission: 0, delegate: 0, delegated: 0 }
              });
              dispatch({ type: 'setStateMultiLayers', keys: ['getItemOrderHistory', 'lastEvaluatedKey'],
                value: { permit: [], permitted: [], permittedInclusively: [], requestPermission: [], requestedPermission: [], delegate: [], delegated: [] }
              });
            }}
          >
            <img className='size2x2' src={iconList} />
          </button>
        */}
        <button className='button1' tabindex='0' onClick={ () => historyFunction(0) } >
          <img className='size2x2' src={iconSearch} alt='' />
        </button>
        { arrowLeft }
        { arrowRight }
      </div>
      <div className="visibleSmallOrLess flexColumn">
        {/* addressMain */}
        <TextLine3 fieldName='historyAddressMain' keys={['getItemOrderHistory', 'addressMain']} face={words.addressMain[state.language]} tooltip={true}
          extraFunctionOnChange={ [
            {
              function: () => {
                dispatch({ type: 'setStateMultiLayers', keys: ['getItemOrderHistory', 'pagingIndex'],
                  value: { itemOrder: 0 }
                });
                dispatch({ type: 'setStateMultiLayers', keys: ['getItemOrderHistory', 'lastEvaluatedKey'],
                  value: { itemOrder: [] }
                });
              },
              arguments: [],
            }
          ]}
        />
        <div className='flexRow justifyContentSpaceBetween alignItemsCenter widthMax '>
          <div className='flexRow'>
            <button className='button1'
              onClick={ () => {
                handleClickMpurse(state, dispatch, ['getItemOrderHistory', 'addressMain']);
                dispatch({ type: 'setStateMultiLayers', keys: ['getItemOrderHistory', 'pagingIndex'],
                  value: { itemOrder: 0 }
                });
                dispatch({ type: 'setStateMultiLayers', keys: ['getItemOrderHistory', 'lastEvaluatedKey'],
                  value: { itemOrder: [] }
                });
              }}
            >
              <img className='size2x2' src={iconMpurse} alt='' />
            </button>
            {/*
              <button className='button1'
                onClick={ () => {
                  handleClickAddressHistory(state, dispatch, cookies, ['getItemOrderHistory', 'addressMain']);
                  dispatch({ type: 'setStateMultiLayers', keys: ['getItemOrderHistory', 'pagingIndex'],
                    value: { permit: 0, permitted: 0, permittedInclusively: 0, requestPermission: 0, requestedPermission: 0, delegate: 0, delegated: 0 }
                  });
                  dispatch({ type: 'setStateMultiLayers', keys: ['getItemOrderHistory', 'lastEvaluatedKey'],
                    value: { permit: [], permitted: [], permittedInclusively: [], requestPermission: [], requestedPermission: [], delegate: [], delegated: [] }
                  });
                }}
              >
                <img className='size2x2' src={iconList} />
              </button>
            */}
            <button className='button1' tabindex='0' onClick={ () => historyFunction(0) } >
              <img className='size2x2' src={iconSearch} alt='' />
            </button>
          </div>
          <div className='flexRow'>
            <div className='marginSide0p5'>
              { arrowLeft }
            </div>
            <div className='marginSide0p5'>
              { arrowRight }
            </div>
          </div>
        </div>
      </div>
    </div>;
  }
  else {
    controlSection =
    <div>
      <div className='visibleMiddleOrMore flexRow justifyContentFlexStart alignItemsCenter '>
        <div className='flexRow'>
          {/*
            <button className='button1'
              onClick={ () => {
                handleClickAddressHistory(state, dispatch, cookies, ['getItemOrderHistory', 'addressMain']);
                dispatch({ type: 'setStateMultiLayers', keys: ['getItemOrderHistory', 'pagingIndex'],
                  value: { permit: 0, permitted: 0, permittedInclusively: 0, requestPermission: 0, requestedPermission: 0, delegate: 0, delegated: 0 }
                });
                dispatch({ type: 'setStateMultiLayers', keys: ['getItemOrderHistory', 'lastEvaluatedKey'],
                  value: { permit: [], permitted: [], permittedInclusively: [], requestPermission: [], requestedPermission: [], delegate: [], delegated: [] }
                });
              }}
            >
              <img className='size2x2' src={iconList} />
            </button>
          */}
          <button className='button1' tabindex='0' onClick={ () => historyFunction(0) } >
            <img className='size2x2' src={iconSearch} alt='' />
          </button>
        </div>
        <div className='flexRow'>
          <div className='marginSide0p5'>
            { arrowLeft }
          </div>
          <div className='marginSide0p5'>
            { arrowRight }
          </div>
        </div>
      </div>
      <div className='visibleSmallOrLess flexRow justifyContentSpaceBetween alignItemsCenter widthMax '>
        <div className='flexRow'>
          {/*
            <button className='button1'
              onClick={ () => {
                handleClickAddressHistory(state, dispatch, cookies, ['getItemOrderHistory', 'addressMain']);
                dispatch({ type: 'setStateMultiLayers', keys: ['getItemOrderHistory', 'pagingIndex'],
                  value: { permit: 0, permitted: 0, permittedInclusively: 0, requestPermission: 0, requestedPermission: 0, delegate: 0, delegated: 0 }
                });
                dispatch({ type: 'setStateMultiLayers', keys: ['getItemOrderHistory', 'lastEvaluatedKey'],
                  value: { permit: [], permitted: [], permittedInclusively: [], requestPermission: [], requestedPermission: [], delegate: [], delegated: [] }
                });
              }}
            >
              <img className='size2x2' src={iconList} />
            </button>
          */}
          <button className='button1' tabindex='0' onClick={ () => historyFunction(0) } >
            <img className='size2x2' src={iconSearch} alt='' />
          </button>
        </div>
        <div className='flexRow'>
          <div className='marginSide0p5'>
            { arrowLeft }
          </div>
          <div className='marginSide0p5'>
            { arrowRight }
          </div>
        </div>
      </div>
    </div>
  }


  return (
    <div>
      {/* PC */}
      <div className="visibleMiddleOrMore">
        <div className='flexColumn'>
          <div className='flexRow justifyContentFlexEnd widthMax marginBottom1' >
            <div className='flexRow justifyContentFlexEnd widthMax' >
              <button className='button2' onClick={ () => handleClickGoTop(state, dispatch, navigate) }>
                { words.back[state.language] }
              </button>
            </div>
          </div>
          {/* history */}
          { controlSection }
          { historyItems }
          {/* development */}
          <div>
            { process.env.REACT_APP_ENVIRONMENT === 'dev' ? <ForDevelopment /> : null }
          </div>
        </div>
      </div>
      {/* SP */}
      <div className="visibleSmallOrLess flexColumn">
        <div className='flexRow justifyContentFlexStart widthMax marginBottom1' >
          <button className='button2' onClick={ () => handleClickGoTop(state, dispatch, navigate) }>
            { words.back[state.language] }
          </button>
        </div>
        {/* history */}
        { controlSection }
        { historyItems }
      </div>
      {/* popup */}
      <Popup layer={0}/>
      <Popup layer={1}/>
      <Popup layer={2}/>
      <Popup layer={3}/>
      <Popup layer={4}/>
    </div>
  );
}

// ITEM ORDER HISTORY RECORD TITLE
function ItemOrderHistoryRecordTitle() {
  const [state, dispatch] = useContext(GlobalState);

  return (
    <div>
      {/* PC */}
      <div className="visibleLargeOrMore">
        <div className='boxHistory flexRow justifyContentSpaceBetween alignItemsCenter textCenter'  >
          <div className='marginSide1 widthMin7' >
            { words.purchaseItemNo[state.language] }
          </div>
          <div className='marginSide1 widthMin25' >
            { words.item[state.language] }
          </div>
          <div className='marginSide1 widthMin12 ' >
            { words.priceTotal[state.language] + words.taxIncluded[state.language] }
          </div>
          <div className='marginSide1 widthMin15' >
            { words.purchaseTime[state.language] }
          </div>
          <div className='marginSide1 widthMin10' >
            { words.status[state.language] }
          </div>
          <button className='dummyPadButton1 backgroundColorMonacottoAlmostWhite' disabled={true} />
        </div>
      </div>
      {/* SP */}
    </div>
  );
}

// ITEM ORDER HISTORY RECORD
function ItemOrderHistoryRecord(props) {
  const [state, dispatch] = useContext(GlobalState);
  const record = props.record;
  const item0 = record.items[0];

  const popup = { type: 'itemOrderHistoryRecordDetail', body: record };

  const buttons =
  <button className='button1' onClick={ () => { dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: popup }) } } >
    <img className='size2x2' src={iconDetail} />
  </button>;

  // 購入商品が複数あった場合。

  let etc = '';

  if (record.items.length >= 2) {
    etc = ' ...';
  }


  return (
    <div>
      {/* PC */}
      <div className="visibleLargeOrMore">
        <div className='boxHistory flexRow justifyContentSpaceBetween alignItemsCenter textCenter'  >
          <div className='marginSide1 widthMin7' >
            { 'No. ' + record.purchaseItemNo.toLocaleString() }
          </div>
          <div className='marginSide1 widthMin25' >
            { item0.information.name + etc }
          </div>
          <div className='flexRow justifyContentCenter alignItemsCenter marginSide1 widthMin12 ' >
            <div className='paddingRight1'>
              { record.priceJpyTotal.toLocaleString() }
            </div>
            <div>
              { words.yen[state.language] }
            </div>
          </div>
          <div className='marginSide1 widthMin15' >
            { localTime(record.serverTimeOfAddressMain, 'yearToSecond') }
          </div>
          <div className='marginSide1 widthMin10' >
            { words[record.status][state.language] }
          </div>
          { buttons }
        </div>
      </div>
      {/* SP */}
      <div className="visibleMiddleOrLess">
        <div className='boxMonacotto1SmallScreen flexColumn alignItemsFlexStart '  >
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter width98PC'>
            <div className='' >
              { words.purchaseItemNo[state.language] }
            </div>
            <div className='flexRow alignItemsCenter' >
              <div>
                { 'No. ' + record.purchaseItemNo.toLocaleString() }
              </div>
              { buttons }
            </div>
          </div>
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter width98PC'>
            <div className='' >
              { words.item[state.language] }
            </div>
            <div className='' >
              { item0.information.name + etc }
            </div>
          </div>
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter width98PC'>
            <div className='' >
              { words.priceTotal[state.language] + words.taxIncluded[state.language] }
            </div>
            <div className='flexRow alignItemsCenter' >
              <div className='paddingRight1'>
                { record.priceJpyTotal.toLocaleString() }
              </div>
              <div>
                { words.yen[state.language] }
              </div>
            </div>
          </div>
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter width98PC'>
            <div className='' >
              { words.purchaseTime[state.language] }
            </div>
            <div className='' >
              { localTime(record.serverTimeOfAddressMain, 'yearToSecond') }
            </div>
          </div>
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter width98PC'>
            <div className='' >
              { words.status[state.language] }
            </div>
            <div className='' >
              { words[record.status][state.language] }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// ITEM ORDER HISTORY RECORD DETAIL
function ItemOrderHistoryRecordDetail(props) {
  const [state, dispatch] = useContext(GlobalState);
  const popupLayer = props.popupLayer;
  const record = state.popup[popupLayer].body;

  const items = record.items.map( item => <MerchandiseInHistory itemInCart={item} popupLayer={popupLayer} /> );

  let sendTokenTxids;

  if (record.sendTokenTxid !== undefined) {
    sendTokenTxids = record.sendTokenTxid.map( txid =>
      <div className='paddingLeft1'>
        {txid}
      </div>
    );
  }


  return (
    <div>
      {/* PC */}
      <div className="visibleLargeOrMore">
        <div className='flexColumn boxDetail'>
          <div>
            {words.addressMain[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.addressMain}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.purchaseItemNo[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.purchaseItemNo.toLocaleString()}
          </div>
        </div>
        { items }
        <div className='flexColumn boxDetail'>
          <div>
            {words.priceTotal[state.language]}
          </div>
          <div className='flexRow justifyContentFlexStart alignItemsCenter padding0p5 '>
            <div className='paddingSide1'>
              {record.priceJpyTotal.toLocaleString()}
            </div>
            <div>
              { words.yen[state.language] + words.taxIncluded[state.language] }
            </div>
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.status[state.language]}
          </div>
          <div className='paddingLeft1'>
            {words[record.status][state.language]}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.purchaseIdentifier[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.purchaseIdentifier}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.purchaseTime[state.language]}
          </div>
          <div className='paddingLeft1'>
            {localTime(record.serverTimeOfAddressMain, 'yearToSecond')}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.extraMona[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.extraMona}
          </div>
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.sendTokenTxid[state.language]}
          </div>
          { sendTokenTxids }
        </div>
        <div className='flexColumn boxDetail'>
          <div>
            {words.sendMonaTxid[state.language]}
          </div>
          <div className='paddingLeft1'>
            {record.sendMonaTxid}
          </div>
        </div>
      </div>
      {/* SP */}
      <div className="visibleMiddleOrLess">
        <div className='flexColumn alignItemsCenter'>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.addressMain[state.language]}
            </div>
            <div className='paddingLeft1 breakAll'>
              {record.addressMain}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.purchaseItemNo[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.purchaseItemNo.toLocaleString()}
            </div>
          </div>
          { items }
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.priceTotal[state.language]}
            </div>
            <div className='flexRow alignItemsCenter'>
              <div className='paddingSide1'>
                {record.priceJpyTotal.toLocaleString()}
              </div>
              <div>
                { words.yen[state.language] + words.taxIncluded[state.language] }
              </div>
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.status[state.language]}
            </div>
            <div className='paddingLeft1'>
              {words[record.status][state.language]}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.purchaseIdentifier[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.purchaseIdentifier}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.purchaseTime[state.language]}
            </div>
            <div className='paddingLeft1'>
              {localTime(record.serverTimeOfAddressMain, 'yearToSecond')}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.extraMona[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.extraMona}
            </div>
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.sendTokenTxid[state.language]}
            </div>
            { sendTokenTxids }
          </div>
          <div className='flexColumn boxMonacotto1SmallScreen width98PC'>
            <div className='width96PC '>
              {words.sendMonaTxid[state.language]}
            </div>
            <div className='paddingLeft1'>
              {record.sendMonaTxid}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// MERCHANDISE IN HISTORY
function MerchandiseInHistory(props) {
  const [state] = useContext(GlobalState);
  const itemInCart = props.itemInCart;
  const popupLayer = props.popupLayer;


  return (
    <div>
      {/* PC */}
      <div className="visibleMiddleOrMore">
        <div className='flexRow justifyContentCenter alignItemsCenter boxShadow01 margin1'>
          <MerchandiseImage item={itemInCart.information} popupLayer={popupLayer} />
          <div className='flexColumn justifyContentFlexStart alignItemsCenter'>
            <MerchandiseName item={itemInCart.information} popupLayer={popupLayer} />
            <div className='flexRow justifyContentCenter alignItemsCenter padding0p5 '>
              <div>
                { words.unitPriceJpy[state.language] }
              </div>
              <div className='marginSide1'>
                { itemInCart.information.priceJpy.toLocaleString() }
              </div>
              <div>
                { words.yen[state.language] + words.taxIncluded[state.language] }
              </div>
            </div>
            <div className='flexRow justifyContentCenter alignItemsCenter padding0p5 '>
              <div>
                { words.amountToBuy[state.language] }
              </div>
              <div className='marginSide1'>
                { itemInCart.amountToBuy.toLocaleString() }
              </div>
              <div>
                { words.ko[state.language] }
              </div>
            </div>
            <div className='flexRow justifyContentCenter alignItemsCenter padding0p5 '>
              <div>
                { words.subtotal[state.language] }
              </div>
              <div className='marginSide1'>
                { itemInCart.priceJpySubTotal.toLocaleString() }
              </div>
              <div>
                { words.yen[state.language] + words.taxIncluded[state.language] }
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* SP */}
      <div className="visibleSmallOrLess ">
        <div className='flexColumn justifyContentFlexStart alignItemsCenter width96vw marginBottom2 boxShadow01'>
          <MerchandiseImage item={itemInCart.information} popupLayer={popupLayer} />
          <MerchandiseName item={itemInCart.information} popupLayer={popupLayer} />
          <div className='flexRow justifyContentCenter alignItemsCenter padding0p5 '>
            <div>
              { words.unitPriceJpy[state.language] }
            </div>
            <div className='marginSide1'>
              { itemInCart.information.priceJpy.toLocaleString() }
            </div>
            <div>
              { words.yen[state.language] + words.taxIncluded[state.language] }
            </div>
          </div>
          <div className='flexRow justifyContentCenter alignItemsCenter padding0p5 '>
            <div>
              { words.amountToBuy[state.language] }
            </div>
            <div className='marginSide1'>
              { itemInCart.amountToBuy.toLocaleString() }
            </div>
            <div>
              { words.ko[state.language] }
            </div>
          </div>
          <div className='flexRow justifyContentCenter alignItemsCenter padding0p5 '>
            <div>
              { words.subtotal[state.language] }
            </div>
            <div className='marginSide1'>
              { itemInCart.priceJpySubTotal.toLocaleString() }
            </div>
            <div>
              { words.yen[state.language] + words.taxIncluded[state.language] }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// SETTING
function Setting() {
  const [state, dispatch, navigate] = useContext(GlobalState);
  const walletType = state.walletType;
  let urlForABroadcaster;

  if (
    state.makeUrlToBroadcast.addressMain !== undefined && state.makeUrlToBroadcast.addressMain !== null && state.makeUrlToBroadcast.addressMain !== '' &&
    state.makeUrlToBroadcast.addressOperator !== undefined && state.makeUrlToBroadcast.addressOperator !== null && state.makeUrlToBroadcast.addressOperator !== '' &&
    state.makeUrlToBroadcast.clientTime !== undefined && state.makeUrlToBroadcast.clientTime !== null && state.makeUrlToBroadcast.clientTime !== '' &&
    state.config.clientParameters?.signatureVersion?.makeUrlToBroadcast !== undefined && state.config.clientParameters?.signatureVersion?.makeUrlToBroadcast !== null && state.config.clientParameters?.signatureVersion?.makeUrlToBroadcast !== '' &&
    state.makeUrlToBroadcast.signature !== undefined && state.makeUrlToBroadcast.signature !== null && state.makeUrlToBroadcast.signature !== ''
  ) {
    urlForABroadcaster = `https://${process.env.REACT_APP_KOM_EXTENSION_URL}livetext?addressmain=${state.makeUrlToBroadcast.addressMain}&addressoperator=${state.makeUrlToBroadcast.addressOperator}&clienttime=${state.makeUrlToBroadcast.clientTime}&signatureversion=${state.config.clientParameters?.signatureVersion?.makeUrlToBroadcast}&signature=${encodeURIComponent(state.makeUrlToBroadcast.signature)}`;
  }


  return (
    <div>
      {/* PC */}
      <div className="visibleMiddleOrMore flexColumn">
        <div className='flexRow justifyContentFlexEnd widthMax marginBottom1' >
          <div className='flexRow ' >
            <button className='button2' onClick={ () => handleClickGoTop(state, dispatch, navigate) }>
              { words.back[state.language] }
            </button>
          </div>
        </div>
        <div className='flexColumn alignItemsCenter borderKOM marginTop1 padding0p5'>
          <div className='widthMax marginTop1 '>
            { words.makeLiveTextUrlForABroadcaster[state.language] }
          </div>
          <div className='flexRow alignItemsCenter marginTopBottom0p5 '>
            <TextLine3 fieldName='makeUrlToBroadcastAddressMain' keys={['makeUrlToBroadcast', 'addressMain']} face={words.addressMain[state.language]} tooltip={true} />
            <GetAddressButton keysToSetAddress={['makeUrlToBroadcast', 'addressMain']} />
          </div>
          <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter '
            onClick={ () => {
              if (walletType === 'mpurse') {
                makeLiveTextUrlForABroadcaster(state, dispatch);
              }
              else if (walletType === 'nfc') {
                const callback = (keyPairs) => {
                  makeLiveTextUrlForABroadcaster(state, dispatch, keyPairs);
                };

                handleClickNfc(state, dispatch, callback);
              }
              else { // qr
                const callback = (keyPairs) => {
                  makeLiveTextUrlForABroadcaster(state, dispatch, keyPairs);
                };

                handleClickScanQr(state, dispatch, callback);
              }
            }}
          >
            { words.makeUrl[state.language] }
          </button>
          <div className='flexRow alignItemsCenter ' >
            <div className='box4 breakAll padding0p5 margin1' >
              { urlForABroadcaster }
            </div>
            <button className='button1' tabindex='0' 
              onClick={ () => {
                navigator.clipboard.writeText( urlForABroadcaster )
                .then()
                .catch();
              }}
            >
              <img className='size2x2' src={iconCopy} alt='' />
            </button>
          </div>
          <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter '
            onClick={ () => {
              if (walletType === 'mpurse') {
                disconnectConnectionForABroadcaster(state, dispatch);
              }
              else if (walletType === 'nfc') {
                const callback = (keyPairs) => {
                  disconnectConnectionForABroadcaster(state, dispatch, keyPairs);
                };

                handleClickNfc(state, dispatch, callback);
              }
              else { // qr
                const callback = (keyPairs) => {
                  disconnectConnectionForABroadcaster(state, dispatch, keyPairs);
                };

                handleClickScanQr(state, dispatch, callback);
              }
            }}
          >
            { words.disconnect[state.language] }
          </button>
        </div>
        <div className='flexColumn alignItemsCenter borderKOM marginTop1 padding0p5'>
          <div className='widthMax marginTop1 '>
            { words.ifYouEncounterContinuousServerErrorsThatPreventYouFromDuelingPleaseLogInHere[state.language] }
          </div>
          <LoginSection mode='clearDuelAndConnection' />
        </div>
        {
          process.env.REACT_APP_ENVIRONMENT === 'dev' ?
          <div className='flexColumn alignItemsCenter borderKOM marginTop1 padding0p5'>
            <div className='flexRow justifyContentSpaceBetween alignItemsCenter widthMax marginTop1 '>
              <div>
                { words.swapMnemonics[state.language] }
              </div>
              <button className='borderNone backgroundColorTransparent cursor borderRadius2 riseOut2 marginSide0p2 ' tabindex='0'
                onClick={ () => handleClickClearAddress(state, dispatch) }
              >
                {words.clear[state.language]}
              </button>
            </div>
            <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter marginTop1 ' tabindex='0'
              onClick={ async () => {
                handleClickSwapMnemonics(state, dispatch);
              }}
            >
              <div>
                { words.display[state.language] }
              </div>
              <div>
                <img className='size2x2' src={iconKeyCard} />
              </div>
            </button>
            <div className='flexColumn alignItemsFlexStart width90PC borderKOM breakAll marginTop1 padding0p5'>
              { state.displaySecret.address }
            </div>
          </div>
          : null
        }
      </div>
      {/* SP */}
      <div className="visibleSmallOrLess flexColumn">
        <div className='flexRow justifyContentFlexEnd widthMax marginBottom1' >
          <button className='button2' onClick={ () => handleClickGoTop(state, dispatch, navigate) }>
            { words.back[state.language] }
          </button>
        </div>
        <div className='flexColumn alignItemsCenter borderKOM marginTop1 padding0p5'>
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter widthMax marginTop1 '>
            <div>
              { words.displayMnemonic[state.language] }
            </div>
            <button className='borderNone backgroundColorTransparent cursor borderRadius2 riseOut2 marginSide0p2 ' tabindex='0'
              onClick={ () => handleClickClearMnemonic(state, dispatch) }
            >
              {words.clear[state.language]}
            </button>
          </div>
          <div className='widthMax colorRed marginTop1 '>
            { words.pleaseBeCarefulOfYourSurroundingsSoThatOthersCannotSee[state.language] }
          </div>
          <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter marginTop1 ' tabindex='0'
            onClick={ async () => {
              const callback = (mnemonics) => handleClickDisplayMnemonic(state, dispatch, mnemonics);
              handleClickNfc(state, dispatch, callback, { args: ['mnemonics'] });
            }}
          >
            <div>
              { words.display[state.language] }
            </div>
            <div>
              <img className='size2x2' src={iconKeyCard} />
            </div>
          </button>
          <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter marginTop1 ' tabindex='0'
            onClick={ async () => {
              const callback = (mnemonics) => handleClickDisplayMnemonic(state, dispatch, mnemonics);
              handleClickScanQr(state, dispatch, callback, { args: ['mnemonics'] });
            }}
          >
            <div>
              { words.display[state.language] }
            </div>
            <div>
              <img className='size2x2' src={iconQr} />
            </div>
          </button>
          <div className='flexColumn alignItemsFlexStart width90PC borderKOM marginTop1 padding0p5'>
            { state.displaySecret.mnemonic }
          </div>
        </div>
        <div className='flexColumn alignItemsCenter borderKOM marginTop1 padding0p5'>
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter widthMax marginTop1 '>
            <div>
              { words.displayWif[state.language] }
            </div>
            <button className='borderNone backgroundColorTransparent cursor borderRadius2 riseOut2 marginSide0p2 ' tabindex='0'
              onClick={ () => handleClickClearWif(state, dispatch) }
            >
              {words.clear[state.language]}
            </button>
          </div>
          <div className='widthMax colorRed marginTop1 '>
            { words.pleaseBeCarefulOfYourSurroundingsSoThatOthersCannotSee[state.language] }
          </div>
          <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter marginTop1 ' tabindex='0'
            onClick={ async () => {
              const callback = (mnemonics, keyPairs) => handleClickDisplayWif(state, dispatch, mnemonics, keyPairs);
              handleClickNfc(state, dispatch, callback, { args: ['mnemonics', 'keyPairs'] });
            }}
          >
            <div>
              { words.display[state.language] }
            </div>
            <div>
              <img className='size2x2' src={iconKeyCard} />
            </div>
          </button>
          <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter marginTop1 ' tabindex='0'
            onClick={ async () => {
              const callback = (mnemonics, keyPairs) => handleClickDisplayWif(state, dispatch, mnemonics, keyPairs);
              handleClickScanQr(state, dispatch, callback, { args: ['mnemonics', 'keyPairs'] });
            }}
          >
            <div>
              { words.display[state.language] }
            </div>
            <div>
              <img className='size2x2' src={iconQr} />
            </div>
          </button>
          <div className='flexColumn alignItemsFlexStart width90PC borderKOM breakAll marginTop1 padding0p5'>
            { state.displaySecret.wif }
          </div>
        </div>
        <div className='flexColumn alignItemsCenter borderKOM marginTop1 padding0p5'>
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter widthMax marginTop1 '>
            <div>
              { words.displayAddress[state.language] }
            </div>
            <button className='borderNone backgroundColorTransparent cursor borderRadius2 riseOut2 marginSide0p2 ' tabindex='0'
              onClick={ () => handleClickClearAddress(state, dispatch) }
            >
              {words.clear[state.language]}
            </button>
          </div>
          <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter marginTop1 ' tabindex='0'
            onClick={ async () => {
              const callback = (mnemonics, keyPairs) => handleClickDisplayAddress(state, dispatch, mnemonics, keyPairs);
              handleClickNfc(state, dispatch, callback, { args: ['mnemonics', 'keyPairs'] });
            }}
          >
            <div>
              { words.display[state.language] }
            </div>
            <div>
              <img className='size2x2' src={iconKeyCard} />
            </div>
          </button>
          <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter marginTop1 ' tabindex='0'
            onClick={ async () => {
              const callback = (mnemonics, keyPairs) => handleClickDisplayAddress(state, dispatch, mnemonics, keyPairs);
              handleClickScanQr(state, dispatch, callback, { args: ['mnemonics', 'keyPairs'] });
            }}
          >
            <div>
              { words.display[state.language] }
            </div>
            <div>
              <img className='size2x2' src={iconQr} />
            </div>
          </button>
          <div className='flexColumn alignItemsFlexStart width90PC borderKOM breakAll marginTop1 padding0p5'>
            { state.displaySecret.address }
          </div>
        </div>
        {
          process.env.REACT_APP_ENVIRONMENT === 'dev' ?
          <div className='flexColumn alignItemsCenter borderKOM marginTop1 padding0p5'>
            <div className='flexRow justifyContentSpaceBetween alignItemsCenter widthMax marginTop1 '>
              <div>
                { words.swapMnemonics[state.language] }
              </div>
              <button className='borderNone backgroundColorTransparent cursor borderRadius2 riseOut2 marginSide0p2 ' tabindex='0'
                onClick={ () => handleClickClearAddress(state, dispatch) }
              >
                {words.clear[state.language]}
              </button>
            </div>
            <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter marginTop1 ' tabindex='0'
              onClick={ async () => {
                handleClickSwapMnemonics(state, dispatch);
              }}
            >
              <div>
                { words.display[state.language] }
              </div>
              <div>
                <img className='size2x2' src={iconKeyCard} />
              </div>
            </button>
            <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter marginTop1 ' tabindex='0'
              onClick={ async () => {
                localStorage.removeItem('swapStatus');
                localStorage.removeItem('oldAddress');
                localStorage.removeItem('oldKeyPair');
                localStorage.removeItem('oldMnemonic');
                localStorage.removeItem('newAddress');
                localStorage.removeItem('newMnemonic');
                localStorage.removeItem('sweepAssetsTxid');
                localStorage.removeItem('sendMonaTxid');
                dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'swapMnemonics'], value: '' }); // 暫定
              }}
            >
              <div>
                { words.display[state.language] }
              </div>
              <div>
                <img className='size2x2' src={iconKeyCard} />
              </div>
            </button>
            <div className='flexColumn alignItemsFlexStart width90PC borderKOM breakAll marginTop1 padding0p5'>
              { state.displaySecret.swapMnemonics }
            </div>
          </div>
          : null
        }
        <div className='flexColumn alignItemsCenter borderKOM marginTop1 padding0p5'>
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter widthMax marginTop1 '>
            <div className='flexColumn alignItemsFlexStart '>
              <div>
                { words.getThePrivateKeyFromTheMnemonic[state.language] }
              </div>
              <div>
                { words.restoringToAnotherWalletFromABackup[state.language] }
              </div>
            </div>
            <button className='borderNone backgroundColorTransparent cursor borderRadius2 riseOut2 marginSide0p2 ' tabindex='0'
              onClick={ () => handleClickClearMnemonicAndWif(state, dispatch) }
            >
              {words.clear[state.language]}
            </button>
          </div>
          <div className='widthMax colorRed marginTop1 '>
            { words.pleaseBeCarefulOfYourSurroundingsSoThatOthersCannotSee[state.language] }
          </div>
          <TextArea2 fieldName='settingWifFromMnemonic' keys={['displaySecret', 'mnemonicToWif']} face={words.mnemonic[state.language]}
            outerClass='widthMax' boxClass='box4 width96PC marginTop1 ' textAreaClass='textArea1'
          />
          <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter marginTop1 ' tabindex='0'
            onClick={ () => {
              handleClickDisplayWifFromMnemonicFilledIn(state, dispatch);
            }}
          >
            <div>
              { words.display[state.language] }
            </div>
          </button>
          <div className='flexColumn alignItemsFlexStart width90PC borderKOM breakAll marginTop1 padding0p5'>
            { state.displaySecret.wifFromMnemonic }
          </div>
        </div>
        <div className='flexColumn alignItemsCenter borderKOM marginTop1 padding0p5'>
          <div className='widthMax marginTop1 '>
            { words.makeLiveTextUrlForABroadcaster[state.language] }
          </div>
          <div className='flexRow alignItemsCenter marginTopBottom0p5 '>
            <TextLine3 fieldName='makeUrlToBroadcastAddressMain' keys={['makeUrlToBroadcast', 'addressMain']} face={words.addressMain[state.language]} tooltip={true} />
            <GetAddressButton keysToSetAddress={['makeUrlToBroadcast', 'addressMain']} />
          </div>
          <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter '
            onClick={ () => {
              if (walletType === 'mpurse') {
                makeLiveTextUrlForABroadcaster(state, dispatch);
              }
              else if (walletType === 'nfc') {
                const callback = (keyPairs) => {
                  makeLiveTextUrlForABroadcaster(state, dispatch, keyPairs);
                };

                handleClickNfc(state, dispatch, callback);
              }
              else { // qr
                const callback = (keyPairs) => {
                  makeLiveTextUrlForABroadcaster(state, dispatch, keyPairs);
                };

                handleClickScanQr(state, dispatch, callback);
              }
            }}
          >
            { words.makeUrl[state.language] }
          </button>
          <div className='box4 width90PC height12 breakAll padding0p5 marginTop1 ' >
            { urlForABroadcaster }
          </div>
          <button className='button1' tabindex='0' 
            onClick={ () => {
              navigator.clipboard.writeText( urlForABroadcaster )
              .then()
              .catch();
            }}
          >
            <img className='size2x2' src={iconCopy} alt='' />
          </button>
          <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter '
            onClick={ () => {
              if (walletType === 'mpurse') {
                disconnectConnectionForABroadcaster(state, dispatch);
              }
              else if (walletType === 'nfc') {
                const callback = (keyPairs) => {
                  disconnectConnectionForABroadcaster(state, dispatch, keyPairs);
                };

                handleClickNfc(state, dispatch, callback);
              }
              else { // qr
                const callback = (keyPairs) => {
                  disconnectConnectionForABroadcaster(state, dispatch, keyPairs);
                };

                handleClickScanQr(state, dispatch, callback);
              }
            }}
          >
            { words.disconnect[state.language] }
          </button>
        </div>
        <div className='flexColumn alignItemsCenter borderKOM marginTop1 padding0p5'>
          <div className='widthMax marginTop1 '>
            { words.ifYouEncounterContinuousServerErrorsThatPreventYouFromDuelingPleaseLogInHere[state.language] }
          </div>
          <LoginSection mode='clearDuelAndConnection' />
        </div>
        {
          process.env.REACT_APP_ENVIRONMENT === 'dev' ?
          <div className='flexColumn alignItemsCenter borderKOM marginTop1 padding0p5'>
            <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter marginTop1 ' tabindex='0'
              onClick={ () => {
                writeMnemonicOnNfc(state, dispatch);
              }}
            >
              <div>
                { words.display[state.language] }
              </div>
            </button>
          </div>
          : null
        }
      </div>
      {/* popup */}
      <Popup layer={0}/>
      <Popup layer={1}/>
    </div>
  );
}

// WEBSOCKET INTERFACE TO BROADCAST
function WebSocketInterfaceToBroadcast(props) {
  const [state, dispatch, navigate] = useContext(GlobalState);

  useEffect( () => {
    // const func = async () => {
    //   let configKeys;
    //   let config;

    //   configKeys = ['clientParameters'];
    //   config = await getConfig(dispatch, 'KnightsOfMonadom', configKeys , 'config');

    //   getRegisteredCard(state, dispatch);
    // };

    // func();

    let chunks = state.duel.chunks;
    let straightManChunks = state.duel.straightManChunks;
    let text = '';

    const websocketClient = new ReconnectingWebSocket('wss://' + process.env.REACT_APP_KOM_WEBSOCKET_DOMAIN);
    dispatch({type: 'setStateMultiLayers', keys: ['webSocketToBroadcastContainer', 'webSocket'], value: websocketClient});

    const requestBody = {
      action: 'connectToBroadcaster',
      subAction: 'connect',
      addressMain: props.addressMain,
      addressOperator: props.addressOperator,
      clientTime: props.clientTime,
      signatureVersion: props.signatureVersion,
      signature: props.signature,
    };

    websocketClient?.send(JSON.stringify(requestBody));


    const onMessage = (event) => {
      let data;
      let mySide;
      let opponentSide;
      let propertyNameMyDeck;
      let propertyNameOpponentDeck;

      if (state.duel.basicInformation?.side === 'A') {
        mySide = 'A';
        opponentSide = 'B';
        propertyNameMyDeck = 'duelistADeck';
        propertyNameOpponentDeck = 'duelistBDeck';
      }
      else if (state.duel.basicInformation?.side === 'B') {
        mySide = 'B';
        opponentSide = 'A';
        propertyNameMyDeck = 'duelistBDeck';
        propertyNameOpponentDeck = 'duelistADeck';
      }

      devLog(event.data);

      try {
        data = JSON.parse(event.data);

        if (data.type === 'duelText') {
          const chunkRecoverd = data.body.content
          .replace(/<-KOM control code : new line->/g, '\n')
          .replace(/<-KOM-field->/, '\n<<フィールドの状況>>\n');

          devLog('chunkRecoverd', chunkRecoverd);

          chunks[data.body.count] = chunkRecoverd;
          dispatch({type: 'setStateMultiLayers', keys: ['duel', 'chunks'], value: chunks});
        }
        else if (data.type === 'duelMessage') {
          if (data.subType === 'waitingInRoom') {
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'basicInformation'], value: data.body});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'timeOutTime'], value: data.body.timeOutTime});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'result', 0], value: data.body.result});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'text'], value: state.config.clientParameters.descriptionAndinstructions});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'status'], value: 'waitingInRoom'});
          }
          else if (data.subType === 'duelStandby') {
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'basicInformation'], value: data.body});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'timeOutTime'], value: data.body.timeOutTime});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'myStatus'], value: 'waitingToSummon'});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'round'], value: 1});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'result', 0], value: data.body.result});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'maginaPoint'], value: data.body.maginaPoint});
            dispatch({
              type: 'setStateMultiLayers',
              keys: ['duel', 'text'],
              value: state.config.clientParameters.descriptionAndinstructions + '\n\nモナーの名の下に！\n'
            });
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'status'], value: 'duelStandby'});
          }
          else if (data.subType === 'summonedMonster') {
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'text'], value: ''});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'status'], value: 'summonedMonster'});
          }
          else if (data.subType === 'battleStandby') {
            text = ''; // 追加処理
            chunks = []; // 追加処理
            straightManChunks = []; // 追加処理
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'text'], value: text}); // 追加処理
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'chunks'], value: chunks}); // 追加処理
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'straightManChunks'], value: straightManChunks}); // 追加処理
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'monstersInformation'], value: data.body.monstersInformation});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'monstersIndex'], value: data.body.monstersIndex});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'round'], value: data.body.round});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'timeOutTime'], value: data.body.timeOutTime});
          }
          else if (data.subType === 'magicPhase') {
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'monstersInformation'], value: data.body.monstersInformation});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'monstersInformationRevised'], value: data.body.monstersInformationRevised});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'magics'], value: data.body.magics});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'maginaPoint'], value: data.body.maginaPoint});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'initiative'], value: data.body.initiative});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'castMagics'], value: []});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'round'], value: data.body.round});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'result', data.body.round], value: data.body.result});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'status'], value: 'magicPhase'});

            // カードオープン
            for (const magic of data.body.magics[opponentSide]) {
              dispatch({type: 'setStateMultiLayers', keys: ['duel', 'basicInformation', propertyNameOpponentDeck, 'cards', magic.index, 'asset'], value: magic.asset});
              dispatch({type: 'setStateMultiLayers', keys: ['duel', 'basicInformation', propertyNameOpponentDeck, 'cards', magic.index, 'visibility'], value: 'open'});

              for (const [index, cost] of magic.costs.entries()) {
                if (state.registeredCard[magic.asset].costs[index].type === 'discardCards') {
                  for (const card of cost.cards) {
                    dispatch({type: 'setStateMultiLayers', keys: ['duel', 'basicInformation', propertyNameOpponentDeck, 'cards', card.index, 'asset'], value: card.asset});
                    dispatch({type: 'setStateMultiLayers', keys: ['duel', 'basicInformation', propertyNameOpponentDeck, 'cards', card.index, 'visibility'], value: 'open'});
                  }
                }
              }
            }

            devLog('monstersInformation', JSON.stringify(data.body.monstersInformation));

            // 魔法ナレーション
            let monsterName = {};

            // -- 同キャラ対決のとき名前に色を付ける。
            if (data.body.monstersInformation.A.asset === data.body.monstersInformation.B.asset) {
              monsterName.A = data.body.monstersInformation.A.name + '(' + sideColor('A') + ')';
              monsterName.B = data.body.monstersInformation.B.name + '(' + sideColor('B') + ')';
            }
            else {
              monsterName.A = data.body.monstersInformation.A.name;
              monsterName.B = data.body.monstersInformation.B.name;
            }

            for (const side of [data.body.initiative, otherSide(data.body.initiative)]) {
              const magic = data.body.magics[side][0];

              if (magic !== undefined) {
                text += monsterName[side] + 'は ' + state.registeredCard[magic.asset].name + ' を発動した。\n';

                if (magic.status === 'canceled') {
                  text += 'しかし打ち消された。\n';
                }
              }
            }

            if (text !== '') {
              text += '\n';
            }

            devLog('text', text);
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'text'], value: text});
          }
          else if (data.subType === 'battleUp') {
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'winner'], value: data.body.winner});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'winningMonster'], value: data.body.winningMonster});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'winningMonsterIndex'], value: data.body.winningMonsterIndex});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'pointA'], value: data.body.pointA});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'pointB'], value: data.body.pointB});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'round'], value: data.body.round});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'result', data.body.round], value: data.body.result});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'maginaPoint'], value: data.body.maginaPoint});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'initiative'], value: data.body.initiative});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'imageNo'], value: data.body.imageNo});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'knightBoxEffect'], value: data.body.knightBoxEffect});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'cardStatusTmp'], value: { A: [], B: [] }});
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'status'], value: 'battleUp'});

            // タイムアウト時刻
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'timeOutTime'], value: data.body.timeOutTime});
            
            if (data.body.status === 'onDuel') {
              if (data.body.winner === state.duel.basicInformation.side) {
                dispatch({type: 'setStateMultiLayers', keys: ['duel', 'myStatus'], value: 'uncomfirmed'});
              } // draw含む。
              else {
                dispatch({type: 'setStateMultiLayers', keys: ['duel', 'myStatus'], value: 'waitingToSummon'});
              }

              dispatch({type: 'setStateMultiLayers', keys: ['duel', 'round'], value: data.body.roundNext});

              // 負けた方はundefined
              dispatch({type: 'setStateMultiLayers', keys: ['duel', 'monsterANextRound'], value: data.body.monsterANextRound});
              dispatch({type: 'setStateMultiLayers', keys: ['duel', 'monsterIndexANextRound'], value: data.body.monsterIndexANextRound});
              dispatch({type: 'setStateMultiLayers', keys: ['duel', 'monsterBNextRound'], value: data.body.monsterBNextRound});
              dispatch({type: 'setStateMultiLayers', keys: ['duel', 'monsterIndexBNextRound'], value: data.body.monsterIndexBNextRound});

              // dispatch({type: 'setStateMultiLayers', keys: ['duel', 'castMagics'], value: []});

              dispatch({type: 'setStateMultiLayers', keys: ['duel', 'status'], value: 'battleUp'});
            }
            else { // duelUp
              dispatch({type: 'setStateMultiLayers', keys: ['duel', 'myStatus'], value: null});

              dispatch({type: 'setStateMultiLayers', keys: ['duel', 'status'], value: 'duelUp'});
            }
          }
          else if (data.subType === 'straightManPhase') {
            straightManChunks[0] = '\n<<つっこみ>>\n';
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'straightManChunks'], value: straightManChunks});
          }
          else {
          }
        }
        else if (data.type === 'straightManText') {
          const chunkRecoverd = data.body.content
          .replace(/<-KOM control code : new line->/g, '\n')

          devLog('chunkRecoverd', chunkRecoverd);

          straightManChunks[data.body.count] = chunkRecoverd;
          dispatch({type: 'setStateMultiLayers', keys: ['duel', 'straightManChunks'], value: straightManChunks});
        }
        // else if (data.type === 'duelRecovery') {
        //   const side = data.body.basicInformation.side;
        //   const roundOnDuel = data.body.roundOnDuel;
        //   const results = data.body.result;

        //   // 基本情報を格納

        //   dispatch({type: 'setStateMultiLayers', keys: ['duel', 'basicInformation'], value: data.body.basicInformation});

        //   // その他のリカバリ情報を格納

        //   dispatch({type: 'setStateMultiLayers', keys: ['duel', 'timeOutTime'], value: data.body.basicInformation.timeOutTime});
        //   dispatch({type: 'setStateMultiLayers', keys: ['duel', 'round'], value: roundOnDuel});
        //   dispatch({type: 'setStateMultiLayers', keys: ['duel', 'maginaPoint'], value: data.body.basicInformation.maginaPoint});

        //   // ユーザーオペレーションによる状態変化を格納

        //   const monstersInformation = {
        //     A: state.registeredCard[data.body.monsterA],
        //     B: state.registeredCard[data.body.monsterB],
        //   };

        //   const monstersIndex = {
        //     A: data.body.monsterIndexA,
        //     B: data.body.monsterIndexB,
        //   };

        //   // 前回バトルの結果を確認して、actionを判定する。
        //   let action;
        //   const winner = results[roundOnDuel - 1].winner;

        //   if (winner === side) {
        //     action = 'skip';
        //   }
        //   else if (winner === otherSide(side)) {
        //     action = 'summon';
        //   }
        //   else {
        //     action = 'summon';
        //   }

        //   dispatch({type: 'setStateMultiLayers', keys: ['duel', 'chunks'], value: []});
        //   dispatch({type: 'setStateMultiLayers', keys: ['duel', 'straightManChunks'], value: []});
        //   dispatch({type: 'setStateMultiLayers', keys: ['duel', 'text'], value: ''});
        //   dispatch({type: 'setStateMultiLayers', keys: ['duel', 'monstersInformation'], value: monstersInformation});
        //   dispatch({type: 'setStateMultiLayers', keys: ['duel', 'monstersIndex'], value: monstersIndex});
        //   dispatch({type: 'setStateMultiLayers', keys: ['duel', 'myStatus'], value: 'comfirmed'});
        //   dispatch({type: 'setStateMultiLayers', keys: ['duel', 'status'], value: 'onBattle'});
        //   dispatch({type: 'setStateMultiLayers', keys: ['duel', 'action'], value: action});
        //   dispatch({type: 'setStateMultiLayers', keys: ['duel', 'winner'], value: undefined});
        //   // dispatch({type: 'setStateMultiLayers', keys: ['duel', 'magics'], value: { [side]: data.body.magics, [otherSide(side)]: [], }});
        //   dispatch({type: 'setStateMultiLayers', keys: ['duel', 'magics'], value: data.body.magics});
        //   dispatch({type: 'setStateMultiLayers', keys: ['duel', 'imageNo'], value: { A: 0, B: 0, }});
        //   dispatch({type: 'setStateMultiLayers', keys: ['duel', 'knightBoxEffect'], value: { A: undefined, B: undefined, }});
        //   dispatch({type: 'setStateMultiLayers', keys: ['animation', 'youWinMona'], value: 'visible'});
        //   dispatch({type: 'setStateMultiLayers', keys: ['duel', 'visible'], value: true});

        //   // 魔法カードオープン・墓地カード反映

        //   const propertyNameOpponentDeck = `duelist${otherSide(side)}Deck`;

        //   for (const result of results) {
        //     if (result.magics !== undefined) {
        //       for (const magic of result.magics[otherSide(side)]) {
        //         dispatch({type: 'setStateMultiLayers', keys: ['duel', 'basicInformation', propertyNameOpponentDeck, 'cards', magic.index, 'asset'], value: magic.asset});
        //         dispatch({type: 'setStateMultiLayers', keys: ['duel', 'basicInformation', propertyNameOpponentDeck, 'cards', magic.index, 'visibility'], value: 'open'});

        //         for (const [index, cost] of magic.costs.entries()) {
        //           if (state.registeredCard[magic.asset].costs[index].type === 'discardCards') {
        //             for (const card of cost.cards) {
        //               dispatch({type: 'setStateMultiLayers', keys: ['duel', 'basicInformation', propertyNameOpponentDeck, 'cards', card.index, 'asset'], value: card.asset});
        //               dispatch({type: 'setStateMultiLayers', keys: ['duel', 'basicInformation', propertyNameOpponentDeck, 'cards', card.index, 'visibility'], value: 'open'});
        //             }
        //           }
        //         }
        //       }
        //     }
        //   }

        //   // resultを配列で格納

        //   dispatch({type: 'setStateMultiLayers', keys: ['duel', 'result'], value: results});

        //   if (data.subType === 'summonedMonster') {
        //     dispatch({type: 'setStateMultiLayers', keys: ['duel', 'pointA'], value: data.body.basicInformation.pointA});
        //     dispatch({type: 'setStateMultiLayers', keys: ['duel', 'pointB'], value: data.body.basicInformation.pointB});
        //   }
        //   else { // battleUpOrDuelUp
        //     // 魔法反映

        //     // dispatch({type: 'setStateMultiLayers', keys: ['duel', 'monstersInformation'], value: results[roundOnDuel].monstersInformation});
        //     dispatch({type: 'setStateMultiLayers', keys: ['duel', 'monstersInformationRevised'], value: results[roundOnDuel].monstersInformationRevised});
        //     // dispatch({type: 'setStateMultiLayers', keys: ['duel', 'magics'], value: data.body.magics});
        //     dispatch({type: 'setStateMultiLayers', keys: ['duel', 'initiative'], value: data.body.basicInformation.initiative});
        //     dispatch({type: 'setStateMultiLayers', keys: ['duel', 'castMagics'], value: []});

        //     // 魔法ナレーション

        //     let text = '';
        //     let monsterName = {};

        //     // -- 同キャラ対決のとき名前に色を付ける。
        //     if (data.body.monsterA === data.body.monsterB) {
        //       monsterName.A = state.registeredCard[data.body.monsterA].name + '(' + sideColor('A') + ')';
        //       monsterName.B = state.registeredCard[data.body.monsterB].name + '(' + sideColor('B') + ')';
        //     }
        //     else {
        //       monsterName.A = state.registeredCard[data.body.monsterA].name;
        //       monsterName.B = state.registeredCard[data.body.monsterB].name;
        //     }

        //     let duelistInOrder;
        //     const initiative = results[roundOnDuel - 1].initiativeNext;

        //     if (initiative === 'A' || initiative === 'B') {
        //       duelistInOrder = [initiative, otherSide(initiative)];
        //     }
        //     else { // coinToss, undefined
        //       duelistInOrder = ['A', 'B'];
        //     }

        //     for (const side of duelistInOrder) {
        //       const magic = data.body.magics[side][0];

        //       if (magic !== undefined) {
        //         text += monsterName[side] + 'は ' + state.registeredCard[magic.asset].name + ' を発動した。\n';
        //         // text += state.duel.basicInformation.duelistUserName[side] + 'は ' + state.registeredCard[magic.asset].name + ' を発動した。\n';

        //         if (magic.status === 'canceled') {
        //           text += 'しかし打ち消された。\n';
        //         }
        //       }
        //     }

        //     // ライブテキスト

        //     if (text !== '') {
        //       text += '\n';
        //     }

        //     text += results[roundOnDuel].liveText;

        //     // フィールドの状況

        //     text += '\n<<フィールドの状況>>\n';
        //     text += results[roundOnDuel].field;

        //     dispatch({type: 'setStateMultiLayers', keys: ['duel', 'text'], value: text});
        //   }
        // }
        else if (data.type === 'systemMessage') {
          if (data.body.status === 'closedTheRoom') {
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'text'], value: ''}); // 追加処理
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'chunks'], value: []}); // 追加処理
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'straightManChunks'], value: []}); // 追加処理
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'status'], value: data.body.reason}); // timeUp, timeUpAfterDuelUp, leave, leaveAfterDuelUp
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'myStatus'], value: 'end'});

            if (data.body.reason === 'timeUp' || data.body.reason === 'leave') {
              dispatch({type: 'setStateMultiLayers', keys: ['duel', 'winner'], value: data.body.winner});
              dispatch({type: 'setStateMultiLayers', keys: ['duel', 'duelWinner'], value: data.body.duelWinner});
            }
            else if (data.body.reason === 'timeUpWithoutOpponentComming') {
              // handleClickEndDuel(state, dispatch, navigate); // ←　戻っちゃダメ！
            }
            else {
            }
          }
          else if (data.body.status === 'gptError') {
            // gptError または irregularPattern によるリトライのため、デュエルテキストをリセットする。
            chunks = []; // 追加処理
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'chunks'], value: chunks});
            // dispatch({type: 'setStateMultiLayers', keys: ['duel', 'straightManChunks'], value: []});
          }
          else if (data.body.status === 'gptErrorInStraightMan') {
            // gptError または irregularPattern によるリトライのため、デュエルテキストをリセットする。
            straightManChunks = []; // 追加処理
            dispatch({type: 'setStateMultiLayers', keys: ['duel', 'straightManChunks'], value: straightManChunks});
          }
          else if (data.body.status === 'transactWriteSummonRejected') {
            // // おそらくコンフリクトしているので、リトライする。
            // if (state.duel.action === 'summon') {
            //   const requestBody = {
            //     action: 'summon',
            //     addressMain: state.active.addressMain,
            //     roomId: state.duel.basicInformation.roomId,
            //     roomSubId: state.duel.basicInformation.roomSubId,
            //     summonedMonster: state.duel.monstersInformation[state.duel.basicInformation.side].asset, 
            //     summonedMonsterIndex: state.duel.monstersIndex[state.duel.basicInformation.side],
            //     castMagics: state.duel.castMagics,
            //   };

            //   handleClickSummonMonster(state, dispatch, requestBody);
            // }
            // else if (state.duel.action === 'skip') {
            //   const message = {
            //     action: 'skip',
            //     addressMain: state.active.addressMain,
            //     roomId: state.duel.basicInformation.roomId,
            //     roomSubId: state.duel.basicInformation.roomSubId,
            //     castMagics: state.duel.castMagics,
            //   };

            //   handleClickNext(state, dispatch, message);
            // }
          }
        }
        else { // undefined エラーメッセージ
          const messages = {
            "internal server error": words.internalServerError[state.language],
            "this address is not permitted.": words.thisAddressIsNotPermitted[state.language],
            "this address is forbidden.": words.thisAddressIsForbidden[state.language],
            "out of service": words.outOfService[state.language],
            "user does not exist.": words.pleaseRegisterAsAUserOnTheSetupPage[state.language],
            "invalid session.": words.pleaseLogin[state.language],
            "not enough points.": words.notEnoughPoints[state.language],
            "valid points do not exist.": words.notEnoughPoints[state.language],
            [`the number of cards in a deck must be between 3 and ${state.config.clientParameters.numberOfSlots.simple}.`]: words.theNumberOfCardsInADeckMustBeBetween3And7[state.language],
            [`the number of cards in a deck must be between 3 and ${state.config.clientParameters.numberOfSlots.advanced}.`]: words.theNumberOfCardsInADeckMustBeBetween3And11[state.language],
            "you can not use magic cards in simple mode.": words.youCanNotUseMagicCardsInSimpleMode[state.language],
            'gameMode or difficultyMode is unmatched.': words.youArePlayingAnotherDuelWhoseGameModeIsDifferent[state.language],
          };

          if (data.applicationMessage !== undefined && messages[data.applicationMessage] !== undefined) {
            dispatch({ type: 'setNotification', key: 'notification', value: messages[data.applicationMessage] });
          }
        }
      }
      catch (error) {
        // エラー通知
        devLog('irregular WebSocket data.', error);
      }
    };

    websocketClient.addEventListener('message', onMessage);


    const onClose = (event) => {
      // // 通知
      // sendLog({
      //   action: 'notifyWithSession',
      //   addressMain: state.active.addressMain,
      //   sessionId: state.session[state.active.addressMain].sessionId,
      //   message: `WebSocket closed; ${JSON.stringify(event)}`,
      // });

      devLog('WebSocket closed by server.', JSON.stringify(event));
      handleClickEndDuel(state, dispatch, navigate);
    };

    websocketClient.addEventListener('close', onClose);


    const onError = (event) => {
      // // 通知
      // sendLog({
      //   action: 'notifyWithSession',
      //   addressMain: state.active.addressMain,
      //   sessionId: state.session[state.active.addressMain].sessionId,
      //   message: `WebSocket error; ${JSON.stringify(event)}`,
      // });

      devLog('WebSocket error.', JSON.stringify(event));
      handleClickEndDuel(state, dispatch, navigate);
    };

    websocketClient.addEventListener('close', onError);


    const beforeUnload = () => {
      websocketClient?.close();
      devLog('closed WebSocket.');
    }

    window.addEventListener('beforeunload', beforeUnload, true);


    return () => {
      try {
        websocketClient?.close();
        devLog('WebSocket closed');
      }
      catch (error) {
        devLog('WebSocket may already be closed.', JSON.stringify(error));
      }

      dispatch({type: 'setStateMultiLayers', keys: ['webSocketContainer', 'webSocket'], value: undefined});

      window.removeEventListener('beforeunload', beforeUnload, true);
    };
  }, []);


  return (
    <div>
    </div>
  );
}

// SELECT SESSION POPUP
function SelectSessionPopup(props) {
  const [state, dispatch] = useContext(GlobalState);
  const popupLayer = props.popupLayer;
  const keysArray = state.popup[popupLayer].body.keysArray;
  const qr = state.popup[popupLayer].body.qr;
  const sessionAddresses = state.popup[popupLayer].body.sessionAddresses;

  // let sessionAddresses = Object.keys(state.session);

  // sessionAddresses = sessionAddresses.filter( address =>
  //   state.session[address].expirationOfSession >= Date.now() && state.balance.monaparty[address] !== undefined
  // );

  let qrButtonDummy;

  if (qr === true) {
    qrButtonDummy =
    <button className='dummyPadButton1 backgroundColorMonacottoAlmostWhite ' disabled={true} >
    </button>
  }

  const title =
  <div className='widthMax'>
    {/* PC */}
    <div className='visibleMiddleOrMore boxMonacotto1 margin0p5' >
      <div className='marginSide1 widthMin25' >
        { words.loggedInAddress[state.language] }
      </div>
      <button className='dummyPadButton1 backgroundColorMonacottoAlmostWhite ' disabled={true} >
      </button>
      { qrButtonDummy }
    </div>
    {/* SP */}
    <div className='visibleSmallOrLess boxMonacotto1SmallScreen alignItemsCenter' >
      <div className='padding0p5 textCenter' >
        { words.loggedInAddress[state.language] }
      </div>
      {/* 
        <button className='dummyPadButton1 backgroundColorMonacottoAlmostWhite ' disabled={true} >
        </button>
      */}
      {/* qrButtonDummy */}
    </div>
  </div>

  const records = sessionAddresses.map( address => {
    let qrButton;

    const selectButton =
    <button className='button1' tabindex='0'
      onClick={ () => {
        for (const keys of keysArray) {
          dispatch({ type: 'setStateMultiLayers', keys: keys, value: address });
        }

        dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayer], value: { type: null, body: null } });
      }}
    >
      <img className='size2x2' src={iconCheck} alt='' />
    </button>

    if (qr === true) {
      qrButton =
      <button className='button1' tabindex='0'
        onClick={ () => {
          const popup = {
            type: 'displayQrPopup',
            body: {
              data: address,
            },
          };

          dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayer + 1], value: popup });
        }}
      >
        <img className='size2x2' src={iconQr} alt='' />
      </button>
    }

    // アクティブカラー

    let activeColor = '';

    if (state.active.addressMain === address) {
      activeColor = ' backgroundColorPinkPale';
    }

    return (
      <div className=''>
        {/* PC */}
        <div className={'visibleMiddleOrMore boxMonacotto1 margin0p5' + activeColor} >
          <div className='marginSide1 widthMin25' >
            { address }
          </div>
          { selectButton }
          { qrButton }
        </div>
        {/* SP */}
        <div className={'visibleSmallOrLess boxMonacotto1SmallScreen alignItemsCenter' + activeColor} >
          <div className='textCenter' >
            { address }
          </div>
          <div className='flexRow justifyContentCenter alignItemsCenter ' >
            { selectButton }
            { qrButton }
          </div>
        </div>
      </div>
    );
  });


  return (
    <div className=''>
      {/* PC */}
      <div className='visibleMiddleOrMore flexColumn alignItemsCenter'>
        <div className='flexColumn alignItemsCenter'>
          { title }
        </div>
        <div className='flexColumn alignItemsCenter'>
          { records }
        </div>
      </div>
      {/* SP */}
      <div className='visibleSmallOrLess flexColumn widthMax'>
        <div className='flexColumn alignItemsCenter widthMax'>
          { title }
        </div>
        <div className='flexColumn alignItemsCenter widthMax'>
          { records }
        </div>
      </div>
    </div>
  );
}

// DISPLAY QR POPUP
function DisplayQrPopup(props) {
  const [state] = useContext(GlobalState);
  const popupLayer = props.popupLayer;
  const data = state.popup[popupLayer].body.data;

  return (
    <div className='flexColumn alignItemsCenter '>
      <QRCodeSVG value={data} />
    </div>
  );
}


// POPUP
function Popup(props) {
  const [state, dispatch] = useContext(GlobalState);

  const popup = state.popup[props.layer];

  const popupComponent = {
    generalList: <GeneralList popupLayer={props.layer} />,
    generalItems: <GeneralItems popupLayer={props.layer} />,
    pointRecordDetail: <PointRecordDetail popupLayer={props.layer} />,
    monsterDetail: <MonsterDetail popupLayer={props.layer} />,
    duelHistoryDetail: <DuelHistoryDetail popupLayer={props.layer} />,
    // selectActionBox: <SelectActionBox popupLayer={props.layer} />,
    itemDetailSP: <ItemDetailSP popupLayer={props.layer} />,
    // itemDetailMore: <ItemDetailMore popupLayer={props.layer} />,
    deckOnDuelSP: <DeckOnDuelSP popupLayer={props.layer} />,
    deckInHistorySP: <DeckInHistorySP popupLayer={props.layer} />,
    creatorInformation: <CreatorInformation popupLayer={props.layer} />,
    itemOrderHistoryRecordDetail: <ItemOrderHistoryRecordDetail popupLayer={props.layer} />,
    merchandiseDetail: <MerchandiseDetail popupLayer={props.layer} />,
    challengeDuelPopup: <ChallengeDuelPopup popupLayer={props.layer} />,
    selectSessionPopup: <SelectSessionPopup popupLayer={props.layer} />,
    displayQrPopup: <DisplayQrPopup popupLayer={props.layer} />,
    userRegistrationPopup: <UserRegistrationPopup popupLayer={props.layer} />,
  };

  if (state.popup[props.layer]?.type === undefined || state.popup[props.layer].type === null) {
    return (
      <div className='invisible' />
    );
  }
  else {
    return (
      <div>
        {/* PC */}
        <div className="visibleMiddleOrMore">
          <div className={'popupBackgroundMultiLayer' + (popup.extendedClassesBackGround !== undefined ? ' ' + popup.extendedClassesBackGround : '')} style={{ '--popupLayer': props.layer }}>
            <button className='closePopupMultiLayer' style={{ '--popupLayer': props.layer }}
              onClick={ () => dispatch({ type: 'setStateMultiLayers', keys: ['popup', props.layer], value: { type: null, body: null } }) }
            />
            <div className='popupMultiLayer' style={{ '--popupLayer': props.layer }}>
              <button className='topRightClose font2 focusEffect01' tabindex='0'
                onClick={ () => dispatch({ type: 'setStateMultiLayers', keys: ['popup', props.layer], value: { type: null, body: null } }) } 
              >
                ×
              </button>
              {popupComponent[state.popup[props.layer].type]}
            </div>
          </div>
        </div>
        {/* SP */}
        <div className="visibleSmallOrLess">
          <div className='popupBackgroundMultiLayer' style={{ '--popupLayer': props.layer }}>
            <button className='closePopupMultiLayer' style={{ '--popupLayer': props.layer }}
              onClick={ () => dispatch({ type: 'setStateMultiLayers', keys: ['popup', props.layer], value: { type: null, body: null } }) }
            />
            <div className='popupMultiLayerSmallScreen ' style={{ '--popupLayer': props.layer }}>
              <button className='topRightClose font2 focusEffect01' tabindex='0'
                onClick={ () => dispatch({ type: 'setStateMultiLayers', keys: ['popup', props.layer], value: { type: null, body: null } }) } 
              >
                ×
              </button>
              {popupComponent[state.popup[props.layer].type]}
            </div>
          </div>
        </div>
      </div>
    );
  }
  // else {
  //   return (
  //     <div className='' >
  //     </div>
  //   );
  // }
}


// GENERAL LIST
function GeneralList(props) {
  const [state, dispatch] = useContext(GlobalState);
  const body = state.popup[props.popupLayer].body;

  return (
    <div className=''>
      {/* PC */}
      <div className='visibleMiddleOrMore flexColumn alignItemsCenter'>
        <div className='flexColumn alignItemsCenter'>
          { body.title }
        </div>
        <div className='flexColumn alignItemsCenter'>
          { body.options }
        </div>
      </div>
      {/* SP */}
      <div className='visibleSmallOrLess flexColumn widthMax'>
        <div className='flexColumn alignItemsCenter widthMax'>
          { body.title }
        </div>
        <div className='flexColumn alignItemsCenter widthMax'>
          { body.options }
        </div>
      </div>
    </div>
  );
}

// GENERAL ITEMS
function GeneralItems(props) {
  const [state, dispatch] = useContext(GlobalState);
  const body = state.popup[props.popupLayer].body;

  return (
    <div className='flexColumn alignItemsCenter'>
      { body }
    </div>
  );
}

// GENERAL OPTION
function GeneralOption(props) {
  const [state, dispatch] = useContext(GlobalState);
  const record = props.record;
  const recordKey = props.recordKey;
  const sortedKeys = props.sortedKeys;
  const stateKeys = props.stateKeys;
  const layer = props.layer;
  let value;

  if (Object.keys(record).length === 1) {
    if (Object.values(record)[0].value !== undefined) {
      value = Object.values(record)[0].value;
    }
    else {
      value = Object.keys(record)[0];
    }
  }
  else {
    if (record[recordKey].value !== undefined) {
      value = record[recordKey].value;
    }
    else {
      value = recordKey;
    }
  }

  const factors = sortedKeys.map( key => {
    if (record[key].face !== undefined) {
      return <div className={record[key].style} >
        { record[key].face }
      </div>;
    }
    else {
      return <div className={record[key].style} >
        { key }
      </div>;
    }
  });


  return (
    <div className=''>
      {/* PC */}
      <button className='visibleMiddleOrMore boxMonacotto1 margin0p5 cursor riseOut2'
        onClick={ () => {
          dispatch({ type: 'setStateMultiLayers', keys: stateKeys, value: value });
          dispatch({ type: 'setStateMultiLayers', keys: ['popup', layer], value: { type: null, body: null } });
        }} 
      >
        { factors }
      </button>
      {/* SP */}
      <button className='visibleSmallOrLess boxMonacotto1SmallScreen cursor riseOut2'
        onClick={ () => {
          dispatch({ type: 'setStateMultiLayers', keys: stateKeys, value: value });
          dispatch({ type: 'setStateMultiLayers', keys: ['popup', layer], value: { type: null, body: null } });
        }} 
      >
        { factors }
      </button>
    </div>
  );
}

// FOR DEVELOPMENT
function ForDevelopment() {
  const [state] = useContext(GlobalState);

  return (
    <div className='invisibleSp' >
        <hr/>
        <div className='widthVW'>
          {'<<active>> ' + JSON.stringify(state.active)}<br/>
          {'<<session>> ' + JSON.stringify(state.session)}<br/>
          {'<<duel>> ' + JSON.stringify(state.duel)}<br/>
          {'<<configure>> ' + JSON.stringify(state.configure)}<br/>
          {'<<deckSet>> ' + JSON.stringify(state.deckSet)}<br/>
          {'<<balance>> ' + JSON.stringify(state.balance)}<br/>
          {'<<assetInfo>> ' + JSON.stringify(state.assetInfo)}<br/>
          {'<<monacard>> ' + JSON.stringify(state.monacard)}<br/>
          {'<<config>> ' + JSON.stringify(state.config)}<br/>
          {'<<configMP>> ' + JSON.stringify(state.configMP)}<br/>
          {'<<popup>> ' + JSON.stringify(state.popup)}<br/>
        </div>
    </div>
  );
}


// TEXT LINE MULTI LAYERS STATE ON PRESS KEY
function TextLine3(props) {
  const [state, dispatch] = useContext(GlobalState);
  const type = props.type !== undefined ? props.type : 'setStateMultiLayers';

  let value = props.keys.reduce( (acc, cur) => { return acc[cur]; }, state );
  if (type === 'setStateMultiLayersFloat') {
    value = value.face;
  }

  let boxClass;

  if (props.boxClass !== undefined) {
    boxClass = props.boxClass;
  }
  else {
    boxClass = 'box1 widthWithButton1NarrowSp';
  }     

  let textLineClass;

  if (props.textLineClass !== undefined) {
    textLineClass = props.textLineClass;
  }
  else {
    textLineClass = 'textLine1';
  }     

  let tooltipClass;

  if (props.tooltip === 'left') {
    tooltipClass = 'tooltipLeft'
  }
  else if (props.tooltip === false) {
    tooltipClass = 'invisible'
  }
  else if (props.tooltip !== undefined) {
    tooltipClass = 'tooltipRight'
  }
  else {
    tooltipClass = 'invisible'
  }

  let disabled;

  if (props.disabled) {
    disabled = true;
  }
  else {
    disabled = false;
  }

  return (
    <div>
            <input className='invisibleCheckBox' id={'UVCB_' + props.fieldName + 'Background'} type='checkbox' />
            <div className={boxClass}
              onMouseEnter={ () => {
                if (props.tooltip !== undefined && value !== undefined && value !== null && value !== '') {
                  document.getElementById('UVCB_' + props.fieldName + 'Tooltip').checked=true; 
                }
              }}
              onMouseLeave={ () => {
                if (props.tooltip !== undefined) {
                  document.getElementById('UVCB_' + props.fieldName + 'Tooltip').checked=false; 
                }
              }}
            >
              <input className={textLineClass} type="text" value={value} placeholder='' disabled={disabled}
                onFocus={ () => {
                  document.getElementById('UVCB_' + props.fieldName + 'Background').checked=true; 
                  document.getElementById('UVCB_' + props.fieldName + 'Label').checked=true; 

                  if (props.tooltip !== undefined) {
                    document.getElementById('UVCB_' + props.fieldName + 'Tooltip').checked=false; 
                  }
                }}
                onBlur={ () => {
                  document.getElementById('UVCB_' + props.fieldName + 'Background').checked=false; 
                  document.getElementById('UVCB_' + props.fieldName + 'Label').checked=false; 

                  if (type === 'setStateMultiLayersFloat') {
                    dispatch({type: 'adjustFace', keys: props.keys});
                  }
                }}
                onChange={ (e) => {
                  let targetValueAdjusted = e.target.value;

                  if (type === 'setStateMultiLayersFloat') {
                    targetValueAdjusted = adjustFloat(props.adjustType, e.target.value, props.adjustExp);
                  }

                  dispatch({type: type, keys: props.keys, value: e.target.value, adjustType: props.adjustType, adjustExp: props.adjustExp});

                  if (props.extraDispatch !== undefined) {
                    for (const aDispatch of props.extraDispatch) {
                      dispatch({type: aDispatch.type, keys: aDispatch.keys, value: e.target.value});
                    }
                  }

                  if (props.extraFunctionOnChange !== undefined) {
                    for (const aFunction of props.extraFunctionOnChange) {
                      let argumentsRevised = aFunction.arguments;

                      if (aFunction.targetValue !== undefined) {
                        argumentsRevised.splice(aFunction.targetValue === true ? argumentsRevised.length : aFunction.targetValue, 0, targetValueAdjusted);
                      }

                      devLog('argumentsRevised', argumentsRevised);
                      aFunction.function(...argumentsRevised);
                    }
                  }

                  if (props.tooltip !== undefined) {
                    document.getElementById('UVCB_' + props.fieldName + 'Tooltip').checked=false; 
                  }
                }}
                onKeyPress={ (e) => {
                  if (e.which === props.keyNo) {
                    if (props.onKeyPressFunction !== undefined) {
                      props.onKeyPressFunction();
                    }
                  }
                }}
              />
              <input className='invisibleCheckBox' id={'UVCB_' + props.fieldName + 'Label'} type='checkbox' />
              <div className={ value === undefined || value === '' || value === null ? 'label1Unfilled' : 'label1Filled'}>
                {props.face}
              </div>
              <input className='invisibleCheckBox' id={'UVCB_' + props.fieldName + 'Tooltip'} type='checkbox' />
              <div className={tooltipClass} >
                {value}
              </div>
            </div>
    </div>
  );
}

// TEXT AREA MULTI LAYERS STATE
function TextArea2(props) {
  const [state, dispatch] = useContext(GlobalState);
  const value = props.keys.reduce( (acc, cur) => { return acc[cur]; }, state );
  const type = props.type !== undefined ? props.type : 'setStateMultiLayers';

  return (
    <div className={'flexColumn alignItemsCenter ' + props.outerClass}>
            <input className='invisibleCheckBox' id={'UVCB_' + props.fieldName + 'Background'} type='checkbox' />
            <div className={props.boxClass}>
              <textarea className={props.textAreaClass} value={value}
                onFocus={ () => {
                  document.getElementById('UVCB_' + props.fieldName + 'Background').checked=true; 
                  document.getElementById('UVCB_' + props.fieldName + 'Label').checked=true; 
                }}
                onBlur={ () => {
                  document.getElementById('UVCB_' + props.fieldName + 'Background').checked=false; 
                  document.getElementById('UVCB_' + props.fieldName + 'Label').checked=false; 
                }}
                onChange={ (e) => {
                  dispatch({type: type, keys: props.keys, value: e.target.value});
                }}
              />
              <input className='invisibleCheckBox' id={'UVCB_' + props.fieldName + 'Label'} type='checkbox' />
              <div className={ value === '' ? 'label1Unfilled' : 'label1Filled'}>
                {props.face}
              </div>
            </div>
    </div>
  );
}

// NOTIFICATION FADING OUT
function NotificationFadingOut() {
  const [state, dispatch] = useContext(GlobalState);

  useEffect( () => {
    return () => {
      dispatch({ type: 'stopNotificationAnimation', key: 'notification' });
    };
  }, []);

  return (
    <CSSTransition in={state.notification.inAnimation} timeout={5000} classNames="messageFade"
      onEntered={ () => {
                    dispatch({ type: 'stopNotificationAnimation', key: 'notification' });
                  }
               }
    >
      <div className={state.notification.inAnimation ? 'maxWidth98vw' : 'invisible'} >
          { state.notification.body[state.notification.index] }
      </div>
    </CSSTransition>
  );
}

// NOTIFICATION PERSISTENT
function NotificationPersistent() {
  const [state] = useContext(GlobalState);
  let notification;

  if (state.notificationPersistent.visible) {
    notification =
    <div className='breakAll preWrap borderKOM2pxBorder padding0p5 ' >
      { state.notificationPersistent.message }
    </div>
  }


  return notification;
}

// ROLLING MAGIC
function RollingMagic() {
  const [state] = useContext(GlobalState);

  let magic;

  if (state.accessing) {
    magic = <div className='magicRollingCenter' />;
  }
  else {
    magic = null;
  }

  return (
    <div>
      { magic }
    </div>
  );
}

// ABOUT KNIGHTS OF MONADOM
function AboutKnightsOfMonadom() {
  const [state, dispatch, navigate] = useContext(GlobalState);
  const location = useLocation();

  // const duelHistoryExample = [
  //   {
  //     roomId: 'MUBqe6g86L3X8cgjstAiEKFPdFdS6RDcnB',
  //     duelNo: 651,
  //     round: 2,
  //   },
  //   {
  //     roomId: 'M9x3hWi8FTvQ5osmxShvDqUukDRX2WfLZH',
  //     duelNo: 1,
  //     round: 2,
  //   },
  //   {
  //     roomId: 'MUBqe6g86L3X8cgjstAiEKFPdFdS6RDcnB',
  //     duelNo: 647,
  //     round: 2,
  //   },
  // ];

  let duelHistoryExampleBlock;
  let creatorsCardExample;

  if (state.config.clientParameters.examples !== undefined) {
    const duelHistoryExample = state.config.clientParameters.examples.duelHistoryExample;

    const duelHistoryQueryParameter01 = `?roomid=${duelHistoryExample[0].roomId}&duelno=${duelHistoryExample[0].duelNo}&round=${duelHistoryExample[0].round}`;
    const duelHistoryQueryParameter02 = `?roomid=${duelHistoryExample[1].roomId}&duelno=${duelHistoryExample[1].duelNo}&round=${duelHistoryExample[1].round}`;
    const duelHistoryQueryParameter03 = `?roomid=${duelHistoryExample[2].roomId}&duelno=${duelHistoryExample[2].duelNo}&round=${duelHistoryExample[2].round}`;

    duelHistoryExampleBlock =
    <div className='flexRow justifyContentCenter alignItemsCenter '>
      <button className='button1'
        onClick={ () => {
          navigate(
            (process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/duelhistorydetail' : '/duelhistorydetail') + duelHistoryQueryParameter01,
            { state: { previousLocation: 'aboutknightsofmonadom' } }
          );
        }}
      >
        <img className='size2x2' src={iconReplay} />
      </button>
      <button className='button1'
        onClick={ () => {
          navigate(
            (process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/duelhistorydetail' : '/duelhistorydetail') + duelHistoryQueryParameter02,
            { state: { previousLocation: 'aboutknightsofmonadom' } }
          );
        }}
      >
        <img className='size2x2' src={iconReplay} />
      </button>
      <button className='button1'
        onClick={ () => {
          navigate(
            (process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/duelhistorydetail' : '/duelhistorydetail') + duelHistoryQueryParameter03,
            { state: { previousLocation: 'aboutknightsofmonadom' } }
          );
        }}
      >
        <img className='size2x2' src={iconReplay} />
      </button>
    </div>

    // const creatorsCardExample = {
    //   asset: 'A12153265217308668697',
    // };

    creatorsCardExample = state.config.clientParameters.examples.creatorsCardExample[0];
  }

  return (
    <div>
      {/* PC */}
      <div className='visibleMiddleOrMore flexColumn alignItemsCenter' >
        <div className='flexRow justifyContentFlexEnd widthMax marginBottom1' >
          <button className='button2' onClick={ () => handleClickGoBack(state, dispatch, navigate, location) }>
            { words.back[state.language] }
          </button>
        </div>
        <div className='flexRow justifyContentCenter '>
          <div className='flexColumn justifyContentFlexStart alignItemsFlexEnd width48vw padding2'>
            <img className='cardImageAbout' src={imageEmilicaBT} />
            <div className='cardImageAbout flexColumn justifyContentCenter alignItemsFlexStart '>
              <div className='font1p5 marginTopBottom1' >
                モナダム対応モナカードを集めてデッキを組もう。<br/>
                {/* モナトカポイントをチャージして準備完了。 */}
              </div>
              <button className='backgroundColorTransparent borderNone riseOut2 flexColumn justifyContentCenter alignItemsCenter cursor'
                onClick={ () => {
                  handleClickPurchaseItem(state, dispatch, navigate, { previousLocation: 'aboutknightsofmonadom' });
                }}
              >
                <div className='marginTopBottom1' >
                  モナダム対応モナカード、モナトカポイントはこちらで購入できます。
                </div>
              </button>
            </div>
            <img className='cardImageAbout' src={imageHalcionBT} />
            <div className='cardImageAbout flexColumn justifyContentCenter alignItemsCenter '>
              <div className='font1p5 marginTopBottom1' >
                遊び方はカンタン。<br/>
                ナイトカードを1枚選択して召喚すれば、<br/>
                ナイトのプロフィールやフィールドの状況に沿ってAIがバトルを展開！
              </div>
            </div>
            <img className='cardImageAbout' src={imageOmennoshoujoBT} />
            <div className='cardImageAbout flexColumn justifyContentCenter alignItemsCenter '>
              <div className='font1p5 marginTopBottom1' >
                バトルの様子を見てみよう！
              </div>
              { duelHistoryExampleBlock }
              <button className='backgroundColorTransparent borderNone riseOut2 flexColumn justifyContentCenter alignItemsCenter cursor'
                onClick={ () => {
                  handleClickGetDuelHistoryAll(state, dispatch, 0, { action: 'duelHistoryAll' });
                  dispatch({type: 'setStateMultiLayers', keys: ['getDuelHistory', 'action'], value: 'duelHistoryAll'});
                  handleClickDuelHistory(state, dispatch, navigate, { previousLocation: 'aboutknightsofmonadom' });
                }}
              >
                <div className='font1p5 marginTopBottom1' >
                  ここからもっとたくさんのバトルが見れるよ。
                </div>
              </button>
            </div>
            <img className='cardImageAbout' src={imageAnkokunomaouLuciferBT} />
            <div className='cardImageAbout flexColumn justifyContentCenter alignItemsCenter '>
              <div className='font1p5 marginTopBottom1' >
                バトルストーリーを楽しむのは、モナダムの大きな醍醐味のひとつ。<br/>
                面白いストーリーができたら、みんなにシェアしよう！<br/>
                <div className='flexRow justifyContentFlexStart alignItemsCenter '>
                  <div className=''>
                    (デュエル戦歴画面のシェアボタン
                  </div>
                    <img className='button1Dummy size2x2' src={iconShare} />
                  <div className=''>
                    を押してね。)
                  </div>
                </div>
              </div>
            </div>
            <img className='cardImageAbout' src={imageKurumichanBT} />
          </div>
          <div className='flexColumn justifyContentFlexStart alignItemsFlexStart width48vw padding2'>
            <div className='height15 flexColumn justifyContentCenter alignItemsCenter'>
              <div className='font2 marginTopBottom1' >
                Knights of Monadomとは<br/>
              </div>
              <div className='marginTopBottom1' >
                ※愛称、モナダム
              </div>
            </div>
            <img className='cardImageAbout' src={imageSayokoBT} />
            <div className='cardImageAbout flexColumn justifyContentCenter alignItemsCenter '>
              <div className='font1p5 marginTopBottom1' >
                対コンピュータ戦、対人戦を選んでデュエルを始めよう。
              </div>
            </div>
            <img className='cardImageAbout' src={imageNarumiyakunBT} />
            <div className='cardImageAbout flexColumn justifyContentCenter alignItemsCenter '>
              <div className='font1p5 marginTopBottom1' >
                魔法を使えば戦略の幅も広がります。
              </div>
              <div className='marginTopBottom1' >
                <img className='width10' src={imageMagicRed} />
              </div>
            </div>
            <img className='cardImageAbout' src={imageDensetsunoshoujoBT} />
            <div className='flexColumn justifyContentCenter alignItemsCenter '>
              <div className='font1p5 marginTopBottom1' >
                自分でモナダム対応カードをつくることもできます。<br/>
                {/* 発行、販売も自由！ */}
              </div>
              <button className='backgroundColorTransparent borderKOMFatBorder marginTopBottom1 riseOut2 cursor'
                onClick={ () => {
                  if (creatorsCardExample !== undefined && Object.keys(state.registeredCard).length >= 1) {
                    const body = {
                      card: {
                        asset: creatorsCardExample.asset, 
                      },
                    };

                    const popup = { type: 'monsterDetail', body: body };
                    dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: popup });
                  }
                }}
              >
                <img className='width50' src={imageCardDetailHarukaMizugiVersion} />
              </button>
            </div>
            <img className='cardImageAbout' src={imageNzorichterBT} />
            <div className='cardImageAbout flexColumn justifyContentCenter alignItemsCenter '>
              <div className='font1p5 marginTopBottom1' >
                {`現在発行、登録されているカードは約${150}種類！`}
              </div>
              <div className='marginTopBottom1' >
                ※公式オリジナルカード、公式レプリカカード、一般クリエイターカードすべて含みます。
              </div>
              <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter '
                onClick={ () => handleClickCardList(state, dispatch, navigate, { previousLocation: 'aboutknightsofmonadom' }) }
              >
                { words.cardList[state.language] }
              </button>
            </div>
            <img className='cardImageAbout' src={imageToshinousanBT} />
          </div>
        </div>
        <div className='flexRow justifyContentCenter alignItemsCenter '>
          <img className='lpMainImage' src={imageMonatoka} />
          <div className='flexColumn margin1 ' >
            <div className='flexRow margin1'>
              <div className='width10'>
                会社名
              </div>
              <div className=''>
                株式会社モナトカ
              </div>
            </div>
            <div className='flexRow margin1'>
              <div className='width10'>
                代表取締役
              </div>
              <div className=''>
                渡邉潤平
              </div>
            </div>
            <div className='flexRow margin1'>
              <div className='width10'>
                所在地
              </div>
              <div className=''>
                〒113-0033 東京都文京区本郷3–38–1本郷信徳ビル7階 HashHub内
              </div>
            </div>
            <div className='flexRow margin1'>
              <div className='width10'>
                設立
              </div>
              <div className=''>
                2022年2月1日
              </div>
            </div>
            <div className='flexRow margin1'>
              <div className='width10'>
                資本金
              </div>
              <div className=''>
                5,000,000円
              </div>
            </div>
            <div className='flexRow margin1'>
              <div className='width10'>
                事業内容
              </div>
              <div className=''>
                オンラインゲームの提供 等
              </div>
            </div>
            <div className='flexRow margin1'>
              <div className='width10'>
                顧問税理士
              </div>
              <div className=''>
                税理士法人　堀口会計
              </div>
            </div>
            <div className='flexRow margin1'>
              <div className='width10'>
                お問合せ先
              </div>
              <div className=''>
                contact@monatoka.com
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* SP */}
      <div className='visibleSmallOrLess flexColumn alignItemsCenter width98vw padding1'>
        <div className='flexRow justifyContentFlexEnd widthMax marginBottom1' >
          <button className='button2' onClick={ () => handleClickGoBack(state, dispatch, navigate, location) }>
            { words.back[state.language] }
          </button>
        </div>
        <img className='cardImageAboutSP' src={imageEmilicaBT} />
        <div className='height15 flexColumn justifyContentCenter alignItemsCenter'>
          <div className='font2 marginTopBottom1' >
            Knights of Monadomとは<br/>
          </div>
          <div className='marginTopBottom1' >
            ※愛称、モナダム
          </div>
        </div>
        <img className='cardImageAboutSP' src={imageSayokoBT} />
        <div className='cardImageAboutSP flexColumn justifyContentCenter alignItemsFlexStart '>
          <div className='font1p5 marginTopBottom1' >
            モナダム対応モナカードを集めてデッキを組もう。<br/>
            モナトカポイントをチャージして準備完了。
          </div>
          <button className='backgroundColorTransparent borderNone riseOut2 flexColumn justifyContentCenter alignItemsCenter marginBottom1'
            onClick={ () => {
              handleClickPurchaseItem(state, dispatch, navigate, { previousLocation: 'aboutknightsofmonadom' });
            }}
          >
            <div className='marginTopBottom1' >
              モナダム対応モナカード、モナトカポイントはこちらで購入できます。
            </div>
          </button>
        </div>
        <img className='cardImageAboutSP' src={imageHalcionBT} />
        <div className='cardImageAboutSP flexColumn justifyContentCenter alignItemsCenter '>
          <div className='font1p5 marginTopBottom1' >
            対コンピュータ戦、対人戦を選んでデュエルを始めよう。
          </div>
        </div>
        <img className='cardImageAboutSP' src={imageNarumiyakunBT} />
        <div className='cardImageAboutSP flexColumn justifyContentCenter alignItemsCenter '>
          <div className='font1p5 marginTopBottom1' >
            遊び方はカンタン。<br/>
            ナイトカードを1枚選択して召喚すれば、<br/>
            ナイトのプロフィールやフィールドの状況に沿ってAIがバトルを展開！
          </div>
        </div>
        <img className='cardImageAboutSP' src={imageOmennoshoujoBT} />
        <div className='cardImageAboutSP flexColumn justifyContentCenter alignItemsCenter '>
          <div className='font1p5 marginTopBottom1' >
            魔法を使えば戦略の幅も広がります。
          </div>
          <div className='marginTopBottom1' >
            <img className='width10' src={imageMagicRed} />
          </div>
        </div>
        <img className='cardImageAboutSP' src={imageDensetsunoshoujoBT} />
        <div className='cardImageAboutSP flexColumn justifyContentCenter alignItemsCenter '>
          <div className='font1p5 marginTopBottom1' >
            バトルの様子を見てみよう！
          </div>
          { duelHistoryExampleBlock }
          <button className='backgroundColorTransparent borderNone riseOut2 flexColumn justifyContentCenter alignItemsCenter marginBottom1'
            onClick={ () => {
              handleClickGetDuelHistoryAll(state, dispatch, 0, { action: 'duelHistoryAll' });
              dispatch({type: 'setStateMultiLayers', keys: ['getDuelHistory', 'action'], value: 'duelHistoryAll'});
              handleClickDuelHistory(state, dispatch, navigate, { previousLocation: 'aboutknightsofmonadom' });
            }}
          >
            <div className='font1p5 marginTopBottom1' >
              ここからもっとたくさんのバトルが見れるよ。
            </div>
          </button>
        </div>
        <img className='cardImageAboutSP' src={imageAnkokunomaouLuciferBT} />
        <div className='flexColumn justifyContentCenter alignItemsCenter '>
          <div className='font1p5 marginTopBottom1' >
            自分でモナダム対応カードをつくることもできます。<br/>
            {/* 発行、販売も自由！ */}
          </div>
          <button className='backgroundColorTransparent borderKOMFatBorder marginTopBottom1 riseOut2'
            onClick={ () => {
              if (creatorsCardExample !== undefined && Object.keys(state.registeredCard).length >= 1) {
                const body = {
                  card: {
                    asset: creatorsCardExample.asset, 
                  },
                  actionBox: 'close',
                };

                const popup = { type: 'itemDetailSP', body: body };
                dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: popup });
              }
            }}
          >
            <img className='width90vw' src={imageCardDetailHarukaMizugiVersion} />
          </button>
        </div>
        <img className='cardImageAboutSP' src={imageNzorichterBT} />
        <div className='cardImageAboutSP flexColumn justifyContentCenter alignItemsCenter '>
          <div className='font1p5 marginTopBottom1' >
            バトルストーリーを楽しむのは、モナダムの大きな醍醐味のひとつ。<br/>
            面白いストーリーができたら、みんなにシェアしよう！<br/>
            <div className='flexRow justifyContentFlexStart alignItemsCenter '>
              <div className=''>
                (デュエル戦歴画面のシェアボタン
              </div>
                <img className='button1Dummy size2x2' src={iconShare} />
              <div className=''>
                を押してね。)
              </div>
            </div>
          </div>
        </div>
        <img className='cardImageAboutSP' src={imageKurumichanBT} />
        <div className='cardImageAboutSP flexColumn justifyContentCenter alignItemsCenter '>
          <div className='font1p5 marginTopBottom1' >
            {`現在発行、登録されているカードは約${150}種類！`}
          </div>
          <div className='marginTopBottom1' >
            ※公式オリジナルカード、公式レプリカカード、一般クリエイターカードすべて含みます。
          </div>
          <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter '
            onClick={ () => handleClickCardList(state, dispatch, navigate, { previousLocation: 'aboutknightsofmonadom' }) }
          >
            { words.cardList[state.language] }
          </button>
        </div>
        <img className='cardImageAboutSP' src={imageToshinousanBT} />
        <img className='cardImageAboutSP' src={imageMonatoka} />
        <div className='flexColumn alignItemsFlexStart marginTopBottom1'>
          <div className='flexRow margin1'>
            <div className='width8'>
              会社名
            </div>
            <div className=''>
              株式会社モナトカ
            </div>
          </div>
          <div className='flexRow margin1'>
            <div className='width8'>
              代表取締役
            </div>
            <div className=''>
              渡邉潤平
            </div>
          </div>
          <div className='flexRow margin1'>
            <div className='width8'>
              所在地
            </div>
            <div className=''>
              〒113-0033 東京都文京区本郷3–38–1本郷信徳ビル7階 HashHub内
            </div>
          </div>
          <div className='flexRow margin1'>
            <div className='width8'>
              設立
            </div>
            <div className=''>
              2022年2月1日
            </div>
          </div>
          <div className='flexRow margin1'>
            <div className='width8'>
              資本金
            </div>
            <div className=''>
              5,000,000円
            </div>
          </div>
          <div className='flexRow margin1'>
            <div className='width8'>
              事業内容
            </div>
            <div className=''>
              オンラインゲームの提供 等
            </div>
          </div>
          <div className='flexRow margin1'>
            <div className='width8'>
              顧問税理士
            </div>
            <div className=''>
              税理士法人　堀口会計
            </div>
          </div>
          <div className='flexRow margin1'>
            <div className='width8'>
              お問合せ先
            </div>
            <div className=''>
              contact@monatoka.com
            </div>
          </div>
        </div>
      </div>
      {/* popup */}
      <Popup layer={0}/>
      <Popup layer={1}/>
      <Popup layer={2}/>
    </div>
  );
}

// RETAILER
function Retailer() {
  const [state, dispatch] = useContext(GlobalState);

  useEffect( () => {
    dispatch({ type: 'setState', key: 'session', value: {} });
  }, []);

  const preFunction = () => {
    dispatch({ type: 'setState', key: 'session', value: {} });
  };

  let activation = <Activation />;
  let activationList = <ActivationList />;

  // if (state.activationList.activatee.length >= 1) {
  //   activation = <Activation />;
  // }


  return (
    <div className='flexColumn alignItemsCenter widthMax ' >
      <div className='margin1 borderKOM ' >
        <WalletSelectSection />
        <div className='padding0p5 ' >
          <LoginSection preFunction={preFunction} postFunction={getActivationList} />
        </div>
      </div>
      <div className='padding0p5 margin1 borderKOM ' >
        { activation }
      </div>
      <div className='' >
        { activationList }
      </div>
      {/* popup */}
      <Popup layer={0}/>
      <Popup layer={1}/>
    </div>
  );
}

// ACTIVATION
function Activation() {
  const [state, dispatch] = useContext(GlobalState);

  return (
    <div>
      <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter marginBottom1 '
        onClick={ () => {
          const callback = (addressMainEncrypted) => {
            dispatch({ type: 'setStateMultiLayers', keys: ['activation', 'addressMainEncrypted'], value: addressMainEncrypted });
          };

          handleClickScanQr(state, dispatch, callback, { callbackType: 'simpelCallback' });
        }}
      >
        <div>
          { words.scan[state.language] }
        </div>
        <img className='size2x2' src={iconQr} alt='' />
      </button>
      <TextLine3 fieldName='activationAddressMainEncrypted' keys={['activation', 'addressMainEncrypted']} face={words.activationCode[state.language]} tooltip='true' />
      <button className='buttonMainColor widthMin20 heightMin4 flexRow justifyContentCenter alignItemsCenter marginTop1 '
        onClick={ () => {
          handleClickActivateMonatokaPoint(state, dispatch);
        }}
      >
        <div>
          { words.activate[state.language] }
        </div>
      </button>
    </div>
  );
}

// ACTIVATION LIST
function ActivationList() {
  const [state, dispatch] = useContext(GlobalState);
  const location = useLocation();
  let historyItems;
  let historyFunction;
  let arrowLeft;
  let arrowRight;

  // アクションごとのhistoryItemsとhistoryFunction設定。

  if (state.activationList.action === 'activationHistoryAll') {
    if (state.activationList.activationHistoryAll?.keyCard !== undefined) {
      historyItems = state.activationList.activationHistoryAll.keyCard.map( record => {
        if (true) {
          return <ActivationRecord record={record} />
        }
        else {
          return null;
        }
      });

      historyItems.unshift(<ActivationRecordTitle />);
    }
    else {
      historyItems = [<ActivationRecordTitle />];
    }

    historyFunction = (pagingIndex) => handleClickGetActivationHistory(state, dispatch, pagingIndex);
  }

  if (state.activationList.action === 'activationHistoryAddressMainEncrypted') {
    if (state.activationList.activationHistoryAddressMainEncrypted?.keyCard !== undefined) {
      historyItems = state.activationList.activationHistoryAddressMainEncrypted.keyCard.map( record => {
        if (true) {
          return <ActivationRecord record={record} />
        }
        else {
          return null;
        }
      });

      historyItems.unshift(<ActivationRecordTitle />);
    }
    else {
      historyItems = [<ActivationRecordTitle />];
    }

    historyFunction = (pagingIndex) => handleClickGetActivationHistory(state, dispatch, pagingIndex);
  }

  // 矢印

  if ( state.activationList.pagingIndex[state.activationList.action] >= 1 ) {
    arrowLeft = <div>
      <button className='flexRow justifyContentCenter alignItemsCenter size2x2Large backgroundColorTransparent borderNone cursor borderRadiusCircle riseOut2 '
        onClick={ () => historyFunction(state.activationList.pagingIndex[state.activationList.action] - 1) }
      >
        <img className='size2x2' src={iconCircleLeft} alt='' />
      </button>
    </div>
  }
  else {
    arrowLeft = <div>
      <button className='size2x2Large backgroundColorTransparent borderNone' disabled={true} >
        <div className='size2x2' />
      </button>
    </div>
  }

  if ( (state.activationList.lastEvaluatedKey[state.activationList.action][state.activationList.pagingIndex[state.activationList.action]] !== undefined &&
        state.activationList.lastEvaluatedKey[state.activationList.action][state.activationList.pagingIndex[state.activationList.action]] !== null        )
       // || (state.activationList.lastEvaluatedKey2[state.activationList.action]?.[state.activationList.pagingIndex[state.activationList.action]] !== undefined &&
       //  state.activationList.lastEvaluatedKey2[state.activationList.action]?.[state.activationList.pagingIndex[state.activationList.action]] !== null        )
  ) {
    arrowRight = <div>
      <button className='flexRow justifyContentCenter alignItemsCenter size2x2Large backgroundColorTransparent borderNone cursor borderRadiusCircle riseOut2 '
        onClick={ () => { historyFunction(state.activationList.pagingIndex[state.activationList.action] + 1) } }
      >
        <img className='size2x2' src={iconCircleRight} alt='' />
      </button>
    </div>
  }
  else {
    arrowRight = <div>
      <button className='size2x2Large backgroundColorTransparent borderNone' disabled={true} >
        <div className='size2x2' />
      </button>
    </div>
  }

  // ページングパラメータリセット関数

  const resetPagingParameters = () => {
    dispatch({ type: 'setStateMultiLayers', keys: ['activationList', 'pagingIndex'],
      value: { activationHistoryAll: 0, activationHistoryAddressMainEncrypted: 0 }
    });

    dispatch({ type: 'setStateMultiLayers', keys: ['activationList', 'lastEvaluatedKey'],
      value: { activationHistoryAll: [], activationHistoryAddressMainEncrypted: [] }
    });

    // dispatch({ type: 'setStateMultiLayers', keys: ['activationList', 'lastEvaluatedKey2'],
    //   value: { activationHistoryAll: [], activationHistoryAddressMainEncrypted: [] }
    // });
  };


  return (
    <div>
      {/* PC */}
      <div className="visibleMiddleOrMore">
        <div className='flexColumn'>
          {/* history */}
          <div className='flexRow justifyContentFlexStart alignItemsCenter '>
            {/* action */}
            <div className='flexRow justifyContentSpaceAround marginSide0p5 '>
              <button className={'box3 focusEffect01 riseOut2 ' + (state.activationList.action === 'activationHistoryAll' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
                onClick={ () => {
                          dispatch({type: 'setStateMultiLayers', keys: ['activationList', 'action'], value: 'activationHistoryAll'});
                }}>
                {words.all[state.language]}
              </button>
            </div>
            <div className='flexRow justifyContentSpaceAround marginSide0p5 '>
              <button className={'box3 focusEffect01 riseOut2 ' + (state.activationList.action === 'activationHistoryAddressMainEncrypted' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
                onClick={ () => {
                          dispatch({type: 'setStateMultiLayers', keys: ['activationList', 'action'], value: 'activationHistoryAddressMainEncrypted'});
                }}>
                {words.single[state.language]}
              </button>
            </div>
            <button className='button1' tabindex='0' onClick={ () => historyFunction(0) } >
              <img className='size2x2' src={iconSearch} alt='' />
            </button>
            { arrowLeft }
            { arrowRight }
          </div>
          { historyItems }
          {/* development */}
          <div>
            { process.env.REACT_APP_ENVIRONMENT === 'dev' ? <ForDevelopment /> : null }
          </div>
        </div>
      </div>
      {/* SP */}
      <div className="visibleSmallOrLess">
        <div className='flexColumn'>
          {/* control */}
          {/* action */}
          <div className='flexRow justifyContentFlexStart alignItemsCenter '>
            <div className='flexRow justifyContentFlexStart '>
              <button className={'box3 focusEffect01 riseOut2 ' + (state.activationList.action === 'activationHistoryAll' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
                onClick={ () => {
                          dispatch({type: 'setStateMultiLayers', keys: ['activationList', 'action'], value: 'activationHistoryAll'});
                }}>
                {words.all[state.language]}
              </button>
            </div>
            <div className='flexRow justifyContentFlexStart '>
              <button className={'box3 focusEffect01 riseOut2 ' + (state.activationList.action === 'activationHistoryAddressMainEncrypted' ? 'colorRed borderSelected' : 'borderNone')} tabindex='0'
                onClick={ () => {
                          dispatch({type: 'setStateMultiLayers', keys: ['activationList', 'action'], value: 'activationHistoryAddressMainEncrypted'});
                }}>
                {words.single[state.language]}
              </button>
            </div>
            <div className='flexColumn justifyContentCenter alignItemsFlexStart '>
              <div className='flexRow justifyContentFlexStart alignItemsCenter '>
                <button className='button1' tabindex='0' onClick={ () => historyFunction(0) } >
                  <img className='size2x2' src={iconSearch} alt='' />
                </button>
              </div>
            </div>
          </div>
          <div className='flexRow justifyContentFlexStart alignItemsCenter margin1 '>
            { arrowLeft }
            { arrowRight }
          </div>
          { historyItems }
        </div>
      </div>
      {/* popup */}
      <Popup layer={0}/>
      <Popup layer={1}/>
      <Popup layer={2}/>
    </div>
  );
}

// ACTIVATION RECORD TITLE
function ActivationRecordTitle() {
  const [state, dispatch] = useContext(GlobalState);

  return (
    <div>
      {/* PC */}
      <div className="visibleLargeOrMore">
        <div className='boxHistory flexRow justifyContentSpaceBetween alignItemsCenter textCenter'  >
          <div className='marginSide1 widthMin40' >
            { words.activationCode[state.language] }
          </div>
          <div className='marginSide1 widthMin12' >
            { words.status[state.language] }
          </div>
          <div className='marginSide1 widthMin12' >
            { words.activationTime[state.language] }
          </div>
          <div className='marginSide1 widthMin12' >
            { words.registrationTime[state.language] }
          </div>
          {/*
            <button className='dummyPadButton1 backgroundColorMonacottoAlmostWhite' disabled={true} />
          */}
        </div>
      </div>
      {/* SP */}
    </div>
  );
}

// ACTIVATION RECORD
function ActivationRecord(props) {
  const [state, dispatch, navigate] = useContext(GlobalState);
  const record = props.record;
  // let buttons;
  // let popup;

  // popup = { type: 'duelHistoryDetail', body: record };
  // buttons =
  // <button className='button1'
  //   onClick={ () => {
  //     dispatch({ type: 'setStateMultiLayers', keys: ['getDuelHistory', 'round'], value: 0 });
  //     // dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: popup });
  //     dispatch({ type: 'setStateMultiLayers', keys: ['popup', 100], value: popup });
  //     navigate(
  //       process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/duelhistorydetail' : '/duelhistorydetail',
  //       { state: { previousLocation: 'duelhistory' } }
  //     )
  //   }}
  // >
  //   <img className='size2x2' src={iconDetail} />
  // </button>;


  return (
    <div>
      {/* PC */}
      <div className="visibleLargeOrMore">
        <div className='boxHistory flexRow justifyContentSpaceBetween alignItemsCenter textCenter'  >
          <div className='marginSide1 widthMin40' >
            { record.addressMainEncrypted }
          </div>
          <div className='marginSide1 widthMin12' >
            { words[record.status][state.language] }
          </div>
          <div className='marginSide1 widthMin12' >
            { localTime(record.activateTime, 'yearToSecond') }
          </div>
          <div className='marginSide1 widthMin12' >
            { localTime(record.registrationTime, 'yearToSecond') }
          </div>
          {/* buttons */}
        </div>
      </div>
      {/* SP */}
      <div className="visibleMiddleOrLess">
        <div className='boxMonacotto1SmallScreen flexColumn alignItemsFlexStart '  >
          {/*
            <div className='flexRow justifyContentFlexEnd alignItemsCenter width98PC'>
              { buttons }
            </div>
          */}
          <div className='flexColumn alignItemsFlexStart width98PC'>
            <div className='' >
              { words.activationCode[state.language] }
            </div>
            <div className='paddingLeft1 breakAll ' >
              { record.addressMainEncrypted }
            </div>
          </div>
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter width98PC'>
            <div className='' >
              { words.status[state.language] }
            </div>
            <div className='' >
              { words[record.status][state.language] }
            </div>
          </div>
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter width98PC'>
            <div className='' >
              { words.activationTime[state.language] }
            </div>
            <div className='' >
              { localTime(record.activateTime, 'yearToSecond') }
            </div>
          </div>
          <div className='flexRow justifyContentSpaceBetween alignItemsCenter width98PC'>
            <div className='' >
              { words.registrationTime[state.language] }
            </div>
            <div className='' >
              { localTime(record.registrationTime, 'yearToSecond') }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// LIVE TEXT
function LiveText() {
  const [state, dispatch] = useContext(GlobalState);
  const urlParams = (new URL(document.location)).searchParams;
  const addressMain = urlParams.get('addressmain');
  const addressOperator = urlParams.get('addressoperator');
  const clientTime = urlParams.get('clienttime');
  const signatureVersion = urlParams.get('signatureversion');
  const signature = decodeURIComponent(urlParams.get('signature'));

  if (state.config.clientParameters.descriptionAndinstructions === undefined || Object.keys(state.registeredCard).length === 0) {
    return null;
  }


  return (
    <div className='' >
      <WebSocketInterfaceToBroadcast
        addressMain={addressMain} addressOperator={addressOperator} clientTime={clientTime} signatureVersion={signatureVersion} signature={signature}
      />
      <DuelText />
    </div>
  );
}

// GET ADDRESS BUTTON
function GetAddressButton(props) {
  const [state, dispatch] = useContext(GlobalState);
  const keysToSetAddress = props.keysToSetAddress;
  const extraFunctionOnFulillment = props.extraFunctionOnFulillment;
  let getAddressButton;

  if (state.walletType === 'mpurse') {
    getAddressButton =
    <button className='button1'
      onClick={ async () => {
        const result = await handleClickMpurse(state, dispatch, keysToSetAddress);
        devLog('handleClickMpurse; result', JSON.stringify(result));

        if (result.status === 'fulfilled') {
          if (extraFunctionOnFulillment !== undefined) {
            extraFunctionOnFulillment();
          }
        }
        else {
          dispatch({ type: 'setNotification', key: 'notification', value: words.failedToGetTheAddress[state.language] });
        }
      }}
    >
      <img className='size2x2' src={iconMpurse} alt='' />
    </button>;
  }
  else if (state.walletType === 'nfc') {
    getAddressButton =
    <button className='button2 flexRow justifyContentCenter alignItemsCenter '
      onClick={ () => {
        const callback = (keyPairs) => {
          const result = getAddressFromKey(state, dispatch, keyPairs[0]);
          devLog('touch NFC to get address; result', JSON.stringify(result));

          if (result.status === 'fulfilled') {
            dispatch({ type: 'setStateMultiLayers', keys: keysToSetAddress, value: result.address });
            extraFunctionOnFulillment();
          }
          else {
            dispatch({ type: 'setNotification', key: 'notification', value: words.failedToGetTheAddress[state.language] });
          }
        };

        handleClickNfc(state, dispatch, callback);
      }}
    >
      <div>
        { words.address[state.language] }
      </div>
      <div>
        <img className='size2x2' src={iconKeyCard} alt='' />
      </div>
    </button>;
  }
  else { // qr
    getAddressButton =
    <button className='button2 flexRow justifyContentCenter alignItemsCenter '
      onClick={ () => {
        const callback = (keyPairs) => {
          const result = getAddressFromKey(state, dispatch, keyPairs[0]);
          devLog('touch NFC to get address; result', JSON.stringify(result));

          if (result.status === 'fulfilled') {
            dispatch({ type: 'setStateMultiLayers', keys: keysToSetAddress, value: result.address });
            extraFunctionOnFulillment();
          }
          else {
            dispatch({ type: 'setNotification', key: 'notification', value: words.failedToGetTheAddress[state.language] });
          }
        };

        handleClickScanQr(state, dispatch, callback);
      }}
    >
      <div>
        { words.address[state.language] }
      </div>
      <div>
        <img className='size2x2' src={iconQr} alt='' />
      </div>
    </button>;
  }


  return getAddressButton;
}


// ---------------------------

// HANDLE CLICK MPURSE
async function handleClickMpurse(state, dispatch, stateKeys, popupLayer) {
  const popupLayerNext = popupLayer !== undefined && popupLayer !== null ? (popupLayer + 1) : 0;
  devLog('handleClickMpurse', JSON.stringify(stateKeys));

  const callback = (keyPairs) => {
    const keyPair = keyPairs[0];
    const result = getAddressFromKey(state, dispatch, keyPair);

    if (result.status === 'fulfilled') {
      const address= result.address;
      dispatch({ type: 'setStateMultiLayers', keys: stateKeys, value: address });
    }
    else {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToGetTheAddress[state.language] });
      return { status: 'rejected'};
    }

    // keepKeyPairsInMemory(state, dispatch, keyPairs);
  };

  if (state.walletType === 'mpurse') {
    if (window.mpurse) {
      const result = window.mpurse.getAddress()
      .then( (result) => {
        dispatch({ type: 'setStateMultiLayers', keys: stateKeys, value: result });

        return {
          status: 'fulfilled',
          body: result,
        };
      })
      .catch( (error) => {
        dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressInYourWalletCanNotBeGotten[state.language] });

        return {
          status: 'rejected',
        };
      });

      return result;
    }
    else {
      dispatch({ type: 'setNotification', key: 'notification', value: words.activateMpurse[state.language] });

      return {
        status: 'rejected',
      };
    }
  }
  else if (state.walletType === 'nfc') {
    dispatch({ type: 'setNotification', key: 'notification', value: words.touchWithYourKeyCard[state.language] });
    handleClickNfc(state, dispatch, callback);
  }
  else { // qr
    dispatch({ type: 'setNotification', key: 'notification', value: words.scanYour2DBarcode[state.language] });
    handleClickScanQr(state, dispatch, callback, { popupLayer: popupLayerNext });
  }
}

// 「ユーザー登録」ボタン押下 HANDLE CLICK SETUP
async function handleClickSetup(state, dispatch, keyPairs) {
  let addressMainActual;
  let message;
  let signature;
  let request = {};
  let messages = {};
  let result;
  let keyPair;

  // state固定
  const stateFixed = {
    addressMain: state.configure.addressMain,
    userName: state.configure.userName,
    // profileText: state.configure.profileText,
    // imageMain: state.configure.imagesNew.main,
    acceptedVersionOfTheTermsAndConditions: state.configure.acceptedVersionOfTheTermsAndConditions,
    acceptedVersionOfPrivacyPolicy: state.configure.acceptedVersionOfPrivacyPolicy,
    signatureVersion: state.config.clientParameters.signatureVersion.configure,
    // addressCheck: state.addressCheck,
    walletType: state.walletType,
    addressCheck: 'strict',
  };

  // if (keyPairs !== undefined && keyPairs !== null) {
  //   keyPair = keyPairs[0];
  //   const result = getAddressFromKey(state, dispatch, keyPair);

  //   if (result.status === 'fulfilled') {
  //     stateFixed.addressMain = result.address;
  //   }
  //   else {
  //     dispatch({ type: 'setNotification', key: 'notification', value: words.failedToGetTheAddress[state.language] });
  //     return 'rejected';
  //   }
  // }

  // 必須項目チェック

  // if (stateFixed.addressMain === undefined || stateFixed.addressMain === null || stateFixed.addressMain === '') {
  //   dispatch({ type: 'setNotification', key: 'notification', value: words.mainAddressMustBeFilled[state.language] });
  //   return;
  // }

  if (stateFixed.userName === undefined || stateFixed.userName === null || stateFixed.userName === '') {
    dispatch({ type: 'setNotification', key: 'notification', value: words.userNameMustBeFilled[state.language] });
    return;
  }

  if (stateFixed.acceptedVersionOfTheTermsAndConditions === undefined ||
      stateFixed.acceptedVersionOfTheTermsAndConditions === null ||
      stateFixed.acceptedVersionOfTheTermsAndConditions === '') {
    dispatch({ type: 'setNotification', key: 'notification', value: words.acceptTheTermsAndConditions[state.language] });
    return;
  }

  if (stateFixed.acceptedVersionOfPrivacyPolicy === undefined ||
      stateFixed.acceptedVersionOfPrivacyPolicy === null ||
      stateFixed.acceptedVersionOfPrivacyPolicy === '') {
    dispatch({ type: 'setNotification', key: 'notification', value: words.acceptThePrivacyPolicy[state.language] });
    return;
  }

  const keyPairsInPool = getValidKeyPairs(state, dispatch);

  const clientTime = Date.now();

  if (
    stateFixed.addressMain !== undefined && stateFixed.addressMain !== null && stateFixed.addressMain !== '' &&
    keyPairsInPool[stateFixed.addressMain]?.keyPair !== undefined
  ) {
    addressMainActual = stateFixed.addressMain;

    message = `I want to play Knights of Monadom. My main address is ${stateFixed.addressMain}. My nickname is ${stateFixed.userName}. I accept the terms and conditions version ${stateFixed.acceptedVersionOfTheTermsAndConditions} and privacy policy version ${stateFixed.acceptedVersionOfPrivacyPolicy}. (epoch time: ${clientTime}, signature version: ${stateFixed.signatureVersion})`;
    devLog('message', message);

    result = signWithKey(state, dispatch, keyPairsInPool[stateFixed.addressMain].keyPair, message);

    if (result.status === 'fulfilled') {
      signature = result.signature;
    }
    else {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSign[state.language] });
      return 'rejected';
    }
  }
  else if (keyPairs !== undefined && keyPairs !== null) {
    keyPair = keyPairs[0];
    result = getAddressFromKey(state, dispatch, keyPair);

    if (result.status === 'fulfilled') {
      addressMainActual = result.address;
    }
    else {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToGetTheAddress[state.language] });
      return 'rejected';
    }

    if (stateFixed.addressMain !== undefined && stateFixed.addressMain !== null && stateFixed.addressMain !== '') {
      if (!checkIfAddressIsMatchedWithAnAddress(state, dispatch, addressMainActual, stateFixed.addressMain, stateFixed.addressCheck)) {
        return { status: 'rejected' };
      }
    }
    else {
      stateFixed.addressMain = addressMainActual;
    }

    message = `I want to play Knights of Monadom. My main address is ${stateFixed.addressMain}. My nickname is ${stateFixed.userName}. I accept the terms and conditions version ${stateFixed.acceptedVersionOfTheTermsAndConditions} and privacy policy version ${stateFixed.acceptedVersionOfPrivacyPolicy}. (epoch time: ${clientTime}, signature version: ${stateFixed.signatureVersion})`;
    devLog('message', message);

    result = signWithKey(state, dispatch, keyPair, message);

    if (result.status === 'fulfilled') {
      signature = result.signature;
    }
    else {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSign[state.language] });
      return 'rejected';
    }
  }
  else if (stateFixed.walletType === 'mpurse') {
    // Mpurseのアドレスが合ってるかチェック
    result = await window.mpurse.getAddress()
    .then( (result) => {
      addressMainActual = result;

      if (stateFixed.addressMain !== undefined && stateFixed.addressMain !== null && stateFixed.addressMain !== '') {
        if (!checkIfAddressIsMatchedWithAnAddress(state, dispatch, addressMainActual, stateFixed.addressMain, stateFixed.addressCheck)) {
          return { status: 'rejected' };
        }
      }
      else {
        stateFixed.addressMain = addressMainActual;
      }

      message = `I want to play Knights of Monadom. My main address is ${stateFixed.addressMain}. My nickname is ${stateFixed.userName}. I accept the terms and conditions version ${stateFixed.acceptedVersionOfTheTermsAndConditions} and privacy policy version ${stateFixed.acceptedVersionOfPrivacyPolicy}. (epoch time: ${clientTime}, signature version: ${stateFixed.signatureVersion})`;
      devLog('message', message);

      // if (stateFixed.addressCheck === 'strict' || stateFixed.addressCheck === 'addressSecond') {
      //   if (addressMainActual !== stateFixed.addressMain) {
      //     dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressFilledAndTheAddressInWalletAreDifferent[state.language] });
      //     return 'rejected';
      //   }
      //   else {
      //     return 'fulfilled';
      //   }
      // }
      // else { // off
      //   return 'fulfilled';
      // }
    })
    .catch( (error) => {
        dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressInYourWalletCanNotBeGotten[state.language] });
        return 'rejected';
    });

    if (result === 'rejected') {
      return { status: 'rejected' };
    }

    // 署名
    result = await window.mpurse.signMessage(message)
    .then( result => {
      signature = result;
      return 'fulfilled';
    })
    .catch( err => {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSign[state.language] });
      return 'rejected';
    });

    if (result === 'rejected') {
      return { status: 'rejected' };
    }
  }
  else {
    if (keyPairs === undefined || keyPairs === null) {
      if (stateFixed.walletType === 'nfc') {
        dispatch({ type: 'setNotification', key: 'notification', value: words.touchWithYourKeyCard[state.language] });
        return {
          status: 'rejected',
          message: 'nfcRequired',
        };
      }
      else if (stateFixed.walletType === 'qr') {
        dispatch({ type: 'setNotification', key: 'notification', value: words.scanYour2DBarcode[state.language] });
        return {
          status: 'rejected',
          message: 'bcRequired',
        };
      }
    }

    // dispatch({ type: 'setNotification', key: 'notification', value: words.failedToLogin[state.language] });
    return { status: 'rejected' };
  }

  const formData = new FormData();

  const requestJson = JSON.stringify({
    "body": {
      "addressMain": stateFixed.addressMain,
      "addressMainActual": addressMainActual,
      "userName": stateFixed.userName,
      // "profileText": stateFixed.profileText,
      "acceptedVersionOfTheTermsAndConditions": stateFixed.acceptedVersionOfTheTermsAndConditions,
      "acceptedVersionOfPrivacyPolicy": stateFixed.acceptedVersionOfPrivacyPolicy,
      "clientTime" : clientTime,
      "signature" : signature,
      "signatureVersion" : stateFixed.signatureVersion,
    }
  });

  formData.append('json', requestJson);

  // if (stateFixed.imageMain !== undefined && stateFixed.imageMain !== null) {
  //   formData.append('imageMain', stateFixed.imageMain, stateFixed.imageMain.name);
  // }

  request.body = formData;
  request.headers = {};
  request.url = 'https://' + process.env.REACT_APP_KOM_API_DOMAIN + '/configure';
  request.method = 'POST';

  messages = {
    "you must accept the terms and conditions of the current version.": words.youMustAcceptTheTermsAndConditionsOfTheCurrentVersion[state.language],
    "internal server error": words.internalServerError[state.language],
    "bad signature": words.badSignature[state.language],
    "bad signature, can't recover address": words.badSignatureCanNotRecoverAddress[state.language],
    "unknown signatureVersion": words.unknownSignatureVersion[state.language],
    "clientTime must be larger than last time.": words.clientTimeMustBeLargerThanLastTime[state.language],
    "this address is not permitted.": words.thisAddressIsNotPermitted[state.language],
    "this address is forbidden.": words.thisAddressIsForbidden[state.language],
    "out of service": words.outOfService[state.language],
    // "you are not delegated.": words.norIsTheSignatureDelegatedFromMainAddress[state.language],
    // "addressMainActual must be filled.": words.clearYourBrowserOrAppCache[state.language],
    "success": words.success[state.language],
  };

  dispatch({ type: 'setState', key: 'accessing', value: true });

  const response = await syncHttpRequest2(request, undefined, undefined, 'notification', messages, dispatch);

  dispatch({ type: 'setState', key: 'accessing', value: false });

  if ( response.status === 'fulfilled' ) {

    // session情報更新
    dispatch({ type: 'setStateMultiLayers', keys: ['session', stateFixed.addressMain],
      value: {'sessionId': response.body.sessionId.addressMain, 'expirationOfSession': response.body.expirationOfSession.addressMain} });

    /*
    // 基本情報をconfigureにセット
    dispatch({ type: 'setState', key: 'configure', value: response.body[stateFixed.addressMain].basicInformation });
    */

    // 各種stateにメインアドレスをセット
    dispatch({ type: 'setStateMultiLayers', keys: ['login', 'addressMain'], value: stateFixed.addressMain });
    dispatch({ type: 'setStateMultiLayers', keys: ['active', 'addressMain'], value: stateFixed.addressMain });
    dispatch({ type: 'setStateMultiLayers', keys: ['getPointHistory', 'addressMain'], value: stateFixed.addressMain });
    dispatch({ type: 'setStateMultiLayers', keys: ['makeUrlToBroadcast', 'addressMain'], value: stateFixed.addressMain });

    // if (addressMainActual !== stateFixed.addressMain) {
    //   dispatch({ type: 'setStateMultiLayers', keys: ['session', addressMainActual],
    //     value: {'sessionId': response.body.sessionId.addressMainActual, 'expirationOfSession': response.body.expirationOfSession.addressMainActual} });
    // }

    // // cookie情報更新(アドレス情報)
    // const userName = state.usersGeneralIndex[stateFixed.addressMain] !== undefined ? state.usersGeneralIndex[stateFixed.addressMain].userName : stateFixed.addressMain;
    // const cookiesNew = [
    //       {
    //         action: 'setUp',
    //         addressType: 'addressMain',
    //         description: `${userName}`,
    //         time: clientTime,
    //         address: stateFixed.addressMain
    //       },
    // ];

    // setAddressesInCookie(state, dispatch, cookies, setCookie, stateFixed.addressMain, cookiesNew);

    // 保有アセット取得
    getBalances(state, dispatch, stateFixed.addressMain)
    .then( (result) => {
      let assets;

      if (result.status === 'fulfilled') {
        const balance = result.body;
        assets = Object.keys(balance);
      }
      else {
        throw new Error(result.error);
      }

      // アセット情報取得
      return getAssetInfo(state, dispatch, assets);
    })
    .then( (result) => {
      if (result.status === 'fulfilled') {
        // assetInfo = result.body;
      }
      else {
        throw new Error(result.error);
      }
    })
    .catch( (error) => {
      // エラー＆再ログインしてください通知。
    })

    // デッキ取得
    loadDeck(state, dispatch, { addressMain: stateFixed.addressMain, sessionId: response.body.sessionId.addressMain });

    // warning処理
    if (response.body.warning === 'inactivePoints') {
      dispatch({
        type: 'setStateMultiLayers',
        keys: ['notificationPersistent', 'message'],
        value: words.thereAreSomeMonatokaPointsThatHaveNotBeenActivatedPleaseContactTheRetailerAndProvideThemWithTheFollowingActivationCode[state.language] + '\n' +
               response.body.warningDetail.activationCode,
      });
      dispatch({ type: 'setStateMultiLayers', keys: ['notificationPersistent', 'visible'], value: true });
    }
    else if (response.body.warning === 'inactivePointsButActiveKeycarda') {
      dispatch({
        type: 'setStateMultiLayers',
        keys: ['notificationPersistent', 'message'],
        value: words.thereAreSomeMonatokaPointsThatHaveNotBeenActivatedPleaseContactMonatokaIncAndProvideThemWithTheFollowingMonacoinAddres[state.language] + '\n' +
               stateFixed.addressMain,
      });
      dispatch({ type: 'setStateMultiLayers', keys: ['notificationPersistent', 'visible'], value: true });
    }
    else if (response.body.warning === 'inactivePointsButNoKeycard') {
      dispatch({
        type: 'setStateMultiLayers',
        keys: ['notificationPersistent', 'message'],
        value: words.thereAreSomeMonatokaPointsThatHaveNotBeenActivatedPleaseContactMonatokaIncAndProvideThemWithTheFollowingMonacoinAddres2[state.language] + '\n' +
               stateFixed.addressMain,
      });
      dispatch({ type: 'setStateMultiLayers', keys: ['notificationPersistent', 'visible'], value: true });
    }
  }
  else {
  }

  return response;
}

// HANDLE CLICK CHALLENGE DUEL
function handleClickChallengeDuel(state, dispatch, navigate, gameMode, difficultyMode) {
  // dispatch({type: 'setStateMultiLayers', keys: ['websocket', 'status'], value: 'open'});

  if (gameMode === 'human') {
    if (difficultyMode === 'advanced') {
      navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/dueladvanced' : '/dueladvanced');
    }
    else {
      navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/duel' : '/duel');
    }
  }
  else { // computer
    if (difficultyMode === 'advanced') {
      navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/duelcomadvanced' : '/duelcomadvanced');
    }
    else {
      navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/duelcom' : '/duelcom');
    }
  }
}

// HANDLE CLICK LEAVE DUEL
function handleClickLeaveDuel(state, dispatch, navigate, popupLayer) {
  const popupLayerNext = popupLayer !== undefined && popupLayer !== null ? (popupLayer + 1) : 0;

  const body =
  <div className='flexColumn alignItemsCenter' >
    <div className='margin1' >
      { words.areYouSureYouWantToLeaveTheDuel[state.language] }
    </div>
    <div className='flexRow alignItemsCenter' >
      <button className='button2 flexRow justifyContentCenter alignItemsCenter '
        onClick={ () => {
          handleClickEndDuel(state, dispatch, navigate);
          dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayerNext], value: { type: null, body: null } }); 
        }}
      >
        <div>
          { 'OK' }
        </div>
      </button>
      <button className='button2 flexRow justifyContentCenter alignItemsCenter '
        onClick={ () => {
          dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayerNext], value: { type: null, body: null } }); 
        }}
      >
        <div>
          { words.cancel[state.language] }
        </div>
      </button>
    </div>
  </div>;

  const popup = {
    type: 'generalItems',
    body: body,
  };

  dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayerNext], value: popup });
}

// HANDLE CLICK END DUEL
function handleClickEndDuel(state, dispatch, navigate) {
  // クライアントのduelステータスを初期化

  const duel = {
    visible: false,
    textCount: 0,
    text: '',
    chunks: [],
    straightManChunks: [],
    round: 0,
    status: undefined,
    myStatus: null,
    action: undefined,
    pointA: 0,
    pointB: 0,
    winner: undefined,
    winningMonster: undefined,
    winningMonsterIndex: undefined,
    monstersInformation: undefined,
    monstersInformationRevised: undefined,
    monstersIndex: undefined,
    result: [],
    maginaPoint: {
      A: 0,
      B: 0,
    },
    basicInformation: undefined,
    castMagics: [],
    magics: {
      A: [],
      B: [],
    },
    initiative: undefined,
    imageNo: {
      A: 0,
      B: 0,
    },
    knightBoxEffect: {
      A: undefined,
      B: undefined,
    },
    selectStatus: {
      mode: 'neutral',
      routeIndex: null,
    },
    cardStatusTmp: {
      A: [],
      B: [],
    },
  };

  dispatch({type: 'setState', key: 'duel', value: duel});

  // デュエル終了させてコネクションを切る

  try {
    const requestBody = {
      action: 'endDuel',
      addressMain: state.active.addressMain,
      sessionId: state.session[state.active.addressMain].sessionId,
    };

    state.webSocketContainer.webSocket?.send(JSON.stringify(requestBody));
    state.webSocketContainer.webSocket?.close();
    devLog('WebSocket closed');
  }
  catch (error) {
    devLog('WebSocket may already be closed.', JSON.stringify(error));
  }

  dispatch({type: 'setStateMultiLayers', keys: ['webSocketContainer', 'webSocket'], value: undefined});

  // dispatch({type: 'setStateMultiLayers', keys: ['websocket', 'status'], value: 'close'});

  const urlParams = (new URL(document.location)).searchParams;
  const roomId = urlParams.get('roomid') === null ? null : urlParams.get('roomid');
  const duelNo = urlParams.get('duelno') === null ? null : parseInt(urlParams.get('duelno'), 10);

  if (roomId === null || duelNo === null) { // roomId, duelNoが設定されてる場合、履歴詳細へnavigate中だからオーバーライドしないようにする。
    navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/top' : '/top');
  }
}

// HANDLE CLICK SUMMON MONSTER
function handleClickSummonMonster(state, dispatch, message) {
  const side = state.duel.basicInformation.side;

  const monstersInformation = {
    [side]: state.registeredCard[message.summonedMonster],
    [otherSide(side)]: state.registeredCard[state.duel.winningMonster],
  };

  const monstersIndex = {
    [side]: message.summonedMonsterIndex,
    [otherSide(side)]: state.duel.winningMonsterIndex,
  };

  dispatch({type: 'setStateMultiLayers', keys: ['duel', 'chunks'], value: []});
  dispatch({type: 'setStateMultiLayers', keys: ['duel', 'straightManChunks'], value: []});
  dispatch({type: 'setStateMultiLayers', keys: ['duel', 'text'], value: ''});
  dispatch({type: 'setStateMultiLayers', keys: ['duel', 'monstersInformation'], value: monstersInformation});
  dispatch({type: 'setStateMultiLayers', keys: ['duel', 'monstersIndex'], value: monstersIndex});
  dispatch({type: 'setStateMultiLayers', keys: ['duel', 'myStatus'], value: 'comfirmed'});
  dispatch({type: 'setStateMultiLayers', keys: ['duel', 'status'], value: 'onBattle'});
  dispatch({type: 'setStateMultiLayers', keys: ['duel', 'action'], value: message.action});
  dispatch({type: 'setStateMultiLayers', keys: ['duel', 'winner'], value: undefined});
  dispatch({type: 'setStateMultiLayers', keys: ['duel', 'magics'], value: { [side]: state.duel.castMagics, [otherSide(side)]: [], }});
  dispatch({type: 'setStateMultiLayers', keys: ['duel', 'imageNo'], value: { A: 0, B: 0, }});
  dispatch({type: 'setStateMultiLayers', keys: ['duel', 'knightBoxEffect'], value: { A: undefined, B: undefined, }});
  dispatch({type: 'setStateMultiLayers', keys: ['animation', 'youWinMona'], value: 'visible'});
  state.webSocketContainer.webSocket?.send(JSON.stringify(message));
  dispatch({type: 'setStateMultiLayers', keys: ['duel', 'visible'], value: true});
}

// HANDLE CLICK COST MONSTER
function handleClickCostMonster(state, dispatch, asset, index) {
  const side = state.duel.basicInformation.side;
  const selectStatus = state.duel.selectStatus;
  const magic = state.duel.castMagics[selectStatus.magicIndex];
  const route = magic.route[selectStatus.routeIndex];
  const cost = magic.costs[route.index];
  const cards = cost.cards;

  if (route.detail.type === 'discardCards') {
    cards.push({ asset: asset, index: index });

    dispatch({type: 'setStateMultiLayers', keys: ['duel', 'castMagics', selectStatus.magicIndex, 'costs', route.index, 'cards'], value: cards});

    if (state.duel.cardStatusTmp[side][index] !== undefined) {
      dispatch({type: 'setStateMultiLayers', keys: ['duel', 'cardStatusTmp', side, index, 'status'], value: 'cost'});
    }
    else {
      dispatch({type: 'setStateMultiLayers', keys: ['duel', 'cardStatusTmp', side, index], value: { status: 'cost' }});
    }
  }

  // ルーティング

  if (route.detail.comparison === 'equal') {
    if (cards.length === route.detail.amount) {
      if (selectStatus.routeIndex === magic.route.length - 1) {
        // 魔法モードを抜けニュートラル
        dispatch({type: 'setStateMultiLayers', keys: ['duel', 'selectStatus'], value: { mode: 'neutral' }});
      }
      else {
        // 魔法モード継続。ルート1つ進む。
        dispatch({type: 'setStateMultiLayers', keys: ['duel', 'selectStatus', 'routeIndex'], value: selectStatus.routeIndex + 1});
      }
    }
  }

  // dispatch({type: 'setStateMultiLayers', keys: ['duel', 'visible'], value: true});
}

// HANDLE CLICK CAST MAGIC
function handleClickCastMagic(state, dispatch, asset, index) {
  const side = state.duel.basicInformation.side;

  // 真剣奈ポイントチェック
  // 仕様未定のため、magicOrderは0のみという前提。

  if ( state.registeredCard[asset].postEffects.filter( effect => effect.type === 'maginaPoint' && effect.amount * -1 > state.duel.maginaPoint[side] ).length > 0 ) {
    return { status: 'rejected' };   
  }

  // 一旦いまセットされてる魔法をキャンセル

  if (state.duel.castMagics[0]) {
    handleClickCancelMagic(state, dispatch, 0)
  }

  // ルーティングとハコ作り

  const route = [];
  const costs = [];
  const targets = [];

  // -- コスト
  if (state.registeredCard[asset].costs !== undefined) {
    for (const [index, cost] of state.registeredCard[asset].costs.entries()) {
      route.push({ type: 'cost', index: index, detail: cost });
      costs[index] = { cards: [] };
    }
  }
  
  // -- 対象
  if (state.registeredCard[asset].preEffects !== undefined) {
    for (const [index, effect] of state.registeredCard[asset].preEffects.entries()) {
      if (effect.targets !== undefined) {
        targets[index] = {};

        for (const key of Object.keys(effect.targets)) {
          if (effect.targets[key].type === 'specify') {
            route.push({ type: 'target', index: index, key: key, detail: effect.targets[key] });

            if (effect.targets[key].source === 'field') {
              targets[index][key] = { side: null };
            }
          }
        }
      }
    }
  }

  devLog('route', JSON.stringify(route));

  // castMagics, magics設定

  const magic = {
    asset: asset,
    index: index,
    costs: costs,
    targets: targets,
    route: route,
  };

  dispatch({type: 'setStateMultiLayers', keys: ['duel', 'castMagics', 0], value: magic}); // とりあえず0固定で。
  dispatch({type: 'setStateMultiLayers', keys: ['duel', 'magics', side, 0], value: magic}); // とりあえず0固定で。

  // 仮墓地送り

  if (state.duel.cardStatusTmp[side][index] !== undefined) {
    dispatch({type: 'setStateMultiLayers', keys: ['duel', 'cardStatusTmp', side, index, 'status'], value: 'cast'});
  }
  else {
    dispatch({type: 'setStateMultiLayers', keys: ['duel', 'cardStatusTmp', side, index], value: { status: 'cast' }});
  }

  // selectStatus変更

  if (route.length >= 1) {
    dispatch({type: 'setStateMultiLayers', keys: ['duel', 'selectStatus'], value: { mode: 'magic', magicIndex: 0, routeIndex: 0 }});
  }

  // if (state.registeredCard[asset].costs?.[0] !== undefined) {
  //   // コスト選択がある場合はコスト選択モード
  //   dispatch({type: 'setStateMultiLayers', keys: ['duel', 'selectStatus'], value: { type: 'cost', detail: state.registeredCard[asset].costs[0] }}); // とりあえず0固定で。
  // }
  // else {
  //   // コスト選択が無い場合は対象選択モード（対象選択があれば）
  //   for (const [index, effect] of state.registeredCard[asset].preEffects.entries()) {
  //     if (effect.target === 'specify') {
  //       dispatch({
  //         type: 'setStateMultiLayers',
  //         keys: ['duel', 'selectStatus'],
  //         value: { type: 'cost', index: index, detail: state.registeredCard[asset].costs[0] }
  //       }); // とりあえず0固定で。

  //       break;
  //     }
  //   }
  // }

  // dispatch({type: 'setStateMultiLayers', keys: ['duel', 'visible'], value: true});
}

// HANDLE CLICK CANCEL MAGIC
function handleClickCancelMagic(state, dispatch, index) {
  const side = state.duel.basicInformation.side;
  const magic = state.duel.castMagics[index];

  // 仮墓地から復活

  dispatch({type: 'setStateMultiLayers', keys: ['duel', 'cardStatusTmp', side, magic.index, 'status'], value: 'available'}); // handに戻るとは限らない。

  for (const cost of magic.costs) {
    for (const card of cost.cards) {
      dispatch({type: 'setStateMultiLayers', keys: ['duel', 'cardStatusTmp', side, card.index, 'status'], value: 'available'}); // handに戻るとは限らない。
    }
  }

  // castMagics, magicsクリア

  const magics = state.duel.castMagics.filter( (magic, i) => i !== index ); // 全魔法クリアが正解かもしれない。

  dispatch({type: 'setStateMultiLayers', keys: ['duel', 'castMagics'], value: magics});
  dispatch({type: 'setStateMultiLayers', keys: ['duel', 'magics', side], value: magics});

  // selectStatus変更

  dispatch({type: 'setStateMultiLayers', keys: ['duel', 'selectStatus'], value: { mode: 'neutral' }});
}

// HANDLE CLICK NEXT
function handleClickNext(state, dispatch, message) {
  const side = state.duel.basicInformation.side;

  const monstersInformation = {
    [side]: state.registeredCard[state.duel.winningMonster],
  };

  const monstersIndex = {
    [side]: state.duel.winningMonsterIndex,
  };

  dispatch({type: 'setStateMultiLayers', keys: ['duel', 'chunks'], value: []});
  dispatch({type: 'setStateMultiLayers', keys: ['duel', 'straightManChunks'], value: []});
  dispatch({type: 'setStateMultiLayers', keys: ['duel', 'text'], value: ''});
  dispatch({type: 'setStateMultiLayers', keys: ['duel', 'monstersInformation'], value: monstersInformation});
  dispatch({type: 'setStateMultiLayers', keys: ['duel', 'monstersIndex'], value: monstersIndex});
  dispatch({type: 'setStateMultiLayers', keys: ['duel', 'myStatus'], value: 'comfirmed'});
  dispatch({type: 'setStateMultiLayers', keys: ['duel', 'status'], value: 'onBattle'});
  dispatch({type: 'setStateMultiLayers', keys: ['duel', 'action'], value: message.action});
  dispatch({type: 'setStateMultiLayers', keys: ['duel', 'winner'], value: undefined});
  dispatch({type: 'setStateMultiLayers', keys: ['duel', 'magics'], value: { [side]: state.duel.castMagics, [otherSide(side)]: [], }});
  dispatch({type: 'setStateMultiLayers', keys: ['duel', 'imageNo'], value: { A: 0, B: 0, }});
  dispatch({type: 'setStateMultiLayers', keys: ['duel', 'knightBoxEffect'], value: { A: undefined, B: undefined, }});
  dispatch({type: 'setStateMultiLayers', keys: ['animation', 'youWinMona'], value: 'visible'});
  state.webSocketContainer.webSocket?.send(JSON.stringify(message));
}

// HANDLE CLICK BUILD DECK
function handleClickBuildDeck(state, dispatch, navigate) {
  navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/builddecks' : '/builddecks');
}

// HANDLE CLICK DUEL HISTORY
function handleClickDuelHistory(state, dispatch, navigate, navigateState) {
  if (navigateState !== undefined && navigateState !== null) {
    navigate((process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/duelhistory' : '/duelhistory'), { state: navigateState });
  }
  else {
    navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/duelhistory' : '/duelhistory');
  }
}

// HANDLE CLICK DUEL RANKING
function handleClickDuelRanking(state, dispatch, navigate) {
  navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/duelranking' : '/duelranking');
}

// HANDLE CLICK CHARGE POINT
function handleClickChargePoint(state, dispatch, navigate) {
  navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/chargepoints' : '/chargepoints');
}

// HANDLE CLICK CARD REGISTRATION
function handleClickCardRegistration(state, dispatch, navigate) {
  navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/cardregistration' : '/cardregistration');
}

// HANDLE CLICK CARD LIST
function handleClickCardList(state, dispatch, navigate, navigateState) {
  if (navigateState !== undefined && navigateState !== null) {
    navigate((process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/cardlist' : '/cardlist'), { state: navigateState });
  }
  else {
    navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/cardlist' : '/cardlist');
  }
}

// HANDLE CLICK PURCHASE ITEM
function handleClickPurchaseItem(state, dispatch, navigate, navigateState) {
  if (navigateState !== undefined && navigateState !== null) {
    navigate((process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/purchaseitems' : '/purchaseitems'), { state: navigateState });
  }
  else {
    navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/purchaseitems' : '/purchaseitems');
  }
}

// HANDLE CLICK CHECK OUT
function handleClickCheckOut(state, dispatch, navigate, navigateState) {
  if (navigateState !== undefined && navigateState !== null) {
    navigate((process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/checkout' : '/checkout'), { state: navigateState });
  }
  else {
    navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/checkout' : '/checkout');
  }
}

// HANDLE CLICK ITEM ORDER HISTORY
function handleClickItemOrderHistory(state, dispatch, navigate) {
  navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/itemorderhistory' : '/itemorderhistory');
}

// HANDLE CLICK SETTING
function handleClickSetting(state, dispatch, navigate) {
  navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/setting' : '/setting');
}

// HANDLE CLICK GO TOP
function handleClickGoTop(state, dispatch, navigate) {
  navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/top' : '/top');
}

// HANDLE CLICK GO BACK
function handleClickGoBack(state, dispatch, navigate, location) {
  if (location?.state?.previousLocation !== undefined) {
    navigate(-1);
  }
  else {
    navigate(process.env.REACT_APP_ENVIRONMENT === 'dev' ? '/build/top' : '/top');
  }
}

// HANDLE CLICK DECK NUMBER
function handleClickDeckNumber(state, dispatch, addressMain) {
  let options = [];

  for (let i = 1; i <= 10; i++) {
    let deckName;

    if (state.deckSet[addressMain].decks[i] !== undefined) {
      deckName = state.deckSet[addressMain].decks[i].deckName;
    }
    else {
      deckName = '';
    }

    options.push(
      <GeneralOption
        record={{
          deckNo: {
            value: i,
            face: i,
            style: 'selectDeckDeckNo'
          },
          deckName: {
            face: deckName,
            style: 'selectDeckDeckName'
          },
        }}
        recordKey='deckNo'
        sortedKeys={ ['deckNo', 'deckName'] }
        stateKeys={ ['deckSet', addressMain, 'currentDeckNo'] } layer={ 0 }
      />
    );
  }

  const popup = {
    type: 'generalList',
    body: {
      'title': 'deck No',
      'options': options,
    },
  }

  dispatch({ type: 'setStateMultiLayers', keys: ['popup', 0], value: popup });
}


// 「ログイン」ボタン押下 HANDLE CLICK LOGIN
async function handleClickLogin(state, dispatch, navigate, mode, keyPairs, popupLayer) {
  let request = {};
  let messages = {};
  let certifications = [];
  const popupLayerNext = (popupLayer !== undefined && popupLayer !== null) ? (popupLayer + 1) : 0;
  const now = Date.now();

  // state固定
  const stateFixed = {
    addressMain: state.login.addressMain,
    // signatureVersion: state.config.clientParameters.signatureVersion.login,
    signatureVersion: '1',
    // addressCheck: state.addressCheck,
    walletType: state.walletType,
    addressCheck: 'strict',
  };

  // 有効なセッションに絞り込む。
  devLog('session', JSON.stringify(state.session));
  devLog('session keys', JSON.stringify(Object.keys(state.session)));

  for (const address of Object.keys(state.session)) {
    // state固定
    stateFixed[address] = {
      // addressCoinType: state.session[address].addressCoinType,
      sessionId: state.session[address].sessionId,
      expirationOfSession: state.session[address].expirationOfSession,
    };

    if (stateFixed[address].expirationOfSession >= now + marginOfSessionTime) {
      certifications.push({
        // addressCoinType: stateFixed[address].addressCoinType,
        address: address,
        sessionId: stateFixed[address].sessionId,
      });
    }
  }

  // アドレスが指定されており、そのアドレスのセッションが存在しない、または、期限が切れていれば、署名する。(キープール or Mpurse)
  // Mpurseの場合、アドレスが指定されていない場合は、アクティブなアドレスで代替するかもしれない。(アドレスチェックモードに依る。)
  // keyPairsが渡された場合(2周目)、keyPairsの先頭の鍵で試みる。アドレスが指定されていない場合は、先頭のアドレスで代替するかもしれない。(アドレスチェックモードに依る。)

  const keyPairsInPool = getValidKeyPairs(state, dispatch);

  if (
    stateFixed.addressMain !== undefined && stateFixed.addressMain !== null && stateFixed.addressMain !== '' &&
    keyPairsInPool[stateFixed.addressMain]?.keyPair !== undefined
  ) {
    certificationWithKey(state, dispatch, [ keyPairsInPool[stateFixed.addressMain].keyPair ], 'login', stateFixed, certifications, now, null, 'matchedKey');
  }
  else if (keyPairs !== undefined && keyPairs !== null) {
    certificationWithKey(state, dispatch, keyPairs, 'login', stateFixed, certifications, now, null, 'firstKey');
  }
  else if (stateFixed.walletType === 'mpurse') {
    // const message = `I want to log in to Knights of Monadom as ${stateFixed.addressMain}. (epoch time: ${now}, signature version: ${stateFixed.signatureVersion})`;
    await setSignatureByAddressMain(state, dispatch, stateFixed, certifications, now, null, 'login');
  }

  // 対象がなかったり、addressMainが指定されているにも関わらずcertificationsに含まれていなかった場合は戻る。ただし、KeyPairsが渡されていない場合は、鍵を要求する。

  if (
    certifications.length === 0 ||
    stateFixed.addressMain !== undefined && stateFixed.addressMain !== null && stateFixed.addressMain !== '' &&
    !certifications.map( certification => certification.address ).includes(stateFixed.addressMain)
  ) {
    if (keyPairs === undefined || keyPairs === null) {
      if (stateFixed.walletType === 'nfc') {
        dispatch({ type: 'setNotification', key: 'notification', value: words.touchWithYourKeyCard[state.language] });
        return {
          status: 'rejected',
          message: 'nfcRequired',
        };
      }
      else if (stateFixed.walletType === 'qr') {
        dispatch({ type: 'setNotification', key: 'notification', value: words.scanYour2DBarcode[state.language] });
        return {
          status: 'rejected',
          message: 'bcRequired',
        };
      }
    }

    // dispatch({ type: 'setNotification', key: 'notification', value: words.failedToLogin[state.language] });
    return { status: 'rejected' };
  }

  request.body = JSON.stringify({
    body: {
      certifications: certifications,
      mode: mode,
    }
  });

  request.url = 'https://' + process.env.REACT_APP_KOM_API_DOMAIN + '/login';
  request.method = 'POST';

  messages = {
  };

  dispatch({ type: 'setState', key: 'accessing', value: true });

  const response = await syncHttpRequest2(request, 'user', undefined, undefined, messages, dispatch);

  dispatch({ type: 'setState', key: 'accessing', value: false });

  if ( response.status === 'fulfilled' ) {
    let certification;

    for (const address of Object.keys(response.body)) {

      // 署名した場合もろもろ更新
      if (response.body[address]['certificatedBy'] === 'signature') {

        // session更新
        dispatch({ type: 'setStateMultiLayers', keys: ['session', address],
          value: {'sessionId': response.body[address]['sessionId'], 'expirationOfSession': response.body[address]['expirationOfSession']} });

        // certification設定
        certification = { addressMain: address, sessionId: response.body[address]['sessionId'] };
      }
      else if (response.body[address]['certificatedBy'] === 'none') {
        dispatch({ type: 'setStateMultiLayers', keys: ['session', address], value: { expirationOfSession: 0 } });
      }

      // カード関連情報取得
      if (response.body[address]['certificatedBy'] === 'signature' || response.body[address]['certificatedBy'] === 'sessionId') {

        // 保有アセット取得
        getBalances(state, dispatch, address)
        .then( (result) => {
          let assets;

          if (result.status === 'fulfilled') {
            const balance = result.body;
            assets = Object.keys(balance);
          }
          else {
            throw new Error(result.error);
          }

          // アセット情報取得
          return getAssetInfo(state, dispatch, assets);
        })
        .then( (result) => {
          if (result.status === 'fulfilled') {
            // assetInfo = result.body;
          }
          else {
            throw new Error(result.error);
          }
        })
        .catch( (error) => {
          // stateFixed.addressMainだったら、エラー＆再ログインしてください通知。
        });

        // // モナカード情報取得
        // const assetCommons = assets.map( asset => assetInfo[asset].asset_longname === null ? asset : assetInfo[asset].asset_longname );
        // getMonacard(state, dispatch, assetCommons);
      }
    }

    // デッキ取得
    loadDeck(state, dispatch, certification);
  }
  else {
    // dispatch({ type: 'setNotification', key: 'notification', value: words.failedToLogin[state.language] });
    return { status: 'rejected' };
  }

  if (stateFixed.addressMain !== undefined && stateFixed.addressMain !== null && stateFixed.addressMain !== '') {
    const responseBody = response.body[stateFixed.addressMain];

    if  (response.body[stateFixed.addressMain].certificatedBy === 'sessionId' || response.body[stateFixed.addressMain].certificatedBy === 'signature') {
      // 明示的ログインアドレスでログイン成功

      // 基本情報をconfigureにセット
      dispatch({ type: 'setState', key: 'configure', value: response.body[stateFixed.addressMain].basicInformation });

      // 各種stateにログインアドレスをセット
      dispatch({ type: 'setStateMultiLayers', keys: ['active', 'addressMain'], value: stateFixed.addressMain });
      dispatch({ type: 'setStateMultiLayers', keys: ['getPointHistory', 'addressMain'], value: stateFixed.addressMain });
      dispatch({ type: 'setStateMultiLayers', keys: ['getItemOrderHistory', 'addressMain'], value: stateFixed.addressMain });
      dispatch({ type: 'setStateMultiLayers', keys: ['makeUrlToBroadcast', 'addressMain'], value: stateFixed.addressMain });

      // addressMainが指定されていなかった場合は、dispatchする。
      if (state.login.addressMain === undefined || state.login.addressMain === null || state.login.addressMain === '') {
        dispatch({ type: 'setStateMultiLayers', keys: ['login', 'addressMain'], value: stateFixed.addressMain });
      }

      // デュエル中であれば、デュエル画面に遷移
      if (responseBody.duelistStatus === 'onDuel') {
        handleClickChallengeDuel(state, dispatch, navigate, responseBody.gameMode, responseBody.difficultyMode);
      }

      // if (state.exhibit.addressMain === undefined || state.exhibit.addressMain === null || state.exhibit.addressMain === '') {
      //   dispatch({ type: 'setStateMultiLayers', keys: ['active', 'addressMain'], value: stateFixed.addressMain });
      // }

      // // cookie情報更新(アドレス情報)
      // const userName = state.usersGeneralIndex[stateFixed.addressMain] !== undefined ? state.usersGeneralIndex[stateFixed.addressMain].userName : stateFixed.addressMain;
      // const cookiesNew = [
      //       {
      //         action: 'login',
      //         addressType: 'addressMain',
      //         description: `${userName}`,
      //         time: now,
      //         address: stateFixed.addressMain
      //       },
      // ];

      // setAddressesInCookie(state, dispatch, cookies, setCookie, stateFixed.addressMain, cookiesNew);
    }
    else {
      // 明示的ログインアドレスでログイン失敗

      if (response.body[stateFixed.addressMain].applicationMessage === 'not accept TAC yet.') {
        // ユーザー登録を促す。
        dispatch({ type: 'setNotification', key: 'notification', value: words.pleaseRegisterAsAUser[state.language] });
      }
      else if (response.body[stateFixed.addressMain].applicationMessage === 'you must accept the terms and conditions of the current version.') {
        // 基本情報をconfigureにセット（認証は成功しているので、規約同意しやすいように基本情報は埋めてあげる。）
        dispatch({ type: 'setState', key: 'configure', value: response.body[stateFixed.addressMain].basicInformation });

        if (response.body[stateFixed.addressMain].applicationMessageParameter.versionOfTheTermsAndConditions === state.config.clientParameters.versionOfTheTermsAndConditions) {
          // クライアントには最新バージョンがロードされているので、規約同意を促す。
          dispatch({ type: 'setNotification', key: 'notification', value: words.youMustAcceptTheTermsAndConditionsOfTheCurrentVersion[state.language] });

          const popup = { type: 'userRegistrationPopup' };
          dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayerNext], value: popup });
        }
        else {
          // クライアントに最新バージョンがロードされていないので、ブラウザリロードと規約同意を促す。
          dispatch({ type: 'setNotification', key: 'notification', value: words.youMustReloadYourBrowserAndAcceptTheTermsAndConditionsOfTheCurrentVersion[state.language] });
        }
      }
      else if (response.body[stateFixed.addressMain].applicationMessage === 'you must accept the privacy policy of the current version.') {
        // 基本情報をconfigureにセット（認証は成功しているので、プライバシーポリシーに同意しやすいように基本情報は埋めてあげる。）
        dispatch({ type: 'setState', key: 'configure', value: response.body[stateFixed.addressMain].basicInformation });

        if (response.body[stateFixed.addressMain].applicationMessageParameter.versionOfPrivacyPolicy === state.config.clientParameters.versionOfPrivacyPolicy) {
          // クライアントには最新バージョンがロードされているので、プライバシーポリシーへの同意を促す。
          dispatch({ type: 'setNotification', key: 'notification', value: words.youMustAcceptThePrivacyPolicyOfTheCurrentVersion[state.language] });

          const popup = { type: 'userRegistrationPopup' };
          dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayerNext], value: popup });
        }
        else {
          // クライアントに最新バージョンがロードされていないので、ブラウザリロードとプライバシーポリシーへの同意を促す。
          dispatch({ type: 'setNotification', key: 'notification', value: words.youMustReloadYourBrowserAndAcceptThePrivacyPolicyOfTheCurrentVersion[state.language] });
        }
      }
      else {
        dispatch({ type: 'setNotification', key: 'notification', value: words.youFailedToLogin[state.language] });
      }
    }
  }
  else {
  }
}

// NFCボタン押下 HANDLE CLICK NFC
async function handleClickNfc(state, dispatch, callback, { args = ['keyPairs'], recordNos = [1] } = {}) {
  if ('NDEFReader' in window) {
    const ndef = new window.NDEFReader();
    await ndef.scan();

    ndef.onreading = async event => {
      console.log("NDEF tag detected");

      const keyPairs = [];
      const mnemonics = [];

      const argumentsAll = { keyPairs, mnemonics };
      const argumentsToPass = args.map( arg => argumentsAll[arg] );

      for (const [index, record] of event.message.records.entries()) {
        if (!recordNos.includes(index)) {
          continue;
        }

        try {
          const textDecoder = new TextDecoder(record.encoding);
          const mnemonicEncrypted = textDecoder.decode(record.data);
          const pass = state.config.clientParameters.specialMessage;
          // const pass = undefined;
          const salt = ['00', '00', '00', '00', '00', '00', '00', '72'];
          const iv = ['00', '00', '00', '00', '00', '00', '00', '00', '00', '00', '00', '00', '00', '01', 'bf', '52'];
          const mnemonic = await decrypt(state, dispatch, mnemonicEncrypted, pass, salt, iv);
          // const mnemonic = textDecoder.decode(record.data);
          // const mnemonic = undefined;
          mnemonics.push(mnemonic);
          const keyPair = getKeyPairsFromMnemonic(mnemonic)[0];
          // const seed = bip39.mnemonicToSeedSync(mnemonic);
          // const root = bip32.fromSeed(seed)
          // const path = "m/44'/22'/0'/0/0";
          // const leaf = root.derivePath(path);
          // const keyPair = ECPair.fromPrivateKey(leaf.privateKey, { network: networkMona });
          keyPairs.push(keyPair);
          // dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'address'], value: getAddressFromKey(state, dispatch, keyPair).address }); // 暫定
          // dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'wif'], value: JSON.stringify(keyPair) }); // 暫定
          // dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonic'], value: typeof mnemonic }); // 暫定
          // const payment = bitcoin.payments.p2pkh({ pubkey: keyPair.publicKey, network: networkMona });
          // const message = 'hoge';
          // let randomBytes = new Uint8Array(32);
          // window.crypto.getRandomValues(randomBytes);
          // const signature = bitcoinMessage.sign(message, keyPair.privateKey, keyPair.compressed, networkMona.messagePrefix, { extraEntropy: Buffer.from(randomBytes) });
          // console.log(`Record type: ${record.recordType}`);
          // console.log(`MIME type: ${record.mediaType}`);
          // console.log(`Data: ${textDecoder.decode(record.data)}`);
          // dispatch({ type: 'setStateMultiLayers', keys: ['login', 'addressMain'], value: signature.toString('base64')}); 
          // dispatch({ type: 'setStateMultiLayers', keys: ['login', 'addressMain'], value: payment.address}); 
          // dispatch({ type: 'setStateMultiLayers', keys: ['cardRegistration', 'feature'], value: keyPair.toWIF()}); 

          // const encryptedMessage = 'kR10Y9tegf2qDkjOFAxK7/YuXX4J4vgHiI3uxaQJQPDVnfc0pR+j2XrK3cn8cJRMwEl9B6DT2ZDcKr2SUxehhe6OcAO57nYLLSOZe+JVP0p4uoMUctfZeoU40Bo2lVjZHnhKd9+zEeDGuqbMXz6ToccqQAa2426B44W5RGRa+9we/Zts5adaalZZu1vnpdn74w3UjBy+ggEwsuu03mjQqLjl1mBB97PaOOdG+XBiczc=';
          // dispatch({ type: 'setStateMultiLayers', keys: ['cardRegistration', 'feature'], value: mnemonic.toString() }); 
          // const encryptedU8A = Uint8Array.from(window.atob(mnemonicEncrypted), byte => byte.charCodeAt(0));
          // dispatch({ type: 'setStateMultiLayers', keys: ['cardRegistration', 'name'], value: mnemonicEncrypted }); 
        }
        catch (error) {
          dispatch({ type: 'setNotification', key: 'notification', value: words.cannotReadDataFromTheKeyCard[state.language] });
        }
      }

      // 先に後処理やっちゃう。
      ndef.onreading = null;
      ndef.onreadingerror = null;

      if (argumentsToPass.some( arg => arg.length === 0 )) {
        dispatch({ type: 'setNotification', key: 'notification', value: words.cannotReadDataFromTheKeyCard[state.language] });
        throw new Error('noArgumentToPass');
      }

      callback(...argumentsToPass);
    };

    ndef.onreadingerror = () => {
      dispatch({ type: 'setNotification', key: 'notification', value: words.cannotReadDataFromTheKeyCard[state.language] });

      ndef.onreading = null;
      ndef.onreadingerror = null;
    };
  }
  else {
    dispatch({ type: 'setNotification', key: 'notification', value: words.yourDeviceDoesNotSupportKeyCards[state.language] });
  }
}

// NFC読み込み READ NFC
async function readNfc(state, dispatch, encryptedMnemonic) {
  if (!('NDEFReader' in window)) {
    dispatch({ type: 'setNotification', key: 'notification', value: words.yourDeviceDoesNotSupportKeyCards[state.language] });
    return;
  }

  const ndef = new window.NDEFReader();

  const result = await ndef.write({
    records: [
      {
        recordType: "url",
        data: "https://knightsofmonadom.monatoka.com/?wallettype=nfc"
      },
      {
        recordType: "text",
        lang: "en",
        encoding: "utf-8",
        data: encryptedMnemonic,
        // data: state.displaySecret.mnemonicToWif,
      },
    ]
  })
  .then( (result) => {
    dispatch({ type: 'setNotification', key: 'notification', value: 'NDEF messages written!' });
    return;
  })
  .catch( (error) => {
    dispatch({ type: 'setNotification', key: 'notification', value: 'Failed to write NDEF messages.' });
    throw new Error(error);
  });

  return result;
}

// NFC書き込み WRITE MNEMONIC ON NFC
async function writeMnemonicOnNfc(state, dispatch, encryptedMnemonic) {
  if (!('NDEFReader' in window)) {
    dispatch({ type: 'setNotification', key: 'notification', value: words.yourDeviceDoesNotSupportKeyCards[state.language] });
    return;
  }

  const ndef = new window.NDEFReader();

  const result = await ndef.write({
    records: [
      {
        recordType: "url",
        data: "https://knightsofmonadom.monatoka.com/?wallettype=nfc"
      },
      {
        recordType: "text",
        lang: "en",
        encoding: "utf-8",
        data: encryptedMnemonic,
        // data: state.displaySecret.mnemonicToWif,
      },
    ]
  })
  .then( (result) => {
    dispatch({ type: 'setNotification', key: 'notification', value: 'NDEF messages written!' });
    return;
  })
  .catch( (error) => {
    dispatch({ type: 'setNotification', key: 'notification', value: 'Failed to write NDEF messages.' });
    throw new Error(error + encryptedMnemonic);
  });

  return result;
}

// NFC書き込んだニーモニックチェック CHECK MNEMONIC ON NFC
function checkMnemonicOnNfc(state, dispatch, mnemonicToBeWritten, mnemonicsWritten) {
  if (mnemonicToBeWritten === mnemonicsWritten[0]) {
    localStorage.setItem('swapStatus', 'checkedMnemonic');
    dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonic'], value: 'check mnemonic OK.' }); // 暫定
  }
  else {
    localStorage.setItem('swapStatus', 'sweptMona');
    dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonic'], value: 'check mnemonic NG.' }); // 暫定
  }
}

// mnemonicから秘密鍵取得 GET KEYPAIRS FROM MNEMONIC
function getKeyPairsFromMnemonic(mnemonic) {
  let keyPairs = [];

  try {
    const seed = bip39.mnemonicToSeedSync(mnemonic);
    const root = bip32.fromSeed(seed)
    const path = "m/44'/22'/0'/0/0";
    const leaf = root.derivePath(path);
    const keyPair = ECPair.fromPrivateKey(leaf.privateKey, { network: networkMona });
    keyPairs.push(keyPair);
  }
  catch (error) {
    devLog('getKeyPairsFromMnemonic ; error', error);
    throw new Error('cannotGetKeyPairsFromMnemonic');
  }

  return keyPairs;
}

// 鍵渡してcertificationつくる CERTIFICATION WITH KEY
function certificationWithKey(state, dispatch, keyPairs, messageType, stateFixed, certifications, clientTime, extendedCertificationProperties, signingMode) {
  let addressIsSpecified;

  // lastEvaluatedSortKey等があるということは、前回の検索時からaddressMainが変更されていないということ。(変更するとリセットされてしまう。)
  // addressMainが指定されていないということは、後ページ検索ではない。
  // なぜならば、addressMainが指定されていない状態にするには、前回の検索のあと、addressMainを削除しなければならないから。
    
  if (stateFixed.addressMain !== undefined && stateFixed.addressMain !== null && stateFixed.addressMain !== '') {
    addressIsSpecified = true;
  }
  else {
    addressIsSpecified = false;
  }

  if (signingMode === 'firstKey') {
    keyPairs = [ keyPairs[0] ];
  }
  else { // matchedKey
  }

  for (const keyPair of keyPairs) {
    // アドレス導出
    let payment;
    let address;
    let addressActual;

    try {
      payment = bitcoin.payments.p2pkh({ pubkey: keyPair.publicKey, network: networkMona });
      addressActual = payment.address;
    }
    catch (err) {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToGetTheAddress[state.language] });
      continue;
    }

    if (signingMode === 'matchedKey') {
      if (addressActual !== stateFixed.addressMain) {
        continue;
      }
      else {
        address = stateFixed.addressMain;
      }
    }
    else { // firstKey
      if (addressIsSpecified) {
        if (checkIfAddressIsMatchedWithAnAddress(state, dispatch, addressActual, stateFixed.addressMain, stateFixed.addressCheck)) {
          address = stateFixed.addressMain;
        }
        else {
          continue;
        }
      }
      else {
        address = addressActual;
        stateFixed.addressMain = addressActual;
      }
    }

    // 渡された鍵ペアに対応するアドレスがcertificationsに含まれていなければ、署名する。
    if (address !== undefined && address !== null && address !== '' && !certifications.map( certification => certification.address ).includes(address)) {
      let message;

      if (messageType === 'login') {
        message = `I want to log in to Knights of Monadom as ${address}. (epoch time: ${clientTime}, signature version: ${stateFixed.signatureVersion})`;
      }
      else if (messageType === 'information') {
        message = `I want to get the information of ${address}. (epoch time: ${clientTime}, signature version: ${stateFixed.signatureVersion})`;
      }

      devLog('message', message);

      let signatureMona;

      const result = signWithKey(state, dispatch, keyPair, message);

      if (result.status === 'fulfilled') {
        signatureMona = result.signature;
      }
      else {
        dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSign[state.language] });
        continue;
      }

      const certification = {
        // addressCoinType: 'mona',
        address: address,
        addressActual: addressActual,
        clientTime: clientTime,
        signatureVersion: stateFixed.signatureVersion,
        signature: signatureMona,
        lastEvaluatedKey: stateFixed.lastEvaluatedKey,
      };

      // 拡張プロパティ
      if (extendedCertificationProperties !== undefined && extendedCertificationProperties !== null) {
        for (const property of extendedCertificationProperties) {
          certification[property.key] = property.value;
        }
      }

      devLog('certification given key', JSON.stringify(certification));

      certifications.push(certification);
    }
  }
}

// CHECK IF ADDRESS IS MATCHED WITH AN ADDRESS
function checkIfAddressIsMatchedWithAnAddress(state, dispatch, addressActual, address, addressCheck) {
  if (addressCheck === 'strict' || addressCheck === 'addressSecond') {
    if (addressActual !== address) {
      dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressFilledAndTheAddressInWalletAreDifferent[state.language] });
      return false;
    }
    else {
      return true;
    }
  }
  else { // off
    return true;
  }
}

// アドレス抽出 GET ADDRESS FROM KEY
function getAddressFromKey (state, dispatch, keyPair) {
  // アドレス導出
  let payment;
  let address;

  try {
    payment = bitcoin.payments.p2pkh({ pubkey: keyPair.publicKey, network: networkMona });
    address = payment.address;

    return {
      status: 'fulfilled',
      address: address,
    };
  }
  catch (err) {
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToGetTheAddress[state.language] });

    return {
      status: 'rejected',
    };
  }
}

// 鍵渡して署名 SIGN WITH KEY
function signWithKey(state, dispatch, keyPair, message) {
  let signatureMona;

  try {
    let randomBytes = new Uint8Array(32);
    window.crypto.getRandomValues(randomBytes);
    signatureMona = bitcoinMessage.sign(message, keyPair.privateKey, keyPair.compressed, networkMona.messagePrefix, { extraEntropy: Buffer.from(randomBytes) }).toString('base64');

    return {
      status: 'fulfilled',
      signature: signatureMona,
    };
  }
  catch (err) {
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSign[state.language] });

    return {
      status: 'rejected'
    };
  }

}

// KEEP KEY PAIRS IN MEMORY
function keepKeyPairsInMemory(state, dispatch, keyPairs) {
  const now = new Date();

  for (const keyPair of keyPairs) {
    // アドレス導出
    let payment;
    let address;

    try {
      payment = bitcoin.payments.p2pkh({ pubkey: keyPair.publicKey, network: networkMona });
      address = payment.address;
    }
    catch (err) {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToGetTheAddress[state.language] });
      continue;
    }

    const keyPairData = {
      keyPair: keyPair,
      expiration: now.getTime() + state.config.clientParameters.periodToKeepKeyPairs,
    };

    const keyPairsState = state.keyPairs;
    keyPairsState[address] = keyPairData;

    // dispatch({ type: 'setNotification', key: 'notification', value: 'keep' + address });
    // dispatch({ type: 'setStateMultiLayers', keys: ['keyPairs', address], value: keyPairData });
    dispatch({ type: 'setState', key: 'keyPairs', value: keyPairsState });
  }
}

// GET VALID KEY PAIRS
function getValidKeyPairs(state, dispatch) {
  const keyPairs = state.keyPairs;
  const validKeyPairs = {};
  const now = new Date();

  for (const address of Object.keys(keyPairs)) {
    if (keyPairs[address].expiration > now.getTime()) {
      validKeyPairs[address] = keyPairs[address];
    }
  }

  dispatch({ type: 'setState', key: 'keyPairs', value: validKeyPairs });

  return validKeyPairs;
}

// DECRYPT
async function decrypt(state, dispatch, encryptedData, password, salt, iv) {
  try {
    // 暗号化データをUint8Arrayに変換
    const encryptedU8A = Uint8Array.from(window.atob(encryptedData), byte => byte.charCodeAt(0));

    // パスワードをUint8Arrayに変換
    const passwordU8A = new TextEncoder().encode(password);

    // ソルトをUint8Arrayに変換
    const saltHexArray = salt;
    const saltNumberArray = saltHexArray.map( hex => Number('0x' + hex) );
    const saltU8A = Uint8Array.from(saltNumberArray);

    // if (salt !== undefined && salt !== null) {
    //   const saltBstr = window.atob(salt); // ソルトをBASE64からバイナリバイト列へデコード
    //   const saltU8A = Uint8Array.from(saltBstr, byte => byte.charCodeAt(0));
    //   deriveKeyAlgorithm.salt = saltU8A;
    // }

    // IVをUint8Arrayに変換
    const ivHexArray = iv;
    const ivNumberArray = ivHexArray.map( hex => Number('0x' + hex) );
    const ivU8A = Uint8Array.from(ivNumberArray);

    // if (iv !== undefined && iv !== null) {
    //   ivU8A = Uint8Array.from(window.atob(iv), byte => byte.charCodeAt(0));
    // }

    // PBKDF2を使用して鍵を導出
    const keyMaterial = await window.crypto.subtle.importKey(
        "raw",
        passwordU8A,
        { name: "PBKDF2" },
        false,
        ["deriveKey"]
    );

    const deriveKeyAlgorithm = {
        name: "PBKDF2",
        salt: saltU8A,
        iterations: 514,
        hash: "SHA-256"
    };

    const key = await window.crypto.subtle.deriveKey(
        deriveKeyAlgorithm,
        keyMaterial,
        { name: "AES-CBC", length: 256 },
        false,
        ["decrypt"]
    );

    // データを復号
    const decryptedABuff = await window.crypto.subtle.decrypt(
        { name: "AES-CBC", iv: ivU8A },
        key,
        encryptedU8A
    );

    // 復号されたデータを文字列に変換
    const decrypted = new TextDecoder().decode(decryptedABuff);

    // トリム
    const decryptedTrimmed = decrypted.trim();

    return decryptedTrimmed;
    // return new Uint8Array(decryptedABuff);
  } catch (e) {
    console.error("Decryption failed", e);
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToDecrypt[state.language] });
    throw e;
  }
}

// ENCRYPT
async function encrypt(state, dispatch, plaintext, password, salt, iv) {
  try {
    // 平文データをUint8Arrayに変換
    // const plaintextU8A = Uint8Array.from(window.atob(plaintext), byte => byte.charCodeAt(0));
    const plaintextU8A = new TextEncoder().encode(plaintext);

    // パスワードをUint8Arrayに変換
    const passwordU8A = new TextEncoder().encode(password);

    // ソルトをUint8Arrayに変換
    const saltHexArray = salt;
    const saltNumberArray = saltHexArray.map( hex => Number('0x' + hex) );
    const saltU8A = Uint8Array.from(saltNumberArray);

    // if (salt !== undefined && salt !== null) {
    //   const saltBstr = window.atob(salt); // ソルトをBASE64からバイナリバイト列へデコード
    //   const saltU8A = Uint8Array.from(saltBstr, byte => byte.charCodeAt(0));
    //   deriveKeyAlgorithm.salt = saltU8A;
    // }

    // IVをUint8Arrayに変換
    const ivHexArray = iv;
    const ivNumberArray = ivHexArray.map( hex => Number('0x' + hex) );
    const ivU8A = Uint8Array.from(ivNumberArray);

    // if (iv !== undefined && iv !== null) {
    //   ivU8A = Uint8Array.from(window.atob(iv), byte => byte.charCodeAt(0));
    // }

    // PBKDF2を使用して鍵を導出
    const keyMaterial = await window.crypto.subtle.importKey(
        "raw",
        passwordU8A,
        { name: "PBKDF2" },
        false,
        ["deriveKey"]
    );

    const deriveKeyAlgorithm = {
        name: "PBKDF2",
        salt: saltU8A,
        iterations: 514,
        hash: "SHA-256"
    };

    const key = await window.crypto.subtle.deriveKey(
        deriveKeyAlgorithm,
        keyMaterial,
        { name: "AES-CBC", length: 256 },
        false,
        ["encrypt"]
    );

    // データを暗号化
    const encryptedABuff = await window.crypto.subtle.encrypt(
        { name: "AES-CBC", iv: ivU8A },
        key,
        plaintextU8A
    );

    // 暗号化されたデータを文字列に変換
    // const encrypted = new TextDecoder().decode(encryptedABuff);
    const encrypted = arrayBufferToBase64(encryptedABuff);

    // トリム
    const encryptedTrimmed = encrypted.trim();

    dispatch({ type: 'setNotification', key: 'notification', value: 'encryptedTrimmed ' + encryptedTrimmed });
    return encryptedTrimmed;
    // return new Uint8Array(decryptedABuff);
  } catch (e) {
    console.error("Encryption failed", e);
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToEncrypt[state.language] });
    // throw e;
  }
}

// QRボタン押下 HANDLE CLICK SCAN QR
function handleClickScanQr(state, dispatch, callback, { args = ['keyPairs'], recordNos = [0], callbackType = 'decryptMnemonic', popupLayer = 0 } = {}) {
  const popup = {
    type: 'generalItems',
    body: <QrCodeScanner popupLayer={popupLayer} callback={callback} callbackType={callbackType} options={ {args, recordNos} } />,
  };

  dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayer], value: popup });
}

// DO SOMETHING WITH ENCRYPTED MNEMONICS
async function doSomethingWithEncryptedMnemonics(state, dispatch, mnemonicsEncrypted, callback, { args = ['keyPairs'], recordNos = [0] } = {}) {
  const keyPairs = [];
  const mnemonics = [];

  const argumentsAll = { keyPairs, mnemonics };
  const argumentsToPass = args.map( arg => argumentsAll[arg] );

  for (const recordNo of recordNos) {
    try {
      const pass = state.config.clientParameters.specialMessage;
      const salt = ['00', '00', '00', '00', '00', '00', '00', '72'];
      const iv = ['00', '00', '00', '00', '00', '00', '00', '00', '00', '00', '00', '00', '00', '01', 'bf', '52'];
      const mnemonic = await decrypt(state, dispatch, mnemonicsEncrypted[recordNo], pass, salt, iv);
      mnemonics.push(mnemonic);
      const keyPair = getKeyPairsFromMnemonic(mnemonic)[0];
      keyPairs.push(keyPair);
    }
    catch (error) {
      dispatch({ type: 'setNotification', key: 'notification', value: words.cannotReadDataFromTheKeyCard[state.language] });
    }
  }

  if (argumentsToPass.some( arg => arg.length === 0 )) {
    dispatch({ type: 'setNotification', key: 'notification', value: words.cannotReadDataFromTheKeyCard[state.language] });
    throw new Error('noArgumentToPass');
  }

  callback(...argumentsToPass);
}

// HANDLE CLICK GENERATE MNEMONIC
function handleClickGenerateMnemonic (state, dispatch) {
  const mnemonic = bip39.generateMnemonic(256);
  dispatch({ type: 'setStateMultiLayers', keys: ['configure', 'addressMain'], value: mnemonic }); 

  const keyPairs = getKeyPairsFromMnemonic(mnemonic);
  const result = getAddressFromKey(state, dispatch, keyPairs[0]);
  let address;

  if (result.status === 'fulfilled') {
    address = result.address;
    dispatch({ type: 'setStateMultiLayers', keys: ['configure', 'userName'], value: address }); 

    return {
      status: 'fulfilled',
      mnemonic: mnemonic,
      keyPairs: keyPairs,
      address: address,
    }
  }
  else {
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToGetTheAddress[state.language] });

    return {
      status: 'rejected',
      mnemonic: mnemonic,
      keyPairs: keyPairs,
    }
  }
}

// // ENCRYPT MONADOMICALLY
// function encryptMonadomically(state, dispatch, plaintext) {
//           const pass = state.config.clientParameters.specialMessage;
//           const salt = ['00', '00', '00', '00', '00', '00', '00', '72'];
//           const iv = ['00', '00', '00', '00', '00', '00', '00', '00', '00', '00', '00', '00', '00', '01', 'bf', '52'];
//           const mnemonic = await decrypt(state, dispatch, mnemonicEncrypted, pass, salt, iv);
//   const keyPairs = getKeyPairsFromMnemonic(mnemonic);
//   const result = getAddressFromKey(state, dispatch, keyPairs[0]);
//   let address;
// 
//   if (result.status === 'fulfilled') {
//     address = result.address;
//     dispatch({ type: 'setStateMultiLayers', keys: ['configure', 'userName'], value: address }); 
// 
//     return {
//       status: 'fulfilled',
//       mnemonic: mnemonic,
//       keyPairs: keyPairs,
//       address: address,
//     }
//   }
//   else {
//     dispatch({ type: 'setNotification', key: 'notification', value: words.failedToGetTheAddress[state.language] });
// 
//     return {
//       status: 'rejected',
//       mnemonic: mnemonic,
//       keyPairs: keyPairs,
//     }
//   }
// }

// 「カード登録申請」ボタン押下 HANDLE CLICK APPLY FOR CARD REGISTRATION
async function handleClickApplyForCardRegistration(state, dispatch, { keyPairs } = {}) {
  let addressMainActual;
  let signature;
  let request = {};
  let messages = {};
  let result;
  let keyPair;

  // state固定
  const stateFixed = {
    // addressMain: state.cardRegistration.addressMain,
    asset: state.cardRegistration.asset,
    name: state.cardRegistration.name,
    feature: state.cardRegistration.feature,
    attribute: state.cardRegistration.attribute,
    contact: state.cardRegistration.contact,
    // imageMain: state.cardRegistration.imagesNew.main,
    signatureVersion: state.config.clientParameters.signatureVersion.applyForCardRegistration,
    // addressCheck: state.addressCheck,
  };

  // 必須項目チェック
  if (stateFixed.asset === undefined || stateFixed.asset === null || stateFixed.asset === '') {
    dispatch({ type: 'setNotification', key: 'notification', value: words.assetMustBeFilled[state.language] });
    return;
  }

  if (stateFixed.name === undefined || stateFixed.name === null || stateFixed.name === '') {
    dispatch({ type: 'setNotification', key: 'notification', value: words.nameMustBeFilled[state.language] });
    return;
  }

  if (stateFixed.feature === undefined || stateFixed.feature === null || stateFixed.feature === '') {
    dispatch({ type: 'setNotification', key: 'notification', value: words.featureMustBeFilled[state.language] });
    return;
  }

  if (stateFixed.contact === undefined || stateFixed.contact === null || stateFixed.contact === '') {
    dispatch({ type: 'setNotification', key: 'notification', value: words.contactMustBeFilled[state.language] });
    return;
  }

  // 文字数チェック
  if (stateFixed.name.length > 25) {
    dispatch({ type: 'setNotification', key: 'notification', value: words.nameIsTooLong[state.language] });
    return;
  }

  if (stateFixed.feature.length > 70) {
    dispatch({ type: 'setNotification', key: 'notification', value: words.featureIsTooLong[state.language] });
    return;
  }

  // アドレスは入力させていないので、ノーチェックで署名する。
  const clientTime = Date.now();

  if (keyPairs !== undefined && keyPairs !== null) {
    let result;

    keyPair = keyPairs[0];
    result = getAddressFromKey(state, dispatch, keyPair);

    if (result.status === 'fulfilled') {
      addressMainActual = result.address;
    }
    else {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToGetTheAddress[state.language] });
      return result;
    }

    // 署名
    const message = `I apply for card registration of ${stateFixed.asset}. My main address is ${addressMainActual}. I am the owner of the card. (epoch time: ${clientTime}, signature version: ${stateFixed.signatureVersion})`;
    devLog('message', message);

    result = signWithKey(state, dispatch, keyPair, message);

    if (result.status === 'fulfilled') {
      signature = result.signature;
    }
    else {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSign[state.language] });
      return 'rejected';
    }
  }
  else {
    result = await window.mpurse.getAddress()
    .then( (result) => {
      addressMainActual = result;

      // if (stateFixed.addressCheck === 'strict' || stateFixed.addressCheck === 'addressSecond') {
      //   if (addressMainActual !== stateFixed.addressMain) {
      //     dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressFilledAndTheAddressInWalletAreDifferent[state.language] });
      //     return 'rejected';
      //   }
      //   else {
      //     return 'fulfilled';
      //   }
      // }
      // else { // off
      //   return 'fulfilled';
      // }

      return 'fulfilled';
    })
    .catch( (error) => {
        dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressInYourWalletCanNotBeGotten[state.language] });
        return 'rejected';
    });

    if (result === 'rejected') {
      return { status: 'rejected' };
    }

    // 署名
    const message = `I apply for card registration of ${stateFixed.asset}. My main address is ${addressMainActual}. I am the owner of the card. (epoch time: ${clientTime}, signature version: ${stateFixed.signatureVersion})`;
    devLog('message', message);

    result = await window.mpurse.signMessage(message)
    .then( result => {
      signature = result;
      return 'fulfilled';
    })
    .catch( err => {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSign[state.language] });
      return 'rejected';
    });

    if (result === 'rejected') {
      return { status: 'rejected' };
    }
  }

  const formData = new FormData();

  const requestJson = JSON.stringify({
    body: {
      addressMain: addressMainActual,
      addressMainActual: addressMainActual,
      asset: stateFixed.asset,
      name: stateFixed.name,
      feature: stateFixed.feature,
      attribute: stateFixed.attribute,
      contact: stateFixed.contact,
      clientTime : clientTime,
      signature : signature,
      signatureVersion : stateFixed.signatureVersion,
    }
  });

  formData.append('json', requestJson);

  // if (stateFixed.imageMain !== undefined && stateFixed.imageMain !== null) {
  //   formData.append('imageMain', stateFixed.imageMain, stateFixed.imageMain.name);
  // }

  request.body = formData;
  request.headers = {};
  request.url = 'https://' + process.env.REACT_APP_KOM_API_DOMAIN + '/apply_for_card_registration';
  request.method = 'POST';

  messages = {
    "you must accept the terms and conditions of the current version.": words.youMustAcceptTheTermsAndConditionsOfTheCurrentVersion[state.language],
    "internal server error": words.internalServerError[state.language],
    "bad signature": words.badSignature[state.language],
    "bad signature, can't recover address": words.badSignatureCanNotRecoverAddress[state.language],
    "unknown signatureVersion": words.unknownSignatureVersion[state.language],
    "clientTime must be larger than last time.": words.clientTimeMustBeLargerThanLastTime[state.language],
    "this address is not permitted.": words.thisAddressIsNotPermitted[state.language],
    "this address is forbidden.": words.thisAddressIsForbidden[state.language],
    "out of service": words.outOfService[state.language],
    "signature by asset owner is required.": words.signatureByAssetOwnerIsRequired[state.language],
    "asset is required; not asset_longname.": words.assetIsRequiredNotAssetLongname[state.language],
    "No such token exists.": words.noSuchTokenExists[state.language],
    // "you are not delegated.": words.norIsTheSignatureDelegatedFromMainAddress[state.language],
    // "addressMainActual must be filled.": words.clearYourBrowserOrAppCache[state.language],
    "success": words.success[state.language],
  };

  dispatch({ type: 'setState', key: 'accessing', value: true });

  const response = await syncHttpRequest2(request, undefined, undefined, 'notification', messages, dispatch);

  dispatch({ type: 'setState', key: 'accessing', value: false });

  if ( response.status === 'fulfilled' ) {
    // session情報更新
    dispatch({ type: 'setStateMultiLayers', keys: ['session', addressMainActual],
      value: {'sessionId': response.body.sessionId.addressMain, 'expirationOfSession': response.body.expirationOfSession.addressMain} });

    // if (addressMainActual !== stateFixed.addressMain) {
    //   dispatch({ type: 'setStateMultiLayers', keys: ['session', addressMainActual],
    //     value: {'sessionId': response.body.sessionId.addressMainActual, 'expirationOfSession': response.body.expirationOfSession.addressMainActual} });
    // }

    // // cookie情報更新(アドレス情報)
    // const userName = state.usersGeneralIndex[stateFixed.addressMain] !== undefined ? state.usersGeneralIndex[stateFixed.addressMain].userName : stateFixed.addressMain;
    // const cookiesNew = [
    //       {
    //         action: 'setUp',
    //         addressType: 'addressMain',
    //         description: `${userName}`,
    //         time: clientTime,
    //         address: stateFixed.addressMain
    //       },
    // ];

    // setAddressesInCookie(state, dispatch, cookies, setCookie, stateFixed.addressMain, cookiesNew);
  }
  else {
  }

  return response;
}

// 「クリエイター情報編集」ボタン押下 HANDLE CLICK EDIT CREATOR INFORMATION
async function handleClickEditCreatorInformation(state, dispatch, asset, { keyPairs } = {}) {
  let addressMainActual;
  let signature;
  let request = {};
  let messages = {};
  let result;
  let keyPair;

  // state固定
  const stateFixed = {
    // addressMain: state.cardRegistration.addressMain,
    creater: state.creatorInformation.creater,
    createrText: state.creatorInformation.createrText,
    monacottoAddressMain: state.creatorInformation.monacottoAddressMain,
    createrLink: state.creatorInformation.createrLink,
    // imageMain: state.cardRegistration.imagesNew.main,
    signatureVersion: state.config.clientParameters.signatureVersion.editCreatorInformation,
    // addressCheck: state.addressCheck,
  };

  // 必須項目チェック
  if (asset === undefined || asset === null || asset === '') {
    dispatch({ type: 'setNotification', key: 'notification', value: words.assetMustBeFilled[state.language] });
    return;
  }

  // // 文字数チェック
  // if (stateFixed.name.length > 25) {
  //   dispatch({ type: 'setNotification', key: 'notification', value: words.nameIsTooLong[state.language] });
  //   return;
  // }

  // if (stateFixed.feature.length > 70) {
  //   dispatch({ type: 'setNotification', key: 'notification', value: words.featureIsTooLong[state.language] });
  //   return;
  // }

  // アドレスは入力させていないので、ノーチェックで署名する。
  const clientTime = Date.now();

  if (keyPairs !== undefined && keyPairs !== null) {
    let result;

    keyPair = keyPairs[0];
    result = getAddressFromKey(state, dispatch, keyPair);

    if (result.status === 'fulfilled') {
      addressMainActual = result.address;
    }
    else {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToGetTheAddress[state.language] });
      return result;
    }

    // 署名
    const message = `I edit the creator information of ${asset}. My main address is ${addressMainActual}. I am the owner of the card. (epoch time: ${clientTime}, signature version: ${stateFixed.signatureVersion})`;
    devLog('message', message);

    result = signWithKey(state, dispatch, keyPair, message);

    if (result.status === 'fulfilled') {
      signature = result.signature;
    }
    else {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSign[state.language] });
      return 'rejected';
    }
  }
  else {
    result = await window.mpurse.getAddress()
    .then( (result) => {
      addressMainActual = result;

      // if (stateFixed.addressCheck === 'strict' || stateFixed.addressCheck === 'addressSecond') {
      //   if (addressMainActual !== stateFixed.addressMain) {
      //     dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressFilledAndTheAddressInWalletAreDifferent[state.language] });
      //     return 'rejected';
      //   }
      //   else {
      //     return 'fulfilled';
      //   }
      // }
      // else { // off
      //   return 'fulfilled';
      // }

      return 'fulfilled';
    })
    .catch( (error) => {
        dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressInYourWalletCanNotBeGotten[state.language] });
        return 'rejected';
    });

    if (result === 'rejected') {
      return { status: 'rejected' };
    }

    // 署名
    const message = `I edit the creator information of ${asset}. My main address is ${addressMainActual}. I am the owner of the card. (epoch time: ${clientTime}, signature version: ${stateFixed.signatureVersion})`;
    devLog('message', message);

    result = await window.mpurse.signMessage(message)
    .then( result => {
      signature = result;
      return 'fulfilled';
    })
    .catch( err => {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSign[state.language] });
      return 'rejected';
    });

    if (result === 'rejected') {
      return { status: 'rejected' };
    }
  }

  const formData = new FormData();

  const requestJson = JSON.stringify({
    body: {
      addressMain: addressMainActual,
      addressMainActual: addressMainActual,
      asset: asset,
      creater: stateFixed.creater,
      createrText: stateFixed.createrText,
      monacottoAddressMain: stateFixed.monacottoAddressMain,
      createrLink: stateFixed.createrLink,
      clientTime : clientTime,
      signature : signature,
      signatureVersion : stateFixed.signatureVersion,
    }
  });

  formData.append('json', requestJson);

  // if (stateFixed.imageMain !== undefined && stateFixed.imageMain !== null) {
  //   formData.append('imageMain', stateFixed.imageMain, stateFixed.imageMain.name);
  // }

  request.body = formData;
  request.headers = {};
  request.url = 'https://' + process.env.REACT_APP_KOM_API_DOMAIN + '/edit_creator_information';
  request.method = 'POST';

  messages = {
    "you must accept the terms and conditions of the current version.": words.youMustAcceptTheTermsAndConditionsOfTheCurrentVersion[state.language],
    "internal server error": words.internalServerError[state.language],
    "bad signature": words.badSignature[state.language],
    "bad signature, can't recover address": words.badSignatureCanNotRecoverAddress[state.language],
    "unknown signatureVersion": words.unknownSignatureVersion[state.language],
    "clientTime must be larger than last time.": words.clientTimeMustBeLargerThanLastTime[state.language],
    "this address is not permitted.": words.thisAddressIsNotPermitted[state.language],
    "this address is forbidden.": words.thisAddressIsForbidden[state.language],
    "out of service": words.outOfService[state.language],
    "signature by asset owner is required.": words.signatureByAssetOwnerIsRequired[state.language],
    "asset is required; not asset_longname.": words.assetIsRequiredNotAssetLongname[state.language],
    // "you are not delegated.": words.norIsTheSignatureDelegatedFromMainAddress[state.language],
    // "addressMainActual must be filled.": words.clearYourBrowserOrAppCache[state.language],
    "success": words.success[state.language],
  };

  dispatch({ type: 'setState', key: 'accessing', value: true });

  const response = await syncHttpRequest2(request, undefined, undefined, 'notification', messages, dispatch);

  dispatch({ type: 'setState', key: 'accessing', value: false });

  if ( response.status === 'fulfilled' ) {
    // session情報更新
    dispatch({ type: 'setStateMultiLayers', keys: ['session', addressMainActual],
      value: {'sessionId': response.body.sessionId.addressMain, 'expirationOfSession': response.body.expirationOfSession.addressMain} });

    // if (addressMainActual !== stateFixed.addressMain) {
    //   dispatch({ type: 'setStateMultiLayers', keys: ['session', addressMainActual],
    //     value: {'sessionId': response.body.sessionId.addressMainActual, 'expirationOfSession': response.body.expirationOfSession.addressMainActual} });
    // }

    // // cookie情報更新(アドレス情報)
    // const userName = state.usersGeneralIndex[stateFixed.addressMain] !== undefined ? state.usersGeneralIndex[stateFixed.addressMain].userName : stateFixed.addressMain;
    // const cookiesNew = [
    //       {
    //         action: 'setUp',
    //         addressType: 'addressMain',
    //         description: `${userName}`,
    //         time: clientTime,
    //         address: stateFixed.addressMain
    //       },
    // ];

    // setAddressesInCookie(state, dispatch, cookies, setCookie, stateFixed.addressMain, cookiesNew);

    // -- 登録カード情報取得
    getRegisteredCard(state, dispatch);
  }
  else {
  }

  return response;
}


// // HANDLE CLICK ADDRESS HISTORY
// function handleClickAddressHistory(state, dispatch, cookies, stateKeys, popupLayer) {
//   const popupLayerNext = popupLayer !== undefined && popupLayer !== null ? (popupLayer + 1) : 0;
//   let addressHistory = cookies.addresses;
// 
//   if (addressHistory === undefined) {
//     addressHistory = [];
//   }
// 
//   const options = addressHistory.map( record => <AddressHistoryRecord record={record} stateKeys={stateKeys} layer={popupLayerNext} /> );
// 
//   const popup = {
//     type: 'generalList',
//     body: {
//       title: <AddressHistoryTitle />,
//       options: options,
//     },
//   };
// 
//   dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayerNext], value: popup });
// }

// HANDLE CLICK VIEW SESSION
function handleClickViewSession(state, dispatch, popupLayer) {
  const popupLayerNext = popupLayer !== undefined && popupLayer !== null ? (popupLayer + 1) : 0;
  let sessionAddresses = Object.keys(state.session);

  // if (addressHistory === undefined) {
  //   addressHistory = [];
  // }

  sessionAddresses = sessionAddresses.filter( address =>
    state.session[address].expirationOfSession >= Date.now() && state.balance[address] !== undefined && state.deckSet[address] !== undefined
  );

  // const records = sessionAddresses.map( address => <SessionRecord record={ {address} } /> );

  // const popup = {
  //   type: 'generalList',
  //   body: {
  //     title: <SessionTitle />,
  //     options: records,
  //   },
  // };

  const popup = {
    type: 'selectSessionPopup',
    body: {
      keysArray: [
        ['active', 'addressMain'],
        ['login', 'addressMain'],
        ['getPointHistory', 'addressMain'],
        ['getItemOrderHistory', 'addressMain'],
        ['makeUrlToBroadcast', 'addressMain'],
      ],
      qr: true,
      sessionAddresses: sessionAddresses,
    },
  };

  dispatch({ type: 'setStateMultiLayers', keys: ['popup', popupLayerNext], value: popup });

  if (sessionAddresses.length === 0) {
    dispatch({ type: 'setState', key: 'session', value: {} });
  }
}

// SESSION TITLE
function SessionTitle() {
  const [state] = useContext(GlobalState);

  return (
    <div className='widthMax'>
      {/* PC */}
      <div className='visibleMiddleOrMore boxMonacotto1 margin0p5' >
        <div className='marginSide1 widthMin25' >
          { words.loggedInAddress[state.language] }
        </div>
      </div>
      {/* SP */}
      <div className='visibleSmallOrLess boxMonacotto1SmallScreen alignItemsCenter' >
        <div className='textCenter' >
          { words.loggedInAddress[state.language] }
        </div>
      </div>
    </div>
  );
}

// SESSION RECORD
function SessionRecord(props) {
  const [state, dispatch] = useContext(GlobalState);
  const record = props.record;

  return (
    <div className=''>
      {/* PC */}
      <div className='visibleMiddleOrMore boxMonacotto1 margin0p5' >
        <div className='marginSide1 widthMin25' >
          { record.address }
        </div>
      </div>
      {/* SP */}
      <div className='visibleSmallOrLess boxMonacotto1SmallScreen alignItemsCenter' >
        <div className='textCenter' >
          { record.address }
        </div>
      </div>
    </div>
  );
}


// SET SIGNATURE BY MAIN ADDRESS
async function setSignatureByAddressMain(state, dispatch, stateFixed, certifications, clientTime, extendedCertificationProperties, messageType) {
  // アクティブなMONAアドレスが有り、そのアドレスがcertificationsに含まれていなければ、署名する。
  // if (stateFixed.addressMain !== undefined && stateFixed.addressMain !== '' && !certifications.map( certification => certification.address ).includes(stateFixed.addressMain)) {
       // (stateFixed[stateFixed.addressMain] === undefined || stateFixed[stateFixed.addressMain].expirationOfSession < clientTime + marginOfSessionTime)) {

    // -- Mpurseのアドレス取得
    const result = await window.mpurse.getAddress()
    .then( async (result) => {
      const addressActual = result;
      let address;

      // lastEvaluatedSortKey等があるということは、前回の検索時からaddressMainが変更されていないということ。(変更するとリセットされてしまう。)
      // addressMainが指定されていないということは、後ページ検索ではない。
      // なぜならば、addressMainが指定されていない状態にするには、前回の検索のあと、addressMainを削除しなければならないから。

      if (stateFixed.addressMain !== undefined && stateFixed.addressMain !== null && stateFixed.addressMain !== '') {
        if (stateFixed.addressCheck === 'strict' || stateFixed.addressCheck === 'addressSecond') {
          if (addressActual !== stateFixed.addressMain) {
            dispatch({ type: 'setNotification', key: 'notification', value: words.mainAddressAndTheAddressInWalletAreDifferent[state.language] });
            return 'rejected';
          }
          else {
            // 続行
          }
        }
        else { // off
          // 続行
        }

        address = stateFixed.addressMain;
      }
      else {
        address = addressActual;
        stateFixed.addressMain = addressActual;
      }

      devLog('address', address);
      devLog('certifications', JSON.stringify(certifications));

      if (!certifications.map( certification => certification.address ).includes(address)) {
        let message;

        if (messageType === 'login') {
          message = `I want to log in to Knights of Monadom as ${address}. (epoch time: ${clientTime}, signature version: ${stateFixed.signatureVersion})`;
        }
        else if (messageType === 'information') {
          message = `I want to get the information of ${address}. (epoch time: ${clientTime}, signature version: ${stateFixed.signatureVersion})`;
        }

        devLog('message', message);

        let signatureMona;

        try {
          signatureMona = await window.mpurse.signMessage(message).then( result => result );

          const certification = {
            // addressCoinType: 'mona',
            address: address,
            addressActual: addressActual,
            clientTime: clientTime,
            signatureVersion: stateFixed.signatureVersion,
            signature: signatureMona,
            lastEvaluatedKey: stateFixed.lastEvaluatedKey,
          };

          // 拡張プロパティ
          if (extendedCertificationProperties !== undefined && extendedCertificationProperties !== null) {
            for (const property of extendedCertificationProperties) {
              certification[property.key] = property.value;
            }
          }

          devLog('certification addressMain', JSON.stringify(certification));

          certifications.push(certification);
        }
        catch (err) {
          dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSign[state.language] });
        }
      }
    })
    .catch( (error) => {
        dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressInYourWalletCanNotBeGotten[state.language] });
    });
  // }
}


// GET BALANCES
async function getBalances(state, dispatch, address) {
  let balancesArray =[];
  const balances = {};
  // const defaultLimit = state.config.clientParameters.counterparty.getTableDefaultLimit;
  const defaultLimit = 500;
  let offset = 0;
  let serverIndex = 0;

  const request = {};
  request.method = 'POST';

  SERVER: while (true) {
    balancesArray =[];
    offset = 0;

    request.url = `https://${state.config.clientParameters.monapaServer.domainAndPath[serverIndex]}`;
    // request.url = 'https://monapa.electrum-mona.org/_api';
    // request.url = 'https://wallet.monaparty.me/_api';
    // request.url = 'https://counterblock.api.monaparty.me/';

    while (true) {
      let balancesArrayPartial;
      const bodyObj = {
        jsonrpc: "2.0",
        id: 0,
        method: "proxy_to_counterpartyd",
        params: {
          method: "get_balances",
          params: {
            filters: [
              {field: "address", op: "==", value: address}
            ],
            offset: offset,
            limit: defaultLimit,
          },
        }
      };

      request.body = JSON.stringify(bodyObj);
      devLog('get balances; request', JSON.stringify(request));

      const response = await syncHttpRequest(request);
      devLog('get balances; response', JSON.stringify(response));

      if (response.status === 'rejected') {
        if (serverIndex >= state.config.clientParameters.monapaServer.domainAndPath.length - 1) {
          return {
            status: 'rejected',
            error: {}
          };
        }
        else {
          serverIndex++;
          continue SERVER;
        }
      }
      else if (response.body.error !== undefined) {
        if (serverIndex >= state.config.clientParameters.monapaServer.domainAndPath.length - 1) {
          // -- 失敗 エラー返却
          return {
            status: 'rejected',
            error: response.body.error
          };
        }
        else {
          serverIndex++;
          continue SERVER;
        }
      }
      else if (response.body.result.length === 0) {
        // -- バランス無し
        balancesArrayPartial = response.body.result;
      }
      else {
        // -- 成功
        balancesArrayPartial = response.body.result;
      }

      balancesArray = balancesArray.concat(balancesArrayPartial);

      if (balancesArrayPartial.length < defaultLimit) {
        break SERVER;
      }
      else {
        offset += defaultLimit;
      }
    }
  }

  // state格納
  for (const balance of balancesArray) {
    balances[balance.asset] = balance;
  }

  dispatch({ type: 'setStateMultiLayers', keys: ['balance', address], value: balances });

  if (balancesArray.length === 0) {
    return {
      status: 'notFound',
      body: {},
      bodyArray: [],
    };
  }
  else {
    return {
      status: 'fulfilled',
      body: balances,
      bodyArray: balancesArray,
    };
  }
}

// GET OWNED ASSETS
async function getOwnedAssets(state, dispatch, address) {
  let ownedAssetsArray =[];
  // const ownedAssets = {};
  let serverIndex = 0;

  const request = {};
  request.method = 'POST';

  const bodyObj = {
    jsonrpc: "2.0",
    id: 0,
    method: "get_owned_assets",
    params: {
      addresses: [
        address,
      ],
    },
  };

  request.body = JSON.stringify(bodyObj);

  SERVER: while (true) {
    request.url = `https://${state.config.clientParameters.monapaServer.domainAndPath[serverIndex]}`;
    // request.url = 'https://monapa.electrum-mona.org/_api';
    // request.url = 'https://wallet.monaparty.me/_api';
    // request.url = 'https://counterblock.api.monaparty.me/';

    devLog('get balances; request', JSON.stringify(request));

    const response = await syncHttpRequest(request);
    devLog('get balances; response', JSON.stringify(response));

    if (response.status === 'rejected') {
      if (serverIndex >= state.config.clientParameters.monapaServer.domainAndPath.length - 1) {
        return {
          status: 'rejected',
          error: {}
        };
      }
      else {
        serverIndex++;
        continue SERVER;
      }
    }
    else if (response.body.error !== undefined) {
      if (serverIndex >= state.config.clientParameters.monapaServer.domainAndPath.length - 1) {
        // -- 失敗 エラー返却
        return {
          status: 'rejected',
          error: response.body.error
        };
      }
      else {
        serverIndex++;
        continue SERVER;
      }
    }
    else if (response.body.result.length === 0) {
      // -- オウンドアッセトは存在しない
      ownedAssetsArray = response.body.result;
    }
    else {
      // -- 成功
      ownedAssetsArray = response.body.result;
      break SERVER;
    }
  }

  // // state格納
  // for (const asset of ownedAssetsArray) {
  //   ownedAssets[asset.asset] = asset;
  // }

  // dispatch({ type: 'setStateMultiLayers', keys: ['ownedAssets', address], value: ownedAssets });

  // if (ownedAssetsArray.length === 0) {
  //   return {
  //     status: 'notFound',
  //     bodyArray: [],
  //   };
  // }
  // else {
    return {
      status: 'fulfilled',
      bodyArray: ownedAssetsArray,
    };
  // }
}


// GET ASSET INFO
async function getAssetInfo(state, dispatch, assets) {
  let assetInfosArray;
  const assetInfos = state.assetInfo;
  let serverIndex = 0;

  const request = {};
  request.method = 'POST';

  const bodyObj = {
    jsonrpc: "2.0",
    id: 0,
    method: "get_assets_info",
    params: {
      assetsList: assets,
    }
  };

  request.body = JSON.stringify(bodyObj);

  SERVER: while (true) {
    request.url = `https://${state.config.clientParameters.monapaServer.domainAndPath[serverIndex]}`;
    // request.url = 'https://monapa.electrum-mona.org/_api';
    // request.url = 'https://wallet.monaparty.me/_api';
    // request.url = 'https://counterblock.api.monaparty.me/';
    // request.body = `{"jsonrpc":"2.0","id":0,"method":"get_assets_info","params":{"assetsList":["${tokenName}"]}}`;

    devLog('get asset info; request', JSON.stringify(request));

    const response = await syncHttpRequest(request);
    devLog('get asset info; response', JSON.stringify(response));

    if (response.status === 'rejected') {
      if (serverIndex >= state.config.clientParameters.monapaServer.domainAndPath.length - 1) {
        return {
          status: 'rejected',
          error: {}
        };
      }
      else {
        serverIndex++;
        continue SERVER;
      }
    }
    else if (response.body.error !== undefined) {
      if (serverIndex >= state.config.clientParameters.monapaServer.domainAndPath.length - 1) {
        // -- 失敗 エラー返却
        return {
          status: 'rejected',
          error: response.body.error
        };
      }
      else {
        serverIndex++;
        continue SERVER;
      }
    }
    else if (response.body.result.length === 0) {
      // -- そんなトークンは存在しない
      assetInfosArray = response.body.result;
    }
    else {
      // -- 成功
      assetInfosArray = response.body.result;
      break SERVER;
    }
  }

  // state格納
  for (const assetInfo of assetInfosArray) {
    assetInfos[assetInfo.asset] = assetInfo;
  }

  dispatch({ type: 'setState', key: 'assetInfo', value: assetInfos });

  if (assetInfosArray.length === 0) {
    return { status: 'notFound', body: {} };
  }
  else {
    return { status: 'fulfilled', body: assetInfos };
  }
}

// GET MONACARD
async function getMonacard(state, dispatch, assets) {
//  const tokenNamesCSV = tokenNames.reduce( (acc, cur) => `${acc},${cur}`, '' );

  const assetCsvsDivided = [];
  let csv = '';

  for (const asset of assets) {
    if ( (new Blob([csv])).size > 1700 ) {
      assetCsvsDivided.push(csv);
      csv = '';
    }

    csv += ',' + asset;
  }

  assetCsvsDivided.push(csv);

  let request = {};
  let response;
  let monacardsArray = [];
  let monacards = state.monacard;

  request.method = 'GET';
  request.body = undefined;

  for (const assetCSV of assetCsvsDivided) {
    request.url = `https://card.mona.jp/api/card_detail?assets=${assetCSV}`
    devLog('get monacard; request', JSON.stringify(request));

    response = await syncHttpRequest(request);
    devLog('get monacard; response', JSON.stringify(response));

    if (response.status === 'rejected') {
      return { status: 'rejected' };
    }
    else if (response.body.error !== undefined) {
      if (response.body.error.message === 'No matched assets.') {
//        return { status: 'notFound', body: [] };
      }
      else {
        return { status: 'rejected', error: response.body.error.message };
      }
    }
    else if (response.body.details === undefined) {
      return { status: 'rejected', error: 'details undefined' };
    }
    else {
      // -- get成功！
//      return { status: 'fulfilled', body: response.body.details };
      monacardsArray = monacardsArray.concat(response.body.details);
    }
  }

  // state格納
  for (const monacard of monacardsArray) {
    monacards[monacard.asset] = monacard;
  }

  dispatch({ type: 'setState', key: 'monacard', value: monacards });

  if (monacardsArray.length === 0) {
    return { status: 'notFound', body: [] };
  }
  else {
    return { status: 'fulfilled', body: monacards };
  }
}


// LOAD DECK
async function loadDeck(state, dispatch, certification) {
  let request = {};
  let messages = {};
  const now = Date.now();

  // state固定
  const stateFixed = {
  };

  const addressMainsValid = Object.keys(state.session).filter( addressMain => state.session[addressMain].expirationOfSession >= now + marginOfSessionTime )
  let certifications = addressMainsValid.map( addressMain => { return { addressMain: addressMain, sessionId: state.session[addressMain].sessionId }; } )

  if (certification !== undefined && certification !== null && !addressMainsValid.includes(certification.addressMain)) {
    certifications = certifications.concat([certification]);
  }

  request.body = JSON.stringify({
    body: {
      certifications: certifications,
    }
  });

  request.url = 'https://' + process.env.REACT_APP_KOM_API_DOMAIN + '/load_deck';
  request.method = 'POST';

  messages = {
  };

  const response = await syncHttpRequest2(request, 'deckSet', undefined, undefined, messages, dispatch);

  if ( response.status === 'rejected' ) {
    const deckSets = {};

    for (const addressMain of Object.keys(state.session)) {
      deckSets[addressMain] = {
        currentDeckNo: 1,
        decks: [
          null,
          {
            deckName: 'deck1',
            cards: [
            ],
          },
        ],
      };
    }

    dispatch({ type: 'setState', key: 'deckSet', value: deckSets });

    return {
      status: 'rejected',
      body: deckSets,
    };
  }

  return {
    status: 'fulfilled',
    body: response.body,
  };
}

// HANDLE CLICK SAVE DECK
async function handleClickSaveDeck(state, dispatch) {
  let request = {};
  let messages = {};

  // state固定
  const stateFixed = {
    addressMain: state.active.addressMain,
    sessionId: state.session[state.active.addressMain].sessionId,
    deckSet: state.deckSet[state.active.addressMain],
  };

  request.body = JSON.stringify({
    body: {
      addressMain: stateFixed.addressMain,
      sessionId: stateFixed.sessionId,
      deckSet: stateFixed.deckSet,
    }
  });

  request.url = 'https://' + process.env.REACT_APP_KOM_API_DOMAIN + '/save_deck';
  request.method = 'POST';

  messages = {
    "you must accept the terms and conditions of the current version.": words.youMustAcceptTheTermsAndConditionsOfTheCurrentVersion[state.language],
    "internal server error": words.internalServerError[state.language],
    "this address is not permitted.": words.thisAddressIsNotPermitted[state.language],
    "this address is forbidden.": words.thisAddressIsForbidden[state.language],
    "out of service": words.outOfService[state.language],
    "user does not exist.": words.pleaseRegisterAsAUserOnTheSetupPage[state.language],
    "invalid session.": words.pleaseLogin[state.language],
    "success": words.success[state.language],
  };

  const response = await syncHttpRequest2(request, undefined, undefined, 'notification', messages, dispatch);

  if ( response.status === 'rejected' ) {
    return {
      status: 'rejected',
    };
  }

  dispatch({ type: 'setStateMultiLayers', keys: ['deckSet', stateFixed.addressMain, 'status'], value: 'saved' });

  return {
    status: 'fulfilled',
  };
}

// ADD CARD TO DECK
function addCardToDeck(state, dispatch, cardTarget) {
  const addressMain = state.active.addressMain;
  const currentDeckNo = state.deckSet[addressMain].currentDeckNo;
  const currentDeckCards = state.deckSet[addressMain].decks[currentDeckNo].cards;

  if (currentDeckCards.length >= state.config.clientParameters.numberOfSlots.advanced) {
    return {
      status: 'rejected',
    };
  }
  else if (currentDeckCards.filter( card => card.asset === cardTarget.asset ).length < cardTarget.quantity) {
    const cardNew = {
      asset: cardTarget.asset,
      // assetCommon: assetCommon(state, cardTarget.asset),
      // imgur_url: state.monacard[cardTarget.asset].imgur_url,
    }
    currentDeckCards.push(cardNew);
    dispatch({ type: 'setStateMultiLayers', keys: ['deckSet', addressMain, 'decks', currentDeckNo, 'cards'], value: currentDeckCards });

    return {
      status: 'fulfilled',
      body: currentDeckCards,
    };
  }
  else {
    return {
      status: 'rejected',
    };
  }
}

// REMOVE CARD FROM DECK
function removeCardFromDeck(state, dispatch, asset) {
  const addressMain = state.active.addressMain;
  const currentDeckNo = state.deckSet[addressMain].currentDeckNo;
  const currentDeckCards = state.deckSet[addressMain].decks[currentDeckNo].cards;

  for (let i = currentDeckCards.length - 1; i >= 0; i--) {
    if (currentDeckCards[i].asset === asset) {
      currentDeckCards.splice(i, 1);
      break;
    }
  }

  dispatch({ type: 'setStateMultiLayers', keys: ['deckSet', addressMain, 'decks', currentDeckNo, 'cards'], value: currentDeckCards });

  return {
    status: 'fulfilled',
    body: currentDeckCards,
  };
}

// GET REGISTERED CARD
async function getRegisteredCard(state, dispatch) {
  let request = {};
  let messages = {};
  const registeredCardsIndex = {};

  // state固定
  const stateFixed = {
  };

  request.body = JSON.stringify({
    "body": {
    }
  });

  request.url = 'https://' + process.env.REACT_APP_KOM_API_DOMAIN + '/get_registered_card';
  request.method = 'POST';

  messages = {
  };

  const response = await syncHttpRequest2(request, undefined, undefined, undefined, messages, dispatch);

  if ( response.status === 'rejected' ) {
    return {
      status: 'rejected',
    };
  }

  for (const registeredCard of response.body) {
    registeredCardsIndex[registeredCard.asset] = registeredCard;
  }

  dispatch({ type: 'setState', key: 'registeredCard', value: registeredCardsIndex });
  devLog('registeredCard', JSON.stringify(registeredCardsIndex));

  return {
    status: 'fulfilled',
    body: registeredCardsIndex,
  };
}


// 「購入:署名」ボタン押下 HANDLE CLICK SIGN PURCHASE
async function handleClickSignPurchase(state, dispatch, addressType, popupLayer, keyPairs) {
  let addressActual;
  let signature;
  let request = {};
  let messages = {};
  let stateFixed = {};
  let bodyObj = {};
  let amountMona;
  let keyPair;

  // state固定
  if (popupLayer !== undefined && popupLayer !== null) {
    stateFixed = {
      exhibitNo: state.popup[popupLayer].body.exhibitNo,
      purchaseNo: state.popup[popupLayer].body.purchaseNo,
      addressMain: state.popup[popupLayer].body.addressMain,
      addressPayFrom: state.popup[popupLayer].body.addressPayFrom,
      amountToBuy: state.popup[popupLayer].body.amountToBuy,
      priceMona: state.configMP.clientParameters.priceMona,
      monatokaPointAddress: state.configMP.clientParameters.monatokaPointAddress,
      signatureByAddressMain: state.popup[popupLayer].body.signatureByAddressMain,
      signatureVersion: state.configMP.clientParameters.signatureVersion.purchasePoint,
      // addressCheck: state.addressCheck,
      addressCheck: 'strict',
      provisionalTransactionFee: state.config.clientParameters.provisionalTransactionFee,
    };
  }
  else {
    stateFixed = {
      purchaseNo: state.purchasePoint.purchaseNo,
      addressMain: state.purchasePoint.addressMain,
      addressPayFrom: state.purchasePoint.addressPayFrom,
      amountToBuy: state.purchasePoint.amountToBuy,
      priceMona: state.configMP.clientParameters.priceMona,
      monatokaPointAddress: state.configMP.clientParameters.monatokaPointAddress,
      signatureByAddressMain: state.purchasePoint.signatureByAddressMain,
      signatureVersion: state.configMP.clientParameters.signatureVersion.purchasePoint,
      // addressCheck: state.addressCheck,
      addressCheck: 'strict',
      provisionalTransactionFee: state.config.clientParameters.provisionalTransactionFee,
    };
  }

  if (keyPairs !== undefined && keyPairs !== null) {
    keyPair = keyPairs[0];
    const result = getAddressFromKey(state, dispatch, keyPair);

    if (result.status === 'fulfilled') {
      stateFixed.addressMain = result.address;
      stateFixed.addressPayFrom = result.address;
    }
    else {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToGetTheAddress[state.language] });
      return 'rejected';
    }
  }

  // 必須項目チェック
  if (stateFixed.addressMain === undefined || stateFixed.addressMain === null || stateFixed.addressMain === '') {
    dispatch({ type: 'setNotification', key: 'notification', value: words.mainAddressMustBeFilled[state.language] });
    return { status: 'rejected' };
  }

  if (stateFixed.addressPayFrom === undefined || stateFixed.addressPayFrom === null || stateFixed.addressPayFrom === '') {
    dispatch({ type: 'setNotification', key: 'notification', value: words.addressYouPayFromMustBeFilled[state.language] });
    return { status: 'rejected' };
  }

  if (stateFixed.amountToBuy === undefined || stateFixed.amountToBuy === null || stateFixed.amountToBuy === '') {
    dispatch({ type: 'setNotification', key: 'notification', value: words.amountToBuyMustBeFilled[state.language] });
    return { status: 'rejected' };
  }

  // 数量チェック
  if (stateFixed.amountToBuy > stateFixed.amountFree) {
    dispatch({ type: 'setNotification', key: 'notification', value: words.amountToBuyMustBeWithinTheNumberOfStock[state.language] });
    return { status: 'rejected' };
  }

  if (stateFixed.amountToBuy < 1) {
    dispatch({ type: 'setNotification', key: 'notification', value: words.amountToBuyMustBeEqualOrLargerThan1[state.language] });
    return { status: 'rejected' };
  }

  devLog('popupLayer', popupLayer);
  devLog('stateFixed', JSON.stringify(stateFixed));


  const clientTime = Date.now();
  const message = `I want to buy ${stateFixed.amountToBuy} monatoka points for ${stateFixed.priceMona} MONA per unit. I want to purchase them as ${stateFixed.addressMain}. I will send MONA from ${stateFixed.addressPayFrom}. (epoch time: ${clientTime}, signature version: ${stateFixed.signatureVersion})`;

  if (keyPair !== undefined) {
    addressActual = stateFixed.addressMain;

    const result = signWithKey(state, dispatch, keyPair, message);

    if (result.status === 'fulfilled') {
      signature = result.signature;
    }
    else {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSign[state.language] });
      return 'rejected';
    }
  }
  else {
    // Mpurseのアドレスが合ってるかチェック
    const result = await window.mpurse.getAddress()
    .then( (result) => {
      addressActual = result;
      devLog('address', stateFixed.addressMain, stateFixed.addressPayFrom, addressActual, stateFixed.addressCheck);

      if (stateFixed.addressCheck === 'strict') {
        if (addressActual !== (addressType === 'addressMain' ? stateFixed.addressMain : stateFixed.addressPayFrom)) {
          dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressFilledAndTheAddressInWalletAreDifferent[state.language] });
          return 'rejected';
        }
        else {
          return 'fulfilled';
        }
      }
      else if (stateFixed.addressCheck === 'addressSecond') {
        if (addressActual !== stateFixed.addressMain && addressActual !== stateFixed.addressPayFrom) {
          dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressFilledAndTheAddressInWalletAreDifferent[state.language] });
          return 'rejected';
        }
        else {
          return 'fulfilled';
        }
      }
      else { // off
        return 'fulfilled';
      }
    })
    .catch( (error) => {
        dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressInYourWalletCanNotBeGotten[state.language] });
        return 'rejected';
    });

    if (result === 'rejected') {
      return { status: 'rejected' };
    }

    // 署名

    try {
      signature= await window.mpurse.signMessage(message).then( result => result );
    }
    catch (err) {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSign[state.language] });
      return { status: 'rejected' };
    }
  }

  // GO！

  if (stateFixed.purchaseNo === null || stateFixed.purchaseNo === '') {
    bodyObj = {
      body: {
        addressType: addressType,
        addressMain: stateFixed.addressMain,
        addressMainActual: addressActual,
        addressPayFrom: stateFixed.addressPayFrom,
        amountToBuy: stateFixed.amountToBuy,
        priceMona: stateFixed.priceMona,
        monatokaPointAddress: stateFixed.monatokaPointAddress,
      }
    };

    bodyObj.body.clientTimeOfAddressMain = clientTime;
    bodyObj.body.signatureByAddressMain = signature;
    bodyObj.body.signatureVersionOfAddressMain = stateFixed.signatureVersion;

    // if (stateFixed.addressMain === stateFixed.addressPayFrom || addressActual === stateFixed.addressPayFrom) {
    //   bodyObj.body.clientTimeOfAddressPayFrom = clientTime;
    //   bodyObj.body.signatureByAddressPayFrom = signature;
    //   bodyObj.body.signatureVersionOfAddressPayFrom = stateFixed.signatureVersion;
    // }

    request.body = JSON.stringify(bodyObj);
  }
  else {
    bodyObj = {
      body: {
        addressType: addressType,
        addressMain: stateFixed.addressMain,
        addressPayFromActual: addressActual,
        purchaseNo: stateFixed.purchaseNo,
      }
    };

    bodyObj.body.clientTimeOfAddressPayFrom = clientTime;
    bodyObj.body.signatureByAddressPayFrom = signature;
    bodyObj.body.signatureVersionOfAddressPayFrom = stateFixed.signatureVersion;

    request.body = JSON.stringify(bodyObj);
  }

  request.url = 'https://' + process.env.REACT_APP_KOM_API_DOMAIN + '/purchase_point';
  request.method = 'POST';

  messages = {
    "you must accept the terms and conditions of the current version.": words.youMustAcceptTheTermsAndConditionsOfTheCurrentVersion[state.language],
    "internal server error": words.internalServerError[state.language],
    "bad signature": words.badSignature[state.language],
    "unknown signatureVersion": words.unknownSignatureVersion[state.language],
    "clientTime must be larger than last time.": words.clientTimeMustBeLargerThanLastTime[state.language],
    "this address is not permitted.": words.thisAddressIsNotPermitted[state.language],
    "this address is forbidden.": words.thisAddressIsForbidden[state.language],
    "order expired.": words.orderExpired[state.language],
    "out of service": words.outOfService[state.language],
    "user does not exist.": words.pleaseRegisterAsAUserOnTheSetupPage[state.language],
    "success": words.success[state.language],
  };

  dispatch({ type: 'setState', key: 'accessing', value: true });

  const response = await syncHttpRequest2(request, undefined, undefined, 'notification', messages, dispatch);

  dispatch({ type: 'setState', key: 'accessing', value: false });

  if ( response.status === 'fulfilled' ) {
    const purchasePoint = response.body.purchasePoint;
    const sessionId = response.body.sessionId;
    const expirationOfSession = response.body.expirationOfSession;

    devLog('purchaseNo', purchasePoint.purchaseNo);

    // purchasePoint情報更新
    if (popupLayer === undefined || popupLayer === null) {
      dispatch({ type: 'setState', key: 'purchasePoint', value: purchasePoint });
    }

    // session情報更新
    if (addressType === 'addressMain') {
      dispatch({ type: 'setStateMultiLayers', keys: ['session', stateFixed.addressMain], value: {'sessionId': sessionId.addressMain, 'expirationOfSession': expirationOfSession.addressMain} });

      if (addressActual !== stateFixed.addressMain) {
        dispatch({ type: 'setStateMultiLayers', keys: ['session', addressActual], value: {'sessionId': sessionId.addressMainActual, 'expirationOfSession': expirationOfSession.addressMainActual} });
      }

      if (sessionId.addressPayFrom !== undefined) {
        dispatch({ type: 'setStateMultiLayers', keys: ['session', stateFixed.addressPayFrom], value: {'sessionId': sessionId.addressPayFrom, 'expirationOfSession': expirationOfSession.addressPayFrom} });
      }
    }
    else { // addressPayFrom
      dispatch({ type: 'setStateMultiLayers', keys: ['session', stateFixed.addressPayFrom], value: {'sessionId': sessionId.addressPayFrom, 'expirationOfSession': expirationOfSession.addressPayFrom} });

      if (addressActual !== stateFixed.addressPayFrom) {
        dispatch({ type: 'setStateMultiLayers', keys: ['session', addressActual], value: {'sessionId': sessionId.addressPayFromActual, 'expirationOfSession': expirationOfSession.addressPayFromActual} });
      }
    }

    // // オーダーNo.通知欄表示(全署名完了していたら)
    // if ( purchasePoint.signatureByAddressMain !== undefined && purchasePoint.signatureByAddressMain !== null && purchasePoint.signatureByAddressMain !== '' &&
    //      purchasePoint.signatureByAddressPayFrom !== undefined && purchasePoint.signatureByAddressPayFrom !== null && purchasePoint.signatureByAddressPayFrom !== '') {
    //   dispatch({ type: 'setNotification', key: 'notification', value: words.acceptedAsPurchaseNoX1[state.language] + purchasePoint.purchaseNo + words.acceptedAsPurchaseNoX2[state.language] });
    // }

    // const exhibitorName = state.usersGeneralIndex[stateFixed.addressMainExhibitor] !== undefined ? state.usersGeneralIndex[stateFixed.addressMainExhibitor].userName : stateFixed.addressMainExhibitor;
    // const cookiesNew = [
    //       {
    //         action: 'purchasePoint',
    //         addressType: 'addressMain',
    //         description: `${exhibitorName} ${tokenNameEither} ${stateFixed.amountToBuy}`,
    //         time: clientTime,
    //         address: stateFixed.addressMain
    //       },
    // ];

    // if (cookiesNew.every( cookie => cookie.address !== stateFixed.addressPayFrom )) {
    //   cookiesNew.push(
    //       {
    //         action: 'purchasePoint',
    //         addressType: 'addressPayFrom',
    //         description: `${exhibitorName} ${tokenNameEither} ${stateFixed.amountToBuy}`,
    //         time: clientTime,
    //         address: stateFixed.addressPayFrom
    //       },
    //   );
    // }

    // if (cookiesNew.every( cookie => cookie.address !== stateFixed.addressSendCardTo )) {
    //   cookiesNew.push(
    //       {
    //         action: 'purchasePoint',
    //         addressType: 'addressSendCardTo',
    //         description: `${exhibitorName} ${tokenNameEither} ${stateFixed.amountToBuy}`,
    //         time: clientTime,
    //         address: stateFixed.addressSendCardTo
    //       }
    //   );
    // }

    // setAddressesInCookie(state, dispatch, cookies, setCookie, stateFixed.addressMain, cookiesNew);

    // MONA送金

    if (purchasePoint.status === 'waitingForMona') {
      let txid;

      // モナ量計算

      const amountToBuyDecimal = new decimal(stateFixed.amountToBuy);
      const priceMonaDecimal = new decimal(stateFixed.priceMona);
      amountMona = amountToBuyDecimal.times(priceMonaDecimal).toNumber();
      devLog(`amountToBuy ${stateFixed.amountToBuy}, priceMona ${stateFixed.priceMona}, amountMona ${amountMona}`);

      // 送金！

      if (keyPair !== undefined) {
        const result = await sendMonaSequence(
          state, dispatch, keyPair, stateFixed.addressPayFrom, stateFixed.monatokaPointAddress, amountMona,
          {
            provisionalTransactionFee: stateFixed.provisionalTransactionFee,
            transactionFeeUpperBound: stateFixed.transactionFeeUpperBound,
          }
        );

        if (result.status === 'fulfilled') {
          txid = result.body;
        }
        else {
          return result;
        }

        // let result;
        // let utxos;
        // let feeRate;
        // let change;
        // let unsignedTxHex;
        // let signedTxHex;

        // devLog('provisionalTransactionFee', stateFixed.provisionalTransactionFee);

        // // UTXO取得
        // result = await getUtxo(state, stateFixed.addressPayFrom);

        // if (result.status === 'fulfilled') {
        //   utxos = result.body;
        //   dispatch({ type: 'setNotification', key: 'notification', value: 'success 1' });
        // }
        // else {
        //   devLog('failed to get UTXO.');
        //   dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSendMona[state.language] + result.error });
        //   return {
        //     status: 'rejected',
        //     error: 'failed to get UTXO.',
        //   };
        // }

        // let utxosSelected;
        // try {
        // // UTXO選定
        // utxos.sort( (a, b) => a.amount - b.amount );
        // utxosSelected = selectUtxoMona(utxos, amountMona + stateFixed.provisionalTransactionFee); // boundaryは概算。provisionalTransactionFeeには少し余裕をもたせるべし。
        // devLog('utxosSelected', JSON.stringify(utxosSelected));
        // }
        // catch (error) {
        //   dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSendMona[state.language] + '1.5' });
        //   return;
        // }

        // if (utxosSelected === null) {
        //   devLog('short of MONA');
        //   dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSendMona[state.language] + '2' });
        //   return {
        //     status: 'rejected',
        //     error: 'short of MONA',
        //   };
        // }

        // // UTX raw transaction取得
        // result = await getRawTransaction(utxosSelected);

        // if (result.status === 'rejected') {
        //   devLog('failed to get unspent raw transactions.');
        //   dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSendMona[state.language] + '3' });
        //   return {
        //     status: 'rejected',
        //     error: 'failed to get unspent raw transactions.',
        //   };
        // }

        // // fee rate取得
        // result = await getFeeRate(state);

        // if (result.status === 'fulfilled') {
        //   feeRate = result.body;
        // }
        // else {
        //   devLog('failed to get fee rate.');
        //   dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSendMona[state.language] + '4' });
        //   return {
        //     status: 'rejected',
        //     error: 'failed to get fee rate.',
        //   };
        // }

        // // お釣り計算
        // const amountMonaDecimal = new decimal(amountMona);
        // const baseAmountWatanabe = decimalAdjust( 'floor',  amountMonaDecimal.times(100000000).toNumber(), 0 ); 

        // result = calculateChangeWatanabe(utxosSelected, baseAmountWatanabe, feeRate, stateFixed.transactionFeeUpperBound);

        // if (result.status === 'fulfilled') {
        //   change = result.body;
        // }
        // else {
        //   devLog('failed to calculate change.');
        //   dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSendMona[state.language] + '5' });
        //   return {
        //     status: 'rejected',
        //     error: result.error,
        //   };
        // }

        // // トランザクション作成
        // const inputs = utxosSelected.map( utxo => {
        //   return {
        //     txid: utxo.txid,
        //     vout: utxo.vout,
        //     rawTransaction: utxo.rawTransaction,
        //   };
        // });

        // devLog('inputs', JSON.stringify(inputs));

        // const outputs = [
        //   {
        //     address: stateFixed.monatokaPointAddress,
        //     amount: baseAmountWatanabe,
        //   },
        //   {
        //     address: stateFixed.addressPayFrom,
        //     amount: change,
        //   },
        // ];

        // devLog('outputs', JSON.stringify(outputs));

        // result = buildUnsignedTransactionSendMona(inputs, outputs);

        // if (result.status === 'fulfilled') {
        //   unsignedTxHex = result.body;
        // }
        // else {
        //   devLog('failed to build transaction.');
        //   dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSendMona[state.language] + '6' });
        //   return {
        //     status: 'rejected',
        //     error: result.error,
        //   };
        // }

        // // トランザクション署名
        // result = signTransaction(unsignedTxHex, keyPair);

        // if (result.status === 'fulfilled') {
        //   signedTxHex = result.body;
        // }
        // else {
        //   devLog('failed to sign transaction.');
        //   dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSendMona[state.language] + '7' });
        //   return {
        //     status: 'rejected',
        //     error: result.error,
        //   };
        // }

        // // ブロードキャスト
        // result = await broadcastTransaction(state, signedTxHex);

        // if (result.status === 'fulfilled') {
        //   txid = result.body;
        // }
        // else {
        //   devLog('failed to broadcast transaction.');
        //   dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSendMona[state.language] + '8' });
        //   return {
        //     status: 'rejected',
        //     error: 'failed to broadcast transaction.',
        //   };
        // }
      }
      else {
        const result = await window.mpurse.sendAsset(
          stateFixed.monatokaPointAddress,
          'MONA', 
          amountMona, 
          'no',
        )
        .then( result => {
          txid = result;
          devLog('succeeded to send MONA', txid);
          // dispatch({ type: 'setNotification', key: 'notification', value: words.succeededToSendMona[state.language] + "\n" + result });

          // sendLog({
          //   action: 'sendMona',
          //   addressMain: stateFixed.addressMain,
          //   purchaseNo: purchasePoint.purchaseNo,
          //   signatureByAddressMainFromClient: purchasePoint.signatureByAddressMain,
          //   sendMonaTxidFromClient: result,
          //   amountToBuy: stateFixed.amountToBuy,
          // });

          return {
            status: 'fulfilled',
            body: txid,
          };
        })
        .catch( error => {
          devLog('failed to send MONA.', error);
          dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSendMona[state.language] });

          /*
          sendLog({
            message: 'fail to send MONA.(window.mpurse.sendAsset())',
            orderNo: resultSendOrder.body.orderNo,
            addressMona: state.addressMona,
            addressPolygon: state.addressPolygon,
            amountMona: state.amountMona.value,
            amountJpyc: state.amountJpyc.value,
            addressMonaFixed: stateFixed.addressMona,
            addressPolygonFixed: stateFixed.addressPolygon,
            amountMonaFixed: stateFixed.amountMona.value,
            amountJpycFixed: stateFixed.amountJpyc.value,
            clientTime: clientTimeMona,
            serverTime: resultSendOrder.body.serverTime,
            walletTime: Date.now(),
            error: error,
          });
          */

          return {
            status: 'failToSendMona',
            error: error,
          };
        });

        if (result.status === 'fulfilled') {
          txid = result.body;
        }
        else {
          return result;
        }
      }

      dispatch({ type: 'setNotification', key: 'notification', value: words.succeededToSendMona[state.language] + "\n" + txid });

      sendLog({
        action: 'sendMona',
        addressMain: stateFixed.addressMain,
        purchaseNo: purchasePoint.purchaseNo,
        signatureByAddressMainFromClient: purchasePoint.signatureByAddressMain,
        sendMonaTxidFromClient: txid,
        amountToBuy: stateFixed.amountToBuy,
      });
    }
  }
  else {
    return { status: 'rejected' };
  }

  return response;
}

// SEND MONA SEQUENCE
async function sendMonaSequence(state, dispatch, keyPair, addressFrom, addressTo, amountMona, { provisionalTransactionFee, transactionFeeUpperBound }) {
  let result;
  let utxos;
  let feeRate;
  let change;
  let unsignedTxHex;
  let signedTxHex;
  let txid;

  devLog('provisionalTransactionFee', provisionalTransactionFee);

  // UTXO取得
  result = await getUtxo(state, addressFrom);

  if (result.status === 'fulfilled') {
    utxos = result.body;
  }
  else {
    devLog('failed to get UTXO.');
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSendMona[state.language] + result.error });
    return {
      status: 'rejected',
      error: 'failed to get UTXO.',
    };
  }

  // UTXO選定
  utxos.sort( (a, b) => a.amount - b.amount );
  const utxosSelected = selectUtxoMona(utxos, amountMona + provisionalTransactionFee); // boundaryは概算。provisionalTransactionFeeには少し余裕をもたせるべし。
  devLog('utxosSelected', JSON.stringify(utxosSelected));

  if (utxosSelected === null) {
    devLog('short of MONA');
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSendMona[state.language] + '2' });
    return {
      status: 'rejected',
      error: 'short of MONA',
    };
  }

  // UTX raw transaction取得
  result = await getRawTransaction(state, utxosSelected);

  if (result.status === 'rejected') {
    devLog('failed to get unspent raw transactions.');
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSendMona[state.language] + '3' });
    return {
      status: 'rejected',
      error: 'failed to get unspent raw transactions.',
    };
  }

  // fee rate取得
  result = await getFeeRate(state);

  if (result.status === 'fulfilled') {
    feeRate = result.body;
  }
  else {
    devLog('failed to get fee rate.');
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSendMona[state.language] + '4' });
    return {
      status: 'rejected',
      error: 'failed to get fee rate.',
    };
  }

  // お釣り計算
  const amountMonaDecimal = new decimal(amountMona);
  const baseAmountWatanabe = decimalAdjust( 'floor',  amountMonaDecimal.times(100000000).toNumber(), 0 ); 

  result = calculateChangeWatanabe(utxosSelected, baseAmountWatanabe, feeRate, transactionFeeUpperBound);

  if (result.status === 'fulfilled') {
    change = result.body;
  }
  else {
    devLog('failed to calculate change.');
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSendMona[state.language] + '5' });
    return {
      status: 'rejected',
      error: result.error,
    };
  }

  // トランザクション作成
  const inputs = utxosSelected.map( utxo => {
    return {
      txid: utxo.txid,
      vout: utxo.vout,
      rawTransaction: utxo.rawTransaction,
    };
  });

  devLog('inputs', JSON.stringify(inputs));

  const outputs = [
    {
      address: addressTo,
      amount: baseAmountWatanabe,
    },
    {
      address: addressFrom,
      amount: change,
    },
  ];

  devLog('outputs', JSON.stringify(outputs));

  result = buildUnsignedTransactionSendMona(inputs, outputs);

  if (result.status === 'fulfilled') {
    unsignedTxHex = result.body;
  }
  else {
    devLog('failed to build transaction.');
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSendMona[state.language] + '6' });
    return {
      status: 'rejected',
      error: result.error,
    };
  }

  // トランザクション署名
  result = signTransaction(unsignedTxHex, keyPair);

  if (result.status === 'fulfilled') {
    signedTxHex = result.body;
  }
  else {
    devLog('failed to sign transaction.');
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSendMona[state.language] + '7' });
    return {
      status: 'rejected',
      error: result.error,
    };
  }

  // ブロードキャスト
  result = await broadcastTransaction(state, signedTxHex);

  if (result.status === 'fulfilled') {
    txid = result.body;
  }
  else {
    devLog('failed to broadcast transaction.');
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSendMona[state.language] + '8' });
    return {
      status: 'rejected',
      error: 'failed to broadcast transaction.',
    };
  }

  return {
    status: 'fulfilled',
    body: txid,
  };
}

// SWEEP ASSETS SEQUENCE
async function sweepAssetsSequence(state, dispatch, keyPair, addressTo, { sweepFlag = 3 } = {}) {
  let requestToCounterblockMona = {};
  let bodyObject = {};
  let addressFrom;
  let result;
  let response = {};
  let transactionUnsigned;
  let transactionSigned;
  let txid;
  let serverIndex = 0;

  // requestToCounterblockMona.url = 'https://monapa.electrum-mona.org/_api';
  // requestToCounterblockMona.url = 'https://wallet.monaparty.me/_api';
  // requestToCounterblockMona.url = 'https://counterblock.api.monaparty.me/';

  requestToCounterblockMona.method = 'POST';

  dispatch({ type: 'setNotification', key: 'notification', value: 'start sweepAssetsSequence.' });

  // sweep元アドレス取得

  result = getAddressFromKey(state, dispatch, keyPair);

  if (result.status === 'fulfilled') {
    addressFrom = result.address;
  }
  else {
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToGetTheAddress[state.language] });
    return result;
  }

  // トランザクション作成

  bodyObject = {
    "jsonrpc":"2.0",
    "id":0,
    "method":"proxy_to_counterpartyd",
    "params":{
      "method":"create_sweep",
      "params": {
        "source": addressFrom,
        "destination": addressTo,
        "flags": sweepFlag,
      }
    }
  };

  requestToCounterblockMona.body = JSON.stringify(bodyObject);

  let buildTransactionRetryCount = 0;

  while (response.status !== 'fulfilled') {
    requestToCounterblockMona.url = `https://${state.config.clientParameters.monapaServer.domainAndPath[serverIndex]}`;
    console.log('request create_sweep', JSON.stringify(requestToCounterblockMona));

    response = await syncHttpRequest(requestToCounterblockMona);
    console.log('response', JSON.stringify(response));

    if (response.status === 'rejected') {
      devLog('create_sweep to counterpartyd; rejected');
      dispatch({ type: 'setNotification', key: 'notification', value: 'create_sweep to counterpartyd; rejected' });
      buildTransactionRetryCount++;

      if (serverIndex >= state.config.clientParameters.monapaServer.domainAndPath.length - 1) {
        serverIndex = 0;
      }
      else {
        serverIndex++;
      }

      await sleep(state.config.clientParameters.retry.buildTransaction.retryWaitingTime);
      // keepSqsMessage = true;
      // continue;
    }
    else {
      transactionUnsigned = response.body.result;
      devLog('transactionUnsigned', transactionUnsigned);
      dispatch({ type: 'setNotification', key: 'notification', value: 'create_sweep to counterpartyd; succeeded ' + JSON.stringify(response.body) });
    }

    if (buildTransactionRetryCount > state.config.clientParameters.retry.buildTransaction.retryMax) {
      // -- retry over エラー終了
      devLog('build transaction retry over.');
      dispatch({ type: 'setNotification', key: 'notification', value: 'build transaction retry over.' });
      dispatch({ type: 'setNotification', key: 'notification', value: 'failed to build transaction.' });
      return result;
    }
  }


  // トランザクション署名

  result = {};
  let signTransactionRetryCount = 0;

  while (result.status !== 'fulfilled') {
    result = signTransaction(transactionUnsigned, keyPair);
    devLog('sign transaction; result', JSON.stringify(result));

    if (result.status === 'fulfilled') {
      devLog('succeeded to sign transaction.');
      transactionSigned = result.body;
      dispatch({ type: 'setNotification', key: 'notification', value: 'succeeded to sign transaction. ' + transactionSigned });
    }
    else {
      devLog('failed to sign transaction.');
      dispatch({ type: 'setNotification', key: 'notification', value: 'failed to sign transaction.' });
      signTransactionRetryCount++;
      await sleep(state.config.clientParameters.retry.signTransaction.retryWaitingTime);
    }

    if (signTransactionRetryCount > state.config.clientParameters.retry.signTransaction.retryMax) {
      // -- retry over エラー終了
      devLog('sign transaction retry over.');
      dispatch({ type: 'setNotification', key: 'notification', value: 'failed to sign transaction.' });
      return result;
    }
  }

  // ブロードキャスト

  result = {};
  let broadcastTransactionRetryCount = 0;

  while (result.status !== 'fulfilled') {
    result = await broadcastTransaction(state, transactionSigned);
    devLog('broadcast transaction; result', JSON.stringify(result));

    if (result.status === 'fulfilled') {
      devLog('succeeded to broadcast transaction.');
      txid = result.body;
      dispatch({ type: 'setNotification', key: 'notification', value: 'succeeded to broadcast transaction. ' + txid });
    }
    else {
      devLog('failed to broadcast transaction.');
      dispatch({ type: 'setNotification', key: 'notification', value: 'failed to broadcast transaction.' });
      broadcastTransactionRetryCount++;
      await sleep(state.config.clientParameters.retry.broadcastTransaction.retryWaitingTime);
    }

    if (broadcastTransactionRetryCount > state.config.clientParameters.retry.broadcastTransaction.retryMax) {
      // -- retry over エラー終了
      devLog('send transaction retry over.');
      dispatch({ type: 'setNotification', key: 'notification', value: 'failed to broadcast transaction.' });
      return result;
    }
  }

  return {
    status: 'fulfilled',
    body: txid,
  };
}

// SWEEP MONA
async function sweepMona(state, dispatch, keyPair, addressTo, { sweepFlag = 3 } = {}) {
  let result;
  let addressFrom;
  let utxos;
  let allMonaBalance;
  let feeRate;
  let unsignedTxHex;
  let signedTxHex;
  let txid;

  const provisionalTransactionFee = state.config.clientParameters.provisionalTransactionFee;
  const transactionFeeUpperBound = state.config.clientParameters.transactionFeeUpperBound;

  dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonic'], value: 'tra MONA' }); // 暫定

  // 旧アドレス取得

  result = getAddressFromKey(state, dispatch, keyPair);

  if (result.status === 'fulfilled') {
    addressFrom = result.address;
  }
  else {
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToGetTheAddress[state.language] });
    throw new Error(result);
  }

  devLog('provisionalTransactionFee', provisionalTransactionFee);

  // UTXO取得
  result = await getUtxo(state, addressFrom);

  if (result.status === 'fulfilled') {
    utxos = result.body;
  }
  else {
    devLog('failed to get UTXO.');
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSendMona[state.language] + result.error });
    return {
      status: 'rejected',
      error: 'failed to get UTXO.',
    };
  }

  // UTX raw transaction取得
  result = await getRawTransaction(state, utxos);

  if (result.status === 'rejected') {
    devLog('failed to get unspent raw transactions.');
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSendMona[state.language] + '3' });
    return {
      status: 'rejected',
      error: 'failed to get unspent raw transactions.',
    };
  }

  // 全MONA計算

  result = await getAddressInfo(state, addressFrom);

  if (result.status === 'fulfilled') {
    allMonaBalance = result.body.balance;
  }
  else {
    devLog('failed to get address info.');
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSendMona[state.language] + '3.5' });

    return {
      status: 'rejected',
      error: 'failed to get address info.',
    };
  }

  // const allMonaBalance = utxos.reduce( (acc, cur) => {
  //   cur.vout
  //   acc + cur
  // }, 0);

  // fee rate取得
  result = await getFeeRate(state);

  if (result.status === 'fulfilled') {
    feeRate = result.body;
  }
  else {
    devLog('failed to get fee rate.');
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSendMona[state.language] + '4' });
    return {
      status: 'rejected',
      error: 'failed to get fee rate.',
    };
  }

  // 概算バイト数計算
  const approximatedTransactionBytes = 1000;

  // transactionFee計算

  const feeRateDecimal = new decimal(feeRate); // MONA/KB = 10^8watanabe/10^3byte = 10^5watanabe/byte
  const transactionFee = decimalAdjust( 'floor',  feeRateDecimal.times(100000).times(approximatedTransactionBytes).toNumber(), 0 ); 
  devLog(`transactionFee ${transactionFee}`);

  // transactionFee歯止め
  if (transactionFee > transactionFeeUpperBound) {
    devLog('transactionFee is too high.');
    return {
      status: 'rejected',
      error: 'transactionFeeIsTooHigh',
    };
  }

  // トランザクション作成
  const inputs = utxos.map( utxo => {
    return {
      txid: utxo.txid,
      vout: utxo.vout,
      rawTransaction: utxo.rawTransaction,
    };
  });

  devLog('inputs', JSON.stringify(inputs));

  const outputs = [
    {
      address: addressTo,
      amount: allMonaBalance - transactionFee,
    },
  ];

  devLog('outputs', JSON.stringify(outputs));

  result = buildUnsignedTransactionSendMona(inputs, outputs);

  if (result.status === 'fulfilled') {
    unsignedTxHex = result.body;
  }
  else {
    devLog('failed to build transaction.');
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSendMona[state.language] + '6' });
    return {
      status: 'rejected',
      error: result.error,
    };
  }

  // トランザクション署名
  result = signTransaction(unsignedTxHex, keyPair);

  if (result.status === 'fulfilled') {
    signedTxHex = result.body;
  }
  else {
    devLog('failed to sign transaction.');
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSendMona[state.language] + '7' });
    return {
      status: 'rejected',
      error: result.error,
    };
  }

  // ブロードキャスト
  result = await broadcastTransaction(state, signedTxHex);

  if (result.status === 'fulfilled') {
    txid = result.body;
  }
  else {
    devLog('failed to broadcast transaction.');
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSendMona[state.language] + '8' });
    return {
      status: 'rejected',
      error: 'failed to broadcast transaction.',
    };
  }

  return {
    status: 'fulfilled',
    body: txid,
  };
}

// GET UTXO
async function getUtxo(state, address) {
  let serverIndex = 0;

  let request = {};
  request.method = 'GET';
  request.headers = {
    'Accept': 'application/json',
  };

  SERVER: while (true) {
    request.url = `https://${state.config.clientParameters.blockbook.domain[serverIndex]}${state.config.clientParameters.blockbook.utxoPath}${address}`;
    devLog('get UTXO; request', JSON.stringify(request));

    const response = await syncHttpRequest(request);
    devLog('get UTXO; response', JSON.stringify(response));

    if ( response.status === 'fulfilled' ) {
      const utxos = response.body;
      devLog('utxos', JSON.stringify(utxos));
      return {
        status: 'fulfilled',
        body: utxos,
      };
    }
    else {
      devLog('get UTXO; rejected');

      if (serverIndex >= state.config.clientParameters.blockbook.domain.length - 1) {
        return {
          status: 'rejected',
          error: response.body + request.url,
        };
      }
      else {
        serverIndex++;
        continue SERVER;
      }
    }
  }
}

// SELECT UTXO MONA
// モナコインUTXO選定
// -- utxosは昇順ソート
function selectUtxoMona(utxos, amount) {
  devLog('selectUtxoMona', JSON.stringify(utxos), amount);
  const minimumGrater= minimumGraterUtxo(utxos, amount);
  devLog('minimumGrater', JSON.stringify(minimumGrater));

  if (minimumGrater !== null) {
    return [minimumGrater];
  }
  else {
    if (utxos.length <= 1) {
      return null;
    }

    const maximum = utxos.pop();
    devLog('maximum', JSON.stringify(maximum));
    const supplement = selectUtxoMona(utxos, amount - maximum.amount);

    if (supplement !== null) {
      supplement.unshift(maximum);
      return supplement;
    }
    else {
      return null;
    }
  }
}

function minimumGraterUtxo(utxos, bound) {
  devLog('minimumGraterUtxo', JSON.stringify(utxos), bound);
  let result = null;

  for(const utxo of utxos) {
   if (utxo.amount >= bound) {
     result = utxo;
     break;
   } 
  }

  return result;
}

// GET TRANSACTION
async function getTransaction(state, txid) {
  let transaction;
  let serverIndex = 0;

  // transaction取得
  let request = {};
  request.method = 'GET';
  request.headers = {
    'Accept': 'application/json',
  };

  SERVER: while (true) {
    request.url = `https://${state.config.clientParameters.blockbook.domain[serverIndex]}${state.config.clientParameters.blockbook.transactionDetailPath}${txid}`;
    devLog('get transaction; request', JSON.stringify(request));

    const response = await syncHttpRequest(request);
    devLog('get transaction; response', JSON.stringify(response));

    if ( response.status === 'fulfilled' ) {
      transaction = response.body;
      devLog('transaction', JSON.stringify(transaction));

      return {
        status: 'fulfilled',
        body: transaction,
      };
    }
    else {
      devLog('get transaction; rejected');

      if (serverIndex >= state.config.clientParameters.blockbook.domain.length - 1) {
        return {
          status: 'rejected',
          error: response.error,
        };
      }
      else {
        serverIndex++;
        continue SERVER;
      }
    }
  }
}

// GET RAW TRANSACTION
async function getRawTransaction(state, utxosSelected) {
  let serverIndex = 0;

  let request = {};
  request.method = 'POST';

  SERVER: while (true) {
    request.url = `https://${state.config.clientParameters.monapaServer.domainAndPath[serverIndex]}`;
    // request.url = 'https://monapa.electrum-mona.org/_api';
    // request.url = 'https://wallet.monaparty.me/_api';
    // request.url = 'https://counterblock.api.monaparty.me/';

    for (const utxo of utxosSelected) {
      request.body = `{"jsonrpc":"2.0","id":0,"method":"proxy_to_counterpartyd","params":{"method":"getrawtransaction","params":{"tx_hash":"${utxo.txid}"}}}`;
      devLog('get UTX raw transaction; request', JSON.stringify(request));

      const response = await syncHttpRequest(request);
      devLog('get UTX raw transaction; response', JSON.stringify(response));

      if ( response.status === 'rejected' ) {
        devLog('get UTXO; rejected');

        if (serverIndex >= state.config.clientParameters.monapaServer.domainAndPath.length - 1) {
          // -- 失敗 エラー返却
          return {
            status: 'rejected',
            error: response.error
          };
        }
        else {
          serverIndex++;
          continue SERVER;
        }
      }

      utxo.rawTransaction = response.body.result;
    }

    break SERVER;
  }

  return {
    status: 'fulfilled',
    body: utxosSelected,
  };
}

// GET ADDRESS INFO
async function getAddressInfo(state, address) {
  let addressInfo;
  let serverIndex = 0;

  // fee rate取得
  let request = {};
  request.method = 'GET';
  request.headers = {
    'Accept': 'application/json',
  };

  SERVER: while (true) {
    request.url = `https://${state.config.clientParameters.blockbook.domain[serverIndex]}${state.config.clientParameters.blockbook.addressPath}${address}`;
    devLog('get address info; request', JSON.stringify(request));

    const response = await syncHttpRequest(request);
    devLog('get address info; response', JSON.stringify(response));

    if ( response.status === 'fulfilled' ) {
      addressInfo = response.body;
      devLog('address info', JSON.stringify(addressInfo));

      return {
        status: 'fulfilled',
        body: addressInfo,
      };
    }
    else {
      devLog('get address info; rejected', response.error);

      if (serverIndex >= state.config.clientParameters.blockbook.domain.length - 1) {
        return {
          status: 'rejected',
          error: response.error,
        };
      }
      else {
        serverIndex++;
        continue SERVER;
      }
    }
  }
}

// GET FEE RATE
async function getFeeRate(state) {
  let feeRate;
  let serverIndex = 0;

  // fee rate取得
  let request = {};
  request.method = 'GET';
  request.headers = {
    'Accept': 'application/json',
  };
  // delete request.body;

  SERVER: while (true) {
    request.url = `https://${state.config.clientParameters.blockbook.domain[serverIndex]}${state.config.clientParameters.blockbook.feePath}${state.config.clientParameters.blockbook.feeBlocks}`;
    devLog('get fee rate; request', JSON.stringify(request));

    const response = await syncHttpRequest(request);
    devLog('get fee rate; response', JSON.stringify(response));

    if ( response.status === 'fulfilled' ) {
      if (response.body.result === '0') {
        feeRate = 0.002;
      }
      else {
        feeRate = toFloat(response.body.result);
      }

      devLog('feeRate', feeRate);

      return {
        status: 'fulfilled',
        body: feeRate,
      };
    }
    else {
      devLog('get fee rate; rejected');

      if (serverIndex >= state.config.clientParameters.blockbook.domain.length - 1) {
        return {
          status: 'rejected',
          error: response.error,
        };
      }
      else {
        serverIndex++;
        continue SERVER;
      }
    }
  }
}

// CALCULATE CHANGE WATANABE
function calculateChangeWatanabe(utxosSelected, baseAmountWatanabe, feeRate, transactionFeeUpperBound) {
  // 概算バイト数計算
  const approximatedTransactionBytes = 1000;

  const feeRateDecimal = new decimal(feeRate); // MONA/KB = 10^8watanabe/10^3byte = 10^5watanabe/byte
  const transactionFee = decimalAdjust( 'floor',  feeRateDecimal.times(100000).times(approximatedTransactionBytes).toNumber(), 0 ); 
  const amountToPay = baseAmountWatanabe + transactionFee;
  const change = utxosSelected.reduce( (acc, cur) => acc + cur.satoshis, 0 ) - amountToPay;
  devLog(`transactionFee ${transactionFee}, amountToPay ${amountToPay}, change ${change}`);

  // transactionFee歯止め
  if (transactionFee > transactionFeeUpperBound) {
    devLog('transactionFee is too high.');
    return {
      status: 'rejected',
      error: 'transactionFeeIsTooHigh',
    };
  }

  return {
    status: 'fulfilled',
    body: change,
  };
}

// BUILD UNSIGNED TRANSACTION SEND MONA
function buildUnsignedTransactionSendMona(inputs, outputs) {
  try {
    console.log('A');

    const txb = new bitcoin.TransactionBuilder(networkMona);

    console.log('B');

    for (const input of inputs) {
      const inputTransaction = bitcoin.Transaction.fromHex(input.rawTransaction);
      console.log(JSON.stringify(inputTransaction.outs[input.vout].script));
      input.scriptPubkey = inputTransaction.outs[input.vout].script;
      console.log(input.scriptPubkey);
      txb.addInput(input.txid, input.vout, null, input.scriptPubkey);
    }

    console.log('C');

    for (const output of outputs) {
      txb.addOutput(output.address, output.amount);
    }

    console.log('D');

    const unsignedTx = txb.buildIncomplete();

    console.log('E');
    console.log(inputs[0].scriptPubkey);

    for (let i = 0; i <= unsignedTx.ins.length - 1; i++) {
      console.log(i);
      unsignedTx.ins[i].script = inputs[i].scriptPubkey;
    }

    console.log('F');

    const unsignedTxHex = unsignedTx.toHex();

    return {
      status: 'fulfilled',
      body: unsignedTxHex,
    };
  }
  catch (error) {
    return {
      status: 'rejected',
      body: error,
    };
  }
}

// SIGN TRANSACTION
function signTransaction(txHex, keyPair, wif) {
  try {
    if (keyPair === undefined || keyPair === null) {
      keyPair = bitcoin.ECPair.fromWIF(wif, networkMona);
    }

    const tx = bitcoin.Transaction.fromHex(txHex);   // The unsigned second part of the 2 part P2SH transactions
    const txb = bitcoin.TransactionBuilder.fromTransaction(tx, networkMona);

    for (let i = 0; i < tx.ins.length; i++) {
      txb.sign(i, keyPair);
    }

    const signedTxHex = txb.build().toHex();

    console.log('inner result', signedTxHex);

    return {
      status: 'fulfilled',
      body: signedTxHex, // The resulting signed transaction in raw hex, ready to be broadcasted
    };
  }
  catch (error) {
    return {
      status: 'rejected',
      body: error,
    };
  }
}

// BROADCAST TRANSACTION
async function broadcastTransaction(state, signedTxHex) {
  let serverIndex = 0;

  let request = {};
  request.method = 'POST';
  request.headers = {
    'Accept': 'application/json',
  };
  request.body = signedTxHex;

  SERVER: while (true) {
    request.url = `https://${state.config.clientParameters.blockbook.domain[serverIndex]}${state.config.clientParameters.blockbook.sendTransactionPath}`;
    devLog('broadcast transaction; request', JSON.stringify(request));

    const response = await syncHttpRequest(request);
    devLog('broadcast transaction; response', JSON.stringify(response));

    if ( response.status === 'fulfilled' ) {
      const txid = response.body.result;
      devLog('txid', txid);

      return {
        status: 'fulfilled',
        body: txid,
      };
    }
    else {
      devLog('broadcast transaction; rejected');

      if (serverIndex >= state.config.clientParameters.blockbook.domain.length - 1) {
        return {
          status: 'rejected',
          error: response.error,
        };
      }
      else {
        serverIndex++;
        continue SERVER;
      }
    }
  }
}

async function handleClickSendMona(state, dispatch, popupLayer) {
  let request = {};
  let response = {};
  let stateFixed = {};
  let messages = {};
  let utxos;
  let utxosSelected;
  let approximatedTransactionBytes;
  let feeRate;
  let transactionFee;
  let change;
  let amountToPay;
  let inputs;
  let outputs;
  let unsignedTransaction;

  // state固定
  if (popupLayer !== undefined && popupLayer !== null) {
    stateFixed = {
      addressPayFrom: state.purchase.addressPayFrom,
      addressPayProceedsTo: state.popup[popupLayer].body.addressPayProceedsTo,
      addressPayRoyaltyTo: state.purchase.addressPayRoyaltyTo,
      monacottoAddressMona: state.config.monacottoAddress.monacottoAddressMona,
      amountToBuy: state.purchase.amountToBuy,
      priceMona: state.popup[popupLayer].body.priceMona,
      priceMonaWatanabe: state.popup[popupLayer].body.priceMonaWatanabe,
      proceedsNetMona: state.popup[popupLayer].body.proceedsNetMona,
      royaltyMona: state.popup[popupLayer].body.royaltyMona,
      feeMona: state.popup[popupLayer].body.feeMona,
      provisionalTransactionFee: state.config.clientParameters.provisionalTransactionFee,
      transactionFeeUpperBound: state.config.clientParameters.transactionFeeUpperBound,
      addressMain: state.purchase.addressMain,
      purchaseNo: state.purchase.purchaseNo,
      signatureByAddressMain: state.purchase.signatureByAddressMain,
    };
  }
  else {
    stateFixed = {
      addressPayFrom: state.purchase.addressPayFrom,
      addressPayProceedsTo: state.itemDetail.item.addressPayProceedsTo,
      addressPayRoyaltyTo: state.purchase.addressPayRoyaltyTo,
      monacottoAddressMona: state.config.monacottoAddress.monacottoAddressMona,
      amountToBuy: state.purchase.amountToBuy,
      priceMona: state.itemDetail.item.priceMona,
      priceMonaWatanabe: state.itemDetail.item.priceMonaWatanabe,
      proceedsNetMona: state.itemDetail.item.proceedsNetMona,
      royaltyMona: state.itemDetail.item.royaltyMona,
      feeMona: state.itemDetail.item.feeMona,
      provisionalTransactionFee: state.config.clientParameters.provisionalTransactionFee,
      transactionFeeUpperBound: state.config.clientParameters.transactionFeeUpperBound,
      addressMain: state.purchase.addressMain,
      purchaseNo: state.purchase.purchaseNo,
      signatureByAddressMain: state.purchase.signatureByAddressMain,
    };
  }

  if (window.mpurse) {
    // Mpurseのアドレスが合ってるかチェック
    let result = await window.mpurse.getAddress()
      .then( (result) => {
        if (result !== stateFixed.addressPayFrom) {
          dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressFilledAndTheAddressInWalletAreDifferent[state.language] });
          return 'rejected';
        }
        else {
          return 'fulfilled';
        }
      })
      .catch( (error) => {
          dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressInYourWalletCanNotBeGotten[state.language] });
          devLog('CNBG', error);
          return 'rejected';
      });

    if (result === 'rejected') {
      return { status: 'rejected' };
    }

    // UTXO取得

    // request.url = `https://${state.config.blockbook.domain}${state.config.blockbook.utxoPath}${stateFixed.addressPayFrom}`;
    // request.method = 'GET';
    // request.headers = {
    //   'Accept': 'application/json',
    // };

    // devLog('get UTXO; request', JSON.stringify(request));

    // response = await syncHttpRequest(request);

    response = await getUtxo(state, stateFixed.addressPayFrom);

    if ( response.status === 'fulfilled' ) {
      utxos = response.body;
      devLog('utxos', JSON.stringify(utxos));
    }
    else {
      devLog('get UTXO; rejected');
      return { status: 'rejected' };
    }

    // UTXO選定
    devLog('priceMona', stateFixed.priceMona, 'amountToBuy', stateFixed.amountToBuy, 'provisionalTransactionFee', stateFixed.provisionalTransactionFee);

    utxos.sort( (a, b) => a.amount - b.amount );
    utxosSelected = selectUtxoMona(utxos, stateFixed.priceMona * stateFixed.amountToBuy + stateFixed.provisionalTransactionFee); // boundaryは概算。provisionalTransactionFeeには少し余裕をもたせるべし。
    devLog('utxosSelected', JSON.stringify(utxosSelected));

    if (utxosSelected === null) {
      devLog('short of MONA');
      return { status: 'rejected' };
    }

    // UTX raw transaction取得
    result = await getRawTransaction(state, utxosSelected);

    // request.url = 'https://monapa.electrum-mona.org/_api';
    // // request.url = 'https://wallet.monaparty.me/_api';
    // // request.url = 'https://counterblock.api.monaparty.me/';
    // request.method = 'POST';

    // for (const utxo of utxosSelected) {
    //   request.body = `{"jsonrpc":"2.0","id":0,"method":"proxy_to_counterpartyd","params":{"method":"getrawtransaction","params":{"tx_hash":"${utxo.txid}"}}}`;
    //   devLog('get UTX raw transaction; request', JSON.stringify(request));

    //   response = await syncHttpRequest(request);
    //   devLog('get UTX raw transaction; response', JSON.stringify(response));

    //   utxo.rawTransaction = response.body.result;
    // }

    // 概算バイト数計算
    approximatedTransactionBytes = 1000;

    // fee rate取得

    // request.url = `https://${state.config.blockbook.domain}${state.config.blockbook.feePath}${state.config.blockbook.feeBlocks}`;
    // request.method = 'GET';
    // request.headers = {
    //   'Accept': 'application/json',
    // };
    // delete request.body;

    // devLog('get fee rate; request', JSON.stringify(request));

    // response = await syncHttpRequest(request);

    response = await getFeeRate(state);

    if ( response.status === 'fulfilled' ) {
      // if (response.body.result === '0') {
      //   feeRate = 0.002;
      // }
      // else {
      //   feeRate = toFloat(response.body.result);
      // }

      feeRate = response.body;
      devLog('feeRate', feeRate);
    }
    else {
      devLog('get fee rate; rejected');
      return { status: 'rejected' };
    }

    // transaction fee, change, amountToPay計算 (transactionFeeはここまで丁寧にする必要なし。)
    const feeRateDecimal = new decimal(feeRate); // MONA/KB = 10^8watanabe/10^3byte = 10^5watanabe/byte
    transactionFee = decimalAdjust( 'floor',  feeRateDecimal.times(100000).times(approximatedTransactionBytes).toNumber(), 0 ); 
    amountToPay = stateFixed.priceMonaWatanabe * stateFixed.amountToBuy + transactionFee;
    change = utxosSelected.reduce( (acc, cur) => acc + cur.satoshis, 0 ) - amountToPay;
    devLog(`transactionFee ${transactionFee}, amountToPay ${amountToPay}, change ${change}`);

    // transactionFee歯止め
    if (transactionFee > stateFixed.transactionFeeUpperBound) {
      devLog('transactionFee is too high.');
      return { status: 'rejected' };
    }

    // トランザクション作成
    inputs = utxosSelected.map( utxo => {
      return {
        txid: utxo.txid,
        vout: utxo.vout,
        rawTransaction: utxo.rawTransaction,
      };
    });

    devLog('inputs', JSON.stringify(inputs));

    if (stateFixed.royaltyMona > 0) {
      outputs = [
        {
          address: stateFixed.addressPayProceedsTo,
          amount: stateFixed.proceedsNetMona * stateFixed.amountToBuy,
        },
        {
          address: stateFixed.addressPayRoyaltyTo,
          amount: stateFixed.royaltyMona * stateFixed.amountToBuy,
        },
        {
          address: stateFixed.monacottoAddressMona,
          amount: stateFixed.feeMona * stateFixed.amountToBuy,
        },
        {
          address: stateFixed.addressPayFrom,
          amount: change,
        },
      ];
    }
    else {
      outputs = [
        {
          address: stateFixed.addressPayProceedsTo,
          amount: stateFixed.proceedsNetMona * stateFixed.amountToBuy,
        },
        {
          address: stateFixed.monacottoAddressMona,
          amount: stateFixed.feeMona * stateFixed.amountToBuy,
        },
        {
          address: stateFixed.addressPayFrom,
          amount: change,
        },
      ];
    }

    devLog('outputs', JSON.stringify(outputs));

    request.body = JSON.stringify({
      body: {
        inputs: inputs,
        outputs: outputs,
      }
    });

    request.url = 'https://' + process.env.REACT_APP_MONACOTTO_API_DOMAIN + '/build_transaction_mona';
    request.method = 'POST'; // ← 使われない
    request.headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }; // ← 使われない

    messages = {
    };

    response = await syncHttpRequest2(request, undefined, undefined, undefined, messages, dispatch);
    devLog('build transaction; response', JSON.stringify(response));

    if ( response.status === 'fulfilled' ) {
      unsignedTransaction = response.body.unsignedTxHex;
    }
    else {
      devLog('failed to build transaction.');
      return { status: 'rejected' };
    }

    devLog('unsignedTransaction bytes', (new Blob([unsignedTransaction])).size);

    // GO!
    // ポップアップで送金内容確認してもらったらGO!
    const popup = {
      type: 'sendMonaConfirmation',
      body: {
        addressPayProceedsTo: stateFixed.addressPayProceedsTo,
        addressPayRoyaltyTo: stateFixed.addressPayRoyaltyTo,
        monacottoAddressMona: stateFixed.monacottoAddressMona,
        proceedsNetMona: stateFixed.proceedsNetMona * stateFixed.amountToBuy,
        royaltyMona: stateFixed.royaltyMona * stateFixed.amountToBuy,
        feeMona: stateFixed.feeMona * stateFixed.amountToBuy,
        transactionFee: transactionFee,
      },
    };

    if (wallet === 'MonaPallet') {
      popup.extendedClassesBackGround = 'alignItemsFlexStart';
    }
    else { // Mpurse
    }

    dispatch({ type: 'setStateMultiLayers', keys: ['popup', (popupLayer !== undefined && popupLayer !== null) ? (popupLayer + 1) : 0], value: popup });

    result = await window.mpurse.sendRawTransaction(unsignedTransaction)
      .then( async (result) => {
        devLog(result);
        // devLog('signedTransaction bytes', (new Blob([signedTransaction])).size);

        // purchase状態更新(部分クリア)
        dispatch({ type: 'setState', key: 'purchase',
          value: {
            purchaseNo: null,
            addressMain: state.purchase.addressMain,
            addressPayFrom: state.purchase.addressPayFrom,
            addressSendCardTo: state.purchase.addressSendCardTo,
            addressMainExhibitor: '',
            exhibitNo: '',
            amountToBuy: state.purchase.amountToBuy,
            signatureByAddressMain: '',
            signatureByAddressPayFrom: '',
            addressPayRoyaltyTo: null,
            disabled: false,
            status: 'waitingForSignature',
          }
        });

        // notification
        dispatch({ type: 'setNotification', key: 'notification', value: words.sentSuccessfully[state.language] + ' ' + result });

        // トークン送信成功通知
        request.body = JSON.stringify({
          "body": {
            addressMain: stateFixed.addressMain,
            purchaseNo: stateFixed.purchaseNo,
            signatureByAddressMain: stateFixed.signatureByAddressMain,
            sendMonaTxid: result,
          }
        });
        request.url = 'https://' + process.env.REACT_APP_MONACOTTO_API_DOMAIN + '/notify_sending_mona';
        request.method = 'POST';
        messages = {
        };

        const response = await syncHttpRequest2(request, undefined, undefined, undefined, messages, dispatch);
        return 'fulfilled';
      })
      .catch( (error) => {
        devLog(error);
        dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSend[state.language] });
        return 'rejected';
      });

    if (result === 'rejected') {
      return { status: 'rejected' };
    }
  }
  else {
    dispatch({ type: 'setNotification', key: 'notification', value: words.activateMpurse[state.language] });
    return { status: 'rejected' };
  }

  return { status: 'fulfilled' };
}

// 「購入:クリア」ボタン押下 HANDLE CLICK CLEAR PURCHASE
async function handleClickClearPurchase(state, dispatch) {
  dispatch({ type: 'setState', key: 'purchasePoint',
    value: {
      purchaseNo: null,
      addressMain: '',
      addressPayFrom: '',
      amountToBuy: 800,
      signatureByAddressMain: '',
      signatureByAddressPayFrom: '',
      disabled: false,
      status: 'waitingForSignature',
    }
  });

  return;
}

// 「ポイント履歴:検索」ボタン押下 HANDLE CLICK GET POINT HISTORY
async function handleClickGetPointHistory(state, dispatch, pagingIndex, activeCertification, consistentRead, keyPairs) {
  let addressActual;
  let request = {};
  let messages = {};
  let certifications = [];
  const now = Date.now();

  if (activeCertification === undefined || activeCertification === null) {
    activeCertification = {
      address: null,
    };
  }

  if (consistentRead === undefined || consistentRead === null) {
    consistentRead = false;
  }

  // state固定
  const stateFixed = {
    addressMain: state.getPointHistory.addressMain,
    lastEvaluatedKey: pagingIndex >= 1 ? state.getPointHistory.lastEvaluatedKey.point[pagingIndex - 1] : null,
    signatureVersion: state.configMP.clientParameters.signatureVersion.getPointHistory,
    walletType: state.walletType,
    addressCheck: 'strict',
  };

  // 有効なセッションに絞り込む。

  for (const address of Object.keys(state.session)) {

    // activeCertificationの場合は別処理
    if (address !== activeCertification.address) {

      // state固定
      stateFixed[address] = {
        // addressCoinType: state.session[address].addressCoinType,
        sessionId: state.session[address].sessionId,
        expirationOfSession: state.session[address].expirationOfSession,
      };

      if (stateFixed[address].expirationOfSession >= now + marginOfSessionTime) {
        certifications.push({
          // addressCoinType: stateFixed[address].addressCoinType,
          address: address,
          sessionId: stateFixed[address].sessionId,
          lastEvaluatedKey: address === stateFixed.addressMain ? stateFixed.lastEvaluatedKey : null,
          consistentRead: address === stateFixed.addressMain ? consistentRead : false,
        });
      }
    }
  }

  // activeCertificationの処理

  if (activeCertification.address !== null) {
    // -- state固定
    stateFixed[activeCertification.address] = {
      sessionId: activeCertification.sessionId,
      expirationOfSession: activeCertification.expirationOfSession,
    };

    // -- 有効だったら(まあほぼ有効なんだろうけど)プッシュ
    if (stateFixed[activeCertification.address].expirationOfSession >= now + marginOfSessionTime) {
      certifications.push({
        address: activeCertification.address,
        sessionId: stateFixed[activeCertification.address].sessionId,
        lastEvaluatedKey: null, // activeCertificationは常に先頭を検索する。
        consistentRead: true, // activeCertificationは常に強力な整合性のある読み込みをする。
      });
    }
  }

  const keyPairsInPool = getValidKeyPairs(state, dispatch);

  const extendedCertificationProperties = [
    { key: 'consistentRead', value: consistentRead },
  ];

  if (
    stateFixed.addressMain !== undefined && stateFixed.addressMain !== null && stateFixed.addressMain !== '' &&
    keyPairsInPool[stateFixed.addressMain]?.keyPair !== undefined
  ) {
    certificationWithKey(state, dispatch, [ keyPairsInPool[stateFixed.addressMain].keyPair ], 'information', stateFixed, certifications, now, extendedCertificationProperties, 'matchedKey');
  }
  else if (keyPairs !== undefined && keyPairs !== null) {
    certificationWithKey(state, dispatch, keyPairs, 'information', stateFixed, certifications, now, extendedCertificationProperties, 'firstKey');
  }
  else if (stateFixed.walletType === 'mpurse') {
    await setSignatureByAddressMain(state, dispatch, stateFixed, certifications, now, extendedCertificationProperties, 'information');
  }

  // 対象がなかったり、addressMainが指定されているにも関わらずcertificationsに含まれていなかった場合は戻る。ただし、KeyPairsが渡されていない場合は、鍵を要求する。

  if (
    certifications.length === 0 ||
    stateFixed.addressMain !== undefined && stateFixed.addressMain !== null && stateFixed.addressMain !== '' &&
    !certifications.map( certification => certification.address ).includes(stateFixed.addressMain)
  ) {
    if (keyPairs === undefined || keyPairs === null) {
      if (stateFixed.walletType === 'nfc') {
        dispatch({ type: 'setNotification', key: 'notification', value: words.touchWithYourKeyCard[state.language] });
        return {
          status: 'rejected',
          message: 'nfcRequired',
        };
      }
      else if (stateFixed.walletType === 'qr') {
        dispatch({ type: 'setNotification', key: 'notification', value: words.scanYour2DBarcode[state.language] });
        return {
          status: 'rejected',
          message: 'bcRequired',
        };
      }
    }

    // dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSearch[state.language] });
    return { status: 'rejected' };
  }

  request.body = JSON.stringify({
    body: {
      action: 'point',
      certifications: certifications,
    },
  });

  request.url = 'https://' + process.env.REACT_APP_KOM_API_DOMAIN + '/get_history';
  request.method = 'POST';

  messages = {
  };

  dispatch({ type: 'setState', key: 'accessing', value: true });

  const response = await syncHttpRequest2(request, 'pointHistory', undefined, undefined, messages, dispatch);

  dispatch({ type: 'setState', key: 'accessing', value: false });

  if ( response.status === 'fulfilled' ) {

    for (const address of Object.keys(response.body)) {
      // 署名した場合もろもろ更新
      if (response.body[address]['certificatedBy'] === 'signature') {

        // session更新
        dispatch({ type: 'setStateMultiLayers', keys: ['session', address],
          value: {'sessionId': response.body[address]['sessionId'], 'expirationOfSession': response.body[address]['expirationOfSession']} });
      }
      else if (response.body[address]['certificatedBy'] === 'none') {
        dispatch({ type: 'setStateMultiLayers', keys: ['session', address], value: { expirationOfSession: 0 } });
      }
    }

    // addressMainが検索対象で、検索成功した場合
    if (response.body[stateFixed.addressMain]?.certificatedBy === 'signature' || response.body[stateFixed.addressMain]?.certificatedBy === 'sessionId') {
      if (response.body[stateFixed.addressMain].lastEvaluatedKey !== undefined) {
        dispatch({ type: 'setStateMultiLayers', keys: ['getPointHistory', 'lastEvaluatedKey', 'point', pagingIndex], value: response.body[stateFixed.addressMain].lastEvaluatedKey });
      }
      else {
        dispatch({ type: 'setStateMultiLayers', keys: ['getPointHistory', 'lastEvaluatedKey', 'point', pagingIndex], value: null });
      }

      dispatch({ type: 'setStateMultiLayers', keys: ['getPointHistory', 'pagingIndex', 'point'], value: pagingIndex });

      // addressMainが指定されていなかった場合は、dispatchする。
      if (state.getPointHistory.addressMain === undefined || state.getPointHistory.addressMain === null || state.getPointHistory.addressMain === '') {
        dispatch({ type: 'setStateMultiLayers', keys: ['getPointHistory', 'addressMain'], value: stateFixed.addressMain });
      }

      // // cookie情報更新(アドレス情報)
      // const userName = state.usersGeneralIndex[stateFixed.addressMain] !== undefined ? state.usersGeneralIndex[stateFixed.addressMain].userName : stateFixed.addressMain;
      // const cookiesNew = [
      //       {
      //         action: 'searchPurchaseHistory',
      //         addressType: 'addressMain',
      //         description: `${userName}`,
      //         time: now,
      //         address: stateFixed.addressMain
      //       },
      // ];

      // setAddressesInCookie(state, dispatch, cookies, setCookie, stateFixed.addressMain, cookiesNew);
    }

    return { status: 'fulfilled', body: response.body };
  }
  else {
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSearch[state.language] });
    return { status: 'rejected' };
  }
}

// 「ポイント合計:検索」ボタン押下 HANDLE CLICK GET POINT SUM
async function handleClickGetPointSum(state, dispatch, activeCertification, consistentRead, keyPairs) {
  let addressActual;
  let request = {};
  let messages = {};
  let certifications = [];
  const now = Date.now();

  if (activeCertification === undefined || activeCertification === null) {
    activeCertification = {
      address: null,
    };
  }

  if (consistentRead === undefined || consistentRead === null) {
    consistentRead = false;
  }

  // state固定
  const stateFixed = {
    addressMain: state.getPointHistory.addressMain,
    signatureVersion: state.configMP.clientParameters.signatureVersion.getPointSum,
    walletType: state.walletType,
    addressCheck: 'strict',
  };

  // 有効なセッションに絞り込む。

  for (const address of Object.keys(state.session)) {

    // activeCertificationの場合は別処理
    if (address !== activeCertification.address) {

      // state固定
      stateFixed[address] = {
        // addressCoinType: state.session[address].addressCoinType,
        sessionId: state.session[address].sessionId,
        expirationOfSession: state.session[address].expirationOfSession,
      };

      if (stateFixed[address].expirationOfSession >= now + marginOfSessionTime) {
        certifications.push({
          // addressCoinType: stateFixed[address].addressCoinType,
          address: address,
          sessionId: stateFixed[address].sessionId,
          consistentRead: address === stateFixed.addressMain ? consistentRead : false,
        });
      }
    }
  }

  // activeCertificationの処理

  if (activeCertification.address !== null) {
    // -- state固定
    stateFixed[activeCertification.address] = {
      sessionId: activeCertification.sessionId,
      expirationOfSession: activeCertification.expirationOfSession,
    };

    // -- 有効だったら(まあほぼ有効なんだろうけど)プッシュ
    if (stateFixed[activeCertification.address].expirationOfSession >= now + marginOfSessionTime) {
      certifications.push({
        address: activeCertification.address,
        sessionId: stateFixed[activeCertification.address].sessionId,
        consistentRead: true, // activeCertificationは常に強力な整合性のある読み込みをする。
      });
    }
  }

  const keyPairsInPool = getValidKeyPairs(state, dispatch);

  const extendedCertificationProperties = [
    { key: 'consistentRead', value: consistentRead },
  ];

  if (
    stateFixed.addressMain !== undefined && stateFixed.addressMain !== null && stateFixed.addressMain !== '' &&
    keyPairsInPool[stateFixed.addressMain]?.keyPair !== undefined
  ) {
    certificationWithKey(state, dispatch, [ keyPairsInPool[stateFixed.addressMain].keyPair ], 'information', stateFixed, certifications, now, extendedCertificationProperties, 'matchedKey');
  }
  else if (keyPairs !== undefined && keyPairs !== null) {
    certificationWithKey(state, dispatch, keyPairs, 'information', stateFixed, certifications, now, extendedCertificationProperties, 'firstKey');
  }
  else if (stateFixed.walletType === 'mpurse') {
    await setSignatureByAddressMain(state, dispatch, stateFixed, certifications, now, extendedCertificationProperties, 'information');
  }

  // 対象がなかったり、addressMainが指定されているにも関わらずcertificationsに含まれていなかった場合は戻る。ただし、KeyPairsが渡されていない場合は、鍵を要求する。

  if (
    certifications.length === 0 ||
    stateFixed.addressMain !== undefined && stateFixed.addressMain !== null && stateFixed.addressMain !== '' &&
    !certifications.map( certification => certification.address ).includes(stateFixed.addressMain)
  ) {
    if (keyPairs === undefined || keyPairs === null) {
      if (stateFixed.walletType === 'nfc') {
        dispatch({ type: 'setNotification', key: 'notification', value: words.touchWithYourKeyCard[state.language] });
        return {
          status: 'rejected',
          message: 'nfcRequired',
        };
      }
      else if (stateFixed.walletType === 'qr') {
        dispatch({ type: 'setNotification', key: 'notification', value: words.scanYour2DBarcode[state.language] });
        return {
          status: 'rejected',
          message: 'bcRequired',
        };
      }
    }

    // dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSearch[state.language] });
    return { status: 'rejected' };
  }

  request.body = JSON.stringify({
    body: {
      action: 'pointSum',
      certifications: certifications,
    },
  });

  request.url = 'https://' + process.env.REACT_APP_KOM_API_DOMAIN + '/get_history';
  request.method = 'POST';

  messages = {
  };

  dispatch({ type: 'setState', key: 'accessing', value: true });

  const response = await syncHttpRequest2(request, 'pointSum', undefined, undefined, messages, dispatch);

  dispatch({ type: 'setState', key: 'accessing', value: false });

  if ( response.status === 'fulfilled' ) {

    for (const address of Object.keys(response.body)) {
      // 署名した場合もろもろ更新
      if (response.body[address]['certificatedBy'] === 'signature') {

        // session更新
        dispatch({ type: 'setStateMultiLayers', keys: ['session', address],
          value: {'sessionId': response.body[address]['sessionId'], 'expirationOfSession': response.body[address]['expirationOfSession']} });
      }
      else if (response.body[address]['certificatedBy'] === 'none') {
        dispatch({ type: 'setStateMultiLayers', keys: ['session', address], value: { expirationOfSession: 0 } });
      }
    }

    // addressMainが検索対象で、検索成功した場合
    if (response.body[stateFixed.addressMain]?.certificatedBy === 'signature' || response.body[stateFixed.addressMain]?.certificatedBy === 'sessionId') {

      // addressMainが指定されていなかった場合は、dispatchする。
      if (state.getPointHistory.addressMain === undefined || state.getPointHistory.addressMain === null || state.getPointHistory.addressMain === '') {
        dispatch({ type: 'setStateMultiLayers', keys: ['getPointHistory', 'addressMain'], value: stateFixed.addressMain });
      }

      // // cookie情報更新(アドレス情報)
      // const userName = state.usersGeneralIndex[stateFixed.addressMain] !== undefined ? state.usersGeneralIndex[stateFixed.addressMain].userName : stateFixed.addressMain;
      // const cookiesNew = [
      //       {
      //         action: 'searchPurchaseHistory',
      //         addressType: 'addressMain',
      //         description: `${userName}`,
      //         time: now,
      //         address: stateFixed.addressMain
      //       },
      // ];

      // setAddressesInCookie(state, dispatch, cookies, setCookie, stateFixed.addressMain, cookiesNew);
    }

    return { status: 'fulfilled', body: response.body };
  }
  else {
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSearch[state.language] });
    return { status: 'rejected' };
  }
}

/*
// 「デュエル履歴検索」ボタン押下 HANDLE CLICK GET DUEL HISTORY
async function handleClickGetDuelHistory(state, dispatch, pagingIndex) {
  let addressActual;
  let request = {};
  let messages = {};
  let certifications = [];
  const now = Date.now();

  // state固定
  const stateFixed = {
    addressMain: state.getPointHistory.addressMain,
    lastEvaluatedPurchaseNo: pagingIndex >= 1 ? state.getPointHistory.lastEvaluatedKey.point[pagingIndex - 1].purchaseNo : null,
    signatureVersion: state.configMP.clientParameters.signatureVersion.getPointHistory,
    addressCheck: 'strict',
  };

  // 有効なセッションに絞り込む。

  for (const address of Object.keys(state.session)) {
    // state固定
    stateFixed[address] = {
      // addressCoinType: state.session[address].addressCoinType,
      sessionId: state.session[address].sessionId,
      expirationOfSession: state.session[address].expirationOfSession,
    };

    if (stateFixed[address].expirationOfSession >= now + marginOfSessionTime) {
      certifications.push({
        // addressCoinType: stateFixed[address].addressCoinType,
        address: address,
        sessionId: stateFixed[address].sessionId,
        lastEvaluatedPurchaseNo: address === stateFixed.addressMain ? stateFixed.lastEvaluatedPurchaseNo : null,
        // consistentRead: address === stateFixed.addressMain ? consistentRead : false,
      });
    }
  }

  // アクティブなMONAアドレスが有り、そのアドレスのセッションが存在しない、または、期限が切れていれば、署名する。
  await setSignatureByAddressMain(state, dispatch, stateFixed, certifications, now);

  // 対象がなければ戻る。
  if (certifications.length === 0) {
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSearch[state.language] });
    return { status: 'rejected' };
  }

  request.body = JSON.stringify({
    body: {
      action: 'point',
      certifications: certifications,
    },
  });

  request.url = 'https://' + process.env.REACT_APP_KOM_API_DOMAIN + '/get_history';
  request.method = 'POST';

  messages = {
  };

  dispatch({ type: 'setState', key: 'accessing', value: true });

  const response = await syncHttpRequest2(request, 'pointHistory', undefined, undefined, messages, dispatch);

  dispatch({ type: 'setState', key: 'accessing', value: false });

  if ( response.status === 'fulfilled' ) {

    for (const address of Object.keys(response.body)) {
      // 署名した場合もろもろ更新
      if (response.body[address]['certificatedBy'] === 'signature') {

        // session更新
        dispatch({ type: 'setStateMultiLayers', keys: ['session', address],
          value: {'sessionId': response.body[address]['sessionId'], 'expirationOfSession': response.body[address]['expirationOfSession']} });
      }
      else if (response.body[address]['certificatedBy'] === 'none') {
        dispatch({ type: 'setStateMultiLayers', keys: ['session', address], value: { expirationOfSession: 0 } });
      }
    }

    // addressMainが検索対象で、検索成功した場合
    if (response.body[stateFixed.addressMain]?.certificatedBy === 'signature' || response.body[stateFixed.addressMain]?.certificatedBy === 'sessionId') {
      if (response.body[stateFixed.addressMain].lastEvaluatedKey !== undefined) {
        dispatch({ type: 'setStateMultiLayers', keys: ['getPointHistory', 'lastEvaluatedKey', 'point', pagingIndex], value: response.body[stateFixed.addressMain].lastEvaluatedKey });
      }
      else {
        dispatch({ type: 'setStateMultiLayers', keys: ['getPointHistory', 'lastEvaluatedKey', 'point', pagingIndex], value: null });
      }

      dispatch({ type: 'setStateMultiLayers', keys: ['getPointHistory', 'pagingIndex', 'point'], value: pagingIndex });

      // // cookie情報更新(アドレス情報)
      // const userName = state.usersGeneralIndex[stateFixed.addressMain] !== undefined ? state.usersGeneralIndex[stateFixed.addressMain].userName : stateFixed.addressMain;
      // const cookiesNew = [
      //       {
      //         action: 'searchPurchaseHistory',
      //         addressType: 'addressMain',
      //         description: `${userName}`,
      //         time: now,
      //         address: stateFixed.addressMain
      //       },
      // ];

      // setAddressesInCookie(state, dispatch, cookies, setCookie, stateFixed.addressMain, cookiesNew);
    }

    return { status: 'fulfilled', body: response.body };
  }
  else {
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSearch[state.language] });
    return { status: 'rejected' };
  }
}
*/

// 「デュエル履歴検索」ボタン押下 HANDLE CLICK GET DUEL HISTORY ALL
async function handleClickGetDuelHistoryAll(state, dispatch, pagingIndex, directAccessParameters) {
  let addressActual;
  let start;
  let end;
  let request = {};
  let messages = {};
  let certifications = [];
  const now = Date.now();

  // state固定
  const stateFixed = {
    action: state.getDuelHistory.action,
    addressMain: state.active.addressMain,
    sessionId: state.session[state.active.addressMain]?.sessionId,
    lastEvaluatedKey: pagingIndex >= 1 ? state.getDuelHistory.lastEvaluatedKey[state.getDuelHistory.action][pagingIndex - 1] : null,
    // lastEvaluatedSortKey: pagingIndex >= 1 ? state.getDuelHistory.lastEvaluatedKey[state.getDuelHistory.action][pagingIndex - 1]?.duelNo : null,
    lastEvaluatedKey2: pagingIndex >= 1 ? state.getDuelHistory.lastEvaluatedKey2[state.getDuelHistory.action]?.[pagingIndex - 1] : null,
    // lastEvaluatedSortKey2: pagingIndex >= 1 ? state.getDuelHistory.lastEvaluatedKey2[state.getDuelHistory.action]?.[pagingIndex - 1]?.duelNo : null,
    epochFrom: state.searchDateRange.from.epoch,
    yearFrom: state.searchDateRange.from.year,
    monthFrom: state.searchDateRange.from.month,
    dayFrom: state.searchDateRange.from.day,
    epochTo: state.searchDateRange.to.epoch,
    yearTo: state.searchDateRange.to.year,
    monthTo: state.searchDateRange.to.month,
    dayTo: state.searchDateRange.to.day,
    duelistAddress: state.getDuelHistory.duelistAddress,
    roomId: state.getDuelHistory.roomId,
    duelNo: state.getDuelHistory.duelNo,
  };

  if (directAccessParameters?.action === 'duelHistorySingle') {
    stateFixed.action = 'duelHistorySingle';
    stateFixed.roomId = directAccessParameters.roomId;
    stateFixed.duelNo = directAccessParameters.duelNo;
  }
  else if (directAccessParameters?.action === 'duelHistoryAll') {
    stateFixed.action = 'duelHistoryAll';
  }

  devLog('from', `${stateFixed.yearFrom}/${stateFixed.monthFrom}/${stateFixed.dayFrom}`);
  devLog('from epoch', localTime(stateFixed.epochFrom));
  devLog('to', `${stateFixed.yearTo}/${stateFixed.monthTo}/${stateFixed.dayTo}`);
  devLog('to epoch', localTime(stateFixed.epochTo));

  // 入力値チェック
  // -- 開始年月日
  if ((stateFixed.yearFrom === undefined || stateFixed.yearFrom === null || stateFixed.yearFrom === '') &&
      (stateFixed.monthFrom === undefined || stateFixed.monthFrom === null || stateFixed.monthFrom === '') &&
      (stateFixed.dayFrom === undefined || stateFixed.dayFrom === null || stateFixed.dayFrom === '')         ) {
    start = undefined;
  }
  else {
    if (stateFixed.yearFrom < 1970 || stateFixed.yearFrom > 9999) {
      devLog('year is out of range.');
      dispatch({ type: 'setNotification', key: 'notification', value: words.yearIsOutOfRange[state.language] });
      return { status: 'rejected' };
    }

    if (stateFixed.monthFrom < 1 || stateFixed.monthFrom > 12 ||
        stateFixed.dayFrom < 1 || stateFixed.dayFrom > 31) {
      devLog('month or day is out of range.');
      dispatch({ type: 'setNotification', key: 'notification', value: words.monthOrDayIsOutOfRange[state.language] });
      return { status: 'rejected' };
    }

    start = stateFixed.epochFrom;
  }

  // -- 終了年月日
  if ((stateFixed.yearTo === undefined || stateFixed.yearTo === null || stateFixed.yearTo === '') &&
      (stateFixed.monthTo === undefined || stateFixed.monthTo === null || stateFixed.monthTo === '') &&
      (stateFixed.dayTo === undefined || stateFixed.dayTo === null || stateFixed.dayTo === '')         ) {
    end = undefined;
  }
  else {
    if (stateFixed.yearTo < 1970 || stateFixed.yearTo > 9999) {
      devLog('year is out of range.');
      dispatch({ type: 'setNotification', key: 'notification', value: words.yearIsOutOfRange[state.language] });
      return { status: 'rejected' };
    }

    if (stateFixed.monthTo < 1 || stateFixed.monthTo > 12 ||
        stateFixed.dayTo < 1 || stateFixed.dayTo > 31       ) {
      devLog('month or day is out of range.');
      dispatch({ type: 'setNotification', key: 'notification', value: words.monthOrDayIsOutOfRange[state.language] });
      return { status: 'rejected' };
    }

    end = stateFixed.epochTo;
  }

  // -- デュエリストアドレス ← このチェックは実は不要。
  if (stateFixed.action === 'duelHistoryAddress') {
    if (stateFixed.duelistAddress === undefined || stateFixed.duelistAddress === null || stateFixed.duelistAddress === '') {
      dispatch({ type: 'setNotification', key: 'notification', value: words.duelistAddressMustBeFilled[state.language] });
      return { status: 'rejected' };
    }
  }


  request.body = JSON.stringify({
    body: {
      action: stateFixed.action,
      duelistAddress: stateFixed.duelistAddress,
      roomId: stateFixed.roomId,
      duelNo: stateFixed.duelNo,
      start: start,
      end: end,
      addressMain: stateFixed.addressMain,
      sessionId: stateFixed.sessionId,
      lastEvaluatedKey: stateFixed.lastEvaluatedKey,
      // lastEvaluatedSortKey: stateFixed.lastEvaluatedSortKey,
      lastEvaluatedKey2: stateFixed.lastEvaluatedKey2,
      // lastEvaluatedSortKey2: stateFixed.lastEvaluatedSortKey2,
    },
  });

  request.url = 'https://' + process.env.REACT_APP_KOM_API_DOMAIN + '/get_history_open';
  request.method = 'POST';

  messages = {
  };

  dispatch({ type: 'setState', key: 'accessing', value: true });

  const response = await syncHttpRequest2(request, stateFixed.action, undefined, undefined, messages, dispatch);

  dispatch({ type: 'setState', key: 'accessing', value: false });

  if ( response.status === 'fulfilled' ) {

    if (response.body.lastEvaluatedKey !== undefined) {
      dispatch({ type: 'setStateMultiLayers', keys: ['getDuelHistory', 'lastEvaluatedKey', stateFixed.action, pagingIndex], value: response.body.lastEvaluatedKey });
    }
    else {
      dispatch({ type: 'setStateMultiLayers', keys: ['getDuelHistory', 'lastEvaluatedKey', stateFixed.action, pagingIndex], value: null });
    }

    if (response.body.lastEvaluatedKey2 !== undefined) {
      dispatch({ type: 'setStateMultiLayers', keys: ['getDuelHistory', 'lastEvaluatedKey2', stateFixed.action, pagingIndex], value: response.body.lastEvaluatedKey2 });
    }
    else {
      dispatch({ type: 'setStateMultiLayers', keys: ['getDuelHistory', 'lastEvaluatedKey2', stateFixed.action, pagingIndex], value: null });
    }

    dispatch({ type: 'setStateMultiLayers', keys: ['getDuelHistory', 'pagingIndex', stateFixed.action], value: pagingIndex });

    // // cookie情報更新(アドレス情報)
    // const userName = state.usersGeneralIndex[stateFixed.addressMain] !== undefined ? state.usersGeneralIndex[stateFixed.addressMain].userName : stateFixed.addressMain;
    // const cookiesNew = [
    //       {
    //         action: 'searchPurchaseHistory',
    //         addressType: 'addressMain',
    //         description: `${userName}`,
    //         time: now,
    //         address: stateFixed.addressMain
    //       },
    // ];

    // setAddressesInCookie(state, dispatch, cookies, setCookie, stateFixed.addressMain, cookiesNew);

    return { status: 'fulfilled', body: response.body };
  }
  else {
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSearch[state.language] });
    return { status: 'rejected' };
  }
}

// 「デュエルランキング検索」ボタン押下 HANDLE CLICK GET DUEL RANKING ALL
async function handleClickGetDuelRankingAll(state, dispatch, pagingIndex) {
  let addressActual;
  let request = {};
  let messages = {};

  const action = state.getDuelRanking.action;
  const now = Date.now();

  // state固定
  const stateFixed = {
    action: action,
    addressMain: state.active.addressMain,
    sessionId: state.session[state.active.addressMain]?.sessionId,
    lastEvaluatedKey: pagingIndex >= 1 ? state.getDuelRanking.lastEvaluatedKey[action.difficultyMode][action.gameMode][action.period][pagingIndex - 1] : null,
    lastRank: pagingIndex >= 1 ? state.getDuelRanking.lastRank[action.difficultyMode][action.gameMode][action.period][pagingIndex - 1] : 0,
  };

  // 入力値チェック


  request.body = JSON.stringify({
    body: {
      action: 'duelRankingAll',
      actionDetail: stateFixed.action,
      addressMain: stateFixed.addressMain,
      sessionId: stateFixed.sessionId,
      lastEvaluatedKey: stateFixed.lastEvaluatedKey,
    },
  });

  request.url = 'https://' + process.env.REACT_APP_KOM_API_DOMAIN + '/get_history_open';
  request.method = 'POST';

  messages = {
  };

  dispatch({ type: 'setState', key: 'accessing', value: true });

  const response = await syncHttpRequest2(request, undefined, undefined, undefined, messages, dispatch);

  dispatch({ type: 'setState', key: 'accessing', value: false });

  if ( response.status === 'fulfilled' ) {

    // 順位付け

    const duelRankingData = response.body.duelRanking.map( (record, index) => {
      record.rank = index + 1 + stateFixed.lastRank;
      return record;
    });

    // ランキングデータを格納

    dispatch({
      type: 'setStateMultiLayers',
      keys: ['getDuelRanking', 'records', stateFixed.action.difficultyMode, stateFixed.action.gameMode, stateFixed.action.period, 'duelRanking'],
      value: duelRankingData
    });

    // lastRank格納

    dispatch({
      type: 'setStateMultiLayers',
      keys: ['getDuelRanking', 'lastRank', stateFixed.action.difficultyMode, stateFixed.action.gameMode, stateFixed.action.period, pagingIndex],
      value: stateFixed.lastRank + duelRankingData.length
    });

    // lastEvaluatedKey, pagingIndex格納

    if (response.body.lastEvaluatedKey !== undefined) {
      dispatch({
        type: 'setStateMultiLayers',
        keys: ['getDuelRanking', 'lastEvaluatedKey', stateFixed.action.difficultyMode, stateFixed.action.gameMode, stateFixed.action.period, pagingIndex],
        value: response.body.lastEvaluatedKey
      });
    }
    else {
      dispatch({
        type: 'setStateMultiLayers',
        keys: ['getDuelRanking', 'lastEvaluatedKey', stateFixed.action.difficultyMode, stateFixed.action.gameMode, stateFixed.action.period, pagingIndex],
        value: null
      });
    }

    dispatch({
      type: 'setStateMultiLayers',
      keys: ['getDuelRanking', 'pagingIndex', stateFixed.action.difficultyMode, stateFixed.action.gameMode, stateFixed.action.period],
      value: pagingIndex
    });

    // // cookie情報更新(アドレス情報)
    // const userName = state.usersGeneralIndex[stateFixed.addressMain] !== undefined ? state.usersGeneralIndex[stateFixed.addressMain].userName : stateFixed.addressMain;
    // const cookiesNew = [
    //       {
    //         action: 'searchPurchaseHistory',
    //         addressType: 'addressMain',
    //         description: `${userName}`,
    //         time: now,
    //         address: stateFixed.addressMain
    //       },
    // ];

    // setAddressesInCookie(state, dispatch, cookies, setCookie, stateFixed.addressMain, cookiesNew);

    return { status: 'fulfilled', body: response.body };
  }
  else {
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSearch[state.language] });
    return { status: 'rejected' };
  }
}

// 「商品検索」ボタン押下 HANDLE CLICK GET REGISTERED ITEM
async function handleClickGetRegisteredItem(state, dispatch, pagingIndex, directAccessParameters) {
  let addressActual;
  let request = {};
  let messages = {};

  // state固定
  const stateFixed = {
    action: state.getRegisteredItem.action,
    addressMain: state.active.addressMain,
    sessionId: state.session[state.active.addressMain]?.sessionId,
    lastEvaluatedKey: pagingIndex >= 1 ? state.getRegisteredItem.lastEvaluatedKey[state.getRegisteredItem.action][pagingIndex - 1] : null,
  };

  // if (directAccessParameters !== undefined) {
  //   stateFixed.action = 'duelHistorySingle';
  //   stateFixed.roomId = directAccessParameters.roomId;
  //   stateFixed.duelNo = directAccessParameters.duelNo;
  // }

  devLog('from', `${stateFixed.yearFrom}/${stateFixed.monthFrom}/${stateFixed.dayFrom}`);
  devLog('from epoch', localTime(stateFixed.epochFrom));
  devLog('to', `${stateFixed.yearTo}/${stateFixed.monthTo}/${stateFixed.dayTo}`);
  devLog('to epoch', localTime(stateFixed.epochTo));

  // 入力値チェック
  // -- なし。


  request.body = JSON.stringify({
    body: {
      action: stateFixed.action,
      addressMain: stateFixed.addressMain,
      sessionId: stateFixed.sessionId,
      lastEvaluatedKey: stateFixed.lastEvaluatedKey,
    },
  });

  request.url = 'https://' + process.env.REACT_APP_KOM_API_DOMAIN + '/get_history_open';
  request.method = 'POST';

  messages = {
  };

  dispatch({ type: 'setState', key: 'accessing', value: true });

  const response = await syncHttpRequest2(request, stateFixed.action, undefined, undefined, messages, dispatch);

  dispatch({ type: 'setState', key: 'accessing', value: false });

  if ( response.status === 'fulfilled' ) {

    if (response.body.lastEvaluatedKey !== undefined) {
      dispatch({ type: 'setStateMultiLayers', keys: ['getRegisteredItem', 'lastEvaluatedKey', stateFixed.action, pagingIndex], value: response.body.lastEvaluatedKey });
    }
    else {
      dispatch({ type: 'setStateMultiLayers', keys: ['getRegisteredItem', 'lastEvaluatedKey', stateFixed.action, pagingIndex], value: null });
    }

    dispatch({ type: 'setStateMultiLayers', keys: ['getRegisteredItem', 'pagingIndex', stateFixed.action], value: pagingIndex });

    // // cookie情報更新(アドレス情報)
    // const userName = state.usersGeneralIndex[stateFixed.addressMain] !== undefined ? state.usersGeneralIndex[stateFixed.addressMain].userName : stateFixed.addressMain;
    // const cookiesNew = [
    //       {
    //         action: 'searchPurchaseHistory',
    //         addressType: 'addressMain',
    //         description: `${userName}`,
    //         time: now,
    //         address: stateFixed.addressMain
    //       },
    // ];

    // setAddressesInCookie(state, dispatch, cookies, setCookie, stateFixed.addressMain, cookiesNew);

    return { status: 'fulfilled', body: response.body };
  }
  else {
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSearch[state.language] });
    return { status: 'rejected' };
  }
}

// ADD TO CART
function addToCart(state, dispatch, item) {
  const thisItemInCart = state.checkout.itemsIncart[item.itemNo];

  if (thisItemInCart?.amountToBuy >= item.stock) {
    return {
      status: 'rejected',
    };
  }
  else if (thisItemInCart !== undefined) {
    const thisItemInCartAmount = thisItemInCart.amountToBuy;
    dispatch({ type: 'setStateMultiLayers', keys: ['checkout', 'itemsIncart', item.itemNo, 'amountToBuy'], value: thisItemInCartAmount + 1 });

    return {
      status: 'fulfilled',
      body: thisItemInCartAmount + 1,
    };
  }
  else {
    const itemNew = {
      itemNo: item.itemNo,
      amountToBuy: 1,
      information: item,
    };

    dispatch({ type: 'setStateMultiLayers', keys: ['checkout', 'itemsIncart', item.itemNo], value: itemNew });

    return {
      status: 'fulfilled',
      body: 1,
    };
  }
}

// REMOVE FROM CART
function removeFromCart(state, dispatch, item) {
  const thisItemInCart = state.checkout.itemsIncart[item.itemNo];

  if (thisItemInCart === undefined || thisItemInCart.amountToBuy === 0) {
    return {
      status: 'rejected',
    };
  }
  else {
    const thisItemInCartAmount = thisItemInCart.amountToBuy;
    dispatch({ type: 'setStateMultiLayers', keys: ['checkout', 'itemsIncart', item.itemNo, 'amountToBuy'], value: thisItemInCartAmount - 1 });

    return {
      status: 'fulfilled',
      body: thisItemInCartAmount - 1,
    };
  }
}

// 「商品購入:署名」ボタン押下 HANDLE CLICK SUBMIT ORDER
async function handleClickSubmitOrder(state, dispatch, navigate, { keyPairs } = {}) {
  let addressActual;
  let signature;
  let request = {};
  let messages = {};
  let bodyObj = {};
  let keyPair;
  const itemsIncartNotZero =[];

  // state固定
  const stateFixed = {
    addressMain: state.active.addressMain,
    mailAddress: state.checkout.mailAddress,
    paymentMethod: state.checkout.paymentMethod,
    itemsIncart: Object.values(state.checkout.itemsIncart),
    signatureVersion: state.config.clientParameters.signatureVersion.purchaseItem,
    // addressCheck: state.addressCheck,
    addressCheck: 'strict',
    minimumPurchaseAmount: state.config.clientParameters.itemSales.minimumPurchaseAmount,
  };

  if (keyPairs !== undefined && keyPairs !== null) {
    keyPair = keyPairs[0];
    const result = getAddressFromKey(state, dispatch, keyPair);

    if (result.status === 'fulfilled') {
      stateFixed.addressMain = result.address;
    }
    else {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToGetTheAddress[state.language] });
      return result;
    }
  }

  const priceJpyTotal = stateFixed.itemsIncart.reduce( (acc, cur) => acc + cur.information.priceJpy * cur.amountToBuy, 0);

  // 必須項目チェック
  if (stateFixed.addressMain === undefined || stateFixed.addressMain === null || stateFixed.addressMain === '') {
    dispatch({ type: 'setNotification', key: 'notification', value: words.pleaseLogin[state.language] });
    return { status: 'rejected' };
  }

  if (stateFixed.mailAddress === undefined || stateFixed.mailAddress === null || stateFixed.mailAddress === '') {
    dispatch({ type: 'setNotification', key: 'notification', value: words.mailAddressMustBeFilled[state.language] });
    return { status: 'rejected' };
  }

  if (stateFixed.paymentMethod === undefined || stateFixed.paymentMethod === null || stateFixed.paymentMethod === '') {
    // dispatch({ type: 'setNotification', key: 'notification', value: words.mailAddressMustBeFilled[state.language] });
    return { status: 'rejected' };
  }

  // 購入量が0の商品は削除する。 
  for (const item of stateFixed.itemsIncart) {
    if (item.amountToBuy >= 1) {
      itemsIncartNotZero.push(item);
    }
  }

  // カートに商品があるかチェック
  // if (Object.values(stateFixed.itemsIncart).filter( item => item.amountToBuy >= 1 ).length < 1) {}
  if (itemsIncartNotZero.length < 1) {
    dispatch({ type: 'setNotification', key: 'notification', value: words.addItemsToYourCart[state.language] });
    return { status: 'rejected' };
  }

  // 購入金額が最低金額以上になっているかチェック
  if (priceJpyTotal < stateFixed.minimumPurchaseAmount) {
    dispatch({ type: 'setNotification', key: 'notification',
      value: words.thePurchaseAmountMustBeMoreThanOrEqualTo1[state.language] + stateFixed.minimumPurchaseAmount + words.thePurchaseAmountMustBeMoreThanOrEqualTo2[state.language]
    });
    return { status: 'rejected' };
  }


  const clientTime = Date.now();
  const message = `I will buy items for ${priceJpyTotal} JPY. I will buy them as ${stateFixed.addressMain}. (epoch time: ${clientTime}, signature version: ${stateFixed.signatureVersion})`;

  if (keyPair !== undefined) {
    addressActual = stateFixed.addressMain;

    const result = signWithKey(state, dispatch, keyPair, message);

    if (result.status === 'fulfilled') {
      signature = result.signature;
    }
    else {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSign[state.language] });
      return 'rejected';
    }
  }
  else {
    // Mpurseのアドレスが合ってるかチェック
    const result = await window.mpurse.getAddress()
    .then( (result) => {
      addressActual = result;

      if (stateFixed.addressCheck === 'strict') {
        if (addressActual !== stateFixed.addressMain) {
          dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressLoggedInAndTheAddressInWalletAreDifferent[state.language] });
          return 'rejected';
        }
        else {
          return 'fulfilled';
        }
      }
      else { // off
        return 'fulfilled';
      }
    })
    .catch( (error) => {
        dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressInYourWalletCanNotBeGotten[state.language] });
        return 'rejected';
    });

    if (result === 'rejected') {
      return { status: 'rejected' };
    }

    // 署名

    try {
      signature= await window.mpurse.signMessage(message).then( result => result );
    }
    catch (err) {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSign[state.language] });
      return { status: 'rejected' };
    }
  }

  // GO！

  bodyObj = {
    body: {
      addressMain: stateFixed.addressMain,
      addressMainActual: addressActual,
      mailAddress: stateFixed.mailAddress,
      paymentMethod: stateFixed.paymentMethod,
      items: itemsIncartNotZero,
      clientTimeOfAddressMain: clientTime,
      signatureByAddressMain: signature,
      signatureVersionOfAddressMain: stateFixed.signatureVersion,
    }
  };

  request.body = JSON.stringify(bodyObj);
  request.url = 'https://' + process.env.REACT_APP_KOM_API_DOMAIN + '/purchase_item';
  request.method = 'POST';

  messages = {
    "you must accept the terms and conditions of the current version.": words.youMustAcceptTheTermsAndConditionsOfTheCurrentVersion[state.language],
    "internal server error": words.internalServerError[state.language],
    "bad signature": words.badSignature[state.language],
    "unknown signatureVersion": words.unknownSignatureVersion[state.language],
    "clientTime must be larger than last time.": words.clientTimeMustBeLargerThanLastTime[state.language],
    "this address is not permitted.": words.thisAddressIsNotPermitted[state.language],
    "this address is forbidden.": words.thisAddressIsForbidden[state.language],
    "out of service": words.outOfService[state.language],
    "user does not exist.": words.pleaseRegisterAsAUserOnTheSetupPage[state.language],
    "success": words.thankYouForYourPurchase[state.language],
  };

  dispatch({ type: 'setState', key: 'accessing', value: true });

  const response = await syncHttpRequest2(request, undefined, undefined, 'notification', messages, dispatch);

  dispatch({ type: 'setState', key: 'accessing', value: false });

  if ( response.status === 'fulfilled' ) {
    // const purchaseItem = response.body.purchaseItem;
    const sessionId = response.body.sessionId;
    const expirationOfSession = response.body.expirationOfSession;
    const paygentLink = response.body.paygentLink;

    // checkout情報更新
    // dispatch({ type: 'setState', key: 'checkout', value: purchaseItem });
    // カートの中は空にする！！
    dispatch({ type: 'setStateMultiLayers', keys: ['checkout', 'itemsIncart'], value: {} });

    // session情報更新
    dispatch({ type: 'setStateMultiLayers', keys: ['session', stateFixed.addressMain], value: {'sessionId': sessionId.addressMain, 'expirationOfSession': expirationOfSession.addressMain} });

    if (addressActual !== stateFixed.addressMain) {
      dispatch({ type: 'setStateMultiLayers', keys: ['session', addressActual], value: {'sessionId': sessionId.addressMainActual, 'expirationOfSession': expirationOfSession.addressMainActual} });
    }

    // // オーダーNo.通知欄表示(全署名完了していたら)
    // if ( purchasePoint.signatureByAddressMain !== undefined && purchasePoint.signatureByAddressMain !== null && purchasePoint.signatureByAddressMain !== '' &&
    //      purchasePoint.signatureByAddressPayFrom !== undefined && purchasePoint.signatureByAddressPayFrom !== null && purchasePoint.signatureByAddressPayFrom !== '') {
    //   dispatch({ type: 'setNotification', key: 'notification', value: words.acceptedAsPurchaseNoX1[state.language] + purchasePoint.purchaseNo + words.acceptedAsPurchaseNoX2[state.language] });
    // }

    // const exhibitorName = state.usersGeneralIndex[stateFixed.addressMainExhibitor] !== undefined ? state.usersGeneralIndex[stateFixed.addressMainExhibitor].userName : stateFixed.addressMainExhibitor;
    // const cookiesNew = [
    //       {
    //         action: 'purchasePoint',
    //         addressType: 'addressMain',
    //         description: `${exhibitorName} ${tokenNameEither} ${stateFixed.amountToBuy}`,
    //         time: clientTime,
    //         address: stateFixed.addressMain
    //       },
    // ];

    // if (cookiesNew.every( cookie => cookie.address !== stateFixed.addressPayFrom )) {
    //   cookiesNew.push(
    //       {
    //         action: 'purchasePoint',
    //         addressType: 'addressPayFrom',
    //         description: `${exhibitorName} ${tokenNameEither} ${stateFixed.amountToBuy}`,
    //         time: clientTime,
    //         address: stateFixed.addressPayFrom
    //       },
    //   );
    // }

    // if (cookiesNew.every( cookie => cookie.address !== stateFixed.addressSendCardTo )) {
    //   cookiesNew.push(
    //       {
    //         action: 'purchasePoint',
    //         addressType: 'addressSendCardTo',
    //         description: `${exhibitorName} ${tokenNameEither} ${stateFixed.amountToBuy}`,
    //         time: clientTime,
    //         address: stateFixed.addressSendCardTo
    //       }
    //   );
    // }

    // setAddressesInCookie(state, dispatch, cookies, setCookie, stateFixed.addressMain, cookiesNew);

    if (stateFixed.paymentMethod === 'paygent') {
      const paygent = window.open(paygentLink);
      paygent.focus();
    }

    // 商品購入履歴画面に飛ばしたあと自動検索が走るように、メインアドレスを設定して、履歴を消す！
    dispatch({ type: 'setStateMultiLayers', keys: ['getItemOrderHistory', 'addressMain'], value: stateFixed.addressMain });
    dispatch({ type: 'setStateMultiLayers', keys: ['itemOrderHistory', stateFixed.addressMain], value: undefined });

    // 商品購入履歴画面に飛ばす。
    handleClickItemOrderHistory(state, dispatch, navigate);
  }
  else {
    return { status: 'rejected' };
  }

  return response;
}

// 「商品注文履歴:検索」ボタン押下 HANDLE CLICK GET ITEM ORDER HISTORY
async function handleClickGetItemOrderHistory(state, dispatch, pagingIndex, activeCertification, consistentRead, keyPairs) {
  let addressActual;
  let request = {};
  let messages = {};
  let certifications = [];
  const now = Date.now();

  if (activeCertification === undefined || activeCertification === null) {
    activeCertification = {
      address: null,
    };
  }

  if (consistentRead === undefined || consistentRead === null) {
    consistentRead = false;
  }

  // state固定
  const stateFixed = {
    addressMain: state.getItemOrderHistory.addressMain,
    lastEvaluatedKey: pagingIndex >= 1 ? state.getItemOrderHistory.lastEvaluatedKey.itemOrder[pagingIndex - 1] : null,
    signatureVersion: state.config.clientParameters.signatureVersion.getItemOrderHistory,
    walletType: state.walletType,
    addressCheck: 'strict',
  };

  // 有効なセッションに絞り込む。

  for (const address of Object.keys(state.session)) {

    // activeCertificationの場合は別処理
    if (address !== activeCertification.address) {

      // state固定
      stateFixed[address] = {
        // addressCoinType: state.session[address].addressCoinType,
        sessionId: state.session[address].sessionId,
        expirationOfSession: state.session[address].expirationOfSession,
      };

      if (stateFixed[address].expirationOfSession >= now + marginOfSessionTime) {
        certifications.push({
          // addressCoinType: stateFixed[address].addressCoinType,
          address: address,
          sessionId: stateFixed[address].sessionId,
          lastEvaluatedKey: address === stateFixed.addressMain ? stateFixed.lastEvaluatedKey : null,
          consistentRead: address === stateFixed.addressMain ? consistentRead : false,
        });
      }
    }
  }

  // activeCertificationの処理

  if (activeCertification.address !== null) {
    // -- state固定
    stateFixed[activeCertification.address] = {
      sessionId: activeCertification.sessionId,
      expirationOfSession: activeCertification.expirationOfSession,
    };

    // -- 有効だったら(まあほぼ有効なんだろうけど)プッシュ
    if (stateFixed[activeCertification.address].expirationOfSession >= now + marginOfSessionTime) {
      certifications.push({
        address: activeCertification.address,
        sessionId: stateFixed[activeCertification.address].sessionId,
        lastEvaluatedKey: null, // activeCertificationは常に先頭を検索する。
        consistentRead: true, // activeCertificationは常に強力な整合性のある読み込みをする。
      });
    }
  }

  const keyPairsInPool = getValidKeyPairs(state, dispatch);

  const extendedCertificationProperties = [
    { key: 'consistentRead', value: consistentRead },
  ];

  if (
    stateFixed.addressMain !== undefined && stateFixed.addressMain !== null && stateFixed.addressMain !== '' &&
    keyPairsInPool[stateFixed.addressMain]?.keyPair !== undefined
  ) {
    certificationWithKey(state, dispatch, [ keyPairsInPool[stateFixed.addressMain].keyPair ], 'information', stateFixed, certifications, now, extendedCertificationProperties, 'matchedKey');
  }
  else if (keyPairs !== undefined && keyPairs !== null) {
    certificationWithKey(state, dispatch, keyPairs, 'information', stateFixed, certifications, now, extendedCertificationProperties, 'firstKey');
  }
  else if (stateFixed.walletType === 'mpurse') {
    await setSignatureByAddressMain(state, dispatch, stateFixed, certifications, now, extendedCertificationProperties, 'information');
  }

  // 対象がなかったり、addressMainが指定されているにも関わらずcertificationsに含まれていなかった場合は戻る。ただし、KeyPairsが渡されていない場合は、鍵を要求する。

  if (
    certifications.length === 0 ||
    stateFixed.addressMain !== undefined && stateFixed.addressMain !== null && stateFixed.addressMain !== '' &&
    !certifications.map( certification => certification.address ).includes(stateFixed.addressMain)
  ) {
    if (keyPairs === undefined || keyPairs === null) {
      if (stateFixed.walletType === 'nfc') {
        dispatch({ type: 'setNotification', key: 'notification', value: words.touchWithYourKeyCard[state.language] });
        return {
          status: 'rejected',
          message: 'nfcRequired',
        };
      }
      else if (stateFixed.walletType === 'qr') {
        dispatch({ type: 'setNotification', key: 'notification', value: words.scanYour2DBarcode[state.language] });
        return {
          status: 'rejected',
          message: 'bcRequired',
        };
      }
    }

    // dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSearch[state.language] });
    return { status: 'rejected' };
  }

  request.body = JSON.stringify({
    body: {
      action: 'itemOrder',
      certifications: certifications,
    },
  });

  request.url = 'https://' + process.env.REACT_APP_KOM_API_DOMAIN + '/get_history';
  request.method = 'POST';

  messages = {
  };

  dispatch({ type: 'setState', key: 'accessing', value: true });

  const response = await syncHttpRequest2(request, 'itemOrderHistory', undefined, undefined, messages, dispatch);

  dispatch({ type: 'setState', key: 'accessing', value: false });

  if ( response.status === 'fulfilled' ) {

    for (const address of Object.keys(response.body)) {
      // 署名した場合もろもろ更新
      if (response.body[address]['certificatedBy'] === 'signature') {

        // session更新
        dispatch({ type: 'setStateMultiLayers', keys: ['session', address],
          value: {'sessionId': response.body[address]['sessionId'], 'expirationOfSession': response.body[address]['expirationOfSession']} });
      }
      else if (response.body[address]['certificatedBy'] === 'none') {
        dispatch({ type: 'setStateMultiLayers', keys: ['session', address], value: { expirationOfSession: 0 } });
      }
    }

    // addressMainが検索対象で、検索成功した場合
    if (response.body[stateFixed.addressMain]?.certificatedBy === 'signature' || response.body[stateFixed.addressMain]?.certificatedBy === 'sessionId') {
      if (response.body[stateFixed.addressMain].lastEvaluatedKey !== undefined) {
        dispatch({ type: 'setStateMultiLayers', keys: ['getItemOrderHistory', 'lastEvaluatedKey', 'itemOrder', pagingIndex], value: response.body[stateFixed.addressMain].lastEvaluatedKey });
      }
      else {
        dispatch({ type: 'setStateMultiLayers', keys: ['getItemOrderHistory', 'lastEvaluatedKey', 'itemOrder', pagingIndex], value: null });
      }

      dispatch({ type: 'setStateMultiLayers', keys: ['getItemOrderHistory', 'pagingIndex', 'itemOrder'], value: pagingIndex });

      // addressMainが指定されていなかった場合は、dispatchする。
      if (state.getItemOrderHistory.addressMain === undefined || state.getItemOrderHistory.addressMain === null || state.getItemOrderHistory.addressMain === '') {
        dispatch({ type: 'setStateMultiLayers', keys: ['getItemOrderHistory', 'addressMain'], value: stateFixed.addressMain });
      }

      // // cookie情報更新(アドレス情報)
      // const userName = state.usersGeneralIndex[stateFixed.addressMain] !== undefined ? state.usersGeneralIndex[stateFixed.addressMain].userName : stateFixed.addressMain;
      // const cookiesNew = [
      //       {
      //         action: 'searchPurchaseHistory',
      //         addressType: 'addressMain',
      //         description: `${userName}`,
      //         time: now,
      //         address: stateFixed.addressMain
      //       },
      // ];

      // setAddressesInCookie(state, dispatch, cookies, setCookie, stateFixed.addressMain, cookiesNew);
    }

    return { status: 'fulfilled', body: response.body };
  }
  else {
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSearch[state.language] });
    return { status: 'rejected' };
  }
}

// HANDLE CLICK GET ACTIVATION HISTORY
async function handleClickGetActivationHistory(state, dispatch, pagingIndex) {
  let addressActual;
  let start;
  let end;
  let request = {};
  let messages = {};
  let certifications = [];
  const now = Date.now();

  // state固定
  const stateFixed = {
    action: state.activationList.action,
    addressMainEncrypted: state.activation.addressMainEncrypted,
    addressActivator: Object.keys(state.session)[0],
    sessionId: state.session[state.active.addressMain]?.sessionId,
    lastEvaluatedKey: pagingIndex >= 1 ? state.activationList.lastEvaluatedKey[state.activationList.action][pagingIndex - 1] : null,
    // lastEvaluatedSortKey: pagingIndex >= 1 ? state.activationList.lastEvaluatedKey[state.activationList.action][pagingIndex - 1]?.duelNo : null,
    // lastEvaluatedKey2: pagingIndex >= 1 ? state.activationList.lastEvaluatedKey2[state.activationList.action]?.[pagingIndex - 1] : null,
    // lastEvaluatedSortKey2: pagingIndex >= 1 ? state.activationList.lastEvaluatedKey2[state.activationList.action]?.[pagingIndex - 1]?.duelNo : null,
  };


  // -- 検索対象アドレスチェック
  if (stateFixed.action === 'activationHistoryAddressMainEncrypted') {
    if (stateFixed.addressMainEncrypted === undefined || stateFixed.addressMainEncrypted === null || stateFixed.addressMainEncrypted === '') {
      dispatch({ type: 'setNotification', key: 'notification', value: words.duelistAddressMustBeFilled[state.language] });
      return { status: 'rejected' };
    }
  }


  request.body = JSON.stringify({
    body: {
      action: stateFixed.action,
      authenticationMethod: 'sessionId',
      addressMainEncrypted: stateFixed.addressMainEncrypted,
      // lastEvaluatedSortKey: stateFixed.lastEvaluatedSortKey,
      // lastEvaluatedKey2: stateFixed.lastEvaluatedKey2,
      // lastEvaluatedSortKey2: stateFixed.lastEvaluatedSortKey2,
      certifications: [
        {
          address: stateFixed.addressActivator,
          sessionId: stateFixed.sessionId,
          lastEvaluatedKey: stateFixed.lastEvaluatedKey,
        },
      ],
    },
  });

  request.url = 'https://' + process.env.REACT_APP_KOM_API_DOMAIN + '/get_history';
  request.method = 'POST';

  messages = {
  };

  dispatch({ type: 'setState', key: 'accessing', value: true });

  const response = await syncHttpRequest2(request, undefined, undefined, undefined, messages, dispatch);
  dispatch({ type: 'setStateMultiLayers', keys: ['activationList', stateFixed.action], value: response.body[stateFixed.addressActivator] });

  dispatch({ type: 'setState', key: 'accessing', value: false });

  if ( response.status === 'fulfilled' ) {

    if (response.body[stateFixed.addressActivator].lastEvaluatedKey !== undefined) {
      dispatch({ type: 'setStateMultiLayers', keys: ['activationList', 'lastEvaluatedKey', stateFixed.action, pagingIndex], value: response.body[stateFixed.addressActivator].lastEvaluatedKey });
    }
    else {
      dispatch({ type: 'setStateMultiLayers', keys: ['activationList', 'lastEvaluatedKey', stateFixed.action, pagingIndex], value: null });
    }

    // if (response.body.lastEvaluatedKey2 !== undefined) {
    //   dispatch({ type: 'setStateMultiLayers', keys: ['activationList', 'lastEvaluatedKey2', stateFixed.action, pagingIndex], value: response.body.lastEvaluatedKey2 });
    // }
    // else {
    //   dispatch({ type: 'setStateMultiLayers', keys: ['activationList', 'lastEvaluatedKey2', stateFixed.action, pagingIndex], value: null });
    // }

    dispatch({ type: 'setStateMultiLayers', keys: ['activationList', 'pagingIndex', stateFixed.action], value: pagingIndex });

    // // cookie情報更新(アドレス情報)
    // const userName = state.usersGeneralIndex[stateFixed.addressMain] !== undefined ? state.usersGeneralIndex[stateFixed.addressMain].userName : stateFixed.addressMain;
    // const cookiesNew = [
    //       {
    //         action: 'searchPurchaseHistory',
    //         addressType: 'addressMain',
    //         description: `${userName}`,
    //         time: now,
    //         address: stateFixed.addressMain
    //       },
    // ];

    // setAddressesInCookie(state, dispatch, cookies, setCookie, stateFixed.addressMain, cookiesNew);

    return { status: 'fulfilled', body: response.body };
  }
  else {
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSearch[state.language] });
    return { status: 'rejected' };
  }
}

// MAKE LIVE TEXT URL FOR A BROADCASTER
async function makeLiveTextUrlForABroadcaster(state, dispatch, keyPairs) {
  const clientTime = new Date();
  const signatureVersion = state.config.clientParameters.signatureVersion.makeUrlToBroadcast;
  const addressMain = state.makeUrlToBroadcast.addressMain;
  let addressOperator;
  let signature;

  if (keyPairs !== undefined && keyPairs !== null) {
    const keyPair = keyPairs[0];
    let result;

    result = getAddressFromKey(state, dispatch, keyPair);

    if (result.status === 'fulfilled') {
      addressOperator = result.address;
    }
    else {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToGetTheAddress[state.language] });
      return { status: 'rejected' };
    }

    const message = `I want to receive the Knights of Monadom live of ${addressMain}. (epoch time: ${clientTime.getTime()}, signature version: ${signatureVersion})`;

    result = signWithKey(state, dispatch, keyPair, message);

    if (result.status === 'fulfilled') {
      signature = result.signature;
    }
    else {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSign[state.language] });
      return 'rejected';
    }
  }
  else {
    let result;

    result = await window.mpurse.getAddress()
    .then( (result) => {
      addressOperator = result;
      return 'fulfilled';
    })
    .catch( (error) => {
      dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressInYourWalletCanNotBeGotten[state.language] });
      return 'rejected';
    });

    if (result === 'rejected') {
      return { status: 'rejected' };
    }

    const message = `I want to receive the Knights of Monadom live of ${addressMain}. (epoch time: ${clientTime.getTime()}, signature version: ${signatureVersion})`;

    // 署名
    result = await window.mpurse.signMessage(message)
    .then( result => {
      signature = result;
      return 'fulfilled';
    })
    .catch( err => {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSign[state.language] });
      return 'rejected';
    });

    if (result === 'rejected') {
      return { status: 'rejected' };
    }
  }

  dispatch({ type: 'setStateMultiLayers', keys: ['makeUrlToBroadcast', 'addressOperator'], value: addressOperator });
  dispatch({ type: 'setStateMultiLayers', keys: ['makeUrlToBroadcast', 'signature'], value: signature });
  dispatch({ type: 'setStateMultiLayers', keys: ['makeUrlToBroadcast', 'clientTime'], value: clientTime.getTime() });

  return { status: 'fulfilled' };
}

// DISCONNECT CONNECTION FOR A BROADCASTER
async function disconnectConnectionForABroadcaster(state, dispatch, keyPairs) {
  const clientTime = new Date();
  const signatureVersion = state.config.clientParameters.signatureVersion.makeUrlToBroadcast;
  const addressMain = state.makeUrlToBroadcast.addressMain;
  let addressOperator;
  let signature;

  if (keyPairs !== undefined && keyPairs !== null) {
    const keyPair = keyPairs[0];
    let result;

    result = getAddressFromKey(state, dispatch, keyPair);

    if (result.status === 'fulfilled') {
      addressOperator = result.address;
    }
    else {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToGetTheAddress[state.language] });
      return { status: 'rejected' };
    }

    const message = `I want to receive the Knights of Monadom live of ${addressMain}. (epoch time: ${clientTime.getTime()}, signature version: ${signatureVersion})`;

    result = signWithKey(state, dispatch, keyPair, message);

    if (result.status === 'fulfilled') {
      signature = result.signature;
    }
    else {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSign[state.language] });
      return { status: 'rejected' };
    }
  }
  else {
    let result;

    result = await window.mpurse.getAddress()
    .then( (result) => {
      addressOperator = result;
      return 'fulfilled';
    })
    .catch( (error) => {
      dispatch({ type: 'setNotification', key: 'notification', value: words.theAddressInYourWalletCanNotBeGotten[state.language] });
      return 'rejected';
    });

    if (result === 'rejected') {
      return { status: 'rejected' };
    }

    const message = `I want to receive the Knights of Monadom live of ${addressMain}. (epoch time: ${clientTime.getTime()}, signature version: ${signatureVersion})`;

    // 署名
    result = await window.mpurse.signMessage(message)
    .then( result => {
      signature = result;
      return 'fulfilled';
    })
    .catch( err => {
      dispatch({ type: 'setNotification', key: 'notification', value: words.failedToSign[state.language] });
      return 'rejected';
    });

    if (result === 'rejected') {
      return { status: 'rejected' };
    }
  }


  const websocketClient = new ReconnectingWebSocket('wss://' + process.env.REACT_APP_KOM_WEBSOCKET_DOMAIN);

  const requestBody = {
    action: 'connectToBroadcaster',
    subAction: 'disconnect',
    addressMain: addressMain,
    addressOperator: addressOperator,
    clientTime: clientTime.getTime(),
    signatureVersion: signatureVersion,
    signature: signature,
  };

  websocketClient?.send(JSON.stringify(requestBody));

  const onMessage = (event) => {
    try {
      const data = JSON.parse(event.data);

      if (data.applicationStatus === '200') {
        dispatch({ type: 'setNotification', key: 'notification', value: words.success[state.language] });
      }
      else {
        dispatch({ type: 'setNotification', key: 'notification', value: data.applicationMessage });
      }
    }
    catch (error) {
      // エラー通知
      devLog('irregular WebSocket data.', error);
    }

    websocketClient?.close();
    devLog('websocket closed.');
  };

  websocketClient.addEventListener('message', onMessage);
}

// HANDLE CLICK DISLPAY MNEMONIC
function handleClickDisplayMnemonic(state, dispatch, mnemonics) {
  dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonic'], value: mnemonics[0] });
  dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonicHidden'], value: mnemonics[0] });

  return;
}

// HANDLE CLICK CLEAR MNEMONIC
function handleClickClearMnemonic(state, dispatch) {
  dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonic'], value: '' });

  return;
}

// HANDLE CLICK DISLPAY WIF
function handleClickDisplayWif(state, dispatch, mnemonics, keyPairs) {
  dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonicHidden'], value: mnemonics[0] });
  dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'wif'], value: keyPairs[0].toWIF() });

  return;
}

// HANDLE CLICK CLEAR WIF
function handleClickClearWif(state, dispatch) {
  dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'wif'], value: '' });

  return;
}

// HANDLE CLICK DISLPAY ADDRESS
function handleClickDisplayAddress(state, dispatch, mnemonics, keyPairs) {
  let address;

  dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonicHidden'], value: mnemonics[0] });

  const result = getAddressFromKey(state, dispatch, keyPairs[0]);

  if (result.status === 'fulfilled') {
    address = result.address;
    dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'address'], value: address }); 
    return result;
  }
  else {
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToGetTheAddress[state.language] });
    return result;
  }
}

// HANDLE CLICK CLEAR ADDRESS
function handleClickClearAddress(state, dispatch) {
  dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'address'], value: '' });

  return;
}

// HANDLE CLICK SWAP MNEMONICS
async function handleClickSwapMnemonics(state, dispatch) {
  const swapStatus = localStorage.getItem('swapStatus');
  // const oldAddress = localStorage.getItem('oldAddress');
  const oldMnemonic = localStorage.getItem('oldMnemonic');
  const oldKeyPair = getKeyPairsFromMnemonic(oldMnemonic)[0];
  const newAddress = localStorage.getItem('newAddress');
  const newMnemonic = localStorage.getItem('newMnemonic');
  const sweepAssetsTxid = localStorage.getItem('sweepAssetsTxid');
  const sweepMonaTxid = localStorage.getItem('sweepMonaTxid');

  // devLog('swapStatus', swapStatus);
  dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'swapMnemonics'], value: swapStatus });

  if (swapStatus === undefined || swapStatus === null) {
    // ニュートラル状態だから、はじめるよ！
    // まず新しいニーモニックを生成して、それと古いニーモニックをローカルストレージに保存するよ。
    // localStorage.setItem('swapStatus', 'keepingMnemonics');
    dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'swapMnemonics'], value: 'keepingMnemonics' }); // 暫定

    const callback = (mnemonics, keyPairs) => keepMnemonics(state, dispatch, mnemonics, keyPairs);
    handleClickNfc(state, dispatch, callback, { args: ['mnemonics', 'keyPairs'] });
  }
  else if (swapStatus === 'keptMnemonics') {
    // トークンをスウィープするよ！
    // localStorage.setItem('swapStatus', 'transfering');
    dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'swapMnemonics'], value: 'transfering' }); // 暫定

    const result = await sweepAssets(state, dispatch, oldKeyPair, newAddress);

    if (result.status === 'fulfilled') {
      const txid = result.body;
      localStorage.setItem('sweepAssetsTxid', txid);
      localStorage.setItem('swapStatus', 'sweepingAssets');
      dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonic'], value: 'sweepAssetsTxid ' + txid }); // 暫定
    }
    else {
      dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonic'], value: 'sweep assets; rejected ' + JSON.stringify(result.error) }); // 暫定
      throw new Error(result.error);
    }
  }
  else if (swapStatus === 'sweepingAssets') {
    // トークンスウィープが承認されたかどうかチェックするよ！
    let confirmations;
    dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'swapMnemonics'], value: 'transfered' }); // 暫定

    const result = await getTransaction(state, sweepAssetsTxid);

    if (result.status === 'fulfilled') {
      confirmations = result.body.confirmations;
      dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonic'], value: 'confirmations of sweep assets ' + confirmations }); // 暫定
    }
    else {
      dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonic'], value: 'sweep assets; rejected ' + JSON.stringify(result.error) }); // 暫定
      throw new Error(result.error);
    }

    if (confirmations >= 1) {
      localStorage.setItem('swapStatus', 'sweptAssets');
    }
    else {
      return {
        status: 'rejected',
        error: 'not confirmed yet.',
      };
    }

    // MONA移転するぞ！
    handleClickSwapMnemonics(state, dispatch);
  }
  else if (swapStatus === 'sweptAssets') {
    // トークンスウィープが完了したので、MONA移転するよ！
    dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'swapMnemonics'], value: 'transfering MONA' }); // 暫定

    const result = await sweepMona(state, dispatch, oldKeyPair, newAddress);

    if (result.status === 'fulfilled') {
      const txid = result.body;
      localStorage.setItem('sweepMonaTxid', txid);
      localStorage.setItem('swapStatus', 'sweepingMona');
      dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonic'], value: 'sweepMonaTxid ' + txid }); // 暫定
    }
    else {
      dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonic'], value: 'sweep MONA; rejected ' + JSON.stringify(result.error) }); // 暫定
      throw new Error(result.error);
    }

  }
  else if (swapStatus === 'sweepingMona') {
    // MONA移転が承認されたかどうかチェックするよ！
    let confirmations;
    dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'swapMnemonics'], value: 'transfered' }); // 暫定

    const result = await getTransaction(state, sweepMonaTxid);

    if (result.status === 'fulfilled') {
      confirmations = result.body.confirmations;
      dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonic'], value: 'confirmations of send MONA ' + confirmations }); // 暫定
    }
    else {
      dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonic'], value: 'sweep assets; rejected ' + JSON.stringify(result.error) }); // 暫定
      throw new Error(result.error);
    }

    if (confirmations >= 1) {
      localStorage.setItem('swapStatus', 'sweptMona');
    }
    else {
      return {
        status: 'rejected',
        error: 'not confirmed yet.',
      };
    }

    // 移転完了NFCタグに書き込むぞ！

    handleClickSwapMnemonics(state, dispatch);
  }
  else if (swapStatus === 'sweptMona') {
    // MONA移転が完了したので、NFCタグ書き換えるよ！
    let newMnemonicEncrypted;

    // localStorage.setItem('swapStatus', 'transfered');
    dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'swapMnemonics'], value: 'transfered' }); // 暫定

    const password = state.config.clientParameters.specialMessage;
    const salt = ['00', '00', '00', '00', '00', '00', '00', '72'];
    const iv = ['00', '00', '00', '00', '00', '00', '00', '00', '00', '00', '00', '00', '00', '01', 'bf', '52'];

    try {
      newMnemonicEncrypted = await encrypt(state, dispatch, newMnemonic, password, salt, iv);
      dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonic'], value: 'new mnemonic encrypted ' + newMnemonicEncrypted }); // 暫定
      await sleep(10000);
    }
    catch (error) {
      dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonic'], value: 'new mnemonic encrypted; rejected ' + error }); // 暫定
      throw new Error(error);
    }

    try {
      await writeMnemonicOnNfc(state, dispatch, newMnemonicEncrypted);
      localStorage.setItem('swapStatus', 'wroteMnemonic');
      dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonic'], value: 'write mnemonic succeeded.' }); // 暫定
    }
    catch (error) {
      dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonic'], value: 'write mnemonic; rejected ' + error }); // 暫定
      throw new Error(error);
    }

    // NFCタグちゃんと書き込まれたかチェックするぞ！

    handleClickSwapMnemonics(state, dispatch);
  }
  else if (swapStatus === 'wroteMnemonic') {
    // NFCタグ書き込みが完了したので、ちゃんと書き込まれたかチェックするよ！

    // localStorage.setItem('swapStatus', 'transfered');
    dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'swapMnemonics'], value: 'transfered' }); // 暫定

    const callback = (mnemonics) => checkMnemonicOnNfc(state, dispatch, newMnemonic, mnemonics);
    handleClickNfc(state, dispatch, callback, { args: ['mnemonics'] });
  }
  else if (swapStatus === 'transfered') {
    localStorage.removeItem('swapStatus');
    dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'swapMnemonics'], value: '' }); // 暫定
  }
  else if (swapStatus === 'keepingMnemonics' || swapStatus === 'transfering') {
    localStorage.removeItem('swapStatus');
    dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'swapMnemonics'], value: '' });
  }
  else {
    devLog('swapStatus', swapStatus);
  }

  // if (swapStatus === undefined || swapStatus === null) {
  //   localStorage.setItem('swapStatus', 'readOldMnemonic');
  // }
  // else if (swapStatus === 'readOldMnemonic') {
  //   localStorage.setItem('swapStatus', 'keptMnemonics');
  // }
  // else if (swapStatus === 'keptMnemonics') {
  //   localStorage.setItem('swapStatus', 'sentToken');
  // }
  // else if (swapStatus === 'sentToken') {
  //   localStorage.setItem('swapStatus', 'sentMona');
  // }
  // else if (swapStatus === 'sentMona') {
  //   localStorage.setItem('swapStatus', 'confirmSentToken');
  // }
  // else if (swapStatus === 'confirmSentToken') {
  //   localStorage.setItem('swapStatus', 'confirmSentMona');
  // }
  // else if (swapStatus === 'confirmSentMona') {
  //   localStorage.setItem('swapStatus', 'transferMonatokaPoint');
  // }
  // else if (swapStatus === 'transferMonatokaPoint') {
  //   localStorage.removeItem('swapStatus');
  // }
  // else {
  //   devLog('swapStatus', swapStatus);
  // }
}

// KEEP MNEMONICS
function keepMnemonics(state, dispatch, oldMnemonics, oldKeyPairs) {
  let oldAddress;
  let newMnemonic;
  let newAddress;
  let result;

  // 旧アドレス取得

  result = getAddressFromKey(state, dispatch, oldKeyPairs[0]);

  if (result.status === 'fulfilled') {
    oldAddress = result.address;
  }
  else {
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToGetTheAddress[state.language] });
    return 'rejected';
  }

  // 新ニーモニック生成

  result = handleClickGenerateMnemonic(state, dispatch);

  if (result.status === 'fulfilled') {
    newMnemonic = result.mnemonic;
    newAddress = result.address;
  }
  else {
    dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'swapMnemonics'], value: 'error keepMnemonics' });
    return;
  }

  localStorage.setItem('oldMnemonic', oldMnemonics[0]);
  localStorage.setItem('oldKeyPair', oldKeyPairs[0]);
  localStorage.setItem('oldAddress', oldAddress);
  localStorage.setItem('newMnemonic', newMnemonic);
  localStorage.setItem('newAddress', newAddress);
  localStorage.setItem('swapStatus', 'keptMnemonics');

  dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonic'], value: localStorage.getItem('oldMnemonic') + ' : ' + newMnemonic + ' : ' + localStorage.getItem('oldKeyPair') }); // 暫定

  // 移転するぞ！

  // handleClickSwapMnemonics(state, dispatch);
}

// SWEEP ASSETS
async function sweepAssets(state, dispatch, oldKeyPair, newAddress) {
  let oldAddress;
  let balanceAssets;
  let ownedAssets;
  let sweepAssetsTxid;
  let result;

  dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonic'], value: 'tra tra' + oldKeyPair.privateKey }); // 暫定

  // 旧アドレス取得

  result = getAddressFromKey(state, dispatch, oldKeyPair);

  if (result.status === 'fulfilled') {
    oldAddress = result.address;
  }
  else {
    dispatch({ type: 'setNotification', key: 'notification', value: words.failedToGetTheAddress[state.language] });
    throw new Error(result);
  }

  // モナパトークン保有チェック

  result = await getBalances(state, dispatch, oldAddress)

  if (result.status === 'fulfilled') {
    balanceAssets = result.bodyArray;
  }
  else {
    dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonic'], value: 'balance' + JSON.stringify(result.error) }); // 暫定
    throw new Error(result);
  }

  // モナパトークンオウンチェック

  result = await getOwnedAssets(state, dispatch, oldAddress)

  if (result.status === 'fulfilled') {
    ownedAssets = result.bodyArray;
  }
  else {
    dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonic'], value: 'own' + JSON.stringify(result.error) }); // 暫定
    throw new Error(result);
  }

  dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonic'], value: `balance: ${balanceAssets.length}, own: ${ownedAssets.length}` }); // 暫定

  if (balanceAssets.length > 0 || ownedAssets.length > 0) {
    // モナパトークンsweep

    result = await sweepAssetsSequence(state, dispatch, oldKeyPair, newAddress);

    if (result.status === 'fulfilled') {
      sweepAssetsTxid = result.body;
    }
    else {
      dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonic'], value: 'sweep assets ' + JSON.stringify(result.error) }); // 暫定
      throw new Error(result);
    }
  }
  else {
    dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonic'], value: 'no asset' }); // 暫定

    return {
      status: 'rejected',
      error: 'no asset',
    };
  }

  return {
    status: 'fulfilled',
    body: sweepAssetsTxid,
  };
}

// HANDLE CLICK DISLPAY WIF FROM MNEMONIC FILLED IN
function handleClickDisplayWifFromMnemonicFilledIn(state, dispatch) {
  const mnemonicFilledIn = state.displaySecret.mnemonicToWif;

  if (!bip39.validateMnemonic(mnemonicFilledIn)) {
    dispatch({ type: 'setNotification', key: 'notification', value: words.invalidMnemonic[state.language] });
    dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'wifFromMnemonic'], value: '' });

    throw new Error('invalidMnemonic');
  }

  const keyPairs = getKeyPairsFromMnemonic(mnemonicFilledIn);
  dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'wifFromMnemonic'], value: keyPairs[0].toWIF() });

  return;
}

// HANDLE CLICK CLEAR MNEMONIC AND WIF
function handleClickClearMnemonicAndWif(state, dispatch) {
  dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'mnemonicToWif'], value: '' });
  dispatch({ type: 'setStateMultiLayers', keys: ['displaySecret', 'wifFromMnemonic'], value: '' });

  return;
}


async function getConfig(dispatch, project, keys, stateKey) {
  let request = {};
  let messages = {};

  request.body = JSON.stringify({
    body: {
      project: project,
      keys: keys,
    }
  });

  request.url = 'https://' + process.env.REACT_APP_KOM_API_DOMAIN + '/get_client_parameter';
  request.method = 'POST';

  messages = {
  };

  const response = await syncHttpRequest2(request, stateKey, undefined, undefined, messages, dispatch);

  if ( response.status === 'fulfilled' ) {
    return {
      status: 'fulfilled',
      body: response,
    };
  }
  else {
    return {
      status: 'rejected',
    };
  }
}

// MAGIC NARRATION
function magicNarration(state, magics, initiative, monsterAssets) {
  let text = '';
  let monsterName = {};

  if (monsterAssets === undefined) {
    return text;
  }

  // -- 同キャラ対決のとき名前に色を付ける。
  if (monsterAssets.A === monsterAssets.B) {
    monsterName.A = state.registeredCard[monsterAssets.A]?.name + '(' + sideColor('A') + ')';
    monsterName.B = state.registeredCard[monsterAssets.B]?.name + '(' + sideColor('B') + ')';
  }
  else {
    monsterName.A = state.registeredCard[monsterAssets.A]?.name;
    monsterName.B = state.registeredCard[monsterAssets.B]?.name;
  }

  for (const side of [initiative, otherSide(initiative)]) {
    const magic = magics?.[side]?.[0];

    if (magic !== undefined) {
      text += monsterName[side] + 'は ' + state.registeredCard[magic.asset].name + ' を発動した。\n';
      // text += state.duel.basicInformation.duelistUserName[side] + 'は ' + state.registeredCard[magic.asset].name + ' を発動した。\n';

      if (magic.status === 'canceled') {
        text += 'しかし打ち消された。\n';
      }
    }
  }

  if (text !== '') {
    text += '\n';
  }

  return text;
}

// CARD STATUS
function getCardStatus(state, side, index) {
  let cardStatus;

  if (state.duel.result !== undefined && side !== undefined && side !== null && index !== undefined && index !== null) {
    cardStatus = state.duel.result[state.duel.result.length - 1]?.cards[side][index].status;
  }

  return cardStatus;
}

// CARD STATUS TMP
function getCardStatusTmp(state, side, index) {
  let cardStatusTmp;

  if (state.duel.cardStatusTmp !== undefined && side !== undefined && side !== null && index !== undefined && index !== null) {
    const status = state.duel.cardStatusTmp[side]?.[index]?.status;

    if (status !== undefined) {
      cardStatusTmp = status;
    }
    else {
      cardStatusTmp = 'available'
    }
  }

  return cardStatusTmp;
}

// GET ACTIVATION LIST
async function getActivationList() {
  return;
}

// ACTIVATE MONATOKA POINT
async function handleClickActivateMonatokaPoint(state, dispatch) {
  // let signature;
  let request = {};
  let messages = {};
  // let result;

  // state固定
  const stateFixed = {
    addressMainEncrypted: state.activation.addressMainEncrypted,
    addressActivator: Object.keys(state.session)[0],
    sessionId: Object.values(state.session)[0].sessionId,
    // signatureVersion: state.config.clientParameters.signatureVersion.applyForCardRegistration,
    // addressCheck: state.addressCheck,
  };

  // 必須項目チェック
  if (stateFixed.addressMainEncrypted === undefined || stateFixed.addressMainEncrypted === null || stateFixed.addressMainEncrypted === '') {
    dispatch({ type: 'setNotification', key: 'notification', value: words.activationCode[state.language] });
    return;
  }

  request.body = JSON.stringify({
    body: {
      addressMainEncrypted: stateFixed.addressMainEncrypted,
      addressActivator: stateFixed.addressActivator,
      sessionId: stateFixed.sessionId,
    }
  });

  request.headers = {};
  request.url = 'https://' + process.env.REACT_APP_KOM_API_DOMAIN + '/activate_monatoka_point';
  request.method = 'POST';

  messages = {
    // "you must accept the terms and conditions of the current version.": words.youMustAcceptTheTermsAndConditionsOfTheCurrentVersion[state.language],
    "internal server error": words.internalServerError[state.language],
    // "bad signature": words.badSignature[state.language],
    // "bad signature, can't recover address": words.badSignatureCanNotRecoverAddress[state.language],
    // "unknown signatureVersion": words.unknownSignatureVersion[state.language],
    // "clientTime must be larger than last time.": words.clientTimeMustBeLargerThanLastTime[state.language],
    "this address is not permitted.": words.thisAddressIsNotPermitted[state.language],
    "this address is forbidden.": words.thisAddressIsForbidden[state.language],
    "out of service": words.outOfService[state.language],
    // "signature by asset owner is required.": words.signatureByAssetOwnerIsRequired[state.language],
    // "asset is required; not asset_longname.": words.assetIsRequiredNotAssetLongname[state.language],
    // "No such token exists.": words.noSuchTokenExists[state.language],
    // "you are not delegated.": words.norIsTheSignatureDelegatedFromMainAddress[state.language],
    // "addressMainActual must be filled.": words.clearYourBrowserOrAppCache[state.language],
    "success": words.success[state.language],
  };

  dispatch({ type: 'setState', key: 'accessing', value: true });

  const response = await syncHttpRequest2(request, undefined, undefined, 'notification', messages, dispatch);

  dispatch({ type: 'setState', key: 'accessing', value: false });

  if ( response.status === 'fulfilled' ) {
    // // cookie情報更新(アドレス情報)
    // const userName = state.usersGeneralIndex[stateFixed.addressMain] !== undefined ? state.usersGeneralIndex[stateFixed.addressMain].userName : stateFixed.addressMain;
    // const cookiesNew = [
    //       {
    //         action: 'setUp',
    //         addressType: 'addressMain',
    //         description: `${userName}`,
    //         time: clientTime,
    //         address: stateFixed.addressMain
    //       },
    // ];

    // setAddressesInCookie(state, dispatch, cookies, setCookie, stateFixed.addressMain, cookiesNew);
  }
  else {
  }

  return response;
}

// SEND LOG
async function sendLog(log) {
  let request = {};
  let messages = {};

  request.body = JSON.stringify({
    body: log
  });

  request.url = 'https://' + process.env.REACT_APP_KOM_API_DOMAIN + '/notify';
  request.method = 'POST';

  messages = {
  };

  const response = await syncHttpRequest2(request, undefined, undefined, undefined, messages, null);
}


// ASSET COMMON
function assetCommon(state, asset) {
  return state.assetInfo[asset].asset_longname === null ? asset : state.assetInfo[asset].asset_longname;
}

// OTHER SIDE
function otherSide(side) {
  if (side === 'A') {
    return 'B';
  }
  else {
    return 'A';
  }
}

// SIDE COLOR
function sideColor(side) {
  if (side === 'A') {
    return '赤';
  }
  else {
    return '青';
  }
}

// BORDER COLOR
function borderColor(side) {
  if (side === 'A') {
    return 'borderColorA';
  }
  else {
    return 'borderColorB';
  }
}

// GET SELECT STATUS TYPE
function getSelectStatusType(state) {
  if (state.duel.selectStatus.mode === 'magic') {
    return state.duel.castMagics[state.duel.selectStatus.magicIndex].route[state.duel.selectStatus.routeIndex].type;
  }
  else { // monster
    return 'neutral';
  }
}


// HTTPリクエスト（同期バージョン2）
async function syncHttpRequest2(request, setResultKey, sortFunction, setNotificationKey, messages, dispatch) {
  if (request.headers === undefined) {
    request.headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    };
  }

  if (request.method === undefined) {
    request.method = 'POST';
  }

  /*
  const method = 'POST';
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  };
  */

  const result = await fetch(request.url, { method: request.method, headers: request.headers, body: request.body })
  .then( response => response.json() )
  .then( async json => {
           const applicationMessage = json.applicationMessage;

           devLog(applicationMessage, messages[applicationMessage], 'setNotificationKey', setNotificationKey);

           if (setNotificationKey !== undefined) {
             devLog('inner', setNotificationKey);
             dispatch({ type: 'setNotification', key: setNotificationKey,
                        value: (messages[applicationMessage] ? messages[applicationMessage] : applicationMessage) });  
           }

           if (json.applicationStatus.substring(0,1) === '2') {
             let body = json.body;

             if (setResultKey !== undefined) {
               devLog(setResultKey);
               devLog(JSON.stringify(body));

               if (sortFunction !== undefined) {
                 body.sort(sortFunction);
               }

               dispatch({ type: 'setState', key: setResultKey, value: body });
             }

             return { status: 'fulfilled', body: body, error: json.errors };
           }
           else {
             return { status: 'rejected', body: json.body };
           }
         }
  )
  .catch( error => {
           return { status: 'rejected', error: error };
          }
  );

  return result;
}

// HTTPリクエスト（同期バージョン）
async function syncHttpRequest(request) {
  if (request.headers === undefined) {
    request.headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    };
  }

  return await fetch(request.url, { method: request.method, headers: request.headers, body: request.body })
  .then( response => response.json() )
  .then( json => {
    devLog('syncHttpRequest fulfilled', JSON.stringify(json));
    return {
      status: 'fulfilled',
      body: json,
    };
  })
  .catch( error => {
    devLog('syncHttpRequest rejected', JSON.stringify(error));
    return {
      status: 'rejected',
      body: error,
      error: error,
    };
  });
}

function toFloat(string) {
  if (Number.isFinite(string) || string === Infinity) {
    return string;
  }

  const numericCharacter = string.replace(/[^\d.]/, '');
  let float = parseFloat(numericCharacter);

  if (Number.isNaN(float)) {
    float = ''
  }

  return float;
}

async function hasCamera() {
  try {
    const devices = await navigator.mediaDevices.enumerateDevices();
    return devices.some(device => device.kind === 'videoinput');
  } catch (error) {
    devLog("An error occurred:", error);
    return false;
  }
}

// SET EPOCH TIME
function setEpochTime(state, dispatch, keys, changedProperty, changedValue) {
  const time = keys.reduce( (acc, cur) => { return acc[cur]; }, state );

  const year = changedProperty === 'year' ? changedValue : time.year;
  const month = changedProperty === 'month' ? changedValue - 1 : time.month - 1;
  const day = changedProperty === 'day' ? changedValue : time.day;
  const hours = changedProperty === 'hours' ? changedValue : time.hours;
  const minutes = changedProperty === 'minutes' ? changedValue : time.minutes;
  const seconds = changedProperty === 'seconds' ? changedValue : time.seconds;
  const milliseconds = changedProperty === 'milliseconds' ? changedValue : time.milliseconds;

  const keysEpoch = keys.concat(['epoch']);

  dispatch({
    type: 'setStateMultiLayersNum',
    keys: keysEpoch,
    value: new Date(year, month, day, hours, minutes, seconds, milliseconds).getTime()
  });
}

// LOCAL TIME
function localTime(epoch, format) {
  const epochNum = parseInt(epoch, 10);
  let time;

  if (Number.isNaN(epochNum) || epochNum === '' || epochNum === undefined) {
    return '';
  }

  time = new Date(epochNum);

  if (format === 'yearToMinute') {
    return `${time.getFullYear()}/${(time.getMonth() + 1).toString().padStart(2, '0')}/${time.getDate().toString().padStart(2, '0')} ${time.getHours().toString().padStart(2, '0')}:${time.getMinutes().toString().padStart(2, '0')}`;
  }
  else if (format === 'yearToSecond') {
    return `${time.getFullYear()}/${(time.getMonth() + 1).toString().padStart(2, '0')}/${time.getDate().toString().padStart(2, '0')} ${time.getHours().toString().padStart(2, '0')}:${time.getMinutes().toString().padStart(2, '0')}:${time.getSeconds().toString().padStart(2, '0')}`;
  }
  else if (format === 'digit14Num') {
    return parseInt(`${time.getFullYear()}${(time.getMonth() + 1).toString().padStart(2, '0')}${time.getDate().toString().padStart(2, '0')}${time.getHours().toString().padStart(2, '0')}${time.getMinutes().toString().padStart(2, '0')}${time.getSeconds().toString().padStart(2, '0')}`, 10);
  }
  else {
    return `${time.getFullYear()}/${(time.getMonth() + 1).toString().padStart(2, '0')}/${time.getDate().toString().padStart(2, '0')} ${time.getHours().toString().padStart(2, '0')}:${time.getMinutes().toString().padStart(2, '0')}:${time.getSeconds().toString().padStart(2, '0')}.${time.getMilliseconds()}`;
  }
}

// MINUTES AND SECONDS
function minutesAndSeconds(milliseconds) {
  if (milliseconds <= 0) {
    return '00:00';
  }

  const seconds = Math.floor(milliseconds / 1000); 
  return Math.floor(seconds / 60).toString().padStart(2, '0') + ':' + (seconds % 60).toString().padStart(2, '0');
}

// SLEEP
function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

// DECIMAL ADJUST
function decimalAdjust(type, value, exp) {
  // If the exp is undefined or zero...
  if (typeof exp === 'undefined' || +exp === 0) {
    return Math[type](value);
  }
  // If value is Infinity...
  if (value === Infinity) {
    return Infinity;
  }
  value = +value;
  exp = +exp;
  // If the value is not a number or the exp is not an integer...
  if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
    return NaN;
  }
  // Shift
  value = value.toString().split('e');
  value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
  // Shift back
  value = value.toString().split('e');
  return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
}

// ADJUST MULTI LAYERS FLOAT
function adjustFloat(type, value, exp) {
  let number = parseFloat(value);

  if (Number.isNaN(number)) {
    number = ''
  }
  else if (type !== undefined) {
    number = decimalAdjust(type, number, exp);
  }

  return number;
}

// URL PARAMS STRINGIFY
function urlParamsStringify(urlParams) {
  return urlParams.reduce( (acc, cur) => {
    if (cur !== null) {
      return acc + (acc === '' ? '?' : '&') + cur;
    }
    else {
      return acc;
    }
  }, '' );
}

// ArrayBufferをBase64文字列に変換する関数
function arrayBufferToBase64(buffer) {
    // ArrayBufferからバイナリ文字列を生成
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    // window.btoa関数でバイナリ文字列をBase64にエンコード
    return window.btoa(binary);
}

// DEVLOG
function devLog(...words) {
  if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
    console.log(words.join(' '));
  }
  return 1;
}

// COPY TO CLIPBOARD
function copyToClipboard(text, textAreaRef) {
  if (textAreaRef.current) {
    textAreaRef.current.value = text;
    textAreaRef.current.select();
    document.execCommand('copy');
  }
}

// REDUCER
function reducer(state, action) {
  let newState = { ...state };
  let numericCharacter;
  let number;
  let amountMona;
  let amountJpyc;
  let isChanged;
  let items;
  let item;
  let newIndex;

  switch (action.type) {
    case 'setState':
      newState[action.key] = action.value;
      return newState;

    case 'setStateMultiLayers':
      action.keys.concat([action.value]).reduce( (acc, cur, idx, src) => {
        if (idx === src.length - 1) {
          return acc;
        }
        if (idx === src.length - 2) {
          return acc[cur] = src[idx + 1];
        }
        else {
          return acc[cur];
        }
      }, newState );
      return newState;

    case 'setStateNum':
      number = parseInt(action.value, 10);

      if (Number.isNaN(number)) {
        newState[action.key] = ''
      }
      else {
        newState[action.key] = number;
      }

      return newState;

    case 'setStateMultiLayersNum':
      number = parseInt(action.value, 10);

      if (Number.isNaN(number)) {
        number = ''
      }

      action.keys.reduce( (acc, cur, idx, src) => {
        if (idx === src.length - 1) {
          return acc[cur] = number;
        }
        else {
          return acc[cur];
        }
      }, newState );

      return newState;

    case 'setStateFloat':
      number = parseFloat(action.value);

      if (Number.isNaN(number)) {
        newState[action.key] = ''
      }
      else {
        newState[action.key] = number;
      }

      return newState;

    // setStateMultiLayersFloat
    case 'setStateMultiLayersFloat':
      numericCharacter = action.value.replace(/[^\d.]/, '');
      number = parseFloat(numericCharacter);

      if (Number.isNaN(number)) {
        number = ''
      }
      else if (action.adjustType !== undefined) {
        number = decimalAdjust(action.adjustType, number, action.adjustExp);
      }

      action.keys.reduce( (acc, cur, idx, src) => {
        if (idx === src.length - 1) {
          acc[cur].face = numericCharacter;
          acc[cur].value = number;
          return acc[cur];
        }
        else {
          return acc[cur];
        }
      }, newState );

      return newState;

    // adjustFace
    case 'adjustFace':
      action.keys.reduce( (acc, cur, idx, src) => {
        if (idx === src.length - 1) {
          acc[cur].face = acc[cur].value;
          return acc[cur];
        }
        else {
          return acc[cur];
        }
      }, newState );

      return newState;

    // pushOut
    case 'pushOut':
      const target = action.targetKeys.reduce( (acc, cur) => {
          return acc[cur];
      }, newState );

      action.keys.reduce( (acc, cur, idx, src) => {
        const pushedOut = target[cur];
        target[cur] = acc;
        return  pushedOut;
      }, action.value );

      return newState;

    case 'setStateIfChange':
      if (newState[action.key] != action.value) {
        newState[action.key] = action.value;
        return newState;
      }
      else {
        return state;
     }

    case 'setStateMultiLayersIfChange':
      isChanged = action.keys.concat([action.value]).reduce( (acc, cur, idx, src) => {
        if (idx === src.length - 1) {
          return acc;
        }
        if (idx === src.length - 2) {
          if (acc[cur] !== src[idx + 1]) {
            acc[cur] = src[idx + 1];
            return true;
          }
          else {
            return false;
          }
        }
        else {
          return acc[cur];
        }
      }, newState );

      if (isChanged) {
        return newState;
      }
      else {
        return state;
      }

    // setNotification
    case 'setNotification':
      newState[action.key].body.push(action.value);

      if (newState[action.key].body.length > state.config.clientParameters.notificationMaxLength) {
        newState[action.key].body.shift();
      }

      newState[action.key].index = newState[action.key].body.length -1;

      newState[action.key].inAnimation = true;

      return newState;

    case 'stopNotificationAnimation':
      newState[action.key].inAnimation = false;
      return newState;

    // itemReplace
    case 'itemReplace':
      items = newState.items[newState.gallery.addressMain].itemPlacement[0].items;
      item = items[action.index];

      if (action.direction === 'left') {
        newIndex = action.index - 1 >= 0 ? action.index -1 : items.length - 1;
      }
      else { // right
        newIndex = action.index + 1 <= items.length - 1 ? action.index + 1 : 0;
      }

      items.splice(action.index, 1);
      items.splice(newIndex, 0, item);

      return newState;

    // default
    default:
      return state;
  }
}

export default App;
